import React, { useState, useEffect } from 'react';
import { DataTable } from '@scuf/datatable';
import { Button, Checkbox, Icon } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import 'styles/hsl.scss';
import { RoleComponent } from 'components/roleComponent';
import useUser from 'helpers/hooks/useUser';
import ModalConfirmEditDetails from './modalConfirmEditDetails';
import { DataDetailDeliveryRow } from 'models/hsl/general';
import useFetch from 'helpers/hooks/useFetch';
import { HSL } from 'resources';

const CLIENTADMIN = '3';
const CLIENT = '4';

const DeliveryDetails: React.FC = () => {
    const { t } = useTranslation(['hsl']);
    const location = useLocation();
    const navigate = useNavigate();
    const selectedDelivery = location.state?.selectedDelivery;
    const { user: userDetails } = useUser();

    const [modalState, setModalState] = useState(false);
    const [modalType, setModalType] = useState<string | null>(null);
    const [data, setData] = useState<DataDetailDeliveryRow[]>([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const [, deleteDelivery, loadingDelete] = useFetch({
        url: HSL.deleteDelivery + `/${selectedDelivery?.Delivery}`,
        method: 'DELETE',
        onError: t('common:errors.Unexpected')
    });

    const [, updateItem, loadingUpdate] = useFetch<any, any>({
        url: HSL.updateReceivedItem,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (selectedDelivery.Items) {
            const detailsDelivery = selectedDelivery.Items.map((detail: DataDetailDeliveryRow) => ({
                ...detail,
                Delivery: selectedDelivery.Delivery
            }));
            setData(detailsDelivery);
        }
    }, [location.state]);

    const handleCheckboxChange = (index: number) => {
        const updatedData = [...data];
        updatedData[index].PartnerReceived = !updatedData[index].PartnerReceived;
        setData(updatedData);
        setIsSaveEnabled(updatedData.some(item => item.PartnerReceived));
    };

    const handleClick = (type: string) => {
        setModalType(type);
        setModalState(true);
    };

    const handleConfirm = async (type: string) => {
        if (type === 'receive') {
            const filteredData = data.filter(item => item.PartnerReceived === true && item.PartnerReceivedDate === null);

            if (filteredData.length > 0) {
                await updateItem(filteredData, () => {
                    navigate('../');
                });
            }
        } else {
            await deleteDelivery(null, resp => {
                if (resp.data === true) {
                    navigate('../');
                }
            });
        }
    };

    return (
        <div className="container">
            <div>
                <DataTable columnResizeMode="fit" data={data}>
                    <DataTable.Column field="Delivery" align="center" header={t('Delivery')} sortable={true} />
                    <DataTable.Column
                        field="PartNumber"
                        align="center"
                        header={t('common:translation.PartNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="PartNumberDescription"
                        align="center"
                        header={t('common:translation.Description')}
                        sortable={true}
                    />
                    <DataTable.Column field="Quantity" align="center" header={t('hsl:translation.Quantity')} sortable={true} />
                    {selectedDelivery.BillofladingApprovedDate ? (
                        <DataTable.Column
                            field="PartnerReceived"
                            renderer={rowData => (
                                <>
                                    <span>{data[rowData.rowIndex].PartnerReceived}</span>
                                    <Checkbox
                                        defaultChecked={data[rowData.rowIndex].PartnerReceived}
                                        checked={!!data[rowData.rowIndex].PartnerReceived}
                                        onChange={() => handleCheckboxChange(rowData.rowIndex)}
                                        disabled={
                                            !!data[rowData.rowIndex].PartnerReceivedDate ||
                                            !(userDetails?.ROLE === CLIENTADMIN || userDetails?.ROLE === CLIENT)
                                        }
                                    />
                                </>
                            )}
                            align="center"
                            header="OK"
                        />
                    ) : (
                        []
                    )}
                </DataTable>
                <div className="button-hsl">
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Button
                            content={t('hsl:translation.Delete')}
                            type="primary"
                            size="medium"
                            loading={loadingDelete}
                            iconPosition="right"
                            icon={<Icon name="delete" root="building" />}
                            onClick={() => handleClick('delete')}
                            description={t('hsl:translation.Delete')}
                        />
                    </RoleComponent>
                    <RoleComponent roles={['CLIENTADMIN', 'CLIENT']}>
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            size="medium"
                            loading={loadingUpdate}
                            iconPosition="right"
                            icon={<Icon name="add" root="building" />}
                            onClick={() => handleClick('receive')}
                            description={t('common:actions.Save')}
                            disabled={!isSaveEnabled}
                        />
                    </RoleComponent>
                </div>
            </div>
            <ModalConfirmEditDetails
                isOpen={modalState}
                onClose={() => setModalState(false)}
                onSubmit={type => handleConfirm(type)}
                type={modalType ?? ''}
            />
        </div>
    );
};

export default DeliveryDetails;
