import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Select, Button, DatePicker, Icon, Modal } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { useTranslation } from 'react-i18next';
import 'styles/hsl.scss';
import useUser from 'helpers/hooks/useUser';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import useFetch from 'helpers/hooks/useFetch';
import { HSL, notificationAtom } from 'resources';
import { useSetRecoilState } from 'recoil';
import { adjustDatesToFrom, formatDate } from 'helpers';
import { FilterLogInventoryDTO } from 'models/hsl/general';

const CLIENTADMIN = '1';
const CLIENT = '2';
const InitialFilter = {
    ItemInventoryId: '',
    RegisterDate: '',
    Observations: '',
    TrackingNumber: '',
    Description: '',
    Action: '',
    ResponsibleName: '',
    DateStart: '',
    DateEnd: '',
    PageIndex: 1,
    PageSize: 5
};

const InventoryDetails: React.FC = () => {
    const { t } = useTranslation(['common', 'hsl', 'repaircenter']);
    const { id } = useParams<{ id: string }>();
    const setNotification = useSetRecoilState(notificationAtom);
    const { user: userDetails } = useUser();
    const [openModal, setOpenModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [filters, setFilters] = useState<FilterLogInventoryDTO>(InitialFilter);
    const [editRow, setEditRow] = useState<{ TrackingNumber: string; Observation: string }>();

    const [, getActions, loadingAction] = useFetch<FilterLogInventoryDTO, any>({
        url: HSL.getActions,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, updateInfoLog, loadingUpdate] = useFetch<any, any>({
        url: HSL.updateLogInventoryInfo,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getFilteredActions(filters);
    }, [id]);

    const getFilteredActions = async (filter: FilterLogInventoryDTO) => {
        const actualFilter = { ...filter, ItemInventoryId: id };
        await getActions(actualFilter, (current: any) => {
            if (current.data.Data) {
                setFilteredData(current.data.Data);
                setTotalItems(current.data.TotalCount);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getFilteredActions(InitialFilter);
    };

    const applyFilters = () => {
        getFilteredActions(filters);
    };

    const handleEditClick = (rowData: ICellData) => {
        setEditRow(filteredData[rowData.rowIndex]);
        setOpenModal(true);
    };

    const handleSave = () => {
        if (editRow) {
            updateInfoLog(editRow, () => {
                getFilteredActions(filters);
            });
            setOpenModal(false);
        }
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <div className="form4Columns">
                    <Input
                        placeholder={t('repaircenter:translation.Responsible')}
                        label={t('repaircenter:translation.Responsible')}
                        value={filters.ResponsibleName}
                        onChange={value => setFilters({ ...filters, ResponsibleName: value })}
                    />
                    <Select
                        placeholder={t('common:translation.Action')}
                        label={t('common:translation.Action')}
                        options={[
                            { text: t('hsl:translation.Entry'), value: 'Entry' },
                            { text: t('hsl:translation.Exit'), value: 'Exit' }
                        ]}
                        value={filters.Action}
                        onChange={value => setFilters({ ...filters, Action: value })}
                    />
                    <Input
                        placeholder={t('common:translation.Description')}
                        label={t('common:translation.Description')}
                        value={filters.Description}
                        onChange={value => setFilters({ ...filters, Description: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.TrackingNumber')}
                        label={t('hsl:translation.TrackingNumber')}
                        value={filters.TrackingNumber}
                        onChange={value => setFilters({ ...filters, TrackingNumber: value })}
                    />
                </div>
                <div className="form4Columns">
                    <Input
                        placeholder={t('hsl:translation.Observations')}
                        label={t('hsl:translation.Observations')}
                        type="number"
                        value={filters.Observations}
                        onChange={value => setFilters({ ...filters, Observations: value })}
                    />
                    <DatePicker
                        type="daterange"
                        data-id="unique-datepicker"
                        label={t('hsl:translation.RegistrationDate')}
                        rangeValue={{ to: filters?.DateEnd, from: filters?.DateStart }}
                        displayFormat={'DD-MM-YYYY'}
                        closeOnSelection={true}
                        closeOnDocumentClick={true}
                        onRangeSelect={({ to, from }) => {
                            const { DateStart, DateEnd } = adjustDatesToFrom(from, to);
                            setFilters({ ...filters, DateStart: DateStart, DateEnd: DateEnd });
                        }}
                    />
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const renderModal = () => {
        return (
            <Modal open={openModal} closeIcon={true} onClose={() => setOpenModal(false)}>
                <Modal.Header>{t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form3Columns">
                        <Input
                            placeholder={t('hsl:translation.TrackingNumber')}
                            label={t('hsl:translation.TrackingNumber')}
                            value={editRow?.TrackingNumber || ''}
                            onChange={value => setEditRow({ ...editRow!, TrackingNumber: value })}
                        />
                        <Input
                            placeholder={t('hsl:translation.Observations')}
                            label={t('hsl:translation.Observations')}
                            value={editRow?.Observation || ''}
                            onChange={value => setEditRow({ ...editRow!, Observation: value })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button content={t('common:actions.Save')} type="primary" onClick={handleSave} loading={loadingUpdate} />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableInventoryRender = () => {
        return (
            <div className="Table-inventory-log">
                <DataTable columnResizeMode="fit" data={filteredData} loading={loadingAction}>
                    <DataTable.Column
                        field="ProductName"
                        align="center"
                        header={t('common:translation.Product')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="PartNumber"
                        align="center"
                        header={t('common:translation.PartNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="CreatedAt"
                        align="center"
                        header={t('hsl:translation.RegistrationDate')}
                        renderer={e => <>{formatDate(e?.rowData.CreatedAt)}</>}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="CompanyName"
                        align="center"
                        header={t('common:translation.Partner')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="ResponsibleName"
                        align="center"
                        header={t('repaircenter:translation.Responsible')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="Action"
                        align="center"
                        header={t('common:translation.Action')}
                        sortable={true}
                        renderer={rowData => (
                            <>{rowData.value === 'Entry' ? t('hsl:translation.Entry') : t('hsl:translation.Exit')}</>
                        )}
                    />
                    <DataTable.Column field="Quantity" align="center" header={t('common:translation.Quantity')} sortable={true} />
                    <DataTable.Column
                        field="Description"
                        align="center"
                        header={t('common:translation.Description')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="TrackingNumber"
                        align="center"
                        header={t('hsl:translation.TrackingNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="Observation"
                        align="center"
                        header={t('hsl:translation.Observations')}
                        sortable={true}
                    />
                    {userDetails?.ROLE === CLIENTADMIN || userDetails?.ROLE === CLIENT ? (
                        <DataTable.Column
                            field="edit"
                            renderer={rowData => (
                                <Icon
                                    size="small"
                                    root="common"
                                    name="edit"
                                    className="icon-event"
                                    onClick={() => handleEditClick(rowData)}
                                />
                            )}
                            align="center"
                            header={t('common:translation.Edit')}
                        />
                    ) : (
                        []
                    )}
                </DataTable>
                <DataTable.Pagination
                    totalItems={totalItems}
                    itemsPerPage={5}
                    onPageChange={pageNumber => {
                        const filterPage: FilterLogInventoryDTO = { ...filters, PageIndex: parseInt(pageNumber.toString()) };
                        getFilteredActions(filterPage);
                    }}
                />
            </div>
        );
    };

    return (
        <div className="container">
            {searchFieldsRender()}
            {tableInventoryRender()}
            {renderModal()}
        </div>
    );
};

export default InventoryDetails;
