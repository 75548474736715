import { Divider, Icon, Table, Carousel, KPIStatistic } from '@scuf/common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS, companyAtom } from 'resources';
import { orderCartAtom } from 'resources/atoms/spareParts';
import { OrderCartDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import useFetch from 'helpers/hooks/useFetch';
import Alert from 'components/alert';
import { CompanyDTO } from 'models/userManager/addCustomer';
import "../../../../../styles/spareParts.scss"

function MyCart(): JSX.Element {
      const { t } = useTranslation(['spareparts', 'common']);
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);
    const [userCompany, setUserCompany] = useRecoilState<CompanyDTO>(companyAtom);
    const [, removeItemFromCart, loadingDel] = useFetch<{ orderCartId: number }>({
        url: SPARE_PARTS.removeItemsFromCart,
        method: 'GET',
        onError: 'Removed',
        onSuccess: 'Removed'
    });


    useEffect(() => {

        SetpriceFreight();
    }, [defaultCart?.total, defaultCart?.orderItems])

    async function deleteItemCart(orderCartId: number) {
        await removeItemFromCart({ orderCartId: parseInt(orderCartId.toString()) }, () => {
            setdefaultCart(old => ({
                ...old,
                orderItems: [...old.orderItems].filter(e => e.id !== orderCartId)
            }));
        });
    }

    async function SetpriceFreight() {
        let priceFreight = 0;

        if (defaultCart.typeId === 4) {
            priceFreight = 0;
        } else if (userCompany.state === 'MG' || userCompany.state === 'SP') {
            if (defaultCart.total <= 49) {
                priceFreight = 9.99;
            }
        } else {
            if (defaultCart.total <= 99) {
                priceFreight = 17.99;
            }
        }

        setdefaultCart({ ...defaultCart, priceFreight });
    }

    return (
        <>
            <Divider />
            <div className="no-click">
                <h1>
                    <Icon name="shopping-bag" size="large" root="building" /> {t('cart.MyCart')}
                </h1>
                <h3>{t('translation.AddedProducts') + '(' + (defaultCart?.orderItems?.length ?? 0) + ')'}</h3>
                <br />
                <div className="div-datatable">
                    {defaultCart?.orderItems?.length > 0 ? (
                        <div key={'tabela'}>
                            <Table className="more-information-table">
                                <Table.Header>
                                    <Table.HeaderCell content={t('cart.Image')} />
                                    <Table.HeaderCell content={t('common:translation.Equipments')} />
                                    <Table.HeaderCell content={t('common:translation.Description')} />
                                    <Table.HeaderCell content={t('common:translation.Quantity')} />
                                    <Table.HeaderCell content={t('Valor Liquido s/ desconto')} />
                                    <Table.HeaderCell content={t('Valor Liquido c/ desconto')} />
                                    <Table.HeaderCell content={t('cart.ValueTotal')} />
                                    <Table.HeaderCell content={t('')} />
                                </Table.Header>
                                <Table.Body className="sroll-table">
                                    {defaultCart
                                        ? defaultCart?.orderItems?.map((item: OrderItemsDTO) => (
                                            <Table.Row key={item.id}>
                                                <Table.Cell>
                                                    {item.images?.toString() !== "" ?
                                                        <Carousel className="carouselcart" autoPlay={true} transitionTime={6000} height={200}>
                                                            {item.images?.map((image: string, idx: number) => (
                                                                <Carousel.Slide
                                                                    key={idx}
                                                                    className='carousel-image'
                                                                    image={`data:image/png;base64,${image}`}
                                                                />
                                                            ))}
                                                        </Carousel>
                                                        : <img className="imagepd" alt="imagem padrão" src={require('../../../../../assets/HON192.png')} />
                                                    }
                                                </Table.Cell>
                                                <Table.Cell>{item?.code}</Table.Cell>
                                                <Table.Cell>{item?.description}</Table.Cell>
                                                <Table.Cell>{item?.quantity}</Table.Cell>
                                                <Table.Cell>${item?.netPrice.toFixed(2)}</Table.Cell>
                                                <Table.Cell>${((1 - item?.discount) * item?.netPrice).toFixed(2)}</Table.Cell>
                                                <Table.Cell>${(item?.quantity * (1 - item?.discount) * item?.netPrice).toFixed(2)}</Table.Cell>
                                                <Table.Cell width="1">
                                                    {loadingDel ? (
                                                        <Icon name="refresh" loading={true} />
                                                    ) : (
                                                        <Icon
                                                            className="clickable"
                                                            root="common"
                                                            name="delete"
                                                            size="medium"
                                                            loading={loadingDel}
                                                            onClick={() => deleteItemCart(item?.id!)}
                                                        />
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                        : t('cart.AlertAdd')}
                                </Table.Body>
                            </Table>
                            <div className="form3Columns">
                                <h5 style={{ color: 'red', fontWeight: 'bold' }}>{t('productinfots.InfoValues')}</h5>
                                <KPIStatistic
                                    value={'Total US$: ' + defaultCart?.total?.toFixed(2)}
                                    targetValue={
                                        userCompany?.state === 'MG' || userCompany?.state === 'SP'
                                            ? defaultCart?.total < 49
                                                ? t('productinfots.InfoAddItem')
                                                : t('translation.FreeShipping')
                                            : userCompany?.state !== null
                                                ? defaultCart?.total < 99
                                                    ? t('productinfots.InfoAddItem')
                                                    : t('translation.FreeShipping')
                                                : ''
                                    }
                                    valueColor="blue"
                                />
                            </div>
                        </div>
                    ) : (
                        <Alert text={t('cart.AlertAdd')} />
                    )}
                </div>
            </div >
        </>
    );
}

export default MyCart;
