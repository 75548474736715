import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Header, Icon, Select, Modal } from '@scuf/common';
import { ItemInventoryPartDetailsDTO, SelectOption } from 'models/hsl/general';
import '../../../../styles/hsl.scss';
import { DataTable } from '@scuf/datatable';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import { HSL } from 'resources';
import { useLocation, Link } from 'react-router-dom';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';

interface AddPart {
    setAddPart: (value: boolean) => void;
    onSubmit: () => void;
}

const SUPERADMIN = '1';
const ADMIN = '2';

const RepairAddPartsUsed: React.FC<AddPart> = ({ setAddPart, onSubmit }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const { user: userDetails } = useUser();
    const location = useLocation();
    const selectedRepair = location.state?.selectedRepair;
    const inicialData = {
        Id: 0,
        CompanyId: selectedRepair?.CompanyId,
        CompanyName: selectedRepair?.CompanyName,
        CompanyCountry: '',
        ProductName: '',
        PartNumberDescription: '',
        PartNumber: '',
        FinalPrice: '',
        ListPrice: '',
        Available: 0,
        Quantity: 0,
        ResponsibleName: selectedRepair?.ResponsibleUser,
        ResponsibleId: userDetails?.SSOID,
        RepairId: selectedRepair?.Id,
        Pendent: false
    };
    const [data, setData] = useState<ItemInventoryPartDetailsDTO>(inicialData);
    const [partPendent, setPartPendent] = useState(false);
    const [availableParts, setAvailableParts] = useState<SelectOption[]>([]);
    const isAdmin = userDetails?.ROLE === SUPERADMIN || userDetails?.ROLE === ADMIN;

    const [partsInventory, getAllPartsInventory] = useFetch<null, ItemInventoryPartDetailsDTO[]>({
        url: HSL.getInventoryItemsPartDetails,
        method: 'GET',
        onError: t('hsl:translation.NotFoundParts')
    });

    const [repairParts, getRepairParts, loadingRepairParts] = useFetch<{ repairId: number }, any>({
        url: HSL.getRepairPartsByRepairId,
        method: 'GET',
        onError: t('hsl:translation.Unexpected')
    });

    const [, addPnInRepair, loadingAddPn] = useFetch<ItemInventoryPartDetailsDTO, ItemInventoryPartDetailsDTO>({
        url: HSL.addPnInRepair,
        method: 'POST',
        onError: t('notification:critical.Add')
    });

    const [, removePnInRepair, loadingRemovePn] = useFetch<any, any>({
        url: HSL.removePnInRepair,
        method: 'POST',
        onError: t('notification:critical.Removed')
    });

    useEffect(() => {
        getAllPartsInventory();
        getRepairParts({ repairId: data.RepairId });
    }, []);

    useEffect(() => {
        if (partsInventory?.data) {
            const uniqueParts = Array.from(
                new Set(partsInventory?.data.map((parts: ItemInventoryPartDetailsDTO) => String(parts.PartNumber)))
            );
            const mappedParts = uniqueParts.map(part => ({
                value: part ?? '',
                text: part ?? ''
            }));
            setAvailableParts(mappedParts);
        }
    }, [partsInventory]);

    useEffect(() => {
        if (repairParts?.data) {
            const hasPendentParts = repairParts.data.some((part: { Pendent: boolean }) => part.Pendent === true);
            setAddPart(hasPendentParts);
        }
    }, [repairParts]);

    const AddPn = async () => {
        await addPnInRepair(data, () => {
            getAllPartsInventory();
            getRepairParts({ repairId: data.RepairId });
        });
        setData(inicialData);
        onSubmit();
    };

    const RemovePn = async (rowData: ICellData) => {
        const rowDataJson = JSON.stringify(rowData);
        await removePnInRepair(rowDataJson, () => {
            getAllPartsInventory();
            getRepairParts({ repairId: data.RepairId });
        });
        onSubmit();
    };

    const handlePartChange = (selectedPartNumber: string) => {
        const selectedPart = partsInventory?.data?.find(part => part.PartNumber === selectedPartNumber);
        if (selectedPart) {
            setData({
                ...data,
                Id: selectedPart.Id,
                PartNumberDescription: selectedPart.PartNumberDescription,
                ProductName: selectedPart.ProductName,
                CompanyCountry: selectedPart.CompanyCountry,
                FinalPrice: selectedPart.FinalPrice.replace(/[^0-9.,-]+/g, '')
                    .replace(',', '.')
                    .replace(/(\.\d*)(?=\.)/g, ''),
                Available: selectedPart.Quantity,
                PartNumber: selectedPart.PartNumber,
                ItemInventoryId: selectedPart.ItemInventoryId
            });
        }
    };

    const text = t('hsl:translation.AddPartPendent');
    const link = <Link to="/hsl/pending-items">{t('hsl:translation.Pendent')}</Link>;

    const renderedText = text.split('{{link}}').map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {index < text.split('{{link}}').length - 1 && link}
        </React.Fragment>
    ));

    const dataParts = () => (
        <>
            <h1>
                <Icon root="common" name="entity-details" size="large" /> {t('hsl:translation.PartsUsed')}
            </h1>
            <div className="container-general-items">
                <Header title={t('hsl:translation.AddPartsUsed')} logo={false} menu={false} />

                <div className="form6Columns">
                    <Select
                        placeholder={t('hsl:translation.Part')}
                        label={t('hsl:translation.Part')}
                        value={data.PartNumber}
                        options={availableParts}
                        search
                        onChange={value => handlePartChange(value)}
                        disabled={isAdmin}
                    />
                    <Input
                        className="description"
                        placeholder={t('hsl:translation.Description')}
                        value={data.PartNumberDescription}
                        onChange={value => setData({ ...data, PartNumberDescription: value })}
                        label={t('hsl:translation.Description')}
                        disabled={true}
                    />
                    <Input
                        placeholder={t('hsl:translation.Price')}
                        value={data.FinalPrice}
                        onChange={value => setData({ ...data, FinalPrice: value })}
                        label={t('hsl:translation.Price')}
                        disabled={true}
                    />
                    <Input
                        placeholder={t('hsl:translation.Available')}
                        value={String(data.Available)}
                        onChange={value => setData({ ...data, Available: Number(value) })}
                        label={t('hsl:translation.Available')}
                        disabled={true}
                    />
                    <Input
                        placeholder={t('hsl:translation.Quantity')}
                        type="number"
                        value={String(data.Quantity)}
                        onChange={value => setData({ ...data, Quantity: Number(value) })}
                        label={t('hsl:translation.Quantity')}
                        disabled={isAdmin}
                    />
                    <Button
                        className="button-add"
                        type="primary"
                        icon="slidercontrols-plus"
                        content={t('maintenancereport:translation.AddPN')}
                        iconPosition="right"
                        onClick={() => {
                            data.Available! < data.Quantity ? setPartPendent(true) : AddPn();
                        }}
                        loading={loadingAddPn}
                        disabled={!data.PartNumber || data.Quantity <= 0}
                    />
                </div>
            </div>
        </>
    );

    const tablePartsRender = () => (
        <div className="Table-parts-repair">
            <DataTable
                columnResizeMode="fit"
                data={repairParts?.data ?? []}
                loading={loadingRepairParts || loadingRemovePn}
                className="partsUsed"
                scrollable
                scrollHeight="145px"
            >
                <DataTable.Column field="Id" align="center" header={t('ID')} sortable />
                <DataTable.Column field="ProductName" align="center" header={t('common:translation.Model')} sortable />
                <DataTable.Column field="PartNumber" align="center" header={t('common:translation.PartNumber')} sortable />
                <DataTable.Column
                    field="PartNumberDescription"
                    align="center"
                    header={t('common:translation.Description')}
                    sortable
                />
                <DataTable.Column field="ResponsibleName" align="center" header={t('hsl:translation.User')} sortable />
                <DataTable.Column field="Quantity" align="center" header={t('hsl:translation.Quantity')} sortable />
                <DataTable.Column
                    field="Pendent"
                    align="center"
                    header={t('hsl:translation.Pendent')}
                    sortable
                    renderer={({ rowData }) => (
                        <span style={{ color: rowData.Pendent ? 'red' : 'black' }}>
                            {rowData.Pendent ? t('common:actions.Yes') : t('common:actions.No')}
                        </span>
                    )}
                />
                {!isAdmin ? (
                    <DataTable.Column
                        field="Eliminate"
                        renderer={e => (
                            <div onClick={() => RemovePn(e.rowData)}>
                                <Icon size="small" root="common" name="delete" rotated={190} className="icon-event" />
                            </div>
                        )}
                        align="center"
                        header={t('common:actions.Delete')}
                    />
                ) : (
                    []
                )}
            </DataTable>
        </div>
    );

    const renderModalAddPartPendent = () => {
        return (
            <Modal
                open={partPendent}
                closeIcon={true}
                onClose={() => setPartPendent(false)}
                closeOnDimmerClick={true}
                size="large"
                className="modal-excluir-content"
            >
                <Modal.Header>
                    <h1>{t('hsl:translation.Attention')}</h1>
                </Modal.Header>
                <Modal.Content>
                    <p>{renderedText}</p>
                </Modal.Content>

                <Modal.Footer>
                    <Button
                        type="primary"
                        content={t('common:translation.Confirm')}
                        onClick={() => {
                            const updatedData = data;
                            updatedData.Pendent = true;
                            setData(updatedData);
                            AddPn();
                            setPartPendent(false);
                        }}
                    />
                    <Button type="primary" content={t('hsl:translation.Cancel')} onClick={() => setPartPendent(false)} />
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            {dataParts()}
            {tablePartsRender()}
            {partPendent && renderModalAddPartPendent()}
        </>
    );
};

export default RepairAddPartsUsed;
