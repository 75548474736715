import { useEffect, useState } from 'react';
import { Accordion, Button, Card, Header, Modal } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserAccess, UserAccount } from 'models';
import { FormClientInfo, FormOverview, FormTypeAccount, FormUserAccess } from './forms';
import { userAccessState, userState } from 'resources';
import 'styles/main.scss';

function SignUpPage() {
    const { t } = useTranslation(['register', 'common']);
    const navigate: NavigateFunction = useNavigate();
    const [typeAccount, setTypeAccount] = useState<string>(t('type.Person'));
    const [, setUser] = useRecoilState(userState);
    const [, setUserAccess] = useRecoilState(userAccessState);
    const [pageState, setPageState] = useState<number>(0);
    const [modalState, setModalState] = useState<boolean>(false);

    function showCancelModal(): void {
        setModalState(true);
    }

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modalCancel"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <Modal.Content>{t('actions.ExitRegisterConfirm')}</Modal.Content>
                <Modal.Footer>
                    <Button
                        type="primary"
                        size="small"
                        className="buttonCancelPrimary"
                        content={t('common:actions.Cancel')}
                        onClick={() => navigate('/')}
                    />
                    <Button type="primary" size="small" content={t('RegisterContinue')} onClick={() => setModalState(false)} />
                </Modal.Footer>
            </Modal>
        );
    }

    useEffect(() => {
        document.title = t('actions.RegisterHoneywellPortal');
        setUser(new UserAccount());
        setUserAccess(new UserAccess());
    }, []);

    return (
        <>
            {renderModal()}
            <Header title={t('common:actions.SignUp')} onLogoClick={() => navigate('/')} menu={false}></Header>
            <div className="container">
                <span>
                    <Card>
                        <Card.Header title={t('actions.CreateAccount')}></Card.Header>
                        <Card.Content>
                            <Accordion>
                                <Accordion.Content title={t('types.Account')} active={pageState === 0}>
                                    <FormTypeAccount
                                        setPageState={setPageState}
                                        setTypeAccount={setTypeAccount}
                                        cancelAction={showCancelModal}
                                    />
                                </Accordion.Content>
                                <Accordion.Content title={t('translation.CustomerInfo')} active={pageState === 1}>
                                    <FormClientInfo setPageState={setPageState} cancelAction={showCancelModal} />
                                </Accordion.Content>
                                <Accordion.Content title={t('translation.AccessUser')} active={pageState === 2}>
                                    <FormUserAccess setPageState={setPageState} cancelAction={showCancelModal} />
                                </Accordion.Content>
                                <Accordion.Content title={t('common:translation.Summary')} active={pageState === 3}>
                                    <FormOverview
                                        typeAccount={typeAccount}
                                        setPageState={setPageState}
                                        cancelAction={showCancelModal}
                                    />
                                </Accordion.Content>
                            </Accordion>
                        </Card.Content>
                    </Card>
                </span>
            </div>
        </>
    );
}

export default SignUpPage;
