import { Header, Icon, Popup, VerticalMenu } from '@scuf/common';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import { signinPopup } from 'resources';
import packageJson from '../../../package.json';

const tam = 845;
type Props = {
    title: string;
    navigate: NavigateFunction;
    messageState: any;
    showState: any;
};
export const BaseHeader: React.FC<Props> = ({ title, navigate, messageState, showState }: Props) => {
    const { setMessage } = messageState;
    const { setShow } = showState;
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const width = window.innerWidth;
    const { t } = useTranslation(['common']);
    async function handleSignIn() {
        signinPopup()
            .then(user => {
                if (user) {
                    navigate('/dashboard');
                }
            })
            .catch(e => {
                setMessage(t('errors.Login'), ` ${e}`);
                setShow(true);
            });
    }
    function handleChangeLang(_lang: string) {
        i18n.changeLanguage(_lang);
    }
    return (
        <>
            {width > tam ? (
                <Header title={t(title)} menu={width < tam ? true : false} onLogoClick={() => navigate('/')}>
                    <Header.Item onClick={() => navigate('/info/maintenance')}>{t('services.Maintenance')}</Header.Item>
                    <Header.Item onClick={() => navigate('/info/certified')}>{t('services.Certified')}</Header.Item>
                    <Header.Item onClick={() => navigate('/info/solutions')}>{t('services.Solutions')}</Header.Item>
                    <Header.Item style={{ fontWeight: 'bold' }} href={process.env.REACT_APP_SSO_LOGIN}>
                        {t('actions.SignIn')}
                    </Header.Item>
                    <Header.Item style={{ fontWeight: 'bold' }} href={process.env.REACT_APP_SSO_REGISTER}>
                        {t('actions.SignUp')}
                    </Header.Item>
                    <Popup
                        className="popup-theme-wrap"
                        element={
                            <Header.IconItem icon={<Icon name="globe" root="common" />} description={t('language.Language')} />
                        }
                        on="click"
                    >
                        <VerticalMenu>
                            <VerticalMenu.Header>{t('language.Language')}</VerticalMenu.Header>
                            <VerticalMenu.Item onClick={() => handleChangeLang('pt')}>
                                {t('language.Portuguese')}
                            </VerticalMenu.Item>
                            <VerticalMenu.Item onClick={() => handleChangeLang('en')}>
                                {t('language.English')}
                            </VerticalMenu.Item>
                            <VerticalMenu.Item onClick={() => handleChangeLang('es')}>
                                {t('language.Español')}
                            </VerticalMenu.Item>
                            <VerticalMenu.Item>
                                {packageJson.version}
                            </VerticalMenu.Item>
                        </VerticalMenu>
                    </Popup>
                </Header>
            ) : (
                <Header title={t(title)} menu={width < tam ? true : false} onMenuToggle={() => setCollapsed(!collapsed)} />
            )}
            {/* <SidebarLayout collapsed={collapsed} noIcons={true}>
                <SidebarLayout.Sidebar>
                    <SidebarLayout.Sidebar.Item content={t("SignIn")} className="sidebarItemBold" />
                    <SidebarLayout.Sidebar.Item content={t("SignUp")} className="sidebarItemBold" />
                    <SidebarLayout.Sidebar.Item content={t("Maintenance")} />
                    <SidebarLayout.Sidebar.Item content={t("Certified")} />
                    <SidebarLayout.Sidebar.Item content={t("Solutions")} />
                    <Popup
                        className="popup-theme-wrap"
                        element={<Header.IconItem icon={<Icon name="globe" root="common" />} description="Languages" />}
                        on="click"
                    >
                        <VerticalMenu>
                            <VerticalMenu.Header>{t("Language")}</VerticalMenu.Header>
                            <VerticalMenu.Item onClick={() => handleChangeLang("pt")}>{t("Portuguese")}</VerticalMenu.Item>
                            <VerticalMenu.Item onClick={() => handleChangeLang("en")}>{t("English")}</VerticalMenu.Item>
                        </VerticalMenu>
                    </Popup>
                </SidebarLayout.Sidebar>
                <SidebarLayout.Content>{children}</SidebarLayout.Content>
            </SidebarLayout> */}
        </>
    );
};

export default BaseHeader;
