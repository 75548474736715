import { useState, useEffect } from 'react';
import { Button, Input, Select, Icon, Header, Modal, DatePicker, Popup, VerticalMenu } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { useTranslation } from 'react-i18next';
import { RoleComponent } from 'components/roleComponent';
import '../../../styles/hsl.scss';
import useFetch from 'helpers/hooks/useFetch';
import { COMPANY_MANAGER, HSL, notificationAtom } from 'resources';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ContractDTO, SelectOption, FilterContractDTO } from 'models/hsl/general';
import { CompanyFilterDTO } from 'models';
import AddContractByForm from './new-contract/addContractByForm';
import AddWarrantyByForm from './new-warranty/addWarrantyByForm';
import ContractDetails from './contractDetails';
import ContractReport from './contractReport';
import { formatDate } from 'helpers';
import { downloadFileExcelContract } from 'resources/api/hsl_api/fileReports';
import { hslCountriesAtom, hslPartnersAtom, hslPartsAtom } from 'resources/atoms/hslAtom';
import { CompanyDTO } from 'models/userManager/addCustomer';

const InitialFilter: FilterContractDTO = {
    Partner: [],
    Country: [],
    PartNumber: [],
    PageIndex: 1,
    PageSize: 10,
    IdSap: '',
    ContractNumber: '',
    WarrantyNumber: '',
    Status: '',
    SerialNumber: '',
    DateStart: '',
    DateEnd: '',
    SaleDate: '',
    CustomerName: '',
    Type: '',
    SLAPriority: ''
};

const InitialContract: ContractDTO = {
    Id: 0,
    Number: '',
    ContractNumber: '',
    WarrantyNumber: '',
    Status: '',
    Partner: '',
    IdSap: '',
    CustomerName: '',
    CustomerAddress: '',
    CustomerCity: '',
    CustomerCountry: '',
    CustomerContactName: '',
    CustomerEmail: '',
    CustomerPhone: '',
    EquipmentLocationName: '',
    EquipmentLocationAddress: '',
    EquipmentLocationCity: '',
    EquipmentLocationCountry: '',
    SaleDate: '',
    StartDate: '',
    EndDate: '',
    InactivateDate: '',
    ContractValue: '',
    HourlyContractValue: '',
    WarrantyHourlyValue: '',
    CustomerNumber: '',
    TrackingNumber: '',
    PurchaseOrder: '',
    SiteCount: 0,
    Description: '',
    Comments: '',
    Type: '',
    Sla: '',
    Quantity: 0,
    SoldTo: '',
    ItemNumber: '',
    Equipments: []
};

function ContractSearch() {
    const setNotification = useSetRecoilState(notificationAtom);
    const [partners, setPartners] = useRecoilState(hslPartnersAtom);
    const [countries, setCountries] = useRecoilState(hslCountriesAtom);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const { t } = useTranslation(['common', 'hsl', 'user', 'home', 'notification']);

    const [openModalInactivateDelete, setOpenModalInactivateDelete] = useState(false);
    const [idContract, setIdContract] = useState<number | null>(null);
    const [contractDetails, setContractDetails] = useState<ContractDTO>(InitialContract);
    const [filteredData, setFilteredData] = useState<ContractDTO[]>([]);
    const [filters, setFilters] = useState(InitialFilter);
    const [showAddContract, setShowAddContract] = useState(false);
    const [showAddWarranty, setShowAddWarranty] = useState(false);
    const [showContractDetails, setShowContractDetails] = useState(false);
    const [showContractReport, setShowContractReport] = useState(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [allPartnerOptions, setAllPartnerOptions] = useState<[]>([]);
    const [partsOptions, setPartsOptions] = useState<SelectOption[]>([]);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

    const handleSuccess = () => {
        setNotification({ type: 'success', message: 'Report' });
    };
    const handleError = () => {
        setNotification({ type: 'information', message: 'NoData' });
    };

    const contractType: SelectOption[] = [
        { value: 'Contract', text: t('hsl:translation.Contract') },
        { value: 'Warranty', text: t('hsl:translation.Warranty') }
    ];

    const statusType: SelectOption[] = [
        { value: 'Active', text: t('hsl:translation.Active') },
        { value: 'Inactive', text: t('hsl:translation.Inactive') }
    ];

    const SLAPriority = [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '5', text: '5' },
        { value: '15', text: '15' }
    ];

    const [inactivate, inactivateContract, loadingInactivate] = useFetch<{ contractId: number | null }>({
        url: HSL.inactivateContract,
        method: 'GET',
        onError: 'LoadInfo'
    });

    const [, getContracts, loadingContracts] = useFetch<FilterContractDTO, ContractDTO[]>({
        url: HSL.getContracts,
        method: 'POST',
        onError: 'LoadInfo'
    });

    const [partner, getAllPartners] = useFetch<any, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartner] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getFilterContracts(filters);
        getAllPartnersByIdSap();
        if (!partners.length) {
            getPartners();
        }
        if (!parts.length) {
            getAllParts();
        }
    }, []);

    useEffect(() => {
        getFilterContracts(filters);
    }, [inactivate]);

    useEffect(() => {
        const partsOptions = parts.map(part => ({
            value: part.PartNumber,
            text: part.PartNumber
        }));
        setPartsOptions(partsOptions);
    }, [parts]);

    const getFilterContracts = async (filters: FilterContractDTO) => {
        try {
            await getContracts({ ...filters }, (current: any) => {
                if (current.data.Data) {
                    setFilteredData(
                        current.data.Data.map((item: any) => ({
                            ...item,
                            Number: item.ContractNumber || item.WarrantyNumber
                        }))
                    );
                    setTotalItems(current.data.TotalCount);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundContracts') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
        }
    };

    const inactivateContracts = async (idContract: number | null) => {
        try {
            await inactivateContract({ contractId: idContract }, (current: any) => {
                if (!current.error) {
                    setNotification(old => ({ ...old, type: 'success', message: t('notification:success.Inactivate') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
        }
    };

    const getAllPartnersByIdSap = async () => {
        try {
            await getAllPartners({ ...partner?.data }, (current: any) => {
                if (current?.data) {
                    setAllPartnerOptions(current.data.companies);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
        }
    };

    const handleExportExcel = async () => {
        setLoadingDownload(true);
        await downloadFileExcelContract(filters, `contracts.xlsx`, handleSuccess, handleError).finally(() =>
            setLoadingDownload(false)
        );
    };

    const getPartners = () => {
        getPartner(null, resp => {
            if (resp.data) {
                const partnersData = resp.data.map((partner: CompanyDTO) => ({
                    value: partner.name,
                    text: partner.name
                }));
                setPartners(partnersData);

                const uniqueCountries = Array.from(new Set(resp.data.map((partner: CompanyDTO) => String(partner.country))));
                const countriesData = uniqueCountries.map(country => ({
                    value: country as string,
                    text: country as string
                }));
                setCountries(countriesData);
            }
        });
    };

    const getAllParts = () => {
        getParts(null, (current: any) => {
            if (current.data.length) {
                setParts(current.data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    const applyFilters = () => {
        getFilterContracts(filters);
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getFilterContracts(InitialFilter);
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:contracts')} logo={false} menu={false}>
                    <div style={{ margin: '10px' }}>
                        <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                            <Button
                                content={t('hsl:translation.AddContracts')}
                                type="primary"
                                size="medium"
                                iconPosition="right"
                                icon={<Icon name="add" root="building" />}
                                onClick={() => setShowAddContract(true)}
                                description={t('hsl:translation.AddContracts')}
                            />
                            <Button
                                content={t('hsl:translation.AddWarranty')}
                                type="primary"
                                size="medium"
                                iconPosition="right"
                                icon={<Icon name="add" root="building" />}
                                onClick={() => setShowAddWarranty(true)}
                                description={t('hsl:translation.AddWarranty')}
                            />
                        </RoleComponent>
                    </div>
                </Header>
                <div className="form4Columns">
                    <Select
                        value={filters.Type}
                        placeholder={t('common:translation.Type')}
                        label={t('common:translation.Type')}
                        options={contractType}
                        onChange={value => setFilters({ ...filters, Type: value })}
                    />
                    <Select
                        value={filters.Status}
                        placeholder={t('hsl:translation.Status')}
                        label={t('hsl:translation.Status')}
                        options={statusType}
                        onChange={value => setFilters({ ...filters, Status: value })}
                    />
                    <Select
                        value={filters.SLAPriority}
                        placeholder={t('hsl:translation.SLA')}
                        label={t('hsl:translation.SLA')}
                        options={SLAPriority}
                        onChange={value => setFilters({ ...filters, SLAPriority: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.Warranty')}
                        label={t('hsl:translation.Warranty')}
                        value={filters.WarrantyNumber}
                        onChange={value => setFilters({ ...filters, WarrantyNumber: value })}
                    />
                </div>
                <div className="form4Columns">
                    <Input
                        placeholder={t('hsl:translation.Contract')}
                        label={t('hsl:translation.Contract')}
                        value={filters.ContractNumber}
                        onChange={value => setFilters({ ...filters, ContractNumber: value })}
                    />
                    <Select
                        placeholder={t('common:translation.PartNumber')}
                        label={t('common:translation.PartNumber')}
                        value={filters.PartNumber}
                        options={partsOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, PartNumber: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.Customer')}
                        label={t('hsl:translation.Customer')}
                        value={filters.CustomerName}
                        onChange={value => setFilters({ ...filters, CustomerName: value })}
                    />
                    <Input
                        placeholder={t('common:translation.SerialNumber')}
                        label={t('common:translation.SerialNumber')}
                        value={filters.SerialNumber}
                        onChange={value => setFilters({ ...filters, SerialNumber: value })}
                    />
                </div>
                <div className="form4Columns">
                    <DatePicker
                        label={t('hsl:translation.StartDate')}
                        reserveSpace={false}
                        value={filters.DateStart}
                        onChange={value => setFilters({ ...filters, DateStart: value ? value.toISOString() : '' })}
                    />
                    <DatePicker
                        label={t('hsl:translation.SaleDate')}
                        reserveSpace={false}
                        value={filters.SaleDate}
                        onChange={value => setFilters({ ...filters, SaleDate: value ? value.toISOString() : '' })}
                    />
                    <DatePicker
                        label={t('hsl:translation.DuoDate')}
                        reserveSpace={false}
                        value={filters.DateEnd}
                        onChange={value => setFilters({ ...filters, DateEnd: value ? value.toISOString() : '' })}
                    />
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            label={t('common:translation.Partner')}
                            placeholder={t('common:translation.Partner')}
                            options={partners}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, Partner: value })}
                        />
                    </RoleComponent>
                </div>
                <div className="form4Columns">
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            placeholder={t('user:address.Country')}
                            label={t('user:address.Country')}
                            options={countries}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, Country: value })}
                        />
                    </RoleComponent>
                </div>

                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const onInactivate = (idContract: number | null) => {
        setOpenModalInactivateDelete(false);
        inactivateContracts(idContract);
    };

    const renderModalInactivateContract = () => {
        return (
            <Modal
                open={openModalInactivateDelete}
                closeIcon={true}
                onClose={() => setOpenModalInactivateDelete(false)}
                closeOnDimmerClick={false}
                size="small"
                className="modal-excluir-content"
            >
                <Modal.Header>
                    <h1>{t('hsl:translation.Inactivate')}</h1>
                </Modal.Header>
                <Modal.Content>
                    <p>{t('hsl:translation.ConfirmInactivate')}</p>
                </Modal.Content>

                <Modal.Footer>
                    <Button
                        type="primary"
                        content={t('common:translation.Confirm')}
                        onClick={() => onInactivate(idContract)}
                        loading={loadingInactivate}
                    />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableContractRender = () => {
        return (
            <div className="Table-contract">
                <DataTable columnResizeMode="fit" data={filteredData} loading={loadingContracts}>
                    <DataTable.HeaderBar>
                        <DataTable.HeaderBar.Item
                            content={t('hsl:translation.ExportExcel')}
                            iconSize="small"
                            icon="file-download"
                            onClick={handleExportExcel}
                            loading={loadingDownload}
                        />
                    </DataTable.HeaderBar>
                    <DataTable.Column field={'Number'} align="center" header={t('Id')} sortable={true} />
                    <DataTable.Column
                        field="Type"
                        align="center"
                        header={t('common:translation.Type')}
                        sortable={true}
                        renderer={rowData => (
                            <>{rowData.value === 'Contract' ? t('hsl:translation.Contract') : t('hsl:translation.Warranty')}</>
                        )}
                    />
                    <DataTable.Column
                        field="Status"
                        align="center"
                        header={t('Status')}
                        sortable={true}
                        renderer={rowData => (
                            <>{rowData.value === 'Active' ? t('hsl:translation.Active') : t('hsl:translation.Inactive')}</>
                        )}
                    />
                    <DataTable.Column field="Partner" align="center" header={t('common:translation.Partner')} sortable={true} />
                    <DataTable.Column
                        field="CustomerName"
                        align="center"
                        header={t('hsl:translation.Customer')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="StartDate"
                        align="center"
                        header={t('hsl:translation.StartDate')}
                        sortable={true}
                        renderer={e => <>{formatDate(e?.rowData.StartDate)}</>}
                    />
                    <DataTable.Column
                        field="SaleDate"
                        align="center"
                        header={t('hsl:translation.SaleDate')}
                        sortable={true}
                        renderer={e => <>{formatDate(e?.rowData.SaleDate)}</>}
                    />
                    <DataTable.Column
                        field="EndDate"
                        align="center"
                        header={t('hsl:translation.EndDate')}
                        sortable={true}
                        renderer={e => <>{formatDate(e?.rowData.EndDate)}</>}
                    />
                    <DataTable.Column
                        field="opcoes"
                        renderer={e => (
                            <Popup
                                className="popup-theme-wrap"
                                element={<Icon size="large" root="common" name="ellipsis-vertical" />}
                                on="click"
                                align="center"
                            >
                                <VerticalMenu>
                                    <VerticalMenu.Header>{t('common:tabs.Options')}</VerticalMenu.Header>
                                    <VerticalMenu.Item
                                        onClick={() => {
                                            setShowContractDetails(true);
                                            setContractDetails(e.rowData);
                                        }}
                                    >
                                        <div className="Menu-vertical">
                                            <Icon size="medium" root="global" name="expand" className="icon-event" />
                                            {t('common:actions.Open')}/{t('common:translation.Edit')}
                                        </div>
                                    </VerticalMenu.Item>
                                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                                        {e.rowData.Status === 'Active' && (
                                            <VerticalMenu.Item
                                                onClick={() => {
                                                    setOpenModalInactivateDelete(true);
                                                    setIdContract(e.rowData.Id);
                                                }}
                                            >
                                                <div className="Menu-vertical">
                                                    <Icon size="medium" root="global" name="badge-stop" className="icon-event" />
                                                    {t('hsl:translation.Inactivate')}
                                                </div>
                                            </VerticalMenu.Item>
                                        )}
                                    </RoleComponent>
                                </VerticalMenu>
                            </Popup>
                        )}
                        header={t('common:tabs.Options')}
                    />
                </DataTable>
                <DataTable.Pagination
                    totalItems={totalItems}
                    itemsPerPage={10}
                    onPageChange={pageNumber => {
                        const filterPage: FilterContractDTO = { ...filters, PageIndex: parseInt(pageNumber.toString()) };
                        getFilterContracts(filterPage);
                    }}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (showAddContract) {
            return (
                <AddContractByForm
                    onSave={() => {
                        setShowAddContract(false);
                        getFilterContracts(filters);
                    }}
                    onBack={() => {
                        setShowAddContract(false);
                        getFilterContracts(filters);
                    }}
                    partner={allPartnerOptions}
                />
            );
        }
        if (showAddWarranty) {
            return (
                <AddWarrantyByForm
                    onSave={() => {
                        setShowAddWarranty(false);
                        getFilterContracts(filters);
                    }}
                    onBack={() => {
                        setShowAddWarranty(false);
                        getFilterContracts(filters);
                    }}
                    partner={allPartnerOptions}
                />
            );
        }
        if (showContractDetails) {
            return (
                <ContractDetails
                    onSave={() => {
                        setShowContractDetails(false);
                        getFilterContracts(filters);
                    }}
                    onBack={() => {
                        setShowContractDetails(false);
                        getFilterContracts(filters);
                    }}
                    contractDetails={contractDetails}
                    partner={allPartnerOptions}
                />
            );
        }
        if (showContractReport) {
            return (
                <ContractReport
                    onBack={() => {
                        setShowContractReport(false);
                        getFilterContracts(filters);
                    }}
                />
            );
        } else {
            return (
                <>
                    {searchFieldsRender()}
                    {renderModalInactivateContract()}
                    {tableContractRender()}
                </>
            );
        }
    };

    return renderContent();
}

export default ContractSearch;
