import { Modal, Tab } from '@scuf/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'styles/repaircenter.scss';
import 'styles/spareParts.scss';
import SparePartsReport from './components/tabs/sparePartsReport';
import SparePartsSearch from './components/tabs/sparePartsSearch';
import { SparePartsAdminChat } from './components/tabs';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import { SparePartsChatTable } from 'models/repaircenter/detailsRma';
import { SPARE_PARTS } from 'resources';
import { useRecoilState } from 'recoil';
import { ChatSpareParts } from 'resources/atoms/spareParts';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import ImageManagement from './components/tabs/ImageManagement';

const SUPERADMIN = '1';
const ADMIN = '2';

function SpareParts() {

    const { user } = useUser();
    const { t } = useTranslation(['spareparts', 'common']);
    const [ChatsSpareParts, setChatsSpareParts] = useRecoilState<SparePartsChatTable[]>(ChatSpareParts);
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [chats, loadChats] = useFetch<{ roleId: number; ssoId: string; companyId: number }, SparePartsChatTable[]>({
        url: SPARE_PARTS.getAllChats
    });

    useEffect(() => {
        UnreadMessage();
        setChatsSpareParts(chats?.data?.map((e: SparePartsChatTable) => ({ ...e, order: 'teste' }))!);
    }, [chats]);

    useEffect(() => {
        loadChats({ roleId: parseInt(user?.ROLE!), ssoId: user?.SSOID!, companyId: user?.COMPANY! });
        setChatsSpareParts(chats?.data?.map((e: SparePartsChatTable) => ({ ...e }))!);
        UnreadMessage();
    }, []);

    const UnreadMessage = () => {
        chats?.data?.forEach(e => {
            if (user?.ROLE === '1' || user?.ROLE === '2') {
                if (e.itWasReadByAdmin === false) {
                    setUnreadMessage(unreadMessage => unreadMessage + 1);
                }
            } else if (user?.ROLE === '3' || user?.ROLE === '4') {
                if (e.itWasReadByCustomer === false) {
                    setUnreadMessage(messageRead => messageRead + 1);
                }
            }
        });
    };

    return (
        <div className="container wrap-content">
            <Tab defaultActiveIndex={0} className="tab-with-divider">
                <Tab.Pane title={t('page_name')}>
                    <SparePartsSearch />
                </Tab.Pane>
                <Tab.Pane title={t('tabs.Report')}>
                    <SparePartsReport />
                </Tab.Pane>
                <Tab.Pane title={t('common:tabs.Chat')} badge={unreadMessage}>
                    <SparePartsAdminChat />
                </Tab.Pane>
                {user?.ROLE === SUPERADMIN || user?.ROLE === ADMIN ? (
                    <Tab.Pane title={t('common:tabs.ImageManagement')}>
                        <ImageManagement />
                    </Tab.Pane>
                ) : (
                    []
                )}
            </Tab>
        </div>
    );
}

export default SpareParts;
