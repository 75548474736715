import { Card, Icon, Loader } from '@scuf/common';
import DataTable from '@scuf/datatable/dist/components/DataTable/DataTable';
import '@scuf/datatable/honeywell-compact/theme.css';
import Alert from 'components/alert';
import { formatDate } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import requestFile from 'helpers/requestFile';
import { RmaFilterModel, RmaModelLite, WorkOrderRmaLiteDto } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FilterAtom, notificationAtom, REPAIR_CENTER, RmaLite } from 'resources';
import 'styles/repaircenter.scss';

const ServiceOrders: React.FC = () => {
    const { t } = useTranslation(['common', 'repaircenter']);
    const navigate = useNavigate();
    const [reportSelected, setReportSelected] = useState<string | null>(null);
    const [workOrderState, setWorkOrderState] = useState<WorkOrderRmaLiteDto[]>([]);
    const [rmaFilter, setRmaFilter] = useRecoilState<RmaFilterModel>(FilterAtom);
    const [pendentsRma, setPendentsRma] = useState<string | undefined>("");
    const [, getPendentOS, loading] = useFetch<{ rma: string | undefined }>
        ({ url: REPAIR_CENTER.getPendentOS, redirectOnError: true });
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const setNotification = useSetRecoilState(notificationAtom);
    const openRma = 16;

    const [, getRmaFilter, loadingRmas] = useFetch<RmaFilterModel, RmaFilterModel>({
        url: REPAIR_CENTER.getRmas,
        method: 'POST',
        redirectOnError: true
    });

    useEffect(() => {
        getOpenedRmas();
    }, []);

    useEffect(() => {
        if (pendentsRma?.length !== 0) {
            getPendentOS({ rma: pendentsRma }, current => {
                setWorkOrderState(current.data);
            });

        }
    }, [pendentsRma]);

    const handleCellClick = (row: WorkOrderRmaLiteDto) => {
        navigate('rma-detail');
        setRmaState({ ...rmaState, id: Number(row.rma) });
    }

    const handlePrint = async (workOrder: string): Promise<void> => {
        setReportSelected(workOrder);
        await requestFile.print(REPAIR_CENTER.getTechnicalReportHTML, {
            method: 'POST',
            params: { ids: [workOrder] }
        });
        setReportSelected(null);
    };

    const getOpenedRmas = async () => {
        await getRmaFilter({
            ...rmaFilter,
            indice: 1,
            statusId: openRma,
            id: 0,
            itemsByPage: 2887
        },
            (resp) => {
                const rmaIds = resp.data?.rmas.map(e => e.id).join(",");
                setPendentsRma(rmaIds);
            }
        );
    }

    return <>
        {
            loading || loadingRmas ? (
                <Loader text={t('common:actions.Loading')} />
            ) : !workOrderState ? (
                <Alert text={t('notification:information.NoData')} />
            ) : (
                <div>
                    <Alert text={t('repaircenter:translation.AlertOS')} />
                    <Card>
                        <div className="table-OS">
                            <DataTable
                                resizableColumns={true}
                                data={workOrderState}
                                scrollHeight="800px"
                                scrollable={true}
                                scrollWidth='100%'
                                search
                                onCellClick={e => handleCellClick(e.rowData)}
                                searchPlaceholder={t('common:actions.SearchRMA')}
                            >
                                <DataTable.Column
                                    field="rma"
                                    header={t('translation.Number')}
                                />
                                <DataTable.Column
                                    field="partnumber"
                                    header={t('common:translation.Model')}
                                />
                                <DataTable.Column
                                    field="modalidade"
                                    header={t('common:translation.Modality')}
                                />
                                <DataTable.Column
                                    field="serial"
                                    header={('SN')}
                                />
                                <DataTable.Column
                                    field="status"
                                    header={t('common:translation.Status')}
                                />
                                <DataTable.Column
                                    field=""
                                    renderer={e =>
                                        <p>{(e.rowData.flagApproved) === 1
                                            ? 'Aprovado' : e.rowData.flagApproved === 0
                                                ? 'Reprovado' : e.rowData.flagApproved !== 'Aguardando Aprovação'
                                                    ? '-' : e.rowData.flagApproved}</p>}
                                    header={t('status.SituationClient')}
                                />
                                <DataTable.Column
                                    field=''
                                    renderer={e => <p>{formatDate(e.rowData.dataEntrada)}</p>}
                                    header={t('repaircenter:rma.service_orders.DateIn')}
                                />
                                <DataTable.Column
                                    field=""
                                    renderer={e => <p>{formatDate(e.rowData.dataSaida) == "1/1/1" ? "-" : formatDate(e.rowData.dataSaida)}</p>}
                                    header={t('repaircenter:rma.service_orders.DateOut')}
                                />
                                <DataTable.Column
                                    field=""
                                    header={t('translation.Code')}
                                    renderer={e => <p>{e.rowData.codigo ?? t('common:status.NotInformed')}</p>}
                                />
                                <DataTable.Column
                                    field=""
                                    header={t('translation.Report')}
                                    renderer={e => e.rowData.os == reportSelected ? (
                                        <Icon loading={true} name="refresh" />
                                    ) : (
                                        <Icon
                                            style={{ cursor: 'pointer' }}
                                            root="common"
                                            name="document-report"
                                            size="medium"
                                            onClick={() => handlePrint(e.rowData?.os)}
                                        />
                                    )}
                                />

                            </DataTable>
                        </div>
                    </Card>
                </div>
            )} </>
};


export default ServiceOrders;
