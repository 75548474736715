import { useState, FC } from 'react';
import { Button, Input, Select, Icon, Header, DatePicker, Divider, Table } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { Equipment, ContractDTO, FormWarrantyData, SLAPriority } from 'models/hsl/general';
import { RoleComponent } from 'components/roleComponent';
import NewWarranty from './addWarrantyByUpload';
import { HSL } from 'resources';
import useFetch from 'helpers/hooks/useFetch';

interface AddWarrantyProps {
    onSave: () => void;
    onBack: () => void;
    partner: {
        id: number;
        name: string;
        idSap: string;
        country: string;
    }[];
}

const AddWarrantyByForm: FC<AddWarrantyProps> = ({ onSave, onBack, partner }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const [showUploadWarranty, setShowUploadWarranty] = useState(false);
    const [data, setData] = useState<FormWarrantyData>({
        Id: undefined,
        IdSap: '',
        WarrantyNumber: '',
        SerialNumber: '',
        PartNumber: '',
        StartDate: '',
        EndDate: '',
        SoldTo: '',
        Customer: '',
        Partner: '',
        Sla: '',
        Country: ''
    });

    const [equipment, setEquipment] = useState<Equipment>({ SerialNumber: '', PartNumber: '', ContractId: null });
    const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);

    const [, createWarrantyByForm, loadingCreate] = useFetch<FormWarrantyData[], ContractDTO>({
        url: HSL.createWarrantyByForm,
        method: 'POST',
        onError: t('notification:critical.Create'),
        onSuccess: t('notification:success.Create')
    });

    const handleAddEquipament = () => {
        setEquipmentList([...equipmentList, equipment]);
        setEquipment({ SerialNumber: '', PartNumber: '', ContractId: null });
    };

    const handleDeleteEquipament = (index: number) => {
        const updatedList = equipmentList.filter((_, i) => i !== index);
        setEquipmentList(updatedList);
    };

    const handleSave = async () => {
        const dataList = equipmentList.map(equip => ({
            ...data,
            SerialNumber: equip.SerialNumber!,
            PartNumber: equip.PartNumber
        }));
        await createWarrantyByForm(dataList, () => {
            onSave();
        });
    };

    const isSaveDisabled = () => {
        return (
            !data.Customer ||
            !data.Partner ||
            !data.Sla ||
            !data.Material ||
            !data.SoldTo ||
            !data.StartDate ||
            !data.EndDate ||
            equipmentList.length === 0
        );
    };

    const handlePartnerChange = (selectedPartnerName: string) => {
        const selectedPartner = partner.find(p => p.name === selectedPartnerName);
        if (selectedPartner) {
            setData({
                ...data,
                CompanyId: selectedPartner.id,
                Partner: selectedPartner.name,
                IdSap: selectedPartner.idSap,
                Country: selectedPartner.country
            });
        }
    };

    return (
        <div>
            {showUploadWarranty ? (
                <NewWarranty onSave={() => setShowUploadWarranty(false)} onBack={() => setShowUploadWarranty(false)} />
            ) : (
                <div>
                    <Header title={t('pages:newWarranty')} logo={false} menu={false}>
                        <div style={{ margin: '10px' }}>
                            <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                                <Button
                                    content={t('hsl:translation.ImportWarranty')}
                                    type="primary"
                                    size="medium"
                                    iconPosition="right"
                                    icon={<Icon name="file-upload" root="building" />}
                                    onClick={() => setShowUploadWarranty(true)}
                                    description={t('hsl:translation.ImportWarranty')}
                                />
                            </RoleComponent>
                        </div>
                    </Header>
                    <div className="form4Columns">
                        <Select
                            indicator="required"
                            className="select-type"
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partner.map(p => ({ text: p.name, value: p.name }))}
                            search={true}
                            value={data.Partner}
                            onChange={value => handlePartnerChange(value)}
                        />
                        {/*
                        <Input
                            indicator="required"
                            value={data.WarrantyNumber}
                            onChange={value => setData({ ...data, WarrantyNumber: value })}
                            label={t('hsl:translation.WarrantyNumber')}
                        />*/}
                        <Select
                            indicator="required"
                            className="select-type"
                            placeholder={t('hsl:translation.SLA')}
                            label={t('hsl:translation.SLA')}
                            options={SLAPriority}
                            value={data.Sla}
                            onChange={value => setData({ ...data, Sla: value })}
                        />
                        <Input
                            indicator="required"
                            value={data.Material}
                            onChange={value => setData({ ...data, Material: value })}
                            label={t('hsl:translation.PartNumberMaterial')}
                        />
                        <Input
                            indicator="required"
                            value={data.SoldTo}
                            onChange={value => setData({ ...data, SoldTo: value })}
                            label={t('hsl:translation.SoldTo')}
                        />
                    </div>
                    <div className="form4Columns">
                        <Input
                            indicator="required"
                            value={data.Customer}
                            onChange={value => setData({ ...data, Customer: value })}
                            label={t('hsl:translation.Customer')}
                        />
                        <DatePicker
                            indicator="required"
                            type="date"
                            reserveSpace={false}
                            label={t('hsl:translation.StartDate')}
                            value={data.StartDate}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setData({ ...data, StartDate: value.toDateString() })}
                        />
                        <DatePicker
                            indicator="required"
                            type="date"
                            reserveSpace={false}
                            label={t('hsl:translation.EndDate')}
                            value={data.EndDate}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setData({ ...data, EndDate: value.toDateString() })}
                        />
                    </div>

                    <Divider direction="horizontal"> {t('hsl:translation.AddEquipament')}</Divider>
                    <div className="form3Columns AddEquipment">
                        <Input
                            indicator="required"
                            value={equipment.SerialNumber}
                            onChange={value => setEquipment({ ...equipment, SerialNumber: value })}
                            label={t('common:translation.SerialNumber')}
                        />
                        <Input
                            indicator="required"
                            value={equipment.PartNumber}
                            onChange={value => setEquipment({ ...equipment, PartNumber: value })}
                            label={t('common:translation.PartNumber')}
                        />
                        <div style={{ paddingBottom: 10 }}>
                            <Button
                                content={t('common:actions.Add')}
                                type="primary"
                                onClick={handleAddEquipament}
                                disabled={!equipment.SerialNumber}
                            />
                        </div>
                    </div>

                    {equipmentList.length > 0 ? (
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                                <Table.HeaderCell content={t('common:translation.PartNumber')} />
                                <Table.HeaderCell content={t('common:actions.Delete')} />
                            </Table.Header>
                            <Table.Body>
                                {equipmentList.map((equip, index) => (
                                    <Table.Row key={equip.SerialNumber}>
                                        <Table.Cell content={equip.SerialNumber} />
                                        <Table.Cell content={equip.PartNumber} />
                                        <Table.Cell>
                                            <Icon
                                                name="delete"
                                                root="common"
                                                size="small"
                                                color="critical"
                                                onClick={() => handleDeleteEquipament(index)}
                                                title={t('common:actions.Delete')}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : null}
                    <Divider direction="horizontal" />
                    <div className="button-hsl">
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            onClick={handleSave}
                            className="button-add"
                            loading={loadingCreate}
                            disabled={isSaveDisabled()}
                        />
                        <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddWarrantyByForm;
