import { Button, Input, Select, Icon, Header, DatePicker, Modal } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import '../../../styles/hsl.scss';
import { RoleComponent } from 'components/roleComponent';
import { useTranslation } from 'react-i18next';
import { FilterQuotationDTO, QuotationDTO } from 'models/hsl/general';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useState, useEffect } from 'react';
import AddQuotation from './new-quotation/addQuotation';
import { HSL, notificationAtom, companyDtoAtom } from 'resources';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { hslPartnersAtom } from 'resources/atoms/hslAtom';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { downloadQuotationFile } from 'resources/api/hsl_api/fileReports';
import { formatDateMDA } from 'helpers';


const SUPERADMIN = '1';
const ADMIN = '2';
function QuotationSearch() {
    const { t } = useTranslation(['hsl', 'common']);
    const setNotification = useSetRecoilState(notificationAtom);
    const companyInfo = useRecoilValue<CompanyDTO>(companyDtoAtom);
    const partners = useRecoilValue(hslPartnersAtom);
    const InitialFilter = {
        Id: '',
        Partner: [],
        CompanyId: companyInfo.idSap,
        TotalPrice: '',
        RegistrationDate: '',
        PageIndex: 1,
        PageSize: 10
    };
    const [showAddQuotation, setShowAddQuotation] = useState(false);
    const [idQuotation, setIdQuotation] = useState<number>(0);
    const [eliminate, setEliminate] = useState(false);
    const [filters, setFilters] = useState(InitialFilter);
    const [filteredData, setFilteredData] = useState([]);
    const { user: userDetails } = useUser();
    const [loading, setLoading] = useState<boolean>(false);

    const [, getQuotations, loadingGetQuotation] = useFetch<FilterQuotationDTO, QuotationDTO[]>({
        url: HSL.getQuotations,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, deleteQuotation, loadingDelete] = useFetch<{ id: number }, ConstrainBoolean>({
        url: `${HSL.deleteQuotation}`,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getFilteredQuotation(InitialFilter);
    }, []);

    const eliminateQuotation = async () => {
        await deleteQuotation({ id: idQuotation }, resp => {
            if (resp?.data === true) {
                applyFilters();
            }
        });
        setEliminate(false);
        getFilteredQuotation(InitialFilter);
    };

    const getFilteredQuotation = async (filters: FilterQuotationDTO) => {
        let normalizedFilter = {
            ...filters,
            Id: typeof filters.Id === 'string' && filters.Id === '' ? 0 : parseInt(filters.Id as string)
        };

        await getQuotations(normalizedFilter, (current: any) => {
            if (current.data) {
                setFilteredData(current.data?.Data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundRepair') }));
            }
        });
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getFilteredQuotation(InitialFilter);
    };

    const applyFilters = () => {
        getFilteredQuotation(filters);
    };

    const handleDownloadFile = async (quotationNumber: number) => {
        setLoading(true);

        await downloadQuotationFile(
            { quotationNumber: quotationNumber },
            `quotation.pdf`,
            () => { },
            () => { }
        );

        setLoading(false);
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:quotation')} logo={false} menu={false}>
                    <div style={{ margin: '10px' }}>
                        <RoleComponent roles={['SUPERADMIN', 'ADMIN', 'CLIENTADMIN', 'CLIENT']}>
                            <Button
                                content={t('hsl:translation.AddQuotation')}
                                type="primary"
                                size="medium"
                                iconPosition="right"
                                icon={<Icon name="add" root="building" />}
                                onClick={() => setShowAddQuotation(true)}
                                description={t('hsl:translation.AddQuotation')}
                            />
                        </RoleComponent>
                    </div>
                </Header>
                <div className="form4Columns">
                    <Input
                        placeholder={t('hsl:translation.Id')}
                        label={t('hsl:translation.Id')}
                        type="number"
                        value={filters.Id ?? ''}
                        onChange={value => setFilters({ ...filters, Id: value })}
                    />
                    {userDetails?.ROLE === SUPERADMIN || userDetails?.ROLE === ADMIN ? (
                        <Select
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partners}
                            value={filters.Partner}
                            search={true}
                            multiple={true}
                            onChange={value => setFilters({ ...filters, Partner: value })}
                        />
                    ) : (
                        []
                    )}
                    <Input
                        placeholder={t('hsl:translation.TotalPrice')}
                        label={t('hsl:translation.TotalPrice')}
                        value={filters.TotalPrice ?? ''}
                        onChange={value => setFilters({ ...filters, TotalPrice: value })}
                    />
                    <DatePicker
                        label={t('hsl:translation.CreateDate')}
                        reserveSpace={false}
                        value={filters.RegistrationDate}
                        onChange={value => setFilters({ ...filters, RegistrationDate: value ? value.toISOString() : '' })}
                    />
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const tableQuotationRender = () => {
        return (
            <div style={{ marginTop: '26px', marginLeft: '20px' }}>
                <DataTable
                    columnResizeMode="fit"
                    data={filteredData ?? []}
                    scrollable={true}
                    scrollWidth="100%"
                    resizableColumns={true}
                    loading={loadingGetQuotation}
                >
                    <DataTable.Column field={'QuotationNumber'} align="center" header={t('hsl:translation.Id')} sortable={true} />
                    <DataTable.Column field={'Partner'} align="center" header={t('common:translation.Partner')} sortable={true} />
                    <DataTable.Column
                        field={'RegistrationDate'}
                        align="center"
                        header={t('common:translation.Date')}
                        renderer={e => <>{formatDateMDA(e.rowData.RegistrationDate)}</>}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'TotalPrice'}
                        align="center"
                        header={t('hsl:translation.TotalPrice')}
                        renderer={e => <>{e.rowData.TotalPrice}</>}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="Print"
                        renderer={e => (
                            <Icon
                                size="small"
                                root="common"
                                name="print"
                                className="icon-event"
                                onClick={() => {
                                    handleDownloadFile(e.rowData.QuotationNumber);
                                }}
                                loading={loading}
                            />
                        )}
                        align="center"
                        header={t('hsl:translation.Print')}
                    />
                    <DataTable.Column
                        field="Delete"
                        renderer={e => (
                            <Icon
                                size="small"
                                root="common"
                                name="delete"
                                rotated={190}
                                className="icon-event"
                                onClick={() => {
                                    setEliminate(true);
                                    setIdQuotation(parseInt(e?.rowData.QuotationNumber));
                                }}
                            />
                        )}
                        align="center"
                        header={t('common:actions.Delete')}
                    />
                    <DataTable.Pagination totalItems={filteredData.length} itemsPerPage={10} />
                </DataTable>
            </div>
        );
    };

    const renderModalEliminateQuotation = () => {
        return (
            <Modal
                open={eliminate}
                closeIcon={true}
                onClose={() => setEliminate(false)}
                closeOnDimmerClick={true}
                size="small"
                className="modal-excluir-content"
            >
                <Modal.Header>
                    <h1>{t('hsl:translation.Delete')}</h1>
                </Modal.Header>
                <Modal.Content>
                    <p>{t('hsl:translation.ConfirmDeleteQuotation')}</p>
                </Modal.Content>

                <Modal.Footer>
                    <Button
                        type="primary"
                        content={t('common:translation.Confirm')}
                        onClick={eliminateQuotation}
                        loading={loadingDelete}
                    />
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            {showAddQuotation ? (
                <AddQuotation
                    onSave={() => setShowAddQuotation(false)}
                    onBack={() => {
                        setShowAddQuotation(false);
                        getFilteredQuotation(filters);
                    }}
                />
            ) : (
                <>
                    {searchFieldsRender()}
                    {tableQuotationRender()}
                    {eliminate && renderModalEliminateQuotation()}
                </>
            )}
        </>
    );
}

export default QuotationSearch;
