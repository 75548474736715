import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@scuf/common';
import { RepairDTO } from 'models/hsl/general';
import { LabelDescription } from 'components';
import { formatDate } from 'helpers';

const calculateMaximumDeadline = (startDate: Date, days: number): Date => {
    const result = startDate;
    let daysAdded = 0;

    while (daysAdded < days) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
            daysAdded++;
        }
    }

    return result;
};

interface RepairDetailsProps {
    details: RepairDTO;
}

const RepairDetails: FC<RepairDetailsProps> = ({ details }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const [data, setData] = useState<RepairDTO>(details);

    useEffect(() => {
        setData(details);
    }, [details]);

    useEffect(() => {
        if (data?.StartDate && data?.SLAPriority) {
            const startDate = new Date(data?.StartDate);
            const priorityDays = +data?.SLAPriority;

            const newMaxEndDate = calculateMaximumDeadline(startDate, priorityDays);
            setData(prevData => ({
                ...prevData,
                MaxEndDate: newMaxEndDate.toDateString()
            }));
        }
    }, [data?.StartDate, data?.SLAPriority]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('common:translation.SerialNumber')}
                        value={data?.SerialNumber}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription title={t('hsl:translation.TypeServices')} value={data?.TypeService} className="uppercase" />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription title={t('common:translation.PartNumber')} value={data?.PartNumber} className="uppercase" />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription title={t('hsl:translation.Contract')} value={data?.Contract} className="uppercase" />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.CustomerName')}
                        value={data?.CustomerName}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.CustomerEmail')}
                        value={data?.CustomerEmail}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.CustomerAddress')}
                        value={data?.CustomerAddress}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.StartDate')}
                        value={formatDate(data?.StartDate)}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription title={t('hsl:translation.SLAPriority')} value={data?.SLAPriority} className="uppercase" />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.MaxEndDate')}
                        value={formatDate(data?.MaxDeadlineDate)}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.ProblemReported')}
                        value={data?.ProblemReported}
                        className="uppercase"
                    />
                </Grid.Column>

                <Grid.Column width={3}>
                    <LabelDescription
                        title={t('hsl:translation.OtherInformation')}
                        value={data?.OtherInfos}
                        className="uppercase"
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default RepairDetails;
