import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Header, Icon, Select } from '@scuf/common';
import { PartQuotation, SelectOption } from 'models/hsl/general';
import { DataTable } from '@scuf/datatable';
import { HSL, notificationAtom } from 'resources';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hslPartsAtom, hslPartsQuotation } from 'resources/atoms/hslAtom';
import useFetch from 'helpers/hooks/useFetch';
import '../../../../styles/hsl.scss';

const inicialData = {
    Id: '',
    PartNumber: '',
    PartNumberDescription: '',
    PriceItem: 0,
    Quantity: 0,
    TotalPriceItem: 0,
    TotalPrice: ''
};

interface AddPartsQuotationProps {
    setTotalPrice: (price: number) => void;
}

const AddPartsQuotation: FC<AddPartsQuotationProps> = ({ setTotalPrice }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const [partsQuotationRecoil, setPartsQuotationRecoil] = useRecoilState(hslPartsQuotation);
    const [partQuotation, setPartQuotation] = useState<PartQuotation>(inicialData);
    const [partsOptions, setPartsOptions] = useState<SelectOption[]>([]);

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (!parts.length) {
            getParts(null, (current: any) => {
                if (current.data.length) {
                    setParts(current.data);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
                }
            });
        }
    }, []);

    useEffect(() => {
        const partsOptions = parts.map(part => ({
            value: part.PartNumber,
            text: part.PartNumber
        }));
        setPartsOptions(partsOptions);
    }, [parts]);

    useEffect(() => {
        if (partQuotation.PartNumber) {
            const selectedPart = parts.find(part => part.PartNumber === partQuotation.PartNumber);
            if (selectedPart) {
                setPartQuotation({
                    ...partQuotation,
                    Id: String(selectedPart.Id),
                    PartNumberDescription: selectedPart.Description,
                    PriceItem: parseFloat(
                        selectedPart.ListPrice
                            .replace(/[^0-9.,-]+/g, '')
                            .replace(',', '.')
                            .replace(/(\.\d*)(?=\.)/g, '')
                    )

                });
            }
        }
    }, [partQuotation.PartNumber]);

    const AddPn = () => {
        const existingPartIndex = partsQuotationRecoil.findIndex(part => part.Id === partQuotation.Id);

        if (existingPartIndex !== -1) {
            const updatedParts = partsQuotationRecoil.map((part, index) => {
                if (index === existingPartIndex) {
                    return {
                        ...part,
                        Quantity: part.Quantity + partQuotation.Quantity,
                        TotalPriceItem: parseFloat((part.PriceItem * (part.Quantity + partQuotation.Quantity)).toFixed(2))
                    };
                }
                return part;
            });

            setPartsQuotationRecoil(updatedParts);
        } else {
            partQuotation.TotalPriceItem = parseFloat((partQuotation.PriceItem * partQuotation.Quantity).toFixed(2));
            setPartsQuotationRecoil([...partsQuotationRecoil, partQuotation]);
        }

        setPartQuotation(inicialData);
    };

    const eliminatePart = (id: string) => {
        const updatedList = partsQuotationRecoil.filter(part => part.Id !== id);
        setPartsQuotationRecoil(updatedList);
    };

    const calculateTotalPrice = () => {
        const totalPrice = partsQuotationRecoil.reduce((total, item) => total + (item.TotalPriceItem || 0), 0);
        setTotalPrice(totalPrice);
        return totalPrice;
    };

    const dataParts = () => (
        <div className="container-general-items">
            <div className="headerQuotation">
                <Icon root="common" name="entity-details" size="large" />
                <Header title={t('hsl:translation.AddParts')} logo={false} menu={false} />
            </div>
            <div className="form5Columns">
                <Select
                    placeholder={t('hsl:translation.Part')}
                    label={t('hsl:translation.Part')}
                    value={partQuotation.PartNumber}
                    options={partsOptions}
                    search
                    onChange={value => setPartQuotation({ ...partQuotation, PartNumber: value })}
                />
                <Input
                    className="description"
                    placeholder={t('hsl:translation.Description')}
                    value={partQuotation.PartNumberDescription}
                    label={t('hsl:translation.Description')}
                    disabled={true}
                />
                <Input
                    className="price"
                    placeholder={t('hsl:translation.Price')}
                    value={String(partQuotation.PriceItem)}
                    onChange={value => setPartQuotation({ ...partQuotation, PriceItem: Number(value) })}
                    label={t('hsl:translation.Price')}
                    disabled={true}
                />
                <Input
                    placeholder={t('hsl:translation.Quantity')}
                    type="number"
                    min={1}
                    value={String(partQuotation.Quantity)}
                    onChange={value => setPartQuotation({ ...partQuotation, Quantity: Number(value) })}
                    label={t('hsl:translation.Quantity')}
                />
                <Button
                    className="button-add"
                    type="primary"
                    icon="slidercontrols-plus"
                    content={t('maintenancereport:translation.AddPN')}
                    iconPosition="right"
                    onClick={AddPn}
                    disabled={!partQuotation.PartNumber || partQuotation.Quantity <= 0}
                />
            </div>
        </div>
    );

    const tablePartsRender = () => (
        <div className="TableQuotation" style={{ marginTop: '26px' }}>
            <DataTable
                columnResizeMode="fit"
                data={partsQuotationRecoil}
                className="partsQuotation"
                scrollable
                scrollHeight="145px"
            >
                <DataTable.Column field="Id" align="center" header={t('ID')} sortable />
                <DataTable.Column field="PartNumber" align="center" header={t('common:translation.PartNumber')} sortable />
                <DataTable.Column
                    field="PartNumberDescription"
                    align="center"
                    header={t('common:translation.Description')}
                    sortable
                />
                <DataTable.Column field="Quantity" align="center" header={t('hsl:translation.Quantity')} sortable />
                <DataTable.Column field="PriceItem" align="center" header={t('hsl:translation.Price')} sortable />
                <DataTable.Column field="TotalPriceItem" align="center" header={t('hsl:translation.Total')} sortable />
                <DataTable.Column
                    field="Eliminate"
                    renderer={e => (
                        <Icon
                            size="small"
                            root="common"
                            name="delete"
                            rotated={190}
                            className="icon-event"
                            onClick={() => eliminatePart(e.rowData?.Id)}
                        />
                    )}
                    align="center"
                    header="Eliminar"
                />
            </DataTable>
            <div className="footer">
                <span>{t('hsl:translation.TotalPrice') + ':'}</span>
                <span>{calculateTotalPrice().toFixed(2)}</span>
            </div>
        </div>
    );

    return (
        <>
            {dataParts()}
            {tablePartsRender()}
        </>
    );
};

export default AddPartsQuotation;
