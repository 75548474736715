import { IPermissionValidator, ModulePermissionValidator } from 'helpers';
import { UserModule } from 'models/user/sso';
import {
    CertifiedPage,
    CompanyManager,
    ContactPage,
    ContractsPage,
    DashboardMenu,
    DetailsRma,
    FinalCustomerManager,
    FindAnAssistancePage,
    Home,
    InfoAssistancePage,
    MaintenancePage,
    NewRma,
    NewRmaSummary,
    PartnersPage,
    PrintOrder,
    RepairCenter,
    RepairCenterPage,
    RmaDetails,
    SendOrderBudget,
    SolutionsPage,
    SpareParts,
    UserManager,
    WarrantyConsultPage,
    InstructionsProceduresPage,
    HslPage
} from 'pages';
import SparePartsPage from 'pages/identity/sparePartsPage';
import MaintenanceReportPage from 'pages/maintenanceReport/maintenanceReportPage';
import NewMaintenanceReport from 'pages/maintenanceReport/newMaintenanceReport/newMaintenanceReport';
import MaintenanceReportDetails from 'pages/maintenanceReport/maintenanceReportDetails';
import Overview from 'pages/manual/acordion/overview';
import TesteManual from 'pages/manual/manual';
import OrdersDetailSelected from 'pages/spareParts/OrdersDetails';
import { SparePartsChat } from 'pages/spareParts/components/tabs';
import ShoppingCart from 'pages/spareParts/components/tabs/shoppingCart/shoppingCart';
import NewCompany from 'pages/userManager/tabs/customerManager/newCompany';
import InventoryDetails from 'pages/hsl/inventory/inventoryDetails';
import DeliverySearch from 'pages/hsl/inventory/delivery/deliverySearch';
import DeliveryDetails from 'pages/hsl/inventory/delivery/deliveryDetails/deliveryDetails';
import NewDelivery from 'pages/hsl/inventory/delivery/newDelivery/newDelivery';
import NewGuia from 'pages/hsl/inventory/delivery/newDelivery/newGuia';
import ContractReport from 'pages/hsl/contracts/contractReport';
import Repair from 'pages/hsl/repair/execute-repair/executeRepair';
import PendingItems from 'pages/hsl/inventory/delivery/pendingItems/pendingItems';

export type rootType = {
    path: string;
    element?: JSX.Element;
    protected?: boolean;
    name?: string;
    permissions?: IPermissionValidator[];
    children?: (rootType | leafType)[];
};

export type leafType = Omit<rootType, 'children'>;

const routes: rootType[] = [
    { path: '*', name: 'error' },
    {
        path: '/',
        element: <Home />,
        protected: false,
        name: 'home',
        children: [
            {
                path: 'info',
                children: [
                    {
                        path: 'repair-center',
                        element: <RepairCenterPage />,
                        protected: false,
                        name: 'repairCenter'
                    },
                    {
                        path: 'spare-parts',
                        element: <SparePartsPage />,
                        protected: false,
                        name: 'spareParts'
                    },
                    {
                        path: 'partners',
                        element: <PartnersPage />,
                        protected: false,
                        name: 'partners'
                    },
                    {
                        path: 'maintenance',
                        element: <MaintenancePage />,
                        protected: false,
                        name: 'maintenance'
                    },
                    {
                        path: 'certified',
                        element: <CertifiedPage />,
                        protected: false,
                        name: 'certified'
                    },
                    {
                        path: 'solutions',
                        element: <SolutionsPage />,
                        protected: false,
                        name: 'solutions'
                    },
                    {
                        path: 'contact',
                        element: <ContactPage />,
                        protected: false,
                        name: 'contact'
                    },
                    {
                        path: 'contract',
                        element: <ContractsPage />,
                        protected: false,
                        name: 'Contracts'
                    },
                    {
                        path: 'instructions',
                        element: <InstructionsProceduresPage />,
                        protected: false,
                        name: 'Instructions'
                    },
                    {
                        path: 'assistance',
                        element: <InfoAssistancePage />,
                        protected: false,
                        name: 'assistance'
                    }
                ]
            },
            {
                path: 'hsl',
                element: <HslPage />,
                protected: true,
                name: 'hsl',
                children: [
                    {
                        path: 'inventory-details/:id',
                        element: <InventoryDetails />,
                        protected: true,
                        name: 'inventoryDetails'
                    },
                    {
                        path: 'pending-items',
                        element: <PendingItems />,
                        protected: true,
                        name: 'pendingItems'
                    },
                    {
                        path: 'delivery',
                        element: <DeliverySearch />,
                        protected: true,
                        name: 'delivery',
                        children: [
                            {
                                path: 'delivery-details',
                                element: <DeliveryDetails />,
                                protected: true,
                                name: 'deliveryDetails'
                            },
                            {
                                path: 'new-delivery',
                                element: <NewDelivery />,
                                protected: true,
                                name: 'newDelivery'
                            },
                            {
                                path: 'new-guia',
                                element: <NewGuia />,
                                protected: true,
                                name: 'newGuia'
                            }
                        ]
                    },
                    {
                        path: 'repair/:id',
                        element: <Repair />,
                        protected: true,
                        name: 'repair'
                    }
                ]
            },

            {
                path: 'repair-center',
                element: <RepairCenter />,
                protected: true,
                name: 'repairCenter',
                permissions: [new ModulePermissionValidator(UserModule.RMA)],
                children: [
                    {
                        path: 'new-rma',
                        element: <NewRma />,
                        protected: true,
                        name: 'newRma',
                        children: [
                            {
                                path: 'details',
                                element: <DetailsRma />,
                                protected: true,
                                name: 'rmaDetails',
                                children: [
                                    {
                                        path: 'summary',
                                        element: <NewRmaSummary />,
                                        protected: true,
                                        name: 'summary'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'rma-detail/:id',
                        element: <RmaDetails />,
                        protected: true,
                        name: 'rmaDetails',
                        children: [
                            {
                                path: 'budget',
                                element: <SendOrderBudget />,
                                protected: true,
                                name: 'budget'
                            }
                        ]
                    }
                ]
            },
            {
                path: 'spare-parts',
                element: <SpareParts />,
                protected: true,
                name: 'spareParts',
                permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)],
                children: [
                    {
                        path: 'chat',
                        element: <SparePartsChat />,
                        protected: true,
                        name: 'spareParts',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    },
                    {
                        path: 'order',
                        element: <OrdersDetailSelected />,
                        protected: true,
                        name: 'order',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    },
                    {
                        path: 'cart',
                        element: <ShoppingCart />,
                        protected: true,
                        name: 'shipping',
                        permissions: [new ModulePermissionValidator(UserModule.SPAREPARTS)]
                    }
                ]
            },
            {
                path: 'user',
                element: <UserManager />,
                protected: true,
                name: 'userManager',
                permissions: [new ModulePermissionValidator(UserModule.USER)],

                children: [
                    {
                        path: 'company',
                        element: <CompanyManager />,
                        protected: true,
                        name: 'companyManager',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    },
                    {
                        path: 'customer',
                        element: <FinalCustomerManager />,
                        protected: true,
                        name: 'customerManager',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    },
                    {
                        path: 'new-company',
                        element: <NewCompany />,
                        protected: true,
                        name: 'newCompany',
                        permissions: [new ModulePermissionValidator(UserModule.USER)]
                    }
                ]
            },
            {
                path: 'maintenance-report',
                element: <MaintenanceReportPage />,
                protected: true,
                name: 'maintenanceReport',
                permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)],
                children: [
                    {
                        path: 'report',
                        element: <MaintenanceReportDetails />,
                        protected: true,
                        name: 'report',
                        permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)]
                    },
                    {
                        path: 'new-report',
                        element: <NewMaintenanceReport />,
                        protected: true,
                        name: 'report',
                        permissions: [new ModulePermissionValidator(UserModule.MAINTENANCE_REPORT)]
                    }
                ]
            },
            {
                path: 'warranty-consult',
                element: <WarrantyConsultPage />,
                protected: false,
                name: 'warrantyConsult'
            },
            {
                path: 'find-assistance',
                element: <FindAnAssistancePage />,
                protected: false,
                name: 'findAssistance'
            },
            {
                path: 'dashboard',
                element: <DashboardMenu />,
                protected: true,
                name: 'dashboard'
            },
            {
                path: 'manual',
                element: <TesteManual />,
                protected: false,
                name: 'Teste Manual'
            },
            {
                path: 'overview',
                element: <Overview />,
                protected: false,
                name: 'overview'
            }
        ]
    }
];

export default routes;
