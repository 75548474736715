import { useRecoilValue } from 'recoil';
import { featuresState } from '../../resources/atoms/featureAtom';
import { companyDtoAtom } from 'resources';

const useFeatureFlag = (feature: string): boolean => {
  const company = useRecoilValue(companyDtoAtom);
  const features = useRecoilValue(featuresState(company?.country));
  return features[feature] || false;
};

export default useFeatureFlag;