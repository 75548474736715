import { Badge, Button, Divider, Grid, Icon, Loader } from '@scuf/common';
import { HttpResponse } from 'axios-http';
import { LabelDescription } from 'components';
import { formatDate, getTotalBudgetFinal, getTotalWithOptional, getTotalwithoutOptional, handleYesOrNo } from 'helpers';
import { BudgetDetailsModel, BudgetItems, WorkOrderBudgerModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { REPAIR_CENTER, RMAFileAtom, companyDtoAtom, notificationAtom } from 'resources';
import { getOsDetails, uploadFile } from 'resources/api/';
import AccessoriesTable from './components/accessoriesTable';
import ApprovalInfo from './components/approvalInfo';
import RejectBudgetModal from './components/rejectBudgetModal';
import ServicesTable from './components/servicesTable';
import useUser from 'helpers/hooks/useUser';
import requestFile from 'helpers/requestFile';
import { CompanyDTO } from 'models/userManager/addCustomer';
import useFetch from 'helpers/hooks/useFetch';

const SendOrderBudget: React.FC = () => {
    const ld = require('lodash');
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const { state } = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [actionLoading, setActionLoading] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [details, setDetails] = useState<BudgetDetailsModel>();
    const [detailsNoCalc, setDetailsNoCalc] = useState<BudgetDetailsModel>();
    const [fileUp, setFileUp] = useRecoilState<File | null>(RMAFileAtom);

    const setNotification = useSetRecoilState(notificationAtom);
    const { user: userDetails } = useUser();
    const [company] = useRecoilState<CompanyDTO>(companyDtoAtom);

    const [response, approveOs, sendingAproveOs] = useFetch<BudgetDetailsModel>({
        url: REPAIR_CENTER.approveOs,
        method: 'POST',
        onError: 'Send'
    });

    const [, resendEmail, sendingEmail] = useFetch<BudgetDetailsModel>({
        url: REPAIR_CENTER.resendEmail,
        method: 'POST',
        onError: 'Send'
    });

    const [resp, reproveOs, sendingReproveOs] = useFetch<BudgetDetailsModel>({
        url: REPAIR_CENTER.reproveOs,
        method: 'POST',
        onError: 'Send'
    });

    let approvedItems = details?.budget?.items;

    const handleData = async (): Promise<void> => {
        setLoading(true);
        const resp = await getOsDetails(state?.data);
        if (!resp.error && resp.data) {
            setDetailsNoCalc(resp.data as BudgetDetailsModel);
        } else {
            window.history.back();
            setNotification(old => ({ ...old, message: 'LoadInfo' }));
        }
        setLoading(false);
    };

    const handleCheck = (check: boolean, element: BudgetItems): void => {
        approvedItems = details?.budget?.items.map((e: BudgetItems) => {
            if (e === element) {
                check ? (e.flagApproved = 1) : (e.flagApproved = 0);
            }
            return e;
        });

        let clone: BudgetDetailsModel = ld.cloneDeep(details);
        setDetailsNoCalc(clone);
    };

    const handleInputChange = (attr: string, value: any): void => {
        if (attr == 'RequireOrderFile') return;

        setDetailsNoCalc(old => ({ ...old, budget: { ...(old?.budget as WorkOrderBudgerModel), [`approved${attr}`]: value } }));
    };

    const validate = (): boolean => {
        return (
            !!details?.budget?.approvedEmailXml &&
            !!details?.budget?.approvedPhone &&
            !!details?.budget?.approvedServicesCnpj &&
            !!details?.budget?.approvedServicesEmail &&
            !!details?.budget?.approvedUserName &&
            !!details?.budget?.approvedPartsCnpj &&
            !!details?.budget?.approvedPartsEmail &&
            details?.budget?.approvedSalesOrder != null
        );
    };
    const getCurrentBudgetStatus = (): boolean => {
        return details?.budget?.flagApproved === 2 && details.status?.code === '07';
    };

    const getApprovalStatus = (flag: number | undefined): string => {
        if (flag === 1) {
            return 'Aprovado';
        } else if (flag === 0) {
            return 'Reprovado';
        } else if (flag === 2) {
            return 'Aguardando Aprovação';
        } else {
            return '-';
        }
    }

    const handleApprove = async (): Promise<void> => {
        setActionLoading(true);

        if (fileUp) {
            const newName = generateNewFileName(fileUp.name);
            const newFile = new File([fileUp], newName, { type: fileUp.type });

            const upload = await uploadFile(newFile, state.data?.rmaId, userDetails?.SSOID!, 'PEDIDO_COMPRA');

            if (upload && upload.error) {
                setNotification(old => ({ ...old, message: 'UploadError' }));
                setActionLoading(false);
                return;
            }

            setFileUp(null);
        }

        try {
            await approveOs({
                ...details,
                budget: {
                    ...details?.budget!,
                    flagApproved: 1,
                    approvedSsoId: userDetails?.SSOID
                },
                companyName: company.name
            });

            setDetails(old => ({ ...old, budget: { ...old?.budget!, flagApproved: 1 } }));
            setNotification(old => ({ ...old, message: t('Send'), type: 'success' }));
        } catch {
            setNotification(old => ({ ...old, message: t('Send') }));
        }

        setActionLoading(false);
    };

    const handleSendEmail = async (): Promise<void> => {
        await resendEmail({
            ...details,
            budget: {
                ...details?.budget!,
                flagApproved: 1,
                approvedSsoId: userDetails?.SSOID
            },
            companyName: company?.name
        });
        setNotification(old => ({ ...old, message: t('Send'), type: 'success' }));
    };

    const generateNewFileName = (oldFileName: string): string => {
        const parts = oldFileName.split('.');
        const timestamp = Date.parse(new Date().toString());
        const newFileName = parts.slice(0, -1).join('.') + `_${timestamp}.${parts[parts.length - 1]}`;
        return newFileName;
    };

    const handleReject = async (): Promise<void> => {
        setActionLoading(true);
        setModalOpen(false);

        await reproveOs({ ...details, budget: { ...details?.budget!, flagApproved: 0 } });
        if (!response?.error) {
            setDetails(old => ({ ...old, budget: { ...old?.budget!, flagApproved: 0 } }));
            setNotification(old => ({ ...old, message: t('Send'), type: 'success' }));
        } else setNotification(old => ({ ...old, message: t('ReproveFailed') }));

        setActionLoading(false);
    };

    useEffect(() => {
        if (detailsNoCalc == undefined) return;

        let withOption: number = getTotalWithOptional(detailsNoCalc?.budget);
        let withoutOption: number = getTotalwithoutOptional(detailsNoCalc?.budget);
        let total = getTotalBudgetFinal(detailsNoCalc?.budget);

        let det: BudgetDetailsModel = ld.cloneDeep(detailsNoCalc);
        det.totalAproved = total;
        det.totalBudgetWithoutOptional = withoutOption;
        det.totalBudget = withOption;

        setDetails(det);
    }, [detailsNoCalc]);

    useEffect(() => {
        handleData();
    }, []);

    return (
        <>
            {(loading && <Loader text={t('common:actions.Loading')} />) || (
                <div className="container">
                    <h1>{t('rma.budget.SendBudget')}</h1>
                    <Divider></Divider>
                    <h2>
                        {`${t('rma.budget.WorkOrder')} #${state?.data?.id}`}{' '}
                        {state?.data?.flagRma === -1 ? (
                            <Badge color="yellow" content={details?.status?.description} />
                        ) : state?.data?.flagRma === 1 ? (
                            <Badge color="green" content={t('common:status.Approved')} />
                        ) : state?.data?.flagRma === 0 ? (
                            <Badge color="red" content={t('common:status.Disapproved')} />
                        ) : (
                            <Badge color="yellow" content={t('common:status.WaitingApproval')} />
                        )
                        }

                    </h2>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('common:translation.PartNumber')}
                                    value={details?.product?.number}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('common:translation.Description')}
                                    value={details?.product?.description}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('common:translation.SerialNumber')}
                                    value={details?.product?.serialNumber}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.EntryInvoice')}
                                    value={details?.entryInvoice}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.FactoryWarranty')}
                                    value={handleYesOrNo(details?.factoryWarranty)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.ServiceWarranty')}
                                    value={handleYesOrNo(details?.serviceWarranty)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.SaleDate')}
                                    value={formatDate(details?.saleDate)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('common:translation.Accessory')}
                                    value={details?.accessory}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.Issued')}
                                    value={formatDate(details?.issued)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.InvoiceIssueDate')}
                                    value={formatDate(details?.invoiceIssueDate)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.InvoiceDate')}
                                    value={formatDate(details?.invoiceDate)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('common:status.SituationClient')}
                                    value={getApprovalStatus(details?.budget?.flagApproved)}
                                ></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription title={t('rma.budget.Carrier')} value={details?.courierName}></LabelDescription>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <LabelDescription
                                    title={t('rma.budget.TrackingCode')}
                                    value={details?.trackingCode}
                                ></LabelDescription>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Column width={12}>
                            <LabelDescription title={t('rma.budget.ReportedIssue')} className="uppercase">
                                {details?.failureReported}
                            </LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={12} className="default-text">
                            <LabelDescription title={t('rma.budget.TechnicalAdvice')} className="uppercase">
                                {t('rma.budget.TechnicalAdviceText1')}
                                <ul className="default-ul">
                                    {details?.changes?.map(
                                        (change, index) => change.cause && <li key={index}>{change.cause}</li>
                                    )}
                                </ul>
                                <p>{t('rma.budget.TechnicalAdviceText2')}</p>
                                <p>{t('rma.budget.TechnicalAdviceText3')}</p>
                                <p>{t('rma.budget.TechnicalAdviceText4')}</p>
                                <p>{t('rma.budget.TechnicalAdviceText5')}</p>
                                <p>{t('rma.budget.TechnicalAdviceText6')}</p>
                            </LabelDescription>
                        </Grid.Column>
                    </Grid>

                    {details?.budget && (
                        <AccessoriesTable budget={details?.budget} onCheck={handleCheck} type="Required"></AccessoriesTable>
                    )}
                    {details?.budget && (
                        <AccessoriesTable budget={details?.budget} onCheck={handleCheck} type="Optional"></AccessoriesTable>
                    )}
                    {details?.budget && <ServicesTable budget={details?.budget}></ServicesTable>}
                    <Divider />
                    <div className="div-alert-os">
                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                        <h5>{t('common:rules.RequiredField')}</h5>
                    </div>
                    {details?.budget && <ApprovalInfo approveDetails={details.budget} onChangeInfo={handleInputChange} />}
                    <div className="buttonsSearch">
                        {getCurrentBudgetStatus() && (
                            <>
                                <Button
                                    content={t('rma.budget.Actions.Reprove')}
                                    loading={actionLoading}
                                    type="secondary"
                                    disabled={
                                        actionLoading ||
                                        validate() == false ||
                                        details?.flagApproved === 1 ||
                                        userDetails?.ROLE === '1' ||
                                        userDetails?.ROLE === '2'
                                    }
                                    onClick={() => setModalOpen(true)}
                                ></Button>
                                <Button
                                    content={t('rma.budget.Actions.Approve')}
                                    loading={actionLoading}
                                    disabled={
                                        validate() == false ||
                                        actionLoading ||
                                        details?.flagApproved === 1 ||
                                        userDetails?.ROLE === '1' ||
                                        userDetails?.ROLE === '2'
                                    }
                                    onClick={handleApprove}
                                ></Button>
                            </>
                        )}
                        <Button
                            content={t('common:actions.SendEmail')}
                            loading={sendingEmail}
                            disabled={!!sendingEmail}
                            onClick={handleSendEmail}
                        ></Button>
                        <Button
                            content={t('common:actions.Print')}
                            loading={actionLoading}
                            disabled={!!actionLoading}
                            onClick={async () => {
                                setActionLoading(true);
                                await requestFile.print(REPAIR_CENTER.getBudgetsPDF, {
                                    method: 'GET',
                                    params: {
                                        id: details?.number,
                                        rmaId: details?.rma?.number,
                                        cnpj: company?.cnpj,
                                        name: company?.name
                                    }
                                });
                                setActionLoading(false);
                            }}
                        ></Button>

                        {isModalOpen && (
                            <RejectBudgetModal
                                open={isModalOpen}
                                onClose={() => setModalOpen(false)}
                                onRejects={handleReject}
                                onChange={(value: string) => handleInputChange('Obs', value)}
                                osId={details?.number!}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SendOrderBudget;
