import { Button, Header, Icon, Input, Loader, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import useFeatureFlag from 'helpers/hooks/useFeatureFlag';
import useFetch from 'helpers/hooks/useFetch';
import { CompanyFilterDTO } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COMPANY_MANAGER, cnpjMask } from 'resources';

const AdminTabManager: React.FC = () => {
    const { t } = useTranslation(['usermanager', 'common']);
    const isFeatureHSWLEnabled = useFeatureFlag('HSLWeb');

    const [reset, setReset] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsByPage, setItemsByPage] = useState(5);

    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });

    const navigate = useNavigate();

    const itemsPerPage = ['5', '10', '15', '20'];

    useEffect(() => {
        handleData();
    }, [reset]);

    useEffect(() => {
        setCurrentPage(companyFilter?.data?.indice ?? 1);
    }, [getCompanies]);

    const handleData = async (indice = 1): Promise<void> => {
        await getCompanies({ ...companyFilter?.data, indice, itemByPage: itemsByPage });
    };

    return (
        <>
            {(loadingData && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <Header title={t('dashboard:admin_tools.user_manager.name')} logo={false} menu={false}>
                        <Button
                            type="primary"
                            size="small"
                            iconPosition="right"
                            icon={<Icon name="add" size="medium" root="building" />}
                            badge="20"
                            onClick={() => navigate('new-company')}
                            content={t('usermanager:translation.NewCompany')}
                        ></Button>
                    </Header>
                    <div className="form4Columns">
                        <Input
                            label={'ID'}
                            placeholder={'ID'}
                            type="number"
                            value={companyFilter?.data?.id?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, id: parseInt(value) }))}
                        ></Input>
                        <Input
                            label={t('user:info.Name')}
                            placeholder={t('user:info.Name')}
                            value={companyFilter?.data?.name?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, name: value }))}
                        ></Input>
                        <Input
                            label={t('user:info.EmailUser')}
                            placeholder={t('user:info.EmailUser')}
                            value={companyFilter?.data?.emailUser?.toString() ?? ''}
                            onChange={(value: string) => setFilter(old => ({ ...old, emailUser: value }))}
                        ></Input>
                        {isFeatureHSWLEnabled ? (
                            <Input
                                label={t('user:company.IdSap')}
                                placeholder={t('user:company.IdSap')}
                                value={companyFilter?.data?.idSap?.toString() ?? ''}
                                onChange={(value: string) => setFilter(old => ({ ...old, idSap: value }))}
                            ></Input>
                        ) : (
                            <Input
                                label={t('user:company.Id')}
                                placeholder={t('user:company.Id')}
                                value={cnpjMask(companyFilter?.data?.cnpj?.toString() ?? '')}
                                onChange={(value: string) => setFilter(old => ({ ...old, cnpj: cnpjMask(value) }))}
                            ></Input>
                        )}

                        <Select
                            className="SelectStyle"
                            label={t('common:translation.ItemperPage')}
                            placeholder={t('common:translation.ItemperPage')}
                            value={itemsByPage.toString()}
                            options={itemsPerPage.map((e: string) => ({ text: e.toString(), value: e }))}
                            onChange={value => setItemsByPage(parseInt(value))}
                        />
                    </div>
                    <div className="buttonsSearch">
                        <Button
                            type="secondary"
                            icon="badge-delete"
                            content={t('common:actions.ResetFilters')}
                            iconPosition="right"
                            onClick={() => {
                                setFilter({
                                    itemByPage: 5,
                                    indice: 1,
                                    companies: companyFilter?.data?.companies,
                                    totalItems: companyFilter?.data?.totalItems
                                });
                                setReset(old => !old);
                            }}
                        />
                        <Button
                            type="primary"
                            icon="filter1"
                            content={t('common:actions.ApplyFilters')}
                            iconPosition="right"
                            onClick={() => handleData()}
                        />
                    </div>
                    <br />
                    <DataTable
                        data={companyFilter?.data?.companies ?? []}
                        onCellClick={(data: ICellData) => navigate('company', { state: { id: data.rowData.id } })}
                    >
                        <DataTable.Column header={'ID'} field="id"></DataTable.Column>
                        <DataTable.Column header={t('user:info.Name')} field="name"></DataTable.Column>
                        {isFeatureHSWLEnabled ? (
                            <DataTable.Column header={t('user:company.IdSap')} field="idSap"></DataTable.Column>
                        ) : (
                            <DataTable.Column header={t('user:company.Id')} field="cnpj"></DataTable.Column>
                        )}
                        <DataTable.Pagination
                            disabledPages={[currentPage]}
                            activePage={currentPage}
                            itemsPerPage={companyFilter?.data?.itemByPage ?? itemsByPage}
                            totalItems={companyFilter?.data?.totalItems ?? 0}
                            onPageChange={(page: any) => {
                                handleData(page);
                            }}
                        ></DataTable.Pagination>
                    </DataTable>
                </>
            )}
        </>
    );
};

export default AdminTabManager;
