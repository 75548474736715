import 'styles/maintenanceReport.scss';
import { Button, Divider, Grid, Icon, Input, Select } from '@scuf/common';
import { LabelDescription } from 'components';
import {
    getTotalBudgetFinal,
    getTotalProductsSelected,
    getTotalServices,
    getTotalWithOptional,
    getTotalwithoutOptional
} from 'helpers';
import { BudgetDetailsModel, WorkOrderBudgerModel } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RMAFileAtom, cnpjMask, telephoneMask } from 'resources';
import FileModal from './fileModal';
import { useRecoilState } from 'recoil';
type Props = {
    approveDetails: WorkOrderBudgerModel;
    onChangeInfo: CallableFunction;
};
const ApprovalInfo: React.FC<Props> = ({ approveDetails, onChangeInfo }: Props) => {
    const { t } = useTranslation(['repaircenter']);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>();
    const [fileUp, setFileUp] = useRecoilState<File | null>(RMAFileAtom);
    const [details, setDetails] = useState<BudgetDetailsModel>();
    const budget = approveDetails;
    const attrToChange = [
        'UserName',
        'Phone',
        'ServicesCnpj',
        'ServicesEmail',
        'PartsCnpj',
        'PartsEmail',
        'EmailXml',
        'Obs',
        'SalesOrder'
    ];
    const optional = ['Obs'];
    const resetFile = (): void => {
        onChangeInfo('RequireOrderFile', null);
        setFileName('');
    };


    useEffect(() => {
        if (fileUp == undefined) return;
        approveDetails.approvedRequireOrderFile = fileUp;
    }, [fileUp]);

    return (
        <>
            <Divider />
            <Grid>
                <Grid.Column width={6}>
                    <h3>{t('rma.budget.Approval.Info')}</h3>
                    <Grid.Row>
                        {attrToChange.map((attr: string, index: number) => (
                            <Grid.Column width={6} key={attr}>
                                {attr !== 'SalesOrder' ? (
                                    <Input
                                        disabled={budget?.flagApproved !== 2}
                                        key={attr}
                                        label={t(`rma.budget.Approval.${attr}`)}
                                        value={(approveDetails[`approved${attr}` as keyof typeof approveDetails] as string) ?? ''}
                                        indicator={!optional.includes(attr) ? 'required' : 'optional'}
                                        fluid
                                        onChange={(value: string) => {
                                            if (attr.includes('Phone')) onChangeInfo(attr, telephoneMask(value));
                                            else if (attr.includes('Cnpj')) onChangeInfo(attr, cnpjMask(value));
                                            else onChangeInfo(attr, value);
                                        }}
                                    ></Input>
                                ) : (
                                    <Select
                                        disabled={budget.flagApproved !== 2}
                                        label={t(`rma.budget.Approval.${attr}`)}
                                        placeholder="Selecione a opção"
                                        indicator="required"
                                        value={approveDetails.approvedSalesOrder}
                                        options={[
                                            { value: true, text: 'Sim' },
                                            { value: false, text: 'Não' }
                                        ]}
                                        onChange={value => {
                                            onChangeInfo(attr, value);
                                        }}
                                    />
                                )}
                            </Grid.Column>
                        ))}
                        <Grid.Column width={6}>
                            <Button
                                icon={<Icon name="attachment" root="common" />}
                                size="small"
                                iconPosition="right"
                                content={t('common:actions.AddMotivo')}
                                onClick={() => setModalOpen(true)}
                            ></Button>

                            {isModalOpen && (
                                <FileModal
                                    onClose={() => setModalOpen(false)}
                                    open={isModalOpen}
                                    onAccept={(file: File) => {
                                        onChangeInfo('RequireOrderFile', file);
                                        setFileUp(file);
                                        setFileName(file.name);
                                    }}
                                    onReset={resetFile}
                                ></FileModal>
                            )}
                        </Grid.Column>
                        {fileName && (
                            <Grid.Column width={6}>
                                <h4>{`${t('common:translation.File')} ${fileName}`}</h4>
                                {<Icon name="delete" color="red" className="clickable" onClick={resetFile} />}
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={6}>
                    <h3>{t('rma.budget.Order.Info')}</h3>
                    <Grid.Row className="grid-with-gap">
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.TotalWithOptionals')}
                                value={`R$ ${getTotalWithOptional(budget)}`}
                            ></LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.TotalWithOutOptionals')}
                                value={`R$ ${getTotalwithoutOptional(budget)}`}
                            ></LabelDescription>
                        </Grid.Column>

                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.PISBenefit')}
                                value={
                                    budget.benefitPISCOFINS && budget.currency === 2
                                        ? `R$ ${(budget.amountPISCOF * budget.currencyRate).toFixed(2)}`
                                        : 0
                                }
                            ></LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.IPIBenefit')}
                                value={
                                    budget.benefitIPI && budget.currency === 2
                                        ? `R$ ${(budget.amountIPI * budget.currencyRate).toFixed(2)}`
                                        : 0
                                }
                            ></LabelDescription>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="grid-with-gap">
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.TotalSpareParts')}
                                value={`R$ ${getTotalProductsSelected(budget)}`}
                            ></LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.TotalServices')}
                                value={`R$ ${getTotalServices(budget).toFixed(2)}`}
                            ></LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <LabelDescription
                                title={t('rma.budget.Order.TotalICMSST')}
                                value={`R$ ${budget.amountST.toFixed(2)}`}
                            ></LabelDescription>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <LabelDescription title={t('rma.budget.Order.Total')}>
                                <div className="badge-total">{`R$ ${getTotalBudgetFinal(budget)}`}</div>
                            </LabelDescription>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </>
    );
};

export default ApprovalInfo;
