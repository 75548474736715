import { atom } from 'recoil';

export type NotificationModel = {
    message?: string | null;
    type?: 'critical' | 'important' | 'information' | 'success';
    // details?: string | null; 
};

export const notificationAtom = atom<NotificationModel>({
    key: 'notificationAtom',
    default: {
        message: null,
        type: 'critical',
        // details: null 
    }
});
