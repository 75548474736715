import { Chat } from 'components';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { ChatTable, SparePartsChatTable } from 'models/repaircenter/detailsRma';
import { UserRole } from 'models/user/sso';
import { useEffect, useState } from 'react';
import { SPARE_PARTS } from 'resources';
import 'styles/repaircenter.scss';
type SparePartsChatType = {
    orderId?: number;
    company?: any;
};

function SparePartsChat({ orderId, company }: SparePartsChatType) {
    const [enable, setEnable] = useState<boolean>(true);
    const [messages, loadMessages, loading, updateMessages] = useFetch<{ sparePartsId: number; roleId: number }, ChatTable[]>({
        url: SPARE_PARTS.getAllMessages
    });
    const { user } = useUser();

    const [, addMessage] = useFetch<SparePartsChatTable, ChatTable>({
        url: SPARE_PARTS.chatAddMessage,
        method: 'POST'
    });

    useEffect(() => {
        if (orderId != undefined) loadMessages({ sparePartsId: orderId, roleId: parseInt(user?.ROLE!) });
    }, [orderId]);

    return (
        <Chat
            loading={loading}
            onSend={async (message, enabled) => {
                await addMessage(
                    {
                        message,
                        orderId: orderId!,
                        userName: user?.NAME! ?? '',
                        itWasReadByAdmin: [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.ROLE!) ? true : false,
                        itWasReadByCustomer: [UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) ? true : false,
                        ssoId: user?.SSOID!,
                        companyId: user?.COMPANY!,
                        roleId: parseInt(user?.ROLE!),
                        enable: enabled ?? true
                    },
                    current => {
                        if (!!current.data) {
                            updateMessages(old => [...old, current.data as ChatTable]);
                        }
                    }
                );
            }}
            messages={messages?.data ?? []}
            companies={company}
        />
    );
}
export default SparePartsChat;
