import useUser from 'helpers/hooks/useUser';
import { UserRole } from 'models/user/sso';
import React, { ReactNode, ReactElement } from 'react';

type Props = {
    roles: (keyof typeof UserRole)[];
    children: ReactNode | ((hasRole: boolean) => ReactElement);
};

export const RoleComponent: React.FC<Props> = ({ roles, children }: Props) => {
    const { user } = useUser();
    const hasRole = roles.some((e: keyof typeof UserRole) => UserRole[e] === user?.ROLE);

    if (typeof children === 'function') {
        return children(hasRole);
    }

    return <>{hasRole ? children : null}</>;
};
