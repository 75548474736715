import { AxiosDownloadFile } from 'axios-http';
import { MAINTENANCES_REPORTS } from 'resources/links';

export function downloadFile(fileName: string, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: MAINTENANCES_REPORTS.getFile,
        param: { fileName },
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}

export function downloadFileExcel(date: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: MAINTENANCES_REPORTS.reportExcel,
        param: date,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });

}
