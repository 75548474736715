import { FC, useEffect, useState } from 'react';
import { Button, Select, Header, Modal } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { hslPartsQuotation } from 'resources/atoms/hslAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ListQuotations, SelectOption } from 'models/hsl/general';
import { COMPANY_MANAGER, companyDtoAtom, HSL } from 'resources';
import { CompanyDTO } from 'models/userManager/addCustomer';
import AddPartsQuotation from './addPartsQuotation';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { downloadQuotationFile } from 'resources/api/hsl_api/fileReports';

interface AddQuotationProps {
    onSave: () => void;
    onBack: () => void;
}

const SUPERADMIN = '1';
const ADMIN = '2';

const AddQuotation: FC<AddQuotationProps> = ({ onBack }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const [partsQuotationRecoil, setPartsQuotationRecoil] = useRecoilState(hslPartsQuotation);
    const companyInfo = useRecoilValue<CompanyDTO>(companyDtoAtom);
    const { user: userDetails } = useUser();
    const [cancel, setCancel] = useState(false);
    const [partners, setPartners] = useState<CompanyDTO[]>([]);
    const [partnersOptions, setPartnersOptions] = useState<SelectOption[]>([]);
    const [selectPartner, setSelectPartner] = useState<SelectOption>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [quotationNumber, setQuotationNumber] = useState<number>(0);

    const isAdmin = userDetails?.ROLE === SUPERADMIN || userDetails?.ROLE === ADMIN;

    const [, getPartners] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, createQuotation, loadingCreating] = useFetch<ListQuotations[], any>({
        url: HSL.createQuotation,
        method: 'POST',
        onError: t('notification:critical.Create')
    });

    useEffect(() => {
        if (isAdmin) {
            getAllPartners();
        } else {
            setPartnersOptions([
                {
                    value: companyInfo?.name ?? '',
                    text: companyInfo?.name ?? ''
                }
            ]);
        }
    }, []);

    const getAllPartners = () => {
        getPartners(null, resp => {
            if (resp.data) {
                setPartners(resp.data);
                const partnersData = resp.data.map((partner: CompanyDTO) => ({
                    value: partner?.name,
                    text: partner?.name
                }));
                setPartnersOptions(partnersData);
            }
        });
    };

    const onSubmit = async () => {

        const partnerSelected = partners.find(partner => partner.name == selectPartner);
        const dataList = partsQuotationRecoil.map(part => ({
            Partner: isAdmin ? partnerSelected?.name : companyInfo.name,
            PartnerIdSap: isAdmin ? partnerSelected?.idSap : companyInfo.idSap,
            ResponsibleCompanyId: isAdmin ? partnerSelected?.id : companyInfo.id,
            PartNumber: part.PartNumber,
            Description: part.PartNumberDescription,
            ListPrice: part.PriceItem,
            Quantity: part.Quantity,
            TotalPriceItem: part.TotalPriceItem,
            TotalPrice: totalPrice
        }));
        console.log("onSubmit")
        await createQuotation(dataList, resp => {
        console.log("crateQuotaton")
            if (resp.data) {
               
                setQuotationNumber(resp.data[0].QuotationNumber);
                setPartsQuotationRecoil([]);
            }
        });
    };

    const handleDownloadFile = async (quotationNumber: number) => {
        setLoading(true);

        await downloadQuotationFile(
            { quotationNumber: quotationNumber },
            `quotation.pdf`,
            () => { },
            () => { }
        );

        setLoading(false);
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:quotation')} logo={false} menu={false}></Header>
                <div className="form3Columns partnerQuotation">
                    {isAdmin ? (
                        <Select
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partnersOptions}
                            value={selectPartner}
                            search={true}
                            onChange={value => setSelectPartner(value)}
                        />
                    ) : (
                        <Select
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partnersOptions}
                            defaultValue={companyInfo.name}
                            disabled={true}
                        />
                    )}
                </div>
                <div className="divQuotation">
                    <AddPartsQuotation setTotalPrice={setTotalPrice} />
                </div>
                <div className="buttonsQuotatios">
                    <Button
                        type="primary"
                        content={t('common:actions.Save')}
                        iconPosition="right"
                        onClick={() => {
                            onSubmit();
                        }}
                        disabled={partsQuotationRecoil.length === 0 || (isAdmin ? !selectPartner : false)}
                        loading={loadingCreating}
                    />
                    <Button
                        type="secondary"
                        content={t('common:actions.Back')}
                        iconPosition="right"
                        onClick={() => {
                            setCancel(true);
                        }}
                    />
                    <Button
                        type="secondary"
                        icon="print"
                        content={t('hsl:translation.Print')}
                        iconPosition="right"
                        disabled={quotationNumber === 0}
                        onClick={() => {
                            handleDownloadFile(quotationNumber);
                        }}
                        loading={loading}
                    />
                </div>
            </div>
        );
    };

    const renderModalCancelQuotation = () => {
        return (
            <Modal
                open={cancel}
                closeIcon={true}
                onClose={() => setCancel(false)}
                closeOnDimmerClick={false}
                size="small"
                className="modal-excluir-content"
            >
                <Modal.Header>
                    <h1>{t('hsl:translation.Cancel')}</h1>
                </Modal.Header>
                <Modal.Content>
                    <p>{t('hsl:translation.ConfirmCancelQuotation')}</p>
                </Modal.Content>

                <Modal.Footer>
                    <Button type="primary" content={t('common:translation.Confirm')} onClick={onBack} />
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            {searchFieldsRender()}
            {cancel && renderModalCancelQuotation()}
        </>
    );
};

export default AddQuotation;
