import { Button, DatePicker, Grid, Icon, Select } from '@scuf/common';
import { strict } from 'assert';
import { HttpResponse, HttpResponseArquivo, HttpResponseRmaReport } from 'axios-http';
import { LabelDescription } from 'components';
import useUser from 'helpers/hooks/useUser';
import { RMAreportTypes, RmaFilterModel, RmaReportModel } from 'models';
import { UserRole } from 'models/user/sso';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FilterAtom, notificationAtom } from 'resources';
import { getRmasLite } from 'resources/api/repaircenter_api';

import { getReportFile, getSatisfactionSurveyReport, rmaUser } from 'resources/api/repaircenter_api/getReport';

function RmaReport() {
    const [error, setError] = useState<string>('');
    const [rangeValue, setRangeValue] = useState<any>(null);
    const [to, setTo] = useState<any>('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const [typeReport, setTypeReport] = useState<any>('');
    const [disabele, setDisabele] = useState<boolean>(true);
    const [fileName, setFileName] = useState<string>('');
    const { user: userDetails } = useUser();
    const [rmaFilter,] = useRecoilState<RmaFilterModel>(FilterAtom);
    const [rmaReportfilter, setRmaReportfilter] = useState<RmaReportModel>({
        ssoId: userDetails?.SSOID!
    } as RmaReportModel);
    const userRole = UserRole;
    const reportTypesAdmin: RMAreportTypes[] = [
        { text: 'RMA', value: 0, restrict: false },
        { text: 'Ordens de Serviço', value: 1, restrict: false },
        { text: 'Pesquisa de Satisfação', value: 3, restrict: true },
        { text: 'Aprovação OS', value: 4, restrict: true },
        { text: 'Chat', value: 5, restrict: true },
    ];

    const reportTypes: RMAreportTypes[] = [
        { text: 'RMA', value: 0, restrict: false },
        { text: 'Ordens de Serviço', value: 1, restrict: false },

    ];

    async function downloadReportFile(reportData: HttpResponseArquivo, date: Date) {
        if (reportData.status != 200) {
            setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
            setLoading(false);
        } else {
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const mounth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();
            const hour = date.getHours();
            const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            const blob = new Blob([reportData.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `report${fileName}-${day}-${mounth}-${year}-${hour}h-${minutes}min`;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }

    }
    async function ConverterDate(DataStart: any, DataEnd: any, typeReport: any) {
        if (!loading) {
            setLoading(true);
            const date = new Date();
            const dateStart = new Date(DataStart);
            const dateEnd = new Date(DataEnd);

            if (typeReport == 3) {
                const resp = await getSatisfactionSurveyReport(dateEnd, dateStart);
                downloadReportFile(resp, date)
                setLoading(false);
            } else {
                let users: HttpResponse = { status: 0, error: false, message: '', data: null };
                let rmaReport: HttpResponseRmaReport;

                if (typeReport == 0) {
                    rmaReport = await getRmasLite({ ...rmaFilter, dateStart: dateStart, dateEnd: dateEnd })
                    users = await rmaUser(Array.from(new Set(rmaReport.data?.map(item => item?.ssoId) || [])).filter(id => id !== undefined) as string[]);
                }
                const resp = await getReportFile(dateEnd, dateStart, typeReport, userDetails?.SSOID, userDetails?.ROLE, userDetails?.COMPANY, users.data);
                downloadReportFile(resp, date)
                setLoading(false);
            }
        }
    }


    async function disabelButton() {
        if (rmaReportfilter.DateEnd == null || (rmaReportfilter.DateStart == null && typeReport == '')) {
            setDisabele(true);
        } else {
            setDisabele(false);
        }
    }

    async function clear() {
        if (to.to == '' && to.from == '' && typeReport == '') {
            return;
        } else {
            setRangeValue({ to: '', from: '' });
            setTypeReport('');

            disabelButton();
        }
    }

    useEffect(() => {
        disabelButton();

    }, [rmaReportfilter]);

    useEffect(() => {
        switch (typeReport) {
            case 0:
                setFileName('RMA');
                break;
            case 1:
                setFileName('ORDENS-DE-SERVICO');
                break;
            // case 2:
            //     setFileName('CHAT');
            //     break;
            case 3:
                setFileName('PESQUISA-DE-SATISFACAO');
                break;
            case 4:
                setFileName('Aprovação-OS');
                break;
        }
    }, [typeReport]);

    return (
        <div className="form4Columns">
            <div>
                <Select
                    indicator="required"
                    className="SelectStyle"
                    label={t('common:translation.Type')}
                    placeholder={t("common:translation.Type")}
                    options={userDetails?.ROLE == userRole.SUPERADMIN ?
                        reportTypesAdmin : userDetails?.ROLE == userRole.ADMIN ?
                            reportTypesAdmin : reportTypes}
                    value={typeReport}
                    onChange={e => {
                        setTypeReport(e);
                        disabelButton();
                    }}
                />

                <DatePicker
                    type="daterange"
                    label={t('common:translation.Period')}
                    error={error}
                    rangeValue={{ from: rmaReportfilter.DateStart ?? '', to: rmaReportfilter.DateEnd ?? '' }}
                    displayFormat={'DD/MM/YYYY'}
                    closeOnDocumentClick={true}
                    value={to}
                    onTextChange={value => setError(value)}
                    onRangeSelect={({ to, from }) => {
                        setRmaReportfilter(old => ({ ...old, DateStart: from, DateEnd: to }));
                        // setTo({
                        //     to: typeof to === 'object' ? setDateEnd(new Date(to)) : '',
                        //     from: typeof from === 'object' ? setDateStart(new Date(from)) : '',
                        //     ...(to && from && { rangeValue: { to, from } })
                        // });
                        disabelButton();
                    }}
                />

            </div>
            <div className="buttonsSearch">
                <Button
                    type="secondary"
                    icon="badge-delete"
                    content={t('common:actions.ResetFilters')}
                    onClick={() => clear()}
                    iconPosition="right"
                />
                <Button
                    type="primary"
                    icon="report"
                    content={t('common:actions.Generate')}
                    loading={loading}
                    iconPosition="right"
                    disabled={disabele}
                    onClick={() => ConverterDate(rmaReportfilter.DateStart, rmaReportfilter.DateEnd, typeReport)}
                />
            </div>

            <div className="container-large-color-icon">
                <Icon root="common" name="badge-important" color="blue" size="xlarge" />
                <h2 className="h2-icon">Relatório RMA</h2>
                <div></div>
            </div>
            <div className="container-large-color">
                <h3 className="h3-icon">
                    {t('maintenancereport:translation.InfoReport')}
                </h3>
                <Grid className="body-content">
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <LabelDescription title="RMA" value={t('repaircenter:rma.order.RmaNumber')} className="uppercase" />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('common:translation.Type')}
                                value={t('repaircenter:rma.InfoType')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.order.ShippingInvoice')}
                                value={t('      repaircenter:rma.order.InfoShippingInvoice')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.order.ShippingInvoiceValue')}
                                value={t('repaircenter:rma.order.InfoShippingInvoiceValue')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:LegalPerson')}
                                value={t('repaircenter:rma.order.InfoLegalPerson')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title="CPF/CNPJ"
                                value={t('repaircenter:rma.order.InfoPersonCpfCnpj')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:company.Name')}
                                value={t('repaircenter:rma.report.InfoName')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('common:translation.Contact')}
                                value={t('repaircenter:rma.report.InfoContact')}

                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title="E-mail"
                                value={t('repaircenter:rma.report.InfoEmail')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:address.Phone')}
                                value={t('repaircenter:rma.report.InfoPhone')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('usermanager:translation.DateOpen')}
                                value={t('repaircenter:rma.report.InfoDate')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:translation.Freight')}
                                value={t('repaircenter:rma.report.InfoFreight')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.FreightPresentation')}
                                value={t('repaircenter:rma.report.InfoFreightPresentation')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.order.PostCode')}
                                value={t('repaircenter:rma.report.InfoPostCode')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.ShippingDetails')}
                                value={t('repaircenter:rma.report.InfoShippingDetails')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('common:translation.Address')}
                                value={t('repaircenter:rma.report.InfoAddress')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.CompletionDate')}
                                value={t('repaircenter:rma.report.InfoCompletionDate')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription title="Status" value={t('Status do RMA')} className="uppercase" />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.ItemType')}
                                value={t('repaircenter:rma.report.InfoItemType')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.EquipmentModel')}
                                value={t('repaircenter:rma.report.InfoEquipmentModel')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.SNEquipment')}
                                value={t('repaircenter:rma.report.InfoSNEquipment')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.DefectReported')}
                                value={t('repaircenter:rma.report.InfoDefectReported')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.order.Accessories')}
                                value={t('repaircenter:rma.report.InfoAccessories')}
                                className="uppercase"
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('common:status.Situation')}
                                value={t('Indica se o Envio para o Centro de Reparos foi Aprovado.')}
                                className="uppercase"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
            <div className="container-large-color-icon">
                <Icon root="common" name="badge-important" color="blue" size="xlarge" />
                <h2 className="h2-icon">{t('repaircenter:rma.report.ServiceOrders')}</h2>
                <div></div>
            </div>
            <div className="container-large-color">
                <h3 className="h3-icon">
                    {t('repaircenter:rma.report.InfoServiceOrders')}
                </h3>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <LabelDescription title={t('common:translation.Number')} value={t('common:translation.ServiceOrderNumber')} className="uppercase" />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="RMA"
                                value={t('repaircenter:rma.report.InfoRMANumber')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:LegalPerson')}
                                value={t('repaircenter:rma.order.InfoLegalPerson')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="CPF/CNPJ"
                                value={t('repaircenter:rma.order.InfoPersonCpfCnpj')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:company.Name')}
                                value={t('repaircenter:rma.report.InfoName')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('common:translation.Contact')}
                                value={t('repaircenter:rma.report.InfoContact')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="E-mail"
                                value={t('repaircenter:rma.report.InfoEmail')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('user:address.Phone')}
                                value={t('repaircenter:rma.report.InfoPhone')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('userManegments:translation.DateOpen')}
                                value={t('repaircenter:rma.report.InfoDateOpen')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title={t('repaircenter:rma.report.DateAprRep')}
                                value={t('repaircenter:rma.report.InfoDateAprRep')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Data de Emissão Nota"
                                value={t('Data em que a Nota Fiscal de Retorno foi Emitida.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Data de Expedição"
                                value={t('Data em que o Equipamento/Acessório saiu do Centro de Reparos.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Código de Rastreio"
                                value={t('Código de rastreio do Equipamento.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Transportadora"
                                value={t('Empresa responsável por realizar o transporte.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Status"
                                value={t('Indica a situação atual do Equipamento/Acessório.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="PN do Equipamento"
                                value={t(' Part Number do Equipamento ou Acessório')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="SN do Equipamento"
                                value={t('Número de Série do Equipamento. Não disponível para Acessórios.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Situação"
                                value={t(
                                    "Indica se foi Aprovado ou Reprovado pelo Cliente. Se coberto pela garantia, a indicação será 'Garantia"
                                )}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Garantia de Fábrica"
                                value={t('Indica se o Equipamento/Acessório possui Garantia de Fábrica.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Garantia de Serviços"
                                value={t('Indica se o Equipamento/Acessório possui Garantia de Serviços.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Taxa do Dólar"
                                value={t('Taxa do dólar utilizada para gerar o Orçamento.')}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Total"
                                value={t(
                                    'Total do Orçamento (incluso peças/serviços) opcionais e obrigatórios). Disponível somente quando não se tratar de Garantia.'
                                )}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Total s/ Opcional"
                                value={t(
                                    'Total sem as peças opcionais, caso existam. Disponível somente quando não se tratar de Garantia.'
                                )}
                                className="uppercase"
                            />
                        </Grid.Column>

                        <Grid.Column width={6}>
                            <LabelDescription
                                title="Total Aprovado"
                                value={t(
                                    'Valor Total aprovado pelo Cliente. Disponível somente quando não se tratar de Garantia.'
                                )}
                                className="uppercase"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

export default RmaReport;
