import { Accordion, Loader, Tab } from '@scuf/common';
import { RmaModelLite } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { REPAIR_CENTER, RmaLite } from 'resources';
import {
    AddressAccordion,
    Administrative,
    EquipmentsAccordion,
    FileTab,
    OrderAccordion,
    Requests,
    ServiceOrdersAccordion,
    ShippingAccordion
} from './components';
import useFetch from 'helpers/hooks/useFetch';
import Alert from 'components/alert';
import RepairCenterChat from './components/tabs/chat';
import useUser from 'helpers/hooks/useUser';
import { RmaChatTable } from 'models/repaircenter/detailsRma';
import { useNavigate } from 'react-router-dom';

const RmaDetailsSelected: React.FC = () => {
    const { user: userDetails } = useUser();
    const { t } = useTranslation(['repaircenter', 'common']);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const { user } = useUser();
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const navigate = useNavigate();
    const location = useLocation();

    const [, getRmaDetails, loading] = useFetch<{ Id: number }>({ url: REPAIR_CENTER.getRmaDetails, redirectOnError: true });
    const [chats, loadChats, updateChat] = useFetch<
        { roleId: number; ssoId: string; companyId: number; rmaId: number },
        RmaChatTable[]
    >({
        url: REPAIR_CENTER.getAllChats
    });

    useEffect(() => {
        const rmaId = rmaState?.id || extractRmaIdFromUrl(location.pathname);
        if (user) {
            loadChats({
                roleId: parseInt(user.ROLE),
                ssoId: user.SSOID,
                companyId: user.COMPANY,
                rmaId: rmaId
            });
        }

        getDetails(rmaId);
        addRmaIdToUrl(location.pathname);
    }, []);

    useEffect(() => {
        UnreadMessage();
    }, [chats]);

    const extractRmaIdFromUrl = (url: string): number => {
        const id = url.lastIndexOf('/');
        const rmaid = Number(url.substring(id + 1));
        return rmaid;
    };

    const addRmaIdToUrl = (url: string) => {
        if (extractRmaIdFromUrl(url)) {
            return;
        } else {
            navigate('/repair-center');
        }
    };

    const UnreadMessage = () => {
        chats?.data?.forEach(e => {
            if ((user?.ROLE == '1' || user?.ROLE == '2') && e.itWasReadByAdmin === false) {
                setUnreadMessage(parseInt(e.message));
            } else if ((user?.ROLE == '3' || user?.ROLE == '4') && e.itWasReadByCustomer === false) {
                setUnreadMessage(parseInt(e.message));
            }
        });
    };

    async function getDetails(rmaNumberClick: number) {
        await getRmaDetails({ Id: rmaNumberClick }, current => {
            setRmaState(current.data);
        });
    }

    return (
        <>
            {loading ? (
                <Loader text={t('common:actions.Loading')} />
            ) : (
                <div className="wrap-content">
                    <Tab className="tab-with-divider">
                        <Tab.Pane title={t('common:translation.Summary')}>
                            <Accordion>
                                <Accordion.Content title={t('rma.order.Order')}>
                                    <OrderAccordion />
                                </Accordion.Content>

                                <Accordion.Content title={t('rma.order.ReceiveAddress')}>
                                    <AddressAccordion />
                                </Accordion.Content>

                                <Accordion.Content title={t('common:translation.Shipping')}>
                                    {!!rmaState?.package?.id ? (
                                        <ShippingAccordion />
                                    ) : (
                                        <Alert text={t('notification:information.NoData')}></Alert>
                                    )}
                                </Accordion.Content>

                                <Accordion.Content
                                    title={`${t('common:translation.Equipments')}/${t('common:translation.Accessories')}`}
                                >
                                    <EquipmentsAccordion />
                                </Accordion.Content>

                                <Accordion.Content title={t('translation.ServiceOrders')}>
                                    <ServiceOrdersAccordion />
                                </Accordion.Content>
                            </Accordion>
                        </Tab.Pane>
                        <Tab.Pane title={t('common:tabs.Chat')} badge={unreadMessage}>
                            <RepairCenterChat rmaId={rmaState?.id} />
                        </Tab.Pane>
                        <Tab.Pane title={t('common:tabs.Files')}>
                            <FileTab />
                        </Tab.Pane>
                        <Tab.Pane title={t('common:tabs.Requests')}>
                            <Requests />
                        </Tab.Pane>

                        {userDetails?.ROLE == '1' || userDetails?.ROLE == '2' ? (
                            <Tab.Pane title={t('common:tabs.Administrative')}>
                                <Administrative />
                            </Tab.Pane>
                        ) : (
                            []
                        )}
                    </Tab>
                </div>
            )}
        </>
    );
};

export default RmaDetailsSelected;
