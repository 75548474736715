import { AxiosDownloadFile } from 'axios-http';
import { HSL } from 'resources/links';

export function downloadFileExcelContract(filters: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: HSL.getContractExcelFile,
        param: filters,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}

export function downloadFileExcelRepair(filters: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: HSL.getRepairExcelFile,
        param: filters,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}

export function downloadFileExcelInventory(filters: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: HSL.getInventoryExcelFile,
        param: filters,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}

export function downloadTemplateExcel(filters: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: HSL.getTemplateExcelFile,
        param: filters,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}

export function downloadQuotationFile(filters: any, downloadName: string, handleSuccess: any, handleError: any) {
    return AxiosDownloadFile({
        url: HSL.getFileQuotation,
        param: filters,
        downloadName: downloadName,
        onSuccess: handleSuccess,
        onError: handleError
    });
}
