import { Badge, BadgedIcon, Button, DatePicker, Header, Icon, Input, Loader, Select } from '@scuf/common';
import DataTable from '@scuf/datatable/dist/components/DataTable/DataTable';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import '@scuf/datatable/honeywell/theme.css';
import { getRoles } from '@testing-library/react';
import { formatDate } from 'helpers';
import { getColors } from 'helpers/getBadgeColors';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { rmaTypeColors } from 'models/repaircenter/detailsRma';
import { Stauts, UserModel, typeOpt } from 'models/spareParts';
import { OrderCartDTO, OrderFilterDTO } from 'models/spareParts/orderById';
import { UserRole } from 'models/user/sso';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { SPARE_PARTS, UserAtom, notificationAtom } from 'resources';
import { GetUserById } from 'resources/api/usermanager_api/clients';
import { orderCartAtom, orderFilterAtom } from 'resources/atoms/spareParts';

function SparePartsSearch() {
    const [reset, setReset] = useState(true);
    const navigate: NavigateFunction = useNavigate();
    const { t } = useTranslation(['spareparts', 'common']);
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);
    const [filter, setFilter] = useRecoilState<OrderFilterDTO>(orderFilterAtom);
    const [orderStatus, setOrderStatus] = useState<Stauts[]>([]);
    const { user } = useUser();
    const [userData, setUser] = useRecoilState<UserModel>(UserAtom);
    const setNotification = useSetRecoilState(notificationAtom);

    let ssoId = user?.SSOID!;

    const handleData = async (): Promise<void> => {
        const resp = await GetUserById({ ssoId });
        if (!resp.error) {
            setUser(resp.data);
        } else setNotification(old => ({ ...old, message: 'LoadInfo' }));
    };

    useEffect(() => {
        const f = async () => {
            await Promise.all([handleData()]);
        };
        f();
    }, []);

    const itemsPerPage = [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 15, text: '15' },
        { value: 20, text: '20' }
    ];

    const [dataOrder, getOrders, loading] = useFetch<OrderFilterDTO>({
        url: SPARE_PARTS.getOrders,
        method: 'POST',
        redirectOnError: true
    });

    async function getOrderFilterDetails(indice = 1, email = user?.EMAIL) {
        await getOrders({ ...filter, indice, email }, current => {
            setFilter(old => ({
                ...old,
                indice: current.data.indice,
                totalItems: current.data.totalItems
            }));
        });
    }

    const [, getCustomerCartItems] = useFetch<{ Email: string }>({ url: SPARE_PARTS.getItemsInCart });

    async function getItemCartNumbers() {
        await getCustomerCartItems({ Email: user?.EMAIL! }, current => {
            setdefaultCart(old => ({ ...old, orderItems: current.data }));
        });
    }

    const [, getAllStatus] = useFetch({ url: SPARE_PARTS.getAlltatus });

    async function getOrderStatus() {
        await getAllStatus(undefined, current => setOrderStatus(current.data));
    }

    useEffect(() => {
        getOrderStatus();
        getItemCartNumbers();
    }, []);

    useEffect(() => {
        getOrderFilterDetails();
    }, [, reset]);

    return (
        <>
            {(loading && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <div>
                        <Header title={t('spareparts:translation.OrderPartsAccessories')} logo={false} menu={false}>
                            <Button
                                type="primary"
                                size="small"
                                icon={<Icon name="doc-search" size="large" root="building" />}
                                badge="20"
                                onClick={() => navigate('cart')}
                                content={t('common:translation.PartNumber')}
                            />
                            <Button
                                type="primary"
                                size="small"
                                icon={<Icon name="shopping-bag" size="large" root="global" />}
                                badge="20"
                                onClick={() => navigate('cart')}
                                content={`${t('translation.Car')} (${defaultCart?.orderItems?.length ?? 0})`}
                            />
                        </Header>
                        <div className="form4Columns">
                            <Input
                                placeholder="ID"
                                label="ID"
                                type="number"
                                onChange={value => setFilter(old => ({ ...old, id: parseInt(value) }))}
                                value={filter?.id?.toString()}
                            />
                            <Input
                                placeholder={t('common:translation.Number')}
                                label={t('common:translation.Number')}
                                onChange={value => setFilter(old => ({ ...old, numberPV: value }))}
                                value={filter?.numberPV}
                            />
                            <DatePicker
                                type="daterange"
                                label={t('common:translation.Period')}
                                rangeValue={{ from: filter?.dateStart ?? '', to: filter?.dateEnd ?? '' }}
                                displayFormat={'DD-MM-YYYY'}
                                onRangeSelect={({ to, from }) => {
                                    setFilter(old => ({ ...old, dateStart: from, dateEnd: to }));
                                }}
                            />
                            {(userData?.roleId.toString() !== UserRole.CLIENT ||
                                userData?.roleId.toString() !== UserRole.CLIENTADMIN) && (
                                    <Input
                                        placeholder={t('common:translation.Customer')}
                                        label={t('common:translation.Customer')}
                                        onChange={value => setFilter(old => ({ ...old, companyName: value }))}
                                        value={filter?.companyName}
                                    />
                                )}
                        </div>
                        <div className="form4Columns">
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.Status')}
                                placeholder={t('common:translation.Status')}
                                options={orderStatus.map(e => ({ text: e.name, value: e.id }))}
                                onChange={value => setFilter(old => ({ ...old, statusId: value }))}
                                value={filter?.statusId}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.Type')}
                                placeholder={t('common:translation.Type')}
                                options={typeOpt}
                                onChange={value => setFilter(old => ({ ...old, typeId: value }))}
                                value={filter?.typeId}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.ItemperPage')}
                                placeholder={t('common:translation.ItemperPage')}
                                options={itemsPerPage}
                                value={filter?.itemsByPage}
                                onChange={value => setFilter(old => ({ ...old, itemsByPage: parseInt(value) }))}
                            />
                        </div>

                        <div className="buttonsSearch">
                            <Button
                                type="secondary"
                                icon="badge-delete"
                                content={t('common:actions.ResetFilters')}
                                iconPosition="right"
                                onClick={() => {
                                    setFilter(old => ({ indice: 1, itemsByPage: old.itemsByPage }));
                                    setReset(old => !old);
                                }}
                            />
                            <Button
                                type="primary"
                                icon="filter1"
                                content={t('common:actions.ApplyFilters')}
                                iconPosition="right"
                                onClick={() => getOrderFilterDetails()}
                            />
                        </div>
                    </div>

                    <div className="div-datatable">
                        <DataTable
                            data={dataOrder?.data?.orders ?? []}
                            search
                            rows={filter?.itemsByPage}
                            onCellClick={(e: ICellData) => navigate('order', { state: { Id: e.rowData.id } })}
                        >
                            <DataTable.Column field="id" header="ID" initialWidth={'10%'} sortable />
                            <DataTable.Column
                                field=""
                                header={t('common:translation.Number')}
                                initialWidth={'10%'}
                                renderer={e => (
                                    <Badge
                                        color={
                                            e.rowData.numberPV !== '' &&
                                                e.rowData.numberPV !== '00000' &&
                                                e.rowData.numberPV !== null
                                                ? undefined
                                                : 'yellow'
                                        }
                                    >
                                        {e.rowData.numberPV !== '' &&
                                            e.rowData.numberPV !== '00000' &&
                                            e.rowData.numberPV !== null
                                            ? e.rowData.numberPV
                                            : e.rowData.numberQuotation}
                                    </Badge>
                                )}
                                sortable
                            />
                            <DataTable.Column
                                field=""
                                header={t('common:translation.Type')}
                                initialWidth={'15%'}
                                renderer={e => (
                                    <Badge color={getColors(e.rowData.type.id, rmaTypeColors)}>{e.rowData.type.name}</Badge>
                                )}
                                sortable
                            />
                            <DataTable.Column
                                field=""
                                header={t('common:translation.Customer')}
                                renderer={e => <Badge>{e.rowData.companyName}</Badge>}
                                sortable
                            />
                            <DataTable.Column
                                field=""
                                header={t('common:translation.Status')}
                                sortable
                                renderer={e => (
                                    <Badge color={getColors(e.rowData.status.id, rmaTypeColors)}>{e.rowData.status.name}</Badge>
                                )}
                            />
                            <DataTable.Column
                                field=""
                                header={t('common:translation.Date')}
                                renderer={e => <>{formatDate(e.rowData.createdAt)}</>}
                                sortable
                            />
                            <DataTable.Pagination
                                activePage={filter?.indice}
                                totalItems={filter?.totalItems!}
                                itemsPerPage={filter?.itemsByPage}
                                disabledPages={[filter?.indice!]}
                                onPageChange={pageNumber => {
                                    getOrderFilterDetails(pageNumber as number);
                                    setFilter(old => ({ ...old, indice: pageNumber as number }));
                                }}
                            />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
}

export default SparePartsSearch;
