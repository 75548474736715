import { Tab } from '@scuf/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'styles/repaircenter.scss';
import { RmaAdminChat, RmaReport, RmaSearch, RmaSummary } from './components';
import ManageEOSmodels from './components/tabs/manageEOSmodels';
import useUser from 'helpers/hooks/useUser';
import { UserRole } from 'models/user/sso';
import { RmaChatTable } from 'models/repaircenter/detailsRma';
import { ChatRma, REPAIR_CENTER } from 'resources';
import useFetch from 'helpers/hooks/useFetch';
import { useRecoilState } from 'recoil';
import ServiceOrders from './components/tabs/serviceOrders';

function RepairCenter() {
    const [width, setWidth] = useState<number>(0);
    var [numResult, setNumResult] = useState<any>([]);
    const { user } = useUser();
    const { t } = useTranslation(['repaircenter', 'common']);
    const [chatsRma, setChatsRmas] = useRecoilState<RmaChatTable[]>(ChatRma);
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [chats, loadChats, loading, updateChat] = useFetch<
        { roleId: number; ssoId: string; companyId: number },
        RmaChatTable[]
    >({
        url: REPAIR_CENTER.getAllChats
    });

    function handleResize() {
        setNumResult(10);
        setWidth(window.innerWidth);
    }
    const UnreadMessage = () => {
        chats?.data?.forEach(e => {
            if (user?.ROLE === '1' || user?.ROLE === '2') {
                if (e.itWasReadByAdmin === false) {
                    setUnreadMessage(unreadMessage => unreadMessage + 1);
                }
            } else if (user?.ROLE === '3' || user?.ROLE === '4') {
                if (e.itWasReadByCustomer === false) {
                    setUnreadMessage(messageRead => messageRead + 1);
                }
            }
        });
    };
    useEffect(() => {
        UnreadMessage();
        setChatsRmas(chats?.data?.map((e: RmaChatTable) => ({ ...e }))!);
    }, [chats]);

    useEffect(() => {
        loadChats({ roleId: parseInt(user?.ROLE!), ssoId: user?.SSOID!, companyId: user?.COMPANY! });
        setWidth(window.innerWidth);

        UnreadMessage();
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <div className="container wrap-content">
            <Tab defaultActiveIndex={0} className="tab-with-divider">
                <Tab.Pane title={t('RMA')}>
                    <RmaSearch />
                </Tab.Pane>
                <Tab.Pane title={t('Consulta Serial')}>
                    <RmaSummary />
                </Tab.Pane>
                <Tab.Pane title={t('common:translation.Report')}>
                    <RmaReport />
                </Tab.Pane>
                {[UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.ROLE!)
                    ? [<Tab.Pane title={t('manage_eos.Name')}>{<ManageEOSmodels />}</Tab.Pane>]
                    : []}
                <Tab.Pane title={t('common:tabs.Chat')} badge={unreadMessage} color="red">
                    <RmaAdminChat />
                </Tab.Pane>
                <Tab.Pane title={t('repaircenter:translation.ServiceOrders')}>
                    <ServiceOrders />
                </Tab.Pane>
            </Tab>
        </div>
    );
}

export default RepairCenter;
