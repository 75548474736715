import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button, Input, Select, Icon, Header, DatePicker, Modal, Badge, Popup, VerticalMenu } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import '../../../styles/hsl.scss';
import AddRepair from './new-repair/addRepair';
import { RoleComponent } from 'components/roleComponent';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { HSL, notificationAtom, COMPANY_MANAGER, companyDtoAtom } from 'resources';
import { SelectOption, FilterRepairDTO, RepairDTO, StatusOption } from 'models/hsl/general';
import { downloadFileExcelRepair } from 'resources/api/hsl_api/fileReports';
import { hslCountriesAtom, hslPartnersAtom } from 'resources/atoms/hslAtom';
import { CompanyDTO } from 'models/userManager/addCustomer';
import ModalDetailsLog from './modalDetailsLog/modalDetailsLog';

const InitialFilter = {
    Id: '',
    Misuse: null,
    CompanyName: [],
    Client: '',
    Country: [],
    PartNumber: [],
    SerialNumber: '',
    TypeService: '',
    ResponsibleUser: '',
    ResponsibleTech: '',
    Status: '',
    DateStart: '',
    DateEnd: '',
    PageIndex: 1,
    PageSize: 10
};

function RepairSearch() {
    const { user: userDetails } = useUser();
    const { t } = useTranslation(['common', 'hsl', 'user', 'pages']);
    const navigate = useNavigate();

    const setNotification = useSetRecoilState(notificationAtom);

    const [companyInfo, setCompanyInfo] = useRecoilState<CompanyDTO>(companyDtoAtom);
    const [partners, setPartners] = useRecoilState(hslPartnersAtom);
    const [countries, setCountries] = useRecoilState(hslCountriesAtom);

    const [parts, setParts] = useState<SelectOption[]>([]);
    const [showAddRepair, setShowAddRepair] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState(InitialFilter);
    const [idRepair, setIdRepair] = useState<number>(0);
    const [eliminate, setEliminate] = useState(false);
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);

    const handleSuccess = () => {
        setNotification({ type: 'success', message: 'Report' });
    };
    const handleError = () => {
        setNotification({ type: 'information', message: 'NoData' });
    };

    const servicesType = [
        { value: 'Contract', text: t('hsl:translation.Contract') },
        { value: 'Warranty', text: t('hsl:translation.Warranty') }
    ];

    const misuseOptions = [
        { value: true, text: t('common:actions.Yes') },
        { value: false, text: t('common:actions.No') }
    ];

    const statusOptions: StatusOption[] = [
        { value: '0', text: t('hsl:translation.EnteredRepair'), color: 'yellow' },
        { value: '1', text: t('hsl:translation.Diagnosis'), color: 'grey' },
        { value: '2', text: t('hsl:translation.Repairing'), color: 'blue' },
        { value: '3', text: t('hsl:translation.WaitingParts'), color: 'red' },
        { value: '4', text: t('hsl:translation.SentCustomer'), color: 'green' }
    ];

    const [, getCompanyById] = useFetch<{ id: number | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: t('common:errors.Unexpected')
    });

    const [, getRepairs, loadingGet] = useFetch<FilterRepairDTO, RepairDTO[]>({
        url: HSL.getRepairs,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, deleteRepair, loadingDelete] = useFetch<null, ConstrainBoolean>({
        url: `${HSL.deleteRepair}/${idRepair}`,
        method: 'DELETE',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartsEquip, loadingEquip] = useFetch<null, string[]>({
        url: HSL.getAllPartNumberEquipment,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartners] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (!companyInfo) {
            getUserCompanyInformations();
        }
        if (!partners?.length) {
            getAllPartners();
        }
        if (!parts?.length) {
            getAllPartsEquip();
        }
    }, []);

    useEffect(() => {
        getFilteredRepair(InitialFilter);
    }, [companyInfo]);

    const getUserCompanyInformations = async () => {
        await getCompanyById({ id: userDetails?.COMPANY }, current => {
            setCompanyInfo(current?.data!);
        });
    };

    const getFilteredRepair = async (filter: any) => {
        const normalizedFilter = { ...filter, Id: parseInt(filter.Id), Status: parseInt(filter.Status) };
        await getRepairs(normalizedFilter, (current: any) => {
            if (current?.data) {
                setFilteredData(current.data?.Data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundRepair') }));
            }
        });
    };

    const handleExportExcel = async () => {
        await downloadFileExcelRepair({ filteredData }, `repair.xlsx`, handleSuccess, handleError);
    };

    const getAllPartners = async () => {
        await getPartners(null, resp => {
            if (resp.data) {
                const partnersData = resp?.data.map((partner: CompanyDTO) => ({
                    value: partner.name,
                    text: partner.name
                }));
                setPartners(partnersData);

                const uniqueCountries = Array.from(new Set(resp?.data.map((partner: CompanyDTO) => String(partner.country))));

                const countriesData = uniqueCountries.map(country => ({
                    value: country as string,
                    text: country as string
                }));
                setCountries(countriesData);
            }
        });
    };

    const getAllPartsEquip = async () => {
        await getPartsEquip(null, resp => {
            if (resp.data?.length) {
                const partsData = resp.data.map((part: string) => ({
                    value: part,
                    text: part
                }));
                setParts(partsData);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getFilteredRepair(InitialFilter);
    };

    const applyFilters = () => {
        getFilteredRepair(filters);
    };

    const eliminateRepair = async () => {
        await deleteRepair(null, resp => {
            if (resp?.data === true) {
                applyFilters();
                setEliminate(false);
            }
        });
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:repair')} logo={false} menu={false}>
                    <div style={{ margin: '10px' }}>
                        <Button
                            content={t('hsl:translation.AddRepair')}
                            type="primary"
                            size="medium"
                            iconPosition="right"
                            icon={<Icon name="add" root="building" />}
                            onClick={() => setShowAddRepair(true)}
                            description={t('hsl:translation.AddRepair')}
                        />
                    </div>
                </Header>

                <div className="form4Columns">
                    <Input
                        placeholder={t('hsl:translation.Id')}
                        label={t('hsl:translation.Id')}
                        type="number"
                        value={filters.Id ?? ''}
                        onChange={value => setFilters({ ...filters, Id: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.Customer')}
                        label={t('hsl:translation.Customer')}
                        value={filters.Client}
                        onChange={value => setFilters({ ...filters, Client: value })}
                    />
                    <Select
                        placeholder={t('hsl:translation.PartNumberEquipment')}
                        label={t('hsl:translation.PartNumberEquipment')}
                        value={filters.PartNumber}
                        loading={loadingEquip}
                        options={parts}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, PartNumber: value })}
                    />
                    <Input
                        placeholder={t('common:translation.SerialNumber')}
                        label={t('common:translation.SerialNumber')}
                        value={filters.SerialNumber}
                        onChange={value => setFilters({ ...filters, SerialNumber: value })}
                    />
                </div>
                <div className="form4Columns">
                    <Select
                        placeholder={t('hsl:translation.TypeServices')}
                        label={t('hsl:translation.TypeServices')}
                        options={servicesType}
                        search={true}
                        onChange={value => setFilters({ ...filters, TypeService: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.User')}
                        label={t('hsl:translation.User')}
                        value={filters.ResponsibleUser}
                        onChange={value => setFilters({ ...filters, ResponsibleUser: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.Technical')}
                        label={t('hsl:translation.Technical')}
                        value={filters.ResponsibleTech}
                        onChange={value => setFilters({ ...filters, ResponsibleTech: value })}
                    />
                    <Select
                        placeholder={t('hsl:translation.Status')}
                        label={t('hsl:translation.Status')}
                        options={statusOptions}
                        search={true}
                        onChange={value => setFilters({ ...filters, Status: value })}
                    />
                </div>
                <div className="form4Columns">
                    <Select
                        placeholder={t('hsl:translation.Misuse')}
                        label={t('hsl:translation.Misuse')}
                        options={misuseOptions}
                        search={true}
                        onChange={value => setFilters({ ...filters, Misuse: value })}
                    />
                    <DatePicker
                        type="date"
                        label={t('hsl:translation.RegistrationDate')}
                        value={filters.DateStart}
                        onChange={value => setFilters({ ...filters, DateStart: value.toISOString() })}
                    />
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partners}
                            search={true}
                            multiple={true}
                            onChange={value => setFilters({ ...filters, CompanyName: value })}
                        />
                        <Select
                            placeholder={t('user:address.Country')}
                            label={t('user:address.Country')}
                            options={countries}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, Country: value })}
                        />
                    </RoleComponent>
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const tableRepairRender = () => {
        return (
            <div className="Table-repair">
                <DataTable columnResizeMode="fit" data={filteredData ?? []} loading={loadingGet} resizableColumns={true}>
                    <DataTable.HeaderBar>
                        <DataTable.HeaderBar.Item
                            content={t('hsl:translation.ExportExcel')}
                            iconSize="small"
                            icon="file-download"
                            onClick={handleExportExcel}
                        />
                    </DataTable.HeaderBar>
                    <DataTable.Column field="Id" align="center" header={t('ID')} sortable={true} />
                    <DataTable.Column
                        field="Misuse"
                        align="center"
                        header={t('hsl:translation.Misuse')}
                        sortable={true}
                        renderer={rowData => <>{misuseOptions.find(option => option.value === rowData.value)?.text ?? '-'}</>}
                    />
                    <DataTable.Column
                        field="CompanyName"
                        align="center"
                        header={t('common:translation.Partner')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="CustomerName"
                        align="center"
                        header={t('hsl:translation.Customer')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="PartNumber"
                        align="center"
                        header={t('common:translation.PartNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="SerialNumber"
                        align="center"
                        header={t('common:translation.SerialNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="TypeService"
                        align="center"
                        header={t('hsl:translation.TypeServices')}
                        sortable={true}
                        renderer={rowData => (
                            <>{servicesType.find(option => option.value === rowData.value.toString())?.text ?? '-'}</>
                        )}
                    />
                    <DataTable.Column field="ResponsibleUser" align="center" header={t('hsl:translation.User')} sortable={true} />
                    <DataTable.Column
                        field="ResponsibleTech"
                        align="center"
                        header={t('hsl:translation.Technical')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="Status"
                        align="center"
                        header={t('Status')}
                        sortable={true}
                        renderer={rowData => {
                            const statusOption = statusOptions.find(option => option.value === rowData.value.toString());
                            const badgeColor = statusOption?.color ?? 'grey';

                            return <Badge color={badgeColor}>{statusOption?.text ?? '-'}</Badge>;
                        }}
                    />
                    <DataTable.Column
                        field="opcoes"
                        renderer={e => (
                            <Popup
                                className="popup-theme-wrap"
                                element={<Icon size="large" root="common" name="ellipsis-vertical" />}
                                on="click"
                                align="center"
                            >
                                <VerticalMenu>
                                    <VerticalMenu.Header>{t('common:tabs.Options')}</VerticalMenu.Header>
                                    <VerticalMenu.Item
                                        onClick={() => {
                                            navigate(`repair/${e?.rowData.Id}`, { state: { selectedRepair: e?.rowData } });
                                        }}
                                    >
                                        <div className="Menu-vertical">
                                            <Icon size="small" root="common" name="briefcase" className="icon-event" />
                                            {t('hsl:translation.RepairAction')}
                                        </div>
                                    </VerticalMenu.Item>
                                    <VerticalMenu.Item
                                        onClick={() => {
                                            setModalDetailsOpen(true);
                                            setIdRepair(parseInt(e?.rowData.Id));
                                        }}
                                    >
                                        <div className="Menu-vertical">
                                            <Icon size="small" root="common" name="search" className="icon-event" />
                                            {t('common:translation.Details')}
                                        </div>
                                    </VerticalMenu.Item>
                                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                                        <VerticalMenu.Item
                                            onClick={() => {
                                                setEliminate(true);
                                                setIdRepair(parseInt(e?.rowData.Id));
                                            }}
                                        >
                                            <div className="Menu-vertical">
                                                <Icon
                                                    size="small"
                                                    root="common"
                                                    name="delete"
                                                    rotated={190}
                                                    className="icon-event"
                                                />
                                                {t('hsl:translation.Delete')}
                                            </div>
                                        </VerticalMenu.Item>
                                    </RoleComponent>
                                </VerticalMenu>
                            </Popup>
                        )}
                        header={t('common:tabs.Options')}
                    />
                    <DataTable.Pagination totalItems={filteredData.length} itemsPerPage={10} />
                </DataTable>
            </div>
        );
    };

    const renderModalEliminateRepair = () => {
        return (
            <Modal
                open={eliminate}
                closeIcon={true}
                onClose={() => setEliminate(false)}
                closeOnDimmerClick={false}
                size="small"
                className="modal-excluir-content"
            >
                <Modal.Header>
                    <h1>{t('hsl:translation.Delete')}</h1>
                </Modal.Header>
                <Modal.Content>
                    <p>{t('hsl:translation.ConfirmDeleteRepair')}</p>
                </Modal.Content>

                <Modal.Footer>
                    <Button
                        type="primary"
                        content={t('common:translation.Confirm')}
                        onClick={() => eliminateRepair()}
                        loading={loadingDelete}
                    />
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <>
            {showAddRepair ? (
                <AddRepair
                    onSave={() => setShowAddRepair(false)}
                    onBack={() => {
                        setShowAddRepair(false);
                        getFilteredRepair(filters);
                    }}
                />
            ) : (
                <>
                    {searchFieldsRender()}
                    {tableRepairRender()}
                    {eliminate && renderModalEliminateRepair()}
                    <ModalDetailsLog open={modalDetailsOpen} repairId={idRepair} onClose={() => setModalDetailsOpen(false)} />
                </>
            )}
        </>
    );
}

export default RepairSearch;
