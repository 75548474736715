import { Button, Divider, Loader, Select } from '@scuf/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { COMPANY_MANAGER } from 'resources';
import { getAllBrazilianStates } from 'helpers/findAssistance';
import useFetch from 'helpers/hooks/useFetch';
import { DataTable } from '@scuf/datatable';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';
import { typeOptionProducts } from 'models';

type AssistanceFilterDTO = {
    itemsPerPage?: number;
    index?: number;
    totalItems?: number;
    companies?: AssistancePartnerDTO[];
    lob?: string;
    state?: string;
};

type AssistancePartnerDTO = {
    id: number;
    name: string;
    email: string;
    phone: string;
    street: string;
    neighborhood: string;
    number: string;
    city: string;
    state: string;
    zipCode: string;
    models: string[];
};

function FindAnAssistancePage(this: any) {
    const [width, setWidth] = useState<number>(0);
    const [state, setState] = useState<any[]>([]);
    const { t } = useTranslation(['common', 'user', 'pages']);
    const [toggleModules, setToggleModules] = useState<number | null>(null);
    const [lob, setLob] = useState<number | null>(null);

    const [assistances, searchAssistance, loadAssistances, updateAssistance] = useFetch<AssistanceFilterDTO, AssistanceFilterDTO>(
        {
            url: COMPANY_MANAGER.getAssistances,
            method: 'POST',
            onError: 'LoadInfo'
        }
    );
    const [filter, setFilter] = useState<AssistanceFilterDTO | undefined>({ index: 1, itemsPerPage: 10 } as AssistanceFilterDTO);

    async function loadStateInfo() {
        const stateData = getAllBrazilianStates();
        setState(stateData);
    }

    async function loadSearch(index = 1) {
        var lobPayload;
        if (lob === 1 || lob === 4 || lob === 7 || lob === 8) lobPayload = 0;
        else if (lob === 2 || lob === 3 || lob === 5) lobPayload = 17;
        else lobPayload = 18;

        await searchAssistance({ ...filter, index, lob: lobPayload.toString() }, current => {
            setFilter({
                ...filter,
                index: current.data?.index,
                totalItems: current.data?.totalItems,
                companies: current.data?.companies
            });
        });
    }

    useEffect(() => {
        loadStateInfo();
        setWidth(window.innerWidth);
    }, []);

    const assistancesWithAddress = assistances?.data?.companies?.map(company => ({
        ...company,
        address: `${company.street ? company.street : ''}, ${company.neighborhood ? company.neighborhood : ''} - ${
            company.zipCode ? company.zipCode : ''
        }`
    }));

    usePreventClickjacking();

    return (
        <div className="container">
            <div>
                <div className="contentPageContainer">
                    <div className="form3Columns">
                        <h2> {t('services.FindAssistance')}</h2>
                        <Divider />
                        <Select
                            indicator="required"
                            placeholder={t('translation.LineProducts')}
                            search={true}
                            value={lob}
                            options={typeOptionProducts ?? []}
                            label={t('translation.LineProducts')}
                            onChange={value => {
                                setLob(value);
                            }}
                        />
                        <Select
                            indicator="required"
                            placeholder={t('placeholders.Select.SelectState')}
                            search={true}
                            options={state}
                            label={t('user:address.State')}
                            value={filter?.state ?? null}
                            onChange={value => setFilter(old => ({ ...old, state: value }))}
                        />
                        <div className="buttonsSearch">
                            <Button
                                loading={loadAssistances}
                                type="secondary"
                                onClick={() => {
                                    setToggleModules(null);
                                    setFilter(old => ({ ...old, lob: undefined, state: undefined }));
                                }}
                                content={t('actions.ResetFilters')}
                                iconPosition="right"
                            />
                            <Button
                                loading={loadAssistances}
                                type="primary"
                                onClick={() => loadSearch()}
                                icon="search"
                                content={t('actions.Search')}
                                iconPosition="right"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {' '}
                    {assistances?.data != null ? (
                        <>
                            {(loadAssistances && <Loader></Loader>) || (
                                <div>
                                    {
                                        <div>
                                            <DataTable
                                                data={assistancesWithAddress ?? []}
                                                loading={loadAssistances}
                                                expandedRows={[
                                                    assistances?.data?.companies?.find(e => e.id == toggleModules) ?? []
                                                ]}
                                                rowExpansionTemplate={data => (
                                                    <div
                                                        style={{
                                                            display: 'grid',
                                                            gridTemplateColumns: 'repeat(4,1fr)',
                                                            maxHeight: '200px',
                                                            overflow: 'scroll'
                                                        }}
                                                    >
                                                        {(data as AssistancePartnerDTO).models.map(e => (
                                                            <div
                                                                style={{
                                                                    padding: '16px',
                                                                    fontStyle: 'oblique',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                {e}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <DataTable.Column
                                                    field="name"
                                                    header={t('user:address.Company')}
                                                ></DataTable.Column>
                                                <DataTable.Column field="email" header={t('user:info.Email')}></DataTable.Column>
                                                <DataTable.Column
                                                    field="phone"
                                                    header={t('user:address.Phone')}
                                                ></DataTable.Column>
                                                <DataTable.Column
                                                    field="address"
                                                    header={t('translation.Address')}
                                                ></DataTable.Column>
                                                <DataTable.Column field="city" header={t('user:address.City')}></DataTable.Column>
                                                <DataTable.Column
                                                    field="state"
                                                    header={t('user:address.State')}
                                                ></DataTable.Column>

                                                <DataTable.Pagination
                                                    itemsPerPage={filter?.itemsPerPage!}
                                                    totalItems={filter?.totalItems!}
                                                    activePage={filter?.index}
                                                    disabledPages={[filter?.index!]}
                                                    onPageChange={async page => {
                                                        await loadSearch(parseInt(page.toString()));
                                                    }}
                                                ></DataTable.Pagination>
                                            </DataTable>
                                        </div>
                                    }
                                </div>
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default FindAnAssistancePage;
