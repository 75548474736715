import React from 'react';
import { Modal, Button } from '@scuf/common';
import { useTranslation } from 'react-i18next';

interface ModalConfirmEditDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (type: string) => void;
    type: string;
}

const ModalConfirmEditDetails: React.FC<ModalConfirmEditDetailsProps> = ({ isOpen, onClose, onSubmit, type }) => {
    const { t } = useTranslation(['hsl', 'common']);

    const handleConfirmar = () => {
        onSubmit(type);
        onClose();
    };

    const handleCancelar = () => {
        onClose();
    };

    return (
        <Modal
            closeIcon={true}
            onClose={onClose}
            open={isOpen}
            closeOnDimmerClick={false}
            size="small"
            className="modal-excluir-content"
        >
            {type === 'delete' ? (
                <>
                    <Modal.Header>
                        <h1>{t('hsl:translation.DeleteDelivery')}</h1>
                    </Modal.Header>
                    <Modal.Content>
                        <p>{t('hsl:translation.ConfirmDelete')}</p>
                    </Modal.Content>
                </>
            ) : (
                <>
                    <Modal.Header>
                        <h1>{t('hsl:translation.ReceiveDelivery')}</h1>
                    </Modal.Header>
                    <Modal.Content>
                        <p>{t('hsl:translation.ConfirmReceive')}</p>
                    </Modal.Content>
                </>
            )}
            <Modal.Footer>
                <Button type="primary" content={t('common:translation.Confirm')} onClick={handleConfirmar} />
                <Button type="secondary" content={t('common:actions.Cancel')} onClick={handleCancelar} />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirmEditDetails;
