import React, { useEffect, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { Button, FileDrop, Icon, Modal, Input, Loader } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import 'styles/hsl.scss';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import { COMPANY_MANAGER, HSL } from 'resources';
import useFetch from 'helpers/hooks/useFetch';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { DeliveryExcel } from 'models/hsl/general';
import { downloadTemplateExcel } from 'resources/api/hsl_api/fileReports';

const NewDelivery: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['hsl', 'common']);
    const [data, setData] = useState<DeliveryExcel[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState<DeliveryExcel | null>(null);

    const [processedFile, postUploadFile, loadingFile] = useFetch<any, DeliveryExcel[]>({
        url: HSL.postUploadFile,
        method: 'POST',
        options: {
            headers: {
                'Content-Type': 'application/json'
            }
        },
        onError: t('common:translation.UploadError')
    });

    const [, createDelivery, loadingCreate] = useFetch<any, any>({
        url: HSL.createDelivery,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, validateDeliveryData, loadingValidate] = useFetch<any, any>({
        url: HSL.validateDelivery,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartnerInfo, loadingPartner] = useFetch<{ idSap: string | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.GetCompanyByIdSap,
        onError: t('common:errors.Unexpected')
    });

    const hasErrors = data.some(item => item.ErrorDuplicated || item.ErrorPartNumber);

    useEffect(() => {
        if (processedFile?.data) {
            setData(processedFile?.data);
        }
    }, [processedFile]);

    const onAdd = () => {
        const newRow: DeliveryExcel = {
            Id: -1,
            Delivery: '',
            IdSap: '',
            PartNumber: '',
            Quantity: '',
            Billoflading: '',
            Carrier: '',
            ErrorPartNumber: false,
            ErrorDuplicated: false
        };
        setEditData(newRow);
        setOpenModal(true);
    };

    const onEdit = (rowData: ICellData) => {
        const item = data[rowData.rowIndex];
        const updatedItem = {
            ...item,
            Id: rowData.rowIndex
        };
        setEditData(updatedItem);
        setOpenModal(true);
    };

    const handleBack = () => {
        navigate(-1);
    };


    const onSaveEdit = () => {
        if (editData) {
            if (editData.Id === -1) {
                const newData = [...data, { ...editData, Id: data.length }];
                validateDeliveryData(newData, (resp: any) => {
                    setData(resp.data);
                    setOpenModal(false);
                    setEditData(null);
                });
            } else {
                const updatedData = data.map((item, index) => (index === editData.Id ? { ...item, ...editData } : item));
                validateDeliveryData(updatedData, (resp: any) => {
                    setData(resp.data);
                    setOpenModal(false);
                    setEditData(null);
                });
            }
        }
    };

    const onDelete = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const onSubmit = async () => {
        const companyInfoArray = await handlePartnerInfo();
        const deliveriesWithInfo = data.map(item => {
            const companyInfo = companyInfoArray.find(info => info.IdSap === item.IdSap);
            return {
                ...item,
                CompanyId: companyInfo?.companyId,
                CompanyName: companyInfo?.companyName,
                Country: companyInfo?.country
            };
        });

        await createDelivery(deliveriesWithInfo, () => {
            navigate('../');
        });
    };

    const handlePartnerInfo = async () => {
        const uniqueIdSaps = Array.from(new Set(data.map(item => item.IdSap)));
        const newCompaniesInfo: Array<{ IdSap?: string; companyId?: number; companyName?: string; country?: string }> = [];

        for (const idSap of uniqueIdSaps) {
            if (idSap) {
                await new Promise<void>(resolve => {
                    getPartnerInfo({ idSap: idSap }, resp => {
                        if (resp.data) {
                            const respObj = {
                                IdSap: resp.data.idSap,
                                companyId: resp.data?.id,
                                companyName: resp.data?.name,
                                country: resp.data?.country
                            };
                            newCompaniesInfo.push(respObj);
                        }
                        resolve();
                    });
                });
            }
        }

        return newCompaniesInfo;
    };

    const handleUploadFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async e => {
            if (e.target) {
                const base64String = (e.target.result as string).replace('data:', '').replace(/^.+,/, '');

                if (file !== undefined) {
                    await postUploadFile({
                        fileName: file.name,
                        fileContent: base64String
                    });
                }
            }
        };
        reader.readAsDataURL(file);
    };

    const handleExportExcel = async () => {
        setLoading(true);
        await downloadTemplateExcel(
            { fileName: 'delivery_template' },
            `delivery_template.xlsx`,
            () => { },
            () => { }
        );

        setLoading(false);
    };

    const renderModal = () => {
        if (!editData) return null;

        return (
            <Modal open={openModal} closeIcon={true} onClose={() => setOpenModal(false)}>
                <Modal.Header>{t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form2Columns">
                        <Input
                            value={editData.Delivery}
                            onChange={value => setEditData({ ...editData, Delivery: value })}
                            label="Delivery"
                        />
                    </div>
                    <div className="form2Columns">
                        <Input
                            value={editData.IdSap}
                            onChange={value => setEditData({ ...editData, IdSap: value })}
                            label="IdSap"
                        />
                        <Input
                            value={editData.PartNumber}
                            onChange={value => setEditData({ ...editData, PartNumber: value })}
                            label="PartNumber"
                        />
                        <Input
                            value={editData.Quantity}
                            onChange={value => setEditData({ ...editData, Quantity: value })}
                            label="Quantity"
                        />
                    </div>
                    <div className="form2Columns">
                        <Input
                            value={editData.Billoflading}
                            onChange={value => setEditData({ ...editData, Billoflading: value })}
                            label="BillofLading"
                        />
                        <Input
                            value={editData.Carrier}
                            onChange={value => setEditData({ ...editData, Carrier: value })}
                            label="Carrier"
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button content={t('common:actions.Save')} type="primary" onClick={onSaveEdit} loading={loadingValidate} />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableDeliveryRender = () => {
        if (!data) {
            return null;
        }

        return (
            <div style={{ marginTop: '26px' }}>
                {loadingFile ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <DataTable columnResizeMode="fit" data={data} scrollable={true} scrollWidth="100%" resizableColumns={true}>
                        <DataTable.HeaderBar>
                            <DataTable.HeaderBar.Item
                                content={t('common:actions.Add')}
                                iconSize="small"
                                icon="slidercontrols-plus"
                                onClick={onAdd}
                            />
                        </DataTable.HeaderBar>
                        <DataTable.Column
                            field="Delivery"
                            align="center"
                            header={t('hsl:translation.Delivery')}
                            sortable={true}
                        />
                        <DataTable.Column field="IdSap" align="center" header={t('hsl:translation.IdSap')} sortable={true} />
                        <DataTable.Column
                            field="PartNumber"
                            align="center"
                            header={t('common:translation.PartNumber')}
                            sortable={true}
                        />
                        <DataTable.Column
                            className="quantity"
                            field="Quantity"
                            align="center"
                            header={t('hsl:translation.Quantity')}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="Billoflading"
                            align="center"
                            header={t('hsl:translation.BillofLading')}
                            sortable={true}
                        />
                        <DataTable.Column field="Carrier" align="center" header={t('hsl:translation.Carrier')} sortable={true} />
                        <DataTable.Column
                            field="Error"
                            align="center"
                            header={t('common:errors.Errors')}
                            renderer={rowData => (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <span>
                                        {' '}
                                        {data[rowData.rowIndex].ErrorPartNumber
                                            ? t('hsl:translation.InvalidPartNumber')
                                            : null}{' '}
                                    </span>

                                    <span>
                                        {data[rowData.rowIndex].ErrorDuplicated ? t('hsl:translation.DuplicatedDelivery') : null}
                                    </span>
                                </div>
                            )}
                        />
                        <DataTable.Column
                            field="Edit"
                            renderer={rowData => (
                                <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                                    <div onClick={() => onDelete(rowData.rowIndex)}>
                                        <Icon size="small" root="common" name="delete" className="icon-event" />
                                    </div>
                                    <div onClick={() => onEdit(rowData)}>
                                        <Icon size="small" root="common" name="edit" className="icon-event" />
                                    </div>
                                </div>
                            )}
                            align="center"
                            header={t('common:actions.Actions')}
                        />
                        <DataTable.Pagination totalItems={data.length} itemsPerPage={10} />
                    </DataTable>
                )}
                <div className="button-hsl">
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        size="medium"
                        iconPosition="right"
                        loading={loadingPartner || loadingCreate}
                        onClick={onSubmit}
                        disabled={hasErrors}
                        description={t('common:actions.Save')}
                    />
                    <Button
                        type="secondary"
                        icon="arrow-left"
                        content={t('common:actions.Back')}
                        onClick={handleBack}
                        className='back-button'
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="container">
            <div className="button-hsl">
                <Button
                    content={t('hsl:translation.DownloadTemplate')}
                    onClick={() => {
                        handleExportExcel();
                    }}
                    type="secondary"
                    size="medium"
                    loading={loading}
                />
            </div>
            <FileDrop
                acceptedTypes={['.xlsx']}
                resetButtonText={t('common:actions.Clean')}
                multiple={false}
                buttonText={t('common:actions.BrowseFiles')}
                placeholder={t('common:actions.Draganddropfileshere')}
                onAccepted={files => handleUploadFile(files[0])}
            />
            {tableDeliveryRender()}
            {renderModal()}
        </div>
    );
};

export default NewDelivery;
