import { useState, useEffect } from 'react';
import { Button, Input, Select, Header } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { useTranslation } from 'react-i18next';
import { RoleComponent } from 'components/roleComponent';
import useFetch from 'helpers/hooks/useFetch';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { HSL, COMPANY_MANAGER, notificationAtom } from 'resources';
import { FilterInventoryDTO, InventoryData, SelectOption } from 'models/hsl/general';
import { downloadFileExcelInventory } from 'resources/api/hsl_api/fileReports';
import { hslCountriesAtom, hslPartnersAtom, hslPartsAtom } from 'resources/atoms/hslAtom';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useNavigate } from 'react-router-dom'; // Importando useNavigate
import '../../../../../styles/hsl.scss';

const InitialFilter: FilterInventoryDTO = {
    CompanyName: [],
    Country: [],
    PartNumber: [],
    PartNumberDescription: '',
    ProductName: [],
    PageIndex: 1,
    PageSize: 10
};

function PendingItems() {
    const navigate = useNavigate(); // Inicializando useNavigate
    const setNotification = useSetRecoilState(notificationAtom);
    const [partners, setPartners] = useRecoilState(hslPartnersAtom);
    const [countries, setCountries] = useRecoilState(hslCountriesAtom);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const { t } = useTranslation(['common', 'hsl', 'user', 'home']);
    const [, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState(InitialFilter);
    const [filteredData, setFilteredData] = useState<InventoryData[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
    const [partsOptions, setPartsOptions] = useState<SelectOption[]>([]);

    const handleSuccess = () => {
        setNotification({ type: 'success', message: 'Report' });
    };
    const handleError = () => {
        setNotification({ type: 'information', message: 'NoData' });
    };

    const [, getProducts] = useFetch<any, any>({
        url: HSL.getProducts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [items, getPendingInventoryItems, loadingItems] = useFetch<FilterInventoryDTO, any>({
        url: HSL.getPendingInventoryItems,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartners] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getFilteredItems(filters);
        fetchData(getProducts, setProductOptions);
        if (!partners.length) {
            getAllPartners();
        }
        if (!parts.length) {
            getAllParts();
        }
    }, []);

    useEffect(() => {
        setFilteredData(items?.data.Data);
    }, [items]);

    useEffect(() => {
        const partsOptions = parts.map(part => ({
            value: part.PartNumber,
            text: part.PartNumber
        }));
        setPartsOptions(partsOptions);
    }, [parts]);

    const fetchData = async (fetchFunction: any, setOptions: any) => {
        await fetchFunction(null, (current: any) => {
            if (current?.data) {
                const options = current?.data?.map((item: any) => ({
                    value: item.PartNumber || item.Name || item,
                    text: item.PartNumber || item.Name || item
                }));
                setOptions(options);
            }
        });
    };

    const getFilteredItems = async (filter: FilterInventoryDTO) => {
        await getPendingInventoryItems(filter, resp => {
            if (resp.data.Data) {
                setFilteredData(resp.data.Data);
                setTotalItems(resp.data.TotalCount);
            }
        });
    };

    const handleExportExcel = async () => {
        setLoading(true);
        await downloadFileExcelInventory({ filteredData }, `inventory.xlsx`, handleSuccess, handleError);
        setLoading(false);
    };

    const getAllPartners = () => {
        getPartners(null, resp => {
            if (resp.data) {
                const partnersData = resp.data.map((partner: CompanyDTO) => ({
                    value: partner.name,
                    text: partner.name
                }));
                setPartners(partnersData);
                const uniqueCountries = Array.from(new Set(resp.data.map((partner: CompanyDTO) => String(partner.country))));
                const countriesData = uniqueCountries.map(country => ({
                    value: country as string,
                    text: country as string
                }));
                setCountries(countriesData);
            }
        });
    };

    const getAllParts = () => {
        getParts(null, (current: any) => {
            if (current.data.length) {
                setParts(current.data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getFilteredItems(InitialFilter);
    };

    const applyFilters = () => {
        getFilteredItems(filters);
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:pendingItems')} logo={false} menu={false} />
                <div className="form4Columns">
                    <Select
                        placeholder={t('common:translation.Product')}
                        label={t('common:translation.Product')}
                        options={productOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, ProductName: value })}
                    />
                    <Select
                        placeholder={t('common:translation.PartNumber')}
                        label={t('common:translation.PartNumber')}
                        value={filters.PartNumber}
                        options={partsOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, PartNumber: value })}
                    />
                    <Input
                        placeholder={t('common:translation.Description')}
                        label={t('common:translation.Description')}
                        value={filters.PartNumberDescription}
                        onChange={value => setFilters({ ...filters, PartNumberDescription: value })}
                    />
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            label={t('common:translation.Partner')}
                            placeholder={t('common:translation.Partner')}
                            options={partners}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, CompanyName: value })}
                        />
                    </RoleComponent>
                </div>
                <div className="form4Columns">
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            placeholder={t('user:address.Country')}
                            label={t('user:address.Country')}
                            options={countries}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, Country: value })}
                        />
                    </RoleComponent>
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const renderCell = (field: string | number, value: number) => {
        const color = value <= 0 ? '#be271c' : '#303030';
        return <span style={{ color }}>{field}</span>;
    };

    const tableInventoryRender = () => {
        return (
            <div className="Table-inventory">
                <DataTable columnResizeMode="fit" data={filteredData} loading={loadingItems}>
                    <DataTable.HeaderBar>
                        <DataTable.HeaderBar.Item
                            content={t('hsl:translation.ExportExcel')}
                            iconSize="small"
                            icon="file-download"
                            onClick={handleExportExcel}
                        />
                    </DataTable.HeaderBar>
                    <DataTable.Column
                        field=" ItemId"
                        align="center"
                        header={t('ID')}
                        sortable={true}
                        renderer={e => renderCell(e.rowIndex + 1, e.rowData.Quantity)}
                    />
                    <DataTable.Column
                        field="CompanyName"
                        align="center"
                        header={t('common:translation.Partner')}
                        sortable={true}
                        renderer={e => renderCell(e.rowData.CompanyName, e.rowData.Quantity)}
                    />
                    <DataTable.Column
                        field="ProductName"
                        align="center"
                        header={t('common:translation.Product')}
                        sortable={true}
                        renderer={e => renderCell(e.rowData.ProductName, e.rowData.Quantity)}
                    />
                    <DataTable.Column
                        field="PartNumber"
                        align="center"
                        header={t('Part Number')}
                        sortable={true}
                        renderer={e => renderCell(e.rowData.PartNumber, e.rowData.Quantity)}
                    />
                    <DataTable.Column
                        field="PartNumberDescription"
                        align="center"
                        header={t('common:translation.Description')}
                        sortable={true}
                        renderer={e => renderCell(e.rowData.PartNumberDescription, e.rowData.Quantity)}
                    />
                    <DataTable.Column
                        field="Quantity"
                        align="center"
                        header={t('hsl:translation.Quantity')}
                        sortable={true}
                        renderer={e => renderCell(e.rowData.Quantity, e.rowData.Quantity)}
                    />
                </DataTable>
                <DataTable.Pagination
                    totalItems={totalItems}
                    itemsPerPage={10}
                    onPageChange={pageNumber => {
                        const filterPage: FilterInventoryDTO = { ...filters, PageIndex: parseInt(pageNumber.toString()) };
                        getFilteredItems(filterPage);
                    }}
                />
            </div>
        );
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="container">
                {searchFieldsRender()}
                {tableInventoryRender()}
                <div className="button-hsl">
                    <Button
                        type="secondary"
                        icon="arrow-left"
                        content={t('common:actions.Back')}
                        onClick={handleBack}
                        className="button-back"
                    />
                </div>
            </div>
        </>
    );
}

export default PendingItems;