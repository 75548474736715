import { Button, Card, Icon, Input, Select, TextArea } from '@scuf/common';
import { RoleComponent } from 'components/roleComponent';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { Report } from 'models/maintenanceReport/allMaintenances';
import { modalitys } from 'models/maintenanceReport/type';
import { CompanyDTO, ResponseLobsDto } from 'models/userManager/addCustomer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, MAINTENANCES_REPORTS, SPARE_PARTS, USER_MODULE, companyAtom, notificationAtom } from 'resources';
import { modelState } from 'resources/atoms/maintenanceReportAtom';
import { DataTable } from '@scuf/datatable';
import NewMaintenanceReportAddItemsDefault from './newMaintenanceReportAddItemsDefault';
import NewMaintenanceReportAddItemsVisit from './newMaintenanceReportAddItemsVisit';
import 'styles/maintenanceReport.scss';

const VISITA = 4;
const GARANTIA = 2;
const valoresLOB: {
    [key: string]: number
} = {
    173: 45.00,
    198: 80.00
};
const emptyReport: Report = {
    Id: 0,
    SsoId: '',
    CompanyId: 0,
    CompanyName: '',
    CompanyCnpj: '',
    UserRequesting: '',
    OsNumber: '',
    ProductName: '',
    SerialNumber: '',
    ModalityId: 0,
    FailureDescription: '',
    TimeSpent: 0,
    RepairActions: '',
    LastDayOfRepair: null,
    AssistanceStartDate: '',
    AssistanceEndDate: '',
    Observations: [],
    NfFileName: '',
    NfApproved: null,
    NfApprovalDate: null,
    ApprovalStatusId: 0,
    RefundSent: null,
    RefundSentDate: null,
    SentToFinanceDepartment: null,
    SentToFinanceDepartmentDate: null,
    RefundValue: null,
    Value: 0,
    UserId: null,
    Code: null,
    CustomerEmail: '',
    CustomerContact: '',
    CustomerName: '',
    CustomerPhone: '',
    ReportItems: [],
    CreatedAt: '',
    UpdatedAt: '',
    PNEquip: ''
};

function NewMaintenanceReport() {
    const { t } = useTranslation(['maintenanceReport', 'common']);
    const { user } = useUser();
    const navigate = useNavigate();

    const [model, setModel] = useRecoilState<Report>(modelState);
    const [valorGarantia, setValorGarantia] = useState<number>();
    const setNotification = useSetRecoilState(notificationAtom);
    const [productOptions, setProductOptions] = useState<any>();
    const [selectedNEquip, setSelectedNEquip] = useState<{ value: number; text: string }>({ value: -1, text: '' });
    const [NEquip, setNEquip] = useState<any>();

    const [userCompany, setUserCompany] = useRecoilState<CompanyDTO>(companyAtom);

    const [productsByLob, getOptionsByLob, isLoadingLob] = useFetch<{ lobs: string }, any>({
        url: SPARE_PARTS.GetProductsOptionsByLOB
    });

    const [lobs, getLobs] = useFetch<{ companyId: number }, ResponseLobsDto[]>({
        url: USER_MODULE.getCompanyLOBs,
        onError: 'LoadInfo'
    });

    const [_, createMaintenance, loading] = useFetch<Report, any>({
        url: MAINTENANCES_REPORTS.createNewMaintenance,
        method: 'POST',
        redirectOnError: false
    });

    const [, getCompanyById] = useFetch<{ id: number | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: 'LoadInfo'
    });

    const [res, getLOBByPartNumber, loadingByLOB] = useFetch<{ partNumberCode: string }, any>({
        url: SPARE_PARTS.getLOBByPartNumber,
        onError: 'LoadInfo'
    });


    const [, getAllPartNumbersLOBs, loadingAllPNLOB] = useFetch<{ lobs: string; product?: string }, any[]>({
        url: SPARE_PARTS.getAllPartNumbersWithoutAllowedPortal,
        onError: 'LoadInfo'
    });

    useEffect(() => {
        if (user) {
            getLobs({ companyId: user.COMPANY });
            getCompanyById({ id: user.COMPANY }, resp => setUserCompany(resp.data!));
        }
        setModel(emptyReport);
    }, []);

    useEffect(() => {
        if (model?.ModalityId && lobs) {
            handleGetProducts(lobs, model.ModalityId);
        }
    }, [model?.ModalityId, lobs]);

    const handleGetProducts = async (lobs: any, modalityId: number): Promise<void> => {
        if (lobs && lobs?.data) {
            let lobCodes = '';
            if (modalityId !== VISITA) {
                if (!productsByLob?.data || productsByLob.data.length === 0) {
                    lobCodes = lobs.data?.map((item: any) => item.lob.lobCode).join(',');
                    await getOptionsByLob({ lobs: lobCodes }, resp => {
                        setProductOptions(
                            resp.data?.map((e: any, index: number) => ({
                                value: index,
                                text: e.name
                            }))
                        );
                    });
                } else {
                    setProductOptions(
                        productsByLob?.data?.map((e: any, index: number) => ({
                            value: index,
                            text: e.name
                        }))
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (user) {
            getLobs({ companyId: user.COMPANY });
            getCompanyById({ id: user.COMPANY }, resp => setUserCompany(resp.data!));
        }
        setModel(emptyReport);
    }, []);

    useEffect(() => {
        const lobCodes = lobs?.data?.map((item: any) => item.lob.lobCode).join(',');
        if (lobCodes && model?.ProductName) {
            getAllPartNumbersLOBs({ lobs: lobCodes, product: model?.ProductName }, resp => {
                setNEquip(
                    resp.data?.map((e: any, index: number) => ({
                        value: index,
                        text: e.code
                    }))
                );
                setModel({ ...model, ReportItems: [] });
                setSelectedNEquip({ value: -1, text: '' });
            });
        }
    }, [lobs, model?.ProductName]);

    useEffect(() => {
        const setValueByPartNumberEquip = async (partNumberCode: string) => {
            getLOBByPartNumber({ partNumberCode: partNumberCode }, res => {
                if (res?.data) {
                    const lobs = res.data.lob;
                    for (const lob of lobs) {
                        const valor = valoresLOB[lob];
                        if (valor !== undefined) {
                            if (model?.ModalityId === GARANTIA) {
                                setModel({ ...model, Value: valor });
                                setValorGarantia(valor);
                            }
                            return;
                        }
                    }
                    if (model?.ModalityId === GARANTIA) {
                        setModel({ ...model, Value: 0 });
                        setValorGarantia(0);
                    }
                }
            });
        };
    }, [model?.PNEquip, model?.ModalityId]);

    const createNewMaintenanceReport = async () => {
        if (user) {
            const body: Report = {
                ...model,
                CompanyId: parseInt(user.COMPANY.toString()),
                CompanyCnpj: userCompany?.cnpj,
                CompanyName: userCompany?.name,
                UserRequesting: user.NAME,
                SsoId: user.SSOID
            };
            try {
                await createMaintenance(body);
                setNotification(old => ({ ...old, message: 'Create', type: 'success' }));
                navigate('/maintenance-report');
            } catch {
                setNotification(old => ({ ...old, message: t('error'), type: 'critical' }));
            }
        }
    };

    const deleteProductVisitRenderer = (index: number) => {
        return (
            <Icon
                className="clickable"
                root="common"
                name="delete"
                size="medium"
                onClick={() => {
                    setModel(prevModel => ({
                        ...prevModel,
                        reportItems: prevModel.ReportItems.filter((_, i) => i !== index)
                    }));
                }}
            />
        );
    };

    const visitProductsTable = () => {
        return (
            <Card>
                <Card.Content>
                    <h1>
                        <Icon root="common" name="device-add" size="large" /> {t('common:translation.Products')}
                    </h1>
                    <div className="table-items-visit">
                        <DataTable data={model.ReportItems} rows={10} scrollable={true} virtualScroll={true} scrollHeight="800px">
                            <DataTable.Column field="Product" header={t('common:translation.Product')} sortable={true} />
                            <DataTable.Column
                                field="SerialNumber"
                                header={t('common:translation.SerialNumber')}
                                sortable={true}
                            />
                            <DataTable.Column
                                field="ProblemDescription"
                                header={t('common:translation.Problem')}
                            ></DataTable.Column>
                            <DataTable.Column field="" header="" renderer={e => deleteProductVisitRenderer(e.rowIndex)} />
                        </DataTable>
                    </div>
                </Card.Content>
            </Card>
        );
    };

    const handleObservation = (value: string) => {
        setModel(prevModel => ({
            ...prevModel,
            observations: [
                {
                    user: user?.NAME,
                    userRole: parseInt(user?.ROLE ?? ''),
                    Observation: value
                }
            ]
        }));
    };

    return (
        <div>
            <div className="container">
                <Card>
                    <Card.Content>
                        <h1>
                            <Icon root="common" name="entity-details" size="large" />{' '}
                            {t('maintenancereport:translation.CreateReportMaintenance')}
                        </h1>
                        <div className="form3Columns">
                            <Select
                                label={t('common:translation.Type')}
                                indicator={'required'}
                                placeholder={t('common:translation.Type')}
                                options={modalitys}
                                value={model?.ModalityId || ''}
                                onChange={id => {
                                    setModel({ ...model, ModalityId: id, ApprovalStatusId: 1 });
                                }}
                            />
                            {model?.ModalityId == VISITA ? (
                                <>
                                    <NewMaintenanceReportAddItemsVisit />
                                    {visitProductsTable()}
                                </>
                            ) : (
                                <>
                                    <Select
                                        label={t('common:translation.Products')}
                                        indicator={'required'}
                                        disabled={isLoadingLob}
                                        loading={loading}
                                        options={productOptions ?? []}
                                        placeholder={isLoadingLob ? t('common:actions.Loading') : t('common:translation.Products')}
                                        value={model?.ProductName &&
                                            productOptions?.findIndex((x: any) => x.text == model?.ProductName)}
                                        search={true}
                                        onChange={(value: number) =>
                                            setModel({ ...model, ProductName: productOptions[value].text })
                                        }
                                    />

                                    <Select
                                        label={t('common:translation.PartNumberDoEquipamento')}
                                        indicator={'required'}
                                        placeholder={loadingAllPNLOB ? t('common:actions.Loading') : t('common:translation.PartNumberDoEquipamento')}
                                        disabled={loadingAllPNLOB}
                                        loading={loadingAllPNLOB}
                                        options={NEquip ?? []}
                                        value={model?.PNEquip &&
                                            NEquip?.findIndex((x: any) => x.text == model?.PNEquip)
                                        }
                                        search={true}
                                        onChange={(value: number) =>
                                            setModel({ ...model, PNEquip: NEquip[value].text })
                                        }
                                    />
                                    <Input
                                        label={t('common:translation.SerialNumber')}
                                        placeholder={t('common:translation.SerialNumber')}
                                        indicator="required"
                                        value={model?.SerialNumber || ''}
                                        onChange={(value: string) => setModel({ ...model, SerialNumber: value })}
                                    />
                                </>
                            )}
                            <Input
                                label={t('common:translation.ServiceOrderNumber')}
                                placeholder={t('common:translation.ServiceOrderNumber')}
                                value={model?.OsNumber || ''}
                                indicator="required"
                                onChange={(value: string) => setModel({ ...model, OsNumber: value })}
                            />
                            <Input
                                label={t('maintenancereport:translation.EndCustomerName')}
                                placeholder={t('maintenancereport:translation.EndCustomerName')}
                                value={model?.CustomerName || ''}
                                indicator="required"
                                onChange={(value: string) => setModel({ ...model, CustomerName: value })}
                            />
                            <Input
                                label={t('maintenancereport:translation.EndCustomerEmail')}
                                placeholder={t('maintenancereport:translation.EndCustomerEmail')}
                                type="email"
                                indicator="required"
                                value={model?.CustomerEmail || ''}
                                onChange={(value: string) => setModel({ ...model, CustomerEmail: value })}
                            />
                            <Input
                                label={t('maintenancereport:translation.EndCustomerPhone')}
                                placeholder={t('maintenancereport:translation.EndCustomerPhone')}
                                value={model?.CustomerPhone || ''}
                                indicator="required"
                                onChange={(value: string) => setModel({ ...model, CustomerPhone: value })}
                            />
                            {model?.ModalityId === VISITA ? (
                                <Input
                                    label={t('maintenancereport:translation.Code')}
                                    placeholder={t('maintenancereport:translation.Code')}
                                    value={model?.Code || ''}
                                    indicator="required"
                                    onChange={(value: string) => setModel({ ...model, Code: value })}
                                />
                            ) : null}
                            <Input
                                label={t('maintenancereport:translation.TimeSpentOnRepair')}
                                placeholder={t('maintenancereport:translation.TimeSpentOnRepair')}
                                type="number"
                                onChange={(value: string) => setModel({ ...model, TimeSpent: parseInt(value) })}
                            />
                            {(model?.ModalityId === GARANTIA || model?.ModalityId === VISITA) && (
                                <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                                    {(hasRole: boolean) => (
                                        <Input
                                            loading={loadingByLOB}
                                            value={valorGarantia?.toString() ?? ''}
                                            label={t('common:translation.Value') + ' R$'}
                                            placeholder={t('common:translation.Value')}
                                            type="number"
                                            onChange={(value: string) => {
                                                setValorGarantia(parseFloat(value))
                                                setModel({ ...model, Value: parseFloat(value) })
                                            }}
                                            disabled={!hasRole}
                                        />
                                    )}
                                </RoleComponent>
                            )}
                        </div>
                        <div className="form3Columns">
                            <TextArea
                                fluid={true}
                                indicator={'required'}
                                className="textArea"
                                value={model?.FailureDescription || ''}
                                label={t('maintenancereport:translation.ProductFailure')}
                                placeholder={t('maintenancereport:translation.ProductFailure')}
                                maxLength={300}
                                onChange={(value: string) => setModel({ ...model, FailureDescription: value })}
                            />
                            <TextArea
                                fluid={true}
                                indicator={'required'}
                                className="textArea"
                                value={model?.RepairActions || ''}
                                label={t('maintenancereport:translation.RepairActions')}
                                placeholder={t('maintenancereport:translation.RepairActions')}
                                maxLength={300}
                                onChange={(value: string) => setModel({ ...model, RepairActions: value })}
                            />
                            <TextArea
                                fluid={true}
                                className="textArea"
                                label={t('maintenancereport:translation.Observations')}
                                placeholder={t('maintenancereport:translation.Observations')}
                                maxLength={500}
                                onChange={(value: string) => handleObservation(value)}
                            />
                        </div>
                        <div className="form3Columns">
                            <Input
                                label={t('maintenancereport:translation.LastRepairDate')}
                                value={model?.LastDayOfRepair || ''}
                                type="date"
                                onChange={(value: string) => setModel({ ...model, LastDayOfRepair: value })}
                            />
                            <Input
                                label={t('maintenancereport:translation.AssistanceStartDate')}
                                value={model?.AssistanceStartDate || ''}
                                indicator="required"
                                type="date"
                                onChange={(value: string) => setModel({ ...model, AssistanceStartDate: value })}
                            />
                            <Input
                                label={t('maintenancereport:translation.AssistanceEndDate')}
                                value={model?.AssistanceEndDate || ''}
                                indicator="required"
                                type="date"
                                onChange={(value: string) => setModel({ ...model, AssistanceEndDate: value })}
                            />
                        </div>
                    </Card.Content>
                </Card>
                {model?.ModalityId === VISITA ? (
                    <></>
                ) : (
                    <Card>
                        <Card.Content>
                            <NewMaintenanceReportAddItemsDefault lobs={lobs} />
                        </Card.Content>
                    </Card>
                )}
                <div className="buttonsSearch">
                    <Button
                        type="primary"
                        icon="slidercontrols-plus"
                        content={t('maintenancereport:translation.CreateReportMaintenance')}
                        iconPosition="right"
                        loading={loading}
                        disabled={
                            !model?.ProductName ||
                            !model?.OsNumber ||
                            !model?.ModalityId ||
                            !model?.FailureDescription ||
                            !model?.AssistanceEndDate ||
                            !model?.AssistanceStartDate
                        }
                        onClick={() => {
                            createNewMaintenanceReport();
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default NewMaintenanceReport;
