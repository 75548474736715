export type AddCompany = {
    street: string;
    number: string;
    complement: string;
    city: string;
    neighborhood: string;
    state: string;
    zipCode: string;
    name: string;
    phone: string;
    cnpj: string;
    email: string;
};

export type AddAdmin = {
    EID: string;
    Email: string;
    LastName: string;
    Extension: string;
    Name: string;
    Password: string;
    PasswordConfirm: string;
    Phone: string;
    Sexo: string;
};

export type CompanyAPI = {
    estabelecimento: {
        cnpj: string;
        bairro: string;
        email: string;
        cidade: {
            nome: string;
        };
        complemento: string;
        cep: string;
        numero: string;
        estado: {
            sigla: string;
        };
        telefone1: string;
        logradouro: string;
    };
    razao_social: string;
};

export type CompanyDTO = {
    id?: number;
    cnpj: string;
    idSap?: string;
    name?: string;
    code: string;
    registerNumber: string;
    street: string;
    email: string;
    number: string;
    complement: string;
    city: string;
    neighborhood: string;
    state: string;
    country: string;
    zipCode: string;
    phone: string;
    users?: any[];
    companyModules?: CompanyModuleDTO[];
    companyLOBs?: CompanyLOBDTO[];
};

export type CompanyModuleDTO = {
    moduleId: number;
    module: { name: string };
};

export type CompanyLOBDTO = {
    id: number;
    lob: LobDto;
};

export type ModuleDTO = {
    id: number;
    name: number;
};

export type LobDto = {
    lobCode: string;
    gbe: string;
    descricao: string;
    companyLOB: (null | string)[];
    id: number;
    createdAt: string;
    updatedAt: string;
};

export type ResponseLobsDto = {
    lob: LobDto;
    company: null | string;
    id: number;
    createdAt: string;
    updatedAt: string;
};

export type SearchLob = {
    filter: string;
    filterType: string;
    lobs: LobDto[];
};

export type CompanyFilterDTO = {
    id?: number;
    cnpj?: string;
    name?: string;
    role?: number;
    module?: number;
    permission?: number;
    totalItems?: number;
    itemByPage: number;
    indice: number;
};

export const latamCountries = [
    { text: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { text: 'Argentina', value: 'Argentina' },
    { text: 'The Bahamas', value: 'The Bahamas' },
    { text: 'Barbados', value: 'Barbados' },
    { text: 'Belize', value: 'Belize' },
    { text: 'Bolivia', value: 'Bolivia' },
    { text: 'Brasil', value: 'Brasil' },
    { text: 'Chile', value: 'Chile' },
    { text: 'Colombia', value: 'Colombia' },
    { text: 'Costa Rica', value: 'Costa Rica' },
    { text: 'Cuba', value: 'Cuba' },
    { text: 'Dominica', value: 'Dominica' },
    { text: 'República Dominicana', value: 'República Dominicana' },
    { text: 'Ecuador', value: 'Ecuador' },
    { text: 'El Salvador', value: 'El Salvador' },
    { text: 'Grenada', value: 'Grenada' },
    { text: 'Guadeloupe', value: 'Guadeloupe' },
    { text: 'Guatemala', value: 'Guatemala' },
    { text: 'Guyana', value: 'Guyana' },
    { text: 'Guyane française', value: 'Guyane française' },
    { text: 'Haïti', value: 'Haïti' },
    { text: 'Honduras', value: 'Honduras' },
    { text: 'Jamaica', value: 'Jamaica' },
    { text: 'Martinique', value: 'Martinique' },
    { text: 'México', value: 'México' },
    { text: 'Nicaragua', value: 'Nicaragua' },
    { text: 'Panamá', value: 'Panamá' },
    { text: 'Paraguay', value: 'Paraguay' },
    { text: 'Perú', value: 'Perú' },
    { text: 'Puerto Rico', value: 'Puerto Rico' },
    { text: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
    { text: 'Saint Lucia', value: 'Saint Lucia' },
    { text: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { text: 'Suriname', value: 'Suriname' },
    { text: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
    { text: 'Uruguay', value: 'Uruguay' },
    { text: 'Venezuela', value: 'Venezuela' }
];
