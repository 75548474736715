import { Button, FileDrop, Modal } from '@scuf/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onAccept: CallableFunction;
    onReset: VoidFunction;
    onClose: VoidFunction;
};

const FileModal: React.FC<Props> = ({ open, onAccept, onReset, onClose }: Props) => {

    const { t } = useTranslation(['common']);
    return (
        <Modal size="small" open={open} onClose={onClose}>
            <FileDrop
                label={t('translation.File').toUpperCase()}
                placeholder={t('actions.Draganddropfileshere')}
                onAccepted={file => onAccept(file[0])}
                buttonText={t('common:actions.BrowseFiles')}
                onReset={onReset}
            ></FileDrop>
            <div className="buttonsSearch">
                <Button onClick={onClose}>{t('actions.Close')}</Button>
            </div>
        </Modal>
    );
};

export default FileModal;
