import React, { useEffect, useState } from 'react';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { Button, FileDrop, Icon, Modal, Input, Select, Loader } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import 'styles/hsl.scss';
import useFetch from 'helpers/hooks/useFetch';
import { HSL } from 'resources';
import { GuiaData } from 'models/hsl/general';
import { downloadTemplateExcel } from 'resources/api/hsl_api/fileReports';

const NewGuia: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['hsl', 'common']);
    const [data, setData] = useState<GuiaData[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState<GuiaData | null>(null);

    const stateOptions = [
        { value: 'OK', text: 'OK' },
        { value: 'ERRO', text: t('common:status.Error') }
    ];

    const [processedFile, postUploadFile, loadingFile] = useFetch<any, GuiaData[]>({
        url: HSL.postGuiaFile,
        method: 'POST',
        options: {
            headers: {
                'Content-Type': 'application/json'
            }
        },
        onSuccess: t('common:translation.UploadSuccess'),
        onError: t('common:translation.UploadError')
    });

    const [, updateGuia, loadingUpdate] = useFetch<GuiaData[], any>({
        url: HSL.updateBillOfLadings,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    const [, validateGuiaData, loadingValidate] = useFetch<any, any>({
        url: HSL.validateGuia,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (processedFile?.data) {
            setData(processedFile?.data);
        }
    }, [processedFile]);

    const onAdd = () => {
        const newRow: GuiaData = {
            Id: 0,
            IdSap: '',
            GUIA: '',
            State: 'OK',
            ErrorNotExist: false
        };
        setEditData(newRow);
        setOpenModal(true);
    };

    const onEdit = (rowData: ICellData) => {
        setEditData(data[rowData.rowIndex]);
        setOpenModal(true);
    };

    const onSave = () => {
        if (editData?.Id === 0) {
            const newData = [...data, { ...editData, Id: data.length }];
            validateGuiaData(newData, (resp: any) => {
                setData(resp.data);
                setOpenModal(false);
                setEditData(null);
            });
        } else {
            const updatedData = data.map(item => (item.Id === editData?.Id ? { ...item, ...editData } : item));
            validateGuiaData(updatedData, (resp: any) => {
                setData(resp.data);
                setOpenModal(false);
                setEditData(null);
            });
        }
    };

    const handleUploadFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async e => {
            if (e.target) {
                const base64String = (e.target.result as string).replace('data:', '').replace(/^.+,/, '');

                if (file !== undefined) {
                    await postUploadFile({
                        fileName: file.name,
                        fileContent: base64String
                    });
                }
            }
        };
        reader.readAsDataURL(file);
    };

    const onDelete = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const onSubmit = async () => {
        await updateGuia(data);
        navigate('../');
    };

    const handleExportExcel = async () => {
        setLoading(true);
        await downloadTemplateExcel(
            { fileName: 'guia_template' },
            `guia_template.xlsx`,
            () => { },
            () => { }
        );

        setLoading(false);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const renderModal = () => {
        if (!editData) return null;

        return (
            <Modal open={openModal} closeIcon={true} onClose={() => setOpenModal(false)}>
                <Modal.Header>{t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form3Columns">
                        <Input
                            value={editData.IdSap}
                            onChange={value => setEditData({ ...editData, IdSap: value })}
                            label="Id Sap"
                        />
                        <Input value={editData.GUIA} onChange={value => setEditData({ ...editData, GUIA: value })} label="Guia" />
                        <Select
                            label={t('hsl:translation.State')}
                            placeholder={t('hsl:translation.State')}
                            options={stateOptions}
                            onChange={value => setEditData({ ...editData, State: value })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button content={t('common:actions.Save')} type="primary" onClick={onSave} loading={loadingValidate} />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableDeliveryRender = () => {
        return (
            <div style={{ marginTop: '26px' }}>
                {loadingFile ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <DataTable columnResizeMode="fit" data={data}>
                        <DataTable.HeaderBar>
                            <DataTable.HeaderBar.Item
                                content={t('common:actions.Add')}
                                iconSize="small"
                                icon="slidercontrols-plus"
                                onClick={onAdd}
                            />
                        </DataTable.HeaderBar>
                        <DataTable.Column field="IdSap" align="center" header={t('hsl:translation.IdSap')} sortable={true} />
                        <DataTable.Column field="GUIA" align="center" header={t('hsl:translation.Guia')} sortable={true} />
                        <DataTable.Column field="State" align="center" header={t('hsl:translation.State')} sortable={true} />
                        <DataTable.Column
                            field="Error"
                            align="center"
                            header={t('common:errors.Errors')}
                            renderer={rowData => (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <span>
                                        {' '}
                                        {data[rowData.rowIndex].ErrorNotExist ? t('hsl:translation.InvalidGuia') : null}{' '}
                                    </span>
                                </div>
                            )}
                        />
                        <DataTable.Column
                            field="delete"
                            renderer={rowIndex => (
                                <div onClick={() => onDelete(rowIndex.rowIndex)}>
                                    <Icon size="small" root="common" name="delete" className="icon-event" />
                                </div>
                            )}
                            align="center"
                            header="Excluir"
                        />
                        <DataTable.Column
                            field="edit"
                            renderer={rowData => (
                                <div onClick={() => onEdit(rowData)}>
                                    <Icon size="small" root="common" name="edit" className="icon-event" />
                                </div>
                            )}
                            align="center"
                            header={t('common:translation.Edit')}
                        />
                        <DataTable.Pagination totalItems={data.length} itemsPerPage={10} />
                    </DataTable>
                )}
                <div className="button-hsl">
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        size="medium"
                        loading={loadingUpdate}
                        iconPosition="right"
                        onClick={onSubmit}
                        description={t('common:actions.Save')}
                    />
                    <Button
                        type="secondary"
                        icon="arrow-left"
                        content={t('common:actions.Back')}
                        onClick={handleBack}
                        className='back-button'
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="container">
            <div className="button-hsl">
                <Button
                    content={t('hsl:translation.DownloadTemplate')}
                    onClick={() => {
                        handleExportExcel();
                    }}
                    type="secondary"
                    size="medium"
                    loading={loading}
                />
            </div>
            <FileDrop
                acceptedTypes={['.xlsx']}
                resetButtonText={t('common:actions.Clean')}
                multiple={false}
                buttonText={t('common:actions.BrowseFiles')}
                placeholder={t('common:actions.Draganddropfileshere')}
                onAccepted={files => handleUploadFile(files[0])}
            />
            {tableDeliveryRender()}
            {renderModal()}
        </div>
    );
};

export default NewGuia;
