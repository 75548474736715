import { Tab } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import 'styles/main.scss';
import 'styles/repaircenter.scss';
import MaintenanceReportSearch from './maintenanceReportSearch'
import MaintenanceReportExport from './maintenanceReportExport'
import useUser from 'helpers/hooks/useUser';

const SUPERADMIN = '1';
const ADMIN = '2';

function MaintenanceReportPage() {
    const { t } = useTranslation(['common', 'maintenancereport', 'spareparts']);
    const { user } = useUser();

    return (
        <div>
            <div className="container">
                <Tab defaultActiveIndex={0} className="tab-with-divider">
                    <Tab.Pane title={t('maintenancereport:page_name')}>
                        <MaintenanceReportSearch />
                    </Tab.Pane>
                    {(user?.ROLE === SUPERADMIN || user?.ROLE === ADMIN) ? (
                        <Tab.Pane title={t('spareparts:tabs.Report')}>
                            <MaintenanceReportExport />
                        </Tab.Pane>
                    ) : ([]
                    )}
                </Tab>
            </div>
        </div>
    );
}

export default MaintenanceReportPage;
