import { ChatSidebar } from 'components';
import SparePartsChat from './SparePartsChat';
import { useEffect, useState } from 'react';
import { COMPANY_MANAGER, SPARE_PARTS } from 'resources';
import { SparePartsChatTable } from 'models/repaircenter/detailsRma';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { CompanyFilterDTO } from 'models';
import { useRecoilState } from 'recoil';
import { ChatSpareParts } from 'resources/atoms/spareParts';
import { Pagination } from '@scuf/common';
import "../../../../styles/spareParts.scss"

const SparePartsAdminChat = () => {
    const [currentChat, setCurrentChat] = useState<number>();
    const { user } = useUser();
    const [chatsSpareParts, setChatsSpareParts] = useRecoilState<SparePartsChatTable[]>(ChatSpareParts);
    const [chats, loadChats, loading, updateChat] = useFetch<{ roleId: number, ssoId: string, companyId: number }, SparePartsChatTable[]>({ url: SPARE_PARTS.getAllChats });
    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({ url: COMPANY_MANAGER.getCompanies, method: 'POST', onError: 'LoadInfo' });
    const [currentPage, setCurrentPage] = useState(1);

    const handleData = async (indice = 1): Promise<void> => {
        getCompanies({ ...companyFilter?.data, indice, CompanyId: Array.from(new Set(chatsSpareParts?.map(item => item?.companyId) || [])) });
    };

    const paginatedChats = chatsSpareParts.slice((currentPage - 1) * 10, currentPage * 10);

    useEffect(() => {
        handleData();
    }, []);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!loading && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '40%' }}>
                            <ChatSidebar
                                company={companyFilter?.data?.companies}
                                items={paginatedChats.map(e => ({ ...e, id: e.orderId, enable: e.enable }))}
                                onChange={(id: number) => {
                                    setChatsSpareParts(old => [...old].map(e => (e?.orderId === id ? { ...e, itWasReadByAdmin: true } : e)));
                                    setCurrentChat(id);
                                }}
                            />
                        </div>
                        {currentChat && <SparePartsChat orderId={currentChat} company={companyFilter?.data?.companies} />}
                    </div>
                    <div style={{ width: '100%', marginTop: '1.5rem' }}>
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={10}
                            totalItems={chatsSpareParts.length}
                            onPageChange={(pageNumber: any) => handlePageChange(pageNumber)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default SparePartsAdminChat;