import { BudgetItems, WorkOrderBudgerModel } from 'models';

export const getTotalWithOptional = (item: WorkOrderBudgerModel | undefined): number => {

    if (item == undefined)
        return 0;

    let totalItem = 0;
    let total = 0;
    let amountValueTotal = 0;
    let ipiValueTotal = 0;
    let stValueTotal = 0;
    let testeTotal = 0;
    let ServTotal = item.items.filter((i: BudgetItems) => (i.serviceType == 1 || i.serviceType == 5) && i.ncm == '0000.00.00')
        .map((i: BudgetItems) => i.amount)
        .reduce((total: number, current: number) => total + current, 0);

    for (var i = 0; i < item.items.length; i++) {
        if ((item.items[i].serviceType == 1 || item.items[i].serviceType == 5) && item.items[i].ncm != '0000.00.00') {
            if (item.currency == 2) {
                amountValueTotal = Math.round(parseFloat(item.items[i].amount) * 100) / 100;
                ipiValueTotal = Math.round(parseFloat(item.items[i].tax.ipi) * 100) / 100;
                stValueTotal = Math.round(parseFloat(item.items[i].tax.st) * 100) / 100;
                testeTotal = amountValueTotal + ipiValueTotal + stValueTotal;
                total = testeTotal * item.currencyRate;
                total = Math.round(total * 100) / 100;
            } else {
                total = item.items[i].amount + item.items[i].tax.ipi + item.items[i].tax.st;
            }
            totalItem += total;
        }
        total = 0;
    }
    return (totalItem + ServTotal).toFixed(2);
};

export const getTotalwithoutOptional = (item: WorkOrderBudgerModel | undefined): number => {

    if (item == undefined)
        return 0;

    var totalItem = 0;
    var total = 0;
    var amountValueTotal = 0;
    var ipiValueTotal = 0;
    var stValueTotal = 0;
    var testeTotal = 0;
    var ServTotal = item.items.filter(
        (i: BudgetItems) => i.optional == 1 && (i.serviceType == 1 || i.serviceType == 5) && i.ncm == '0000.00.00'
    )
        .map((i: BudgetItems) => i.amount)
        .reduce((total: number, current: number) => total + current, 0);

    for (var i = 0; i < item.items.length; i++) {
        if (
            (item.items[i].serviceType == 1 || item.items[i].serviceType == 5) &&
            item.items[i].ncm != '0000.00.00' &&
            item.items[i].optional === 1
        ) {
            if (item.currency == 2) {
                amountValueTotal = Math.round(parseFloat(item.items[i].amount) * 100) / 100;
                ipiValueTotal = Math.round(parseFloat(item.items[i].tax.ipi) * 100) / 100;
                stValueTotal = Math.round(parseFloat(item.items[i].tax.st) * 100) / 100;
                testeTotal = amountValueTotal + ipiValueTotal + stValueTotal;
                total = testeTotal * item.currencyRate;
                total = Math.round(total * 100) / 100;
            } else {
                total = item.items[i].unitaryValue * item.items[i].quantity + item.items[i].tax.ipi + item.items[i].tax.st;
            }
            totalItem += total;
            total = 0;
        }
    }
    return (totalItem + ServTotal).toFixed(2);
};

export const getTotalProductsSelected = (item: WorkOrderBudgerModel | undefined): string => {

    if (item == undefined)
        return "0";

    var totalItem: number = 0;
    var total = 0;
    var amountValueTotal = 0;
    var ipiValueTotal = 0;
    var stValueTotal = 0;
    var testeTotal = 0;
    for (var i = 0; i < item.items.length; i++) {
        if (
            (item.items[i].serviceType == 1 || item.items[i].serviceType == 5) &&
            item.items[i].ncm != '0000.00.00' &&
            (item.items[i].flagApproved == 1 || item.items[i].flagApproved == 2)
            && item.items[i].optional === 0
        ) {
            if (item.currency == 2) {
                amountValueTotal = Math.round(parseFloat(item.items[i].amount) * 100) / 100;
                ipiValueTotal = Math.round(parseFloat(item.items[i].tax.ipi) * 100) / 100;
                stValueTotal = Math.round(parseFloat(item.items[i].tax.st) * 100) / 100;
                testeTotal = amountValueTotal + ipiValueTotal + stValueTotal;
                total = testeTotal * item.currencyRate;
                total = Math.round(total * 100) / 100;
            } else {
                total = item.items[i].unitaryValue * item.items[i].quantity + item.items[i].tax.ipi + item.items[i].tax.st;
            }
            totalItem += total;
        }
    }

    return totalItem.toFixed(2);
};

export const getTotalServices = (item: WorkOrderBudgerModel): number => {
    return item.items.filter(
        (i: BudgetItems) =>
            (i.serviceType == 1 || i.serviceType == 5) && (i.flagApproved == 1 || i.flagApproved == 2 || i.optional === 1) && i.ncm == '0000.00.00'
    )
        .map((i: BudgetItems) => i.amount)
        .reduce((total: number, current: number) => total + current, 0);
};

export const getTotalBudgetFinal = (item: WorkOrderBudgerModel | undefined): string => {

    if (item == undefined)
        return "0";

    var totalItem: number = 0;
    var total = 0;
    var amountValueTotal = 0;
    var ipiValueTotal = 0;
    var stValueTotal = 0;
    var testeTotal = 0;

    /*
    var ServTotal = item.items.filter(
        (i: BudgetItems) => i.optional == 1 && (i.serviceType == 1 || i.serviceType == 5) && i.ncm == '0000.00.00'
    )
        .map((i: BudgetItems) => i.amount)
        .reduce((total: number, current: number) => total + current, 0);
    */

    var ServTotal = getTotalServices(item);

    for (var i = 0; i < item.items.length; i++) {
        if (
            (item.items[i].serviceType == 1 || item.items[i].serviceType == 5) &&
            item.items[i].ncm != '0000.00.00' &&
            (item.items[i].flagApproved == 1 || item.items[i].flagApproved == 2 || item.items[i].optional === 1)
        ) {
            if (item.currency == 2) {
                amountValueTotal = Math.round(parseFloat(item.items[i].amount) * 100) / 100;
                ipiValueTotal = Math.round(parseFloat(item.items[i].tax.ipi) * 100) / 100;
                stValueTotal = Math.round(parseFloat(item.items[i].tax.st) * 100) / 100;
                testeTotal = amountValueTotal + ipiValueTotal + stValueTotal;
                total = testeTotal * item.currencyRate;
                total = Math.round(total * 100) / 100;
            } else {
                total = item.items[i].unitaryValue * item.items[i].quantity + item.items[i].tax.ipi + item.items[i].tax.st;
            }
            totalItem += total;
        }
    }

    return (totalItem + ServTotal).toFixed(2);
};