import { useEffect, useState, FC } from 'react';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { Button, FileDrop, Icon, Modal, Input, Loader, DatePicker, Popup, VerticalMenu } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import 'styles/hsl.scss';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import { useSetRecoilState } from 'recoil';
import { HSL, COMPANY_MANAGER, notificationAtom } from 'resources';
import { CompanyDTO } from 'models/userManager/addCustomer';
import useFetch from 'helpers/hooks/useFetch';
import { UploadWarrantyData, ContractDTO } from 'models/hsl/general';
import { downloadTemplateExcel } from 'resources/api/hsl_api/fileReports';

interface UploadWarrantyProps {
    onSave: () => void;
    onBack: () => void;
}

const AddWarrantyByUpload: FC<UploadWarrantyProps> = ({ onSave, onBack }) => {
    const setNotification = useSetRecoilState(notificationAtom);
    const { t } = useTranslation(['hsl', 'common']);
    const [data, setData] = useState<UploadWarrantyData[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState<UploadWarrantyData | null>(null);
    const [isAdding, setIsAdding] = useState(false);
    const [loading, setLoading] = useState(false);

    const [processedFile, postUploadFile, loadingFile] = useFetch<any, UploadWarrantyData[]>({
        url: HSL.postUploadWarrantyFile,
        method: 'POST'
    });

    const [, getPartnerInfo, loadingPartner] = useFetch<{ idSap: string | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.GetCompanyByIdSap,
        onError: 'LoadInfo'
    });

    const [, createWarrantyByUpload, loadingCreate] = useFetch<UploadWarrantyData[], ContractDTO>({
        url: HSL.createWarrantyByUpload,
        method: 'POST',
        onError: t('notification:critical.Create'),
        onSuccess: t('notification:success.Create')
    });

    useEffect(() => {
        if (processedFile?.data) {
            setData(processedFile?.data);
        }
    }, [processedFile]);

    const onAdd = () => {
        setEditData(null);
        setIsAdding(true);
        setOpenModal(true);
    };

    const onEdit = (rowData: ICellData) => {
        const item = data[rowData.rowIndex];
        const updatedItem = {
            ...item,
            Id: rowData.rowIndex
        };
        setEditData(updatedItem);
        setOpenModal(true);
    };

    const onSaveEdit = () => {
        if (editData) {
            let updatedData;
            if (isAdding) {
                updatedData = [editData, ...data];
            } else {
                updatedData = data.map((item, index) => (index === editData.Id ? { ...item, ...editData } : item));
            }
            setData(updatedData);
            setOpenModal(false);
            setEditData(null);
            setIsAdding(false);
        }
    };

    const onDelete = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const onSubmit = async () => {
        const companyInfoArray = await handlePartnerInfo();
        const contractsWithInfo = data.map(item => {
            const companyInfo = companyInfoArray.find(info => info.IdSap === item.IdSap);
            return {
                ...item,
                CompanyId: companyInfo?.id,
                Partner: companyInfo?.companyName,
                Country: companyInfo?.country
            };
        });
        await createWarrantyByUpload(contractsWithInfo, () => {
            onSave();
        });
    };

    const handleUploadFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async e => {
            if (e.target) {
                const base64String = (e.target.result as string).replace('data:', '').replace(/^.+,/, '');

                if (file !== undefined) {
                    try {
                        await postUploadFile({
                            fileName: file.name,
                            fileContent: base64String
                        });
                        setNotification(old => ({ ...old, type: 'success', message: t('common:translation.UploadSuccess') }));
                    } catch (e) {
                        setNotification(old => ({ ...old, type: 'critical', message: t('common:translation.UploadError') }));
                    }
                }
            }
        };
        reader.readAsDataURL(file);
    };

    const handlePartnerInfo = async () => {
        const uniqueIdSaps = Array.from(new Set(data.map(item => item.IdSap)));
        const newCompaniesInfo: Array<{ IdSap?: string; companyName?: string; country?: string; id?: number }> = [];

        for (const idSap of uniqueIdSaps) {
            if (idSap) {
                await new Promise<void>(resolve => {
                    getPartnerInfo({ idSap: idSap }, resp => {
                        if (resp.data) {
                            const respObj = {
                                id: resp.data.id,
                                IdSap: resp.data.idSap,
                                companyName: resp.data?.name,
                                country: resp.data?.country
                            };
                            newCompaniesInfo.push(respObj);
                        }
                        resolve();
                    });
                });
            }
        }

        return newCompaniesInfo;
    };

    const handleExportExcel = async () => {
        setLoading(true);
        await downloadTemplateExcel(
            { fileName: 'warranty_template' },
            `warranty_template.xlsx`,
            () => { },
            () => { }
        );

        setLoading(false);
    };

    const renderModal = () => {
        return (
            <Modal open={openModal} closeIcon={true} onClose={() => setOpenModal(false)}>
                <Modal.Header>{t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form4Columns">
                        <Input
                            value={editData?.Garantia}
                            onChange={value => setEditData({ ...editData!, Garantia: value })}
                            label={t('hsl:translation.ContractNumber')}
                        />
                        <Input
                            value={editData?.Sla}
                            onChange={value => setEditData({ ...editData!, Sla: value })}
                            label={t('hsl:translation.SLA')}
                        />
                        <Input
                            value={editData?.Serie}
                            onChange={value => setEditData({ ...editData!, Serie: value })}
                            label={t('common:translation.SerialNumber')}
                        />
                        <Input
                            value={editData?.Material}
                            onChange={value => setEditData({ ...editData!, Material: value })}
                            label={t('hsl:translation.PartNumberMaterial')}
                        />
                    </div>
                    <div className="form4Columns">
                        <Input
                            value={editData?.Modelo}
                            onChange={value => setEditData({ ...editData!, Modelo: value })}
                            label={t('common:translation.Model')}
                        />
                        <Input
                            value={editData?.SoldTo}
                            onChange={value => setEditData({ ...editData!, SoldTo: value })}
                            label={t('repaircenter:translation.SoldTo')}
                        />
                        <Input
                            value={editData?.Cliente}
                            onChange={value => setEditData({ ...editData!, Cliente: value })}
                            label={t('common:translation.Customer')}
                        />
                        <Input
                            value={editData?.IdSap}
                            onChange={value => setEditData({ ...editData!, IdSap: value })}
                            label={t('hsl:translation.IdSap')}
                        />
                    </div>
                    <div className="form4Columns">
                        <DatePicker
                            type="date"
                            reserveSpace={false}
                            label={t('hsl:translation.StartDate')}
                            value={editData?.Inicio}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setEditData({ ...editData!, Inicio: value.toDateString() })}
                        />
                        <DatePicker
                            type="date"
                            reserveSpace={false}
                            label={t('hsl:translation.EndDate')}
                            value={editData?.Termino}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setEditData({ ...editData!, Termino: value.toDateString() })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button content={t('common:actions.Save')} type="primary" onClick={onSaveEdit} />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableContractRender = () => {
        return data ? (
            <div style={{ marginTop: '26px' }}>
                {loadingFile ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <DataTable columnResizeMode="fit" data={data ?? []}>
                        <DataTable.HeaderBar>
                            <DataTable.HeaderBar.Item content="Add" iconSize="small" icon="slidercontrols-plus" onClick={onAdd} />
                        </DataTable.HeaderBar>
                        <DataTable.Column field="IdSap" align="center" header={t('hsl:translation.IdSap')} sortable={true} />
                        <DataTable.Column
                            field="Material"
                            align="center"
                            header={t('hsl:translation.Material')}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="Garantia"
                            align="center"
                            header={t('hsl:translation.Warranty')}
                            sortable={true}
                        />
                        <DataTable.Column field="Sla" align="center" header={t('hsl:translation.SLA')} sortable={true} />
                        <DataTable.Column field="Modelo" align="center" header={t('common:translation.Model')} sortable={true} />
                        <DataTable.Column
                            field="Serie"
                            align="center"
                            header={t('common:translation.SerialNumber')}
                            sortable={true}
                        />
                        <DataTable.Column field="Inicio" align="center" header={t('hsl:translation.StartDate')} sortable={true} />
                        <DataTable.Column field="Termino" align="center" header={t('hsl:translation.EndDate')} sortable={true} />
                        <DataTable.Column
                            field="SoldTo"
                            align="center"
                            header={t('hsl:translation.SoldTo')}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="Cliente"
                            align="center"
                            header={t('hsl:translation.Customer')}
                            sortable={true}
                        />
                        <DataTable.Column
                            field="opcoes"
                            renderer={e => (
                                <Popup
                                    className="popup-theme-wrap"
                                    element={<Icon size="large" root="common" name="ellipsis-vertical" />}
                                    on="click"
                                    align="center"
                                >
                                    <VerticalMenu>
                                        <VerticalMenu.Header>{t('common:tabs.Options')}</VerticalMenu.Header>
                                        <VerticalMenu.Item onClick={() => onEdit(e)}>
                                            <div className="Menu-vertical">
                                                <Icon size="small" root="common" name="edit" className="icon-event" />
                                                {t('common:translation.Edit')}
                                            </div>
                                        </VerticalMenu.Item>
                                        <VerticalMenu.Item onClick={() => onDelete(e.rowIndex)}>
                                            <div className="Menu-vertical">
                                                <Icon size="small" root="common" name="delete" className="icon-event" />
                                                {t('common:actions.Delete')}
                                            </div>
                                        </VerticalMenu.Item>
                                    </VerticalMenu>
                                </Popup>
                            )}
                            header={t('common:tabs.Options')}
                        />
                        <DataTable.Pagination totalItems={data.length} itemsPerPage={10} />
                    </DataTable>
                )}
                <div className="button-hsl">
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        size="medium"
                        iconPosition="right"
                        loading={loadingCreate || loadingPartner}
                        onClick={onSubmit}
                        description={t('common:actions.Save')}
                        disabled={data.length === 0}
                    />
                    <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
                </div>
            </div>
        ) : null;
    };

    return (
        <div>
            <div className="button-hsl">
                <Button
                    content={t('hsl:translation.DownloadTemplate')}
                    onClick={() => {
                        handleExportExcel();
                    }}
                    type="secondary"
                    size="medium"
                    loading={loading}
                />
            </div>
            <FileDrop
                acceptedTypes={['.xlsx']}
                resetButtonText={t('common:actions.Clean')}
                multiple={false}
                buttonText={t('common:actions.BrowseFiles')}
                placeholder={t('common:actions.Draganddropfileshere')}
                onAccepted={files => handleUploadFile(files[0])}
            />
            {tableContractRender()}
            {renderModal()}
        </div>
    );
};

export default AddWarrantyByUpload;
