import { useState, FC } from 'react';
import { Button, Input, Select, Divider, Table, DatePicker, Icon } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { ContractDTO, Equipment, SelectOption } from 'models/hsl/general';
import { notificationAtom, HSL, emailMask } from 'resources';
import { useSetRecoilState } from 'recoil';
import useFetch from 'helpers/hooks/useFetch';
import { RoleComponent } from 'components/roleComponent';
import useUser from 'helpers/hooks/useUser';

interface ContractDetailsProps {
    onSave: () => void;
    onBack: () => void;
    contractDetails: ContractDTO;
    partner: {
        name: string;
        idSap: string;
    }[];
}

const ContractDetails: FC<ContractDetailsProps> = ({ onSave, onBack, contractDetails, partner }) => {
    const { t } = useTranslation(['hsl', 'common', 'repaircenter', 'user']);
    const setNotification = useSetRecoilState(notificationAtom);
    const { user: userDetails } = useUser();
    const [dataContract, setDataContract] = useState<ContractDTO>(contractDetails || []);
    const [equipment, setEquipment] = useState<Equipment>({ SerialNumber: '', PartNumber: '', ContractId: null });
    const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
    const [areAllEquipmentsValidated, setAreAllEquipmentsValidated] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const isEditable = userDetails?.ROLE === '1' || userDetails?.ROLE === '2';

    const contractType: SelectOption[] = [
        { value: 'Contract', text: t('common:translation.Contract') },
        { value: 'Warranty', text: t('common:services.Warranty') }
    ];

    const [, updateContract, loadingUpdate] = useFetch<ContractDTO, ContractDTO[]>({
        url: HSL.updateContract,
        method: 'POST',
        onError: 'LoadInfo'
    });

    const [, validateEquipments, loadingValidate] = useFetch<Equipment[], Equipment[]>({
        url: HSL.validateEquipment,
        method: 'POST',
        onError: 'LoadInfo'
    });

    const onValidate = async () => {
        try {
            await validateEquipmentList();
            const hasErrorDuplicated = equipmentList.some(equip => equip.ErrorDuplicated === true);
            if (hasErrorDuplicated) {
                setNotification({
                    type: 'information',
                    message: t('hsl:translation.NoValidEquipments')
                });
                setIsValidated(false);
            } else {
                setIsValidated(true);
            }
        } catch (error) {
            setNotification({
                type: 'critical',
                message: t('common:errors.Unexpected')
            });
            setIsValidated(false);
        }
    };


    const onUpdate = async () => {
        try {
            const newContract = {
                ...dataContract,
                Equipments: [
                    ...equipmentList
                ]
            };
            updateContracts(newContract);
            handleSave();

        } catch (error) {
            setNotification({
                type: 'critical',
                message: t('common:errors.Unexpected')
            });
        }
        setEquipmentList([]);
    };

    const handleSave = () => {
        onSave();
    };

    const updateContracts = async (newContract: ContractDTO) => {
        try {
            await updateContract({ ...newContract }, (current: any) => {
                if (current.data) {
                    setNotification(old => ({ ...old, type: 'success', message: t('notification:success.Change') }));
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundContracts') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
        }
    };

    const validateEquipmentList = async () => {
        try {
            await validateEquipments(equipmentList, (current: any) => {
                if (current.data) {
                    setEquipmentList(current.data);
                    const allValidated = current.data.every((equip: Equipment) => !equip.ErrorDuplicated);
                    setAreAllEquipmentsValidated(allValidated);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('common:errors.Unexpected') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
            setAreAllEquipmentsValidated(false);
        }
    };


    const handlePartnerChange = (selectedPartnerName: string) => {
        const selectedPartner = partner.find(p => p.name === selectedPartnerName);
        if (selectedPartner) {
            setDataContract({
                ...dataContract,
                Partner: selectedPartner.name,
                IdSap: selectedPartner.idSap
            });
        }
    };

    const handleAddEquipament = () => {
        const updatedEquipment = {
            ...equipment,
            ContractId: dataContract.Equipments[0].ContractId
        };
        setEquipmentList([...equipmentList, updatedEquipment]);
        setEquipment({ SerialNumber: '', PartNumber: '', ContractId: null });
    };


    const handleDeleteEquipament = (index: number) => {
        const updatedList = equipmentList.filter((_, i) => i !== index);
        setEquipmentList(updatedList);
    };

    return (
        <div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.ContractNumber ? dataContract.ContractNumber : dataContract.WarrantyNumber}
                    onChange={value => setDataContract({ ...dataContract, ContractNumber: value })}
                    label={dataContract.ContractNumber ? t('hsl:translation.Contract') : t('hsl:translation.Warranty')}
                />
                <Select
                    disabled={!isEditable}
                    className="select-type"
                    placeholder={t('common:translation.Partner')}
                    label={t('common:translation.Partner')}
                    options={partner.map(p => ({ text: p.name, value: p.name }))}
                    search={true}
                    value={dataContract.Partner}
                    onChange={value => handlePartnerChange(value)}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerName}
                    onChange={value => setDataContract({ ...dataContract, CustomerName: value })}
                    label={t('hsl:translation.Customer')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerAddress}
                    onChange={value => setDataContract({ ...dataContract, CustomerAddress: value })}
                    label={t('hsl:translation.ReceiveAddress')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerCity}
                    onChange={value => setDataContract({ ...dataContract, CustomerCity: value })}
                    label={t('hsl:translation.City')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerCountry}
                    onChange={value => setDataContract({ ...dataContract, CustomerCountry: value })}
                    label={t('hsl:translation.ClientCountry')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerContactName}
                    onChange={value => setDataContract({ ...dataContract, CustomerContactName: value })}
                    label={t('hsl:translation.ClientContactName')}
                />
                <Input
                    disabled={!isEditable}
                    value={emailMask(dataContract.CustomerEmail)}
                    onChange={value => setDataContract({ ...dataContract, CustomerEmail: value })}
                    label={t('hsl:translation.ClientEmail')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerPhone}
                    onChange={value => setDataContract({ ...dataContract, CustomerPhone: value })}
                    label={t('hsl:translation.ClientPhone')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.EquipmentLocationName}
                    onChange={value => setDataContract({ ...dataContract, EquipmentLocationName: value })}
                    label={t('hsl:translation.EquipmentLocationName')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.EquipmentLocationAddress}
                    onChange={value => setDataContract({ ...dataContract, EquipmentLocationAddress: value })}
                    label={t('hsl:translation.EquipmentLocationAddress')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.EquipmentLocationCity}
                    onChange={value => setDataContract({ ...dataContract, EquipmentLocationCity: value })}
                    label={t('hsl:translation.EquipmentLocationCity')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.EquipmentLocationCountry}
                    onChange={value => setDataContract({ ...dataContract, EquipmentLocationCountry: value })}
                    label={t('hsl:translation.EquipmentLocationCountry')}
                />
                <DatePicker
                    disabled={!isEditable}
                    reserveSpace={false}
                    value={dataContract.StartDate}
                    onChange={value => setDataContract({ ...dataContract, StartDate: value ? value.toISOString() : '' })}
                    label={t('hsl:translation.StartDate')}
                />
                <DatePicker
                    disabled={!isEditable}
                    reserveSpace={false}
                    value={dataContract.SaleDate}
                    onChange={value => setDataContract({ ...dataContract, SaleDate: value ? value.toISOString() : '' })}
                    label={t('hsl:translation.SaleDate')}
                />
                <DatePicker
                    disabled={!isEditable}
                    reserveSpace={false}
                    value={dataContract.EndDate}
                    onChange={value => setDataContract({ ...dataContract, EndDate: value ? value.toISOString() : '' })}
                    label={t('hsl:translation.EndDate')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.ContractValue}
                    onChange={value => setDataContract({ ...dataContract, ContractValue: value })}
                    label={t('hsl:translation.ContractValue')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.HourlyContractValue}
                    onChange={value => setDataContract({ ...dataContract, HourlyContractValue: value })}
                    label={t('hsl:translation.HourlyContractValue')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.WarrantyHourlyValue}
                    onChange={value => setDataContract({ ...dataContract, WarrantyHourlyValue: value })}
                    label={t('hsl:translation.HourlyWarrantyValue')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.CustomerNumber}
                    onChange={value => setDataContract({ ...dataContract, CustomerNumber: value })}
                    label={t('hsl:translation.ClientNumber')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.TrackingNumber}
                    onChange={value => setDataContract({ ...dataContract, TrackingNumber: value })}
                    label={t('hsl:translation.TrackingNumber')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.PurchaseOrder}
                    onChange={value => setDataContract({ ...dataContract, PurchaseOrder: value })}
                    label={t('hsl:translation.PurchaseOrder')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.SiteCount?.toString()}
                    onChange={value => setDataContract({ ...dataContract, SiteCount: parseInt(value) })}
                    label={t('hsl:translation.SiteNumber')}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.Description}
                    onChange={value => setDataContract({ ...dataContract, Description: value })}
                    label={t('common:translation.Description')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    disabled={!isEditable}
                    value={dataContract.Comments}
                    onChange={value => setDataContract({ ...dataContract, Comments: value })}
                    label={t('common:translation.Comments')}
                />
                <Select
                    disabled={!isEditable}
                    className="select-type"
                    value={dataContract.Type}
                    placeholder={t('common:translation.Type')}
                    label={t('common:translation.Type')}
                    options={contractType}
                    search={true}
                    onChange={value => setDataContract({ ...dataContract, Type: value })}
                />
                <Input
                    disabled={!isEditable}
                    value={dataContract.Sla}
                    onChange={value => setDataContract({ ...dataContract, Sla: value })}
                    label={t('hsl:translation.SLA')}
                />
            </div>
            <div style={{ padding: '30px 0px' }}>
                <Divider direction="horizontal"> {t('common:translation.Equipments')}</Divider>
                <Table>
                    <Table.Header>
                        <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                        <Table.HeaderCell content={t('common:translation.PartNumber')} />
                    </Table.Header>
                    <Table.Body>
                        {dataContract.Equipments?.map((equip, index) => (
                            <Table.Row key={index}>
                                <Table.Cell content={equip.SerialNumber} />
                                <Table.Cell content={equip.PartNumber} />
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <RoleComponent roles={['ADMIN', 'SUPERADMIN']}>
                    <Divider direction="horizontal"> {t('hsl:translation.AddEquipament')}</Divider>
                    <div className="form3Columns AddEquipment">
                        <Input
                            indicator="required"
                            value={equipment.SerialNumber}
                            onChange={value => setEquipment({ ...equipment, SerialNumber: value })}
                            label={t('common:translation.SerialNumber')}
                        />
                        <Input
                            indicator="required"
                            label={t('common:translation.PartNumber')}
                            value={equipment.PartNumber}
                            onChange={value => setEquipment({ ...equipment, PartNumber: value })}
                        />
                        <Button
                            className="Add"
                            content={t('common:actions.Add')}
                            type="primary"
                            onClick={handleAddEquipament}
                            disabled={!equipment.PartNumber || !equipment.SerialNumber}
                        />
                    </div>
                    {equipmentList.length > 0 ? (
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                                <Table.HeaderCell content={t('common:translation.PartNumber')} />
                                <Table.HeaderCell content={t('common:errors.Errors')} />
                                <Table.HeaderCell content={t('common:actions.Delete')} />
                            </Table.Header>
                            <Table.Body>
                                {equipmentList.map((equip, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell content={equip.SerialNumber} />
                                        <Table.Cell content={equip.PartNumber} />
                                        <Table.Cell content={equip.ErrorDuplicated ? `${t('hsl:translation.DuplicatedSerialNumber')} ${equip.NumberDuplicated}` : null} />
                                        <Table.Cell>
                                            <Icon
                                                name="delete"
                                                root="common"
                                                size="small"
                                                color="critical"
                                                onClick={() => handleDeleteEquipament(index)}
                                                title={t('common:actions.Delete')}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : null}
                </RoleComponent>
            </div>
            <div className="button-hsl">
                <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                    <Button
                        content={t('common:actions.Validate')}
                        type="primary"
                        onClick={onValidate}
                        className="button-add"
                        loading={loadingValidate}
                        disabled={equipmentList.length <= 0}

                    />
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        onClick={onUpdate}
                        className="button-add"
                        loading={loadingUpdate}
                        disabled={(!isValidated || !areAllEquipmentsValidated || equipmentList.some(equip => !!equip.NumberDuplicated)) && equipmentList.length > 0}
                    />
                </RoleComponent>
                <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
            </div>
        </div>
    );
};

export default ContractDetails;
