import { Button, Card, Checkbox, FileDrop, Icon, Input, Modal, Select, Table } from '@scuf/common';
import { LabelDescription } from 'components';
import Alert from 'components/alert';
import { RmaDetailModel, RmaModel, RmaModelLite, RmaOption, shippingType, typeOptions, typeSituation } from 'models';
import { rmaItems } from 'models/repaircenter/detailsRma';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { CreatNewRma, Rma, notificationAtom, rmaDetailState } from 'resources';
import { processingRmaByAdmin } from 'resources/api/repaircenter_api/administrative';

interface ChangeStatusProps {
    rmaState: RmaModelLite;
    setRmaState: React.Dispatch<React.SetStateAction<RmaModelLite>>;
}

function Processing({ rmaState, setRmaState }: ChangeStatusProps): JSX.Element {
    const [statusList, setStatusList] = useState<any[]>([]);
    const [modalState, setModalState] = useState<boolean>(false);
    const [currentStatus, setCurrentStatus] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const { t } = useTranslation(['repaircenter', 'common']);
    const [file, setFile] = useState<File | null>(null);

    async function sendRmaByAdmin() {
        setLoading(true);

        const resp = await processingRmaByAdmin({ file: file, rmaDTO: rmaState });

        if (resp.error) {
            setNotification(old => ({ ...old, message: 'Change' }));
        } else {
            setRmaState(old => ({ ...old, statusId: resp.data.statusId }));
            setNotification(old => ({ ...old, message: 'Change', type: 'success' }));
            window.location.reload();
        }
        setLoading(false);
        setModalState(false);
    }

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modal"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <Card.Footer>
                    <FileDrop
                        placeholder={t('common:actions.Draganddropfileshere')}
                        resetButtonText={t('common:actions.Delete')}
                        onReset={() => setFile(null)}
                        onAccepted={file => {
                            setFile(file[0]);
                        }}
                    />
                    <br />
                    <div className="buttonModal">
                        <Button
                            content={file ? t('common:actions.Finish') : t('common:actions.Close')}
                            onClick={() => setModalState(false)}
                            size="medium"
                            textTransform={true}
                        />
                    </div>
                </Card.Footer>
            </Modal>
        );
    }

    function loadStatusList() {
        let list: RmaOption[] = [];
        typeOptions.forEach(element => {
            list.push(element);
        });
        setStatusList(list);
        setCurrentStatus(t('repaircenterdash:RMA_CREATED'));
    }

    useEffect(() => {
        loadStatusList();
    }, []);

    return (
        <div className="container-changes">
            {renderModal()}
            <div className="container-selects">
                {rmaState?.statusId === 1 ? (
                    [
                        <div>
                            <div className="container-selects">
                                <div className="div-datatable">
                                    <h2>{t('common:translation.Items')}</h2>
                                    <Table>
                                        <Table.Header>
                                            {/* <Table.HeaderCell>{t('common:translation.Number')}</Table.HeaderCell> */}
                                            <Table.HeaderCell>{t('common:translation.Model')}</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                {t('repaircenter:manage_eos.translation.ProblemLocation')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>{t('common:translation.Description')}</Table.HeaderCell>
                                            <Table.HeaderCell>{t('common:status.Approved')}</Table.HeaderCell>
                                        </Table.Header>
                                        {rmaState?.rmaItems.map((item: rmaItems) => (
                                            <Table.Row>
                                                <Table.Cell>{item.model}</Table.Cell>
                                                <Table.Cell>{item.rmaItemsEquipment?.problemLocation?.name ?? ''} </Table.Cell>
                                                <Table.Cell>
                                                    {item.rmaItemsAccessories?.problemDescription ??
                                                        item.rmaItemsEquipment?.problemType?.name ??
                                                        item.rmaItemsEquipment?.problemTypeDescription}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Checkbox
                                                        checked={item?.approved}
                                                        onChange={(value: boolean) =>
                                                            setRmaState(old => ({
                                                                ...old,
                                                                rmaItems: old.rmaItems.map(e =>
                                                                    e === item ? { ...e, approved: value } : e
                                                                )
                                                            }))
                                                        }
                                                    ></Checkbox>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table>
                                </div>
                                <br></br>
                                <div>
                                    <h2>{t('rma.budget.ShippingTransportation')}</h2>
                                </div>
                                <br></br>
                                <div className="form3Columns">
                                    <Select
                                        className="select"
                                        options={shippingType}
                                        label={t('rma.shipping.Shipping')}
                                        placeholder={t('rma.shipping.Shipping')}
                                        value={rmaState?.shipping?.typeId}
                                        onChange={(value: number) => {
                                            setRmaState(old => ({
                                                ...old,
                                                shipping: { ...old.shipping, typeId: value }
                                            }));
                                        }}
                                    />
                                    <Select
                                        className="select"
                                        indicator="required"
                                        placeholder={t('common:status.Situation')}
                                        options={typeSituation}
                                        label={t('common:status.Situation')}
                                        value={parseInt(rmaState?.shipping?.situation)}
                                        onChange={(value: any) => {
                                            setRmaState(old => ({
                                                ...old,
                                                shipping: { ...old.shipping, situation: value.toString() }
                                            }));
                                        }}
                                    />

                                    <Input
                                        placeholder={t('rma.order.PostCode')}
                                        label={t('rma.order.PostCode')}
                                        value={rmaState?.shipping?.code}
                                        maxLength={30}
                                        max={30}
                                        onChange={(value: any) => {
                                            setRmaState(old => ({ ...old, shipping: { ...old.shipping, code: value } }));
                                        }}
                                    ></Input>
                                </div>

                                <div className="form3Columns">
                                    <Input
                                        placeholder={t('rma.order.Observations')}
                                        label={t('rma.order.Observations')}
                                        helperText={t('repaircenter:translation.ObservationsHelp')}
                                        value={rmaState?.shipping?.observation}
                                        maxLength={300}
                                        max={300}
                                        onChange={(value: any) => {
                                            setRmaState(old => ({ ...old, shipping: { ...old.shipping, observation: value } }));
                                        }}
                                    ></Input>
                                </div>
                                {file && (
                                    <LabelDescription title={t('common:translation.File')}>
                                        <>
                                            {file?.name}{' '}
                                            <Icon
                                                name="delete"
                                                onClick={() => {
                                                    setFile(null);
                                                }}
                                            ></Icon>
                                        </>
                                    </LabelDescription>
                                )}
                            </div>

                            <div className="container-button">
                                <Button
                                    type="inline-secondary"
                                    icon="attachment"
                                    onClick={() => setModalState(true)}
                                    content={t('common:actions.Add')}
                                    iconPosition="right"
                                />
                                <Button
                                    content={t('common:actions.SetProcees')}
                                    loading={loading}
                                    disabled={
                                        !rmaState.shipping ||
                                        (rmaState?.shipping?.typeId == 3 && rmaState.shipping.situation == null)
                                    }
                                    onClick={() => sendRmaByAdmin()}
                                />
                            </div>
                        </div>
                    ]
                ) : (
                    <Alert text={t('common:translation.RmaNotProcess')} />
                )}
            </div>
        </div>
    );
}
export default Processing;
