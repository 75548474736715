import { CompanyDTO } from 'models/userManager/addCustomer';

export type SsoDTO = {
    grant_type: string;
    code: string | null;
    redirect_uri: string;
};

export type UserDTO = {
    ssoId: string;
    name: string;
    email: string;
    phone: string;
    roleId: number;
    companyId: number | null;
    verified: boolean;
    company: CompanyDTO | null;
    role: {
        name: string;
    };
};
export const UserRole = {
    SUPERADMIN: '1',
    ADMIN: '2',
    CLIENTADMIN: '3',
    CLIENT: '4'
};

export const UserModule = {
    SPAREPARTS: 1,
    RMA: 2,
    MAINTENANCE_REPORT: 3,
    HSL_WEB: 4,
    USER: 5,
    CENTER_CA: 6
};
