import { Badge, Button, DatePicker, Header, Icon, Input, Loader, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { statusOptions, typeOptions, typeSituation } from 'models/repaircenter/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { REPAIR_CENTER, RmaLite, cnpjMask } from 'resources/';
import { RMAFilterHistory, RmaFilterModel, RmaModel, RmaModelLite } from 'models';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { listYesNop } from 'models/spareParts';
import { formatDate } from 'helpers';
import { rmaStatusColors, rmaTypeColors } from 'models/repaircenter/detailsRma';
import { getColors } from 'helpers/getBadgeColors';
import { useRecoilState } from 'recoil';
import { FilterAtom } from 'resources/';

function RmaSearch() {
    const navigate: NavigateFunction = useNavigate();
    const { t } = useTranslation(['repaircenter', 'common']);
    const [error, setError] = useState<string>('');
    const [reset, setReset] = useState(true);
    const itemsPerPage = ['5', '10', '15', '20'];
    const [dataRma, getRmaFilter, loadingRmas] = useFetch<RmaFilterModel, RmaFilterModel>({
        url: REPAIR_CENTER.getRmas,
        method: 'POST',
        redirectOnError: true
    });

    const { user: userDetails } = useUser();
    const [rmaFilter, setRmaFilter] = useRecoilState<RmaFilterModel>(FilterAtom);
    const [rmaState, setRmaState] = useRecoilState<RmaModelLite>(RmaLite);
    const [call] = useState(false);
    const [historyValue, setHistoryValue] = useState<RMAFilterHistory>();
    const [, setHistoric] = useState<string[]>([]);

    const loadHistory = () => {
        const savedHistory = JSON.parse(localStorage.getItem('historicoPesquisa') || '[]');
        setHistoric(savedHistory);
        const newValue = historyValue?.serialNumber;
        if (newValue && !savedHistory.includes(newValue)) {
            const newHistory = [...savedHistory, newValue];
            setHistoric(newHistory);
            localStorage.setItem('historicoPesquisa', JSON.stringify(newHistory));
        }
    };

    async function getRmaFilterDetails(indice = 1) {
        await getRmaFilter({ ...rmaFilter, indice }, current => {
            setRmaFilter(old => ({ ...old, totalItems: current.data?.totalItems }));
        });
    }

    function handleCellClick(row: RmaModel) {
        navigate(`rma-detail/${row.id}`);
        setRmaState({ ...rmaState, id: row.id });
    }

    useEffect(() => {
        getRmaFilterDetails();
    }, [reset, call]);

    return (
        <>
            {(loadingRmas && <Loader text={t('common:actions.Loading')} />) || (
                <>
                    <div>
                        <Header title={t('translation.RMA')} logo={false} menu={false}>
                            <div className="buttonsSearch">
                                <Button
                                    content={t('translation.CreatenewRMA')}
                                    type="primary"
                                    size="medium"
                                    iconPosition="right"
                                    icon={<Icon name="add" root="building" />}
                                    badge="20"
                                    onClick={() => navigate('new-rma')}
                                    description={t('translation.CreatenewRMA')}
                                />
                            </div>
                        </Header>
                        <div className="form4Columns">
                            <Input
                                placeholder="RMA"
                                label="RMA"
                                type="number"
                                onChange={(value: string) =>
                                    setRmaFilter(old => ({ ...old, id: isNaN(parseInt(value)) ? undefined : parseInt(value) }))
                                }
                                value={rmaFilter?.id?.toString() ?? ''}
                            />
                            <Input
                                placeholder={t('common:translation.SerialNumber')}
                                label={t('common:translation.SerialNumber')}
                                onChange={(event: any) => {
                                    setRmaFilter(old => ({ ...old, serialNumber: event }));
                                    setHistoryValue({ serialNumber: event });
                                    loadHistory();
                                }}
                                value={rmaFilter?.serialNumber?.toString() ?? ''}
                                list="historicoList"
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.StatusRMA')}
                                placeholder={t('common:translation.StatusRMA')}
                                options={statusOptions}
                                onChange={value =>
                                    setRmaFilter(old => ({
                                        ...old,
                                        statusId: isNaN(parseInt(value)) ? undefined : parseInt(value)
                                    }))
                                }
                                value={rmaFilter?.statusId ?? ''}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.Type')}
                                placeholder={t('common:translation.Type')}
                                options={typeOptions}
                                onChange={value =>
                                    setRmaFilter(old => ({
                                        ...old,
                                        typeId: isNaN(parseInt(value)) ? undefined : parseInt(value)
                                    }))
                                }
                                value={rmaFilter?.typeId ?? ''}
                            />
                        </div>
                        <div className="form4Columns">
                            <Select
                                className="SelectStyle"
                                label={t('repaircenter:rma.order.TransportType')}
                                placeholder={t('repaircenter:rma.order.TransportType')}
                                options={typeSituation}
                                value={parseInt(rmaFilter?.shippingSituation!)}
                                onChange={value =>
                                    setRmaFilter(old => ({ ...old, shippingSituation: value == 1 ? null : value.toString() }))
                                }
                            />
                            <Input
                                placeholder={t('rma.budget.WorkOrder')}
                                label={t('rma.budget.WorkOrder')}
                                onChange={value => setRmaFilter(old => ({ ...old, order: parseInt(value) }))}
                                value={rmaFilter?.order?.toString() ?? ''}
                            />
                            <Input
                                label={t('rma.order.Customer.InvoiceCNPJ')}
                                placeholder={t('rma.order.Customer.InvoiceCNPJ')}
                                value={cnpjMask(rmaFilter?.cnpjNota ?? '')}
                                onChange={value => {
                                    setRmaFilter(old => ({ ...old, cnpjNota: cnpjMask(value.toString()) }));
                                }}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.ItemperPage')}
                                placeholder={t('common:translation.ItemperPage')}
                                value={rmaFilter?.itemsByPage?.toString()}
                                options={itemsPerPage.map((e: string) => ({ text: e.toString(), value: e }))}
                                onChange={value => setRmaFilter(old => ({ ...old, itemsByPage: parseInt(value) }))}
                            />
                        </div>
                        {userDetails?.ROLE == '1' || userDetails?.ROLE == '2' ? (
                            <div className="form4Columns">
                                <Input
                                    label={t('Cnpj')}
                                    placeholder={t('Cnpj')}
                                    value={cnpjMask(rmaFilter?.cnpj ?? '')}
                                    onChange={value => {
                                        setRmaFilter(old => ({ ...old, cnpj: cnpjMask(value.toString()) }));
                                    }}
                                />
                                <Input
                                    label={t('repaircenter:rma.order.NFNumber')}
                                    placeholder={t('repaircenter:rma.order.NFNumber')}
                                    onChange={value => setRmaFilter(old => ({ ...old, invoice: value }))}
                                    value={rmaFilter?.invoice?.toString() ?? ''}
                                />
                                <Select
                                    className="SelectStyle"
                                    label={t('repaircenter:rma.order.RequestedShipping')}
                                    placeholder={t('repaircenter:rma.order.RequestedShipping')}
                                    value={
                                        rmaFilter?.shippingTypeId == 3 ? true : rmaFilter?.shippingTypeId == 2 ? false : undefined
                                    }
                                    options={listYesNop}
                                    onChange={value => setRmaFilter(old => ({ ...old, shippingTypeId: value ? 3 : 2 }))}
                                />

                                <DatePicker
                                    closeOnSelection
                                    type="daterange"
                                    viewType="compact"
                                    label={t('common:translation.Period')}
                                    error={error}
                                    rangeValue={{ from: rmaFilter?.dateStart ?? '', to: rmaFilter?.dateEnd ?? '' }}
                                    displayFormat={'DD/MM/YYYY'}
                                    closeOnDocumentClick={true}
                                    onTextChange={value => setError(value)}
                                    onRangeSelect={({ to, from }) => {
                                        setRmaFilter(old => ({ ...old, dateStart: from, dateEnd: to }));
                                    }}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="buttonsSearch">
                            <Button
                                type="secondary"
                                icon="badge-delete"
                                content={t('common:actions.ResetFilters')}
                                iconPosition="right"
                                onClick={() => {
                                    setRmaFilter(
                                        old =>
                                        ({
                                            indice: 1,
                                            itemsByPage: old.itemsByPage
                                        } as RmaFilterModel)
                                    );
                                    setReset(old => !old);
                                }}
                            />
                            <Button
                                type="primary"
                                icon="filter1"
                                content={t('common:actions.ApplyFilters')}
                                iconPosition="right"
                                onClick={() => getRmaFilterDetails()}
                            />
                        </div>
                    </div>
                    <div className="div-datatable">
                        <DataTable
                            data={dataRma?.data?.rmas ?? []}
                            rows={rmaFilter?.itemsByPage}
                            onCellClick={e => handleCellClick(e.rowData)}
                        >
                            <DataTable.Column field="id" header={t('Id')} sortable />

                            <DataTable.Column
                                field=" "
                                renderer={e => (
                                    <Badge color={getColors(e.rowData.type.id, rmaTypeColors)}>{e.rowData.type.name}</Badge>
                                )}
                                header={t('common:translation.Type')}
                            />
                            <DataTable.Column
                                field="cnpjCompany"
                                renderer={e => <p>{cnpjMask(e.rowData.cnpjCompany || '')}</p>}
                                header={t('CNPJ')}
                            />
                            <DataTable.Column
                                field="cnpjNota"
                                renderer={e => <p>{cnpjMask(e.rowData.cnpjNota || '')}</p>}
                                header={t('rma.order.Customer.InvoiceCNPJ')}
                            />
                            <DataTable.Column
                                field=" "
                                renderer={e => (
                                    <Badge color={getColors(e.rowData.status.id, rmaStatusColors)}>{e.rowData.status.name}</Badge>
                                )}
                                header={t('common:translation.StatusRMA')}
                            />
                            <DataTable.Column
                                field=" "
                                renderer={e => <p>{e.rowData.rmaItems.length}</p>}
                                header={t('repaircenter:rma.order.Equipments')}
                            />
                            <DataTable.Column
                                field=" "
                                renderer={e => <p>{formatDate(e.rowData.createdAt)}</p>}
                                header={t('common:translation.Date')}
                            />
                            <DataTable.Pagination
                                activePage={rmaFilter?.indice}
                                totalItems={rmaFilter?.totalItems!}
                                itemsPerPage={rmaFilter?.itemsByPage}
                                disabledPages={[rmaFilter?.indice]}
                                onPageChange={pageNumber => {
                                    getRmaFilterDetails(pageNumber as number);
                                    setRmaFilter(old => ({ ...old, indice: parseInt(pageNumber.toString()) }));
                                }}
                            />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
}

export default RmaSearch;
