import { Button, DatePicker } from '@scuf/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';
import { downloadFileExcel } from 'resources/api/MaintenancesReport_api/fileMaintenance';

function MaintenanceReportExport() {
    const [rangeValue, setRangeValue] = useState<{ to: any; from: any }>();
    const { t } = useTranslation(['spareparts', 'common']);
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);

    const handleSuccess = (message: string) => { setNotification({ type: "success", message: "Report" }) };
    const handleError = (message: string) => { setNotification({ type: 'information', message: "NoData" }); };


    useEffect(() => {
        setEmptyValue();
    }, []);

    useEffect(() => {
        enableButton();
    }, [rangeValue]);

    const handleReport = async () => {
        setLoading(true);

        await downloadFileExcel({ DateEnd: rangeValue?.to, DateStart: rangeValue?.from },
            `report${rangeValue?.to}-${rangeValue?.from} .xlsx`,
            handleSuccess, handleError)

        setLoading(false);
    }

    function setEmptyValue() {
        setRangeValue({ to: '', from: '' });
    }

    const enableButton = () => {
        return (rangeValue?.to ?? '') !== '' && (rangeValue?.from ?? '') !== '';
    };

    return (
        <div className="form3Columns">
            <div>
                <DatePicker
                    indicator="required"
                    disableFuture={true}
                    type="daterange"
                    label={t('common:translation.Period')}
                    rangeValue={rangeValue}
                    displayFormat={'DD-MM-YYYY'}
                    closeOnSelection={true}
                    closeOnDocumentClick={true}
                    onRangeSelect={({ to, from }) => {
                        setRangeValue({
                            to,
                            from
                        });
                    }}
                />
            </div>
            <div className="buttonsSearch">
                <Button
                    type="secondary"
                    icon="badge-delete"
                    content={t('common:actions.ResetFilters')}
                    onClick={setEmptyValue}
                    iconPosition="right"
                />
                <Button
                    type="primary"
                    icon="report"
                    content={t('common:actions.Generate')}
                    loading={loading}
                    iconPosition="right"
                    disabled={!enableButton()}
                    onClick={handleReport}
                />
            </div>
        </div>
    );
}

export default MaintenanceReportExport;
