import { atom } from "recoil";

export const hslPartnersAtom = atom({
    key: 'hslPartnersAtom',
    default: [] as { value: string; text: string }[]
});

export const hslCountriesAtom = atom({
    key: 'hslCountriesAtom',
    default: [] as { value: string; text: string }[]
});

export const hslPartsAtom = atom({
    key: 'hslPartsAtom',
    default: [] as {
        CreatedAt: string;
        Description: string;
        FinalPrice: string;
        Id: number;
        ListPrice: string;
        PartNumber: string;
        UpdatedAt: string;
    }[]
});

export const hslPartsQuotation = atom({
    key: 'hslPartsQuotation',
    default: [] as {
        Id?: string;
        PartNumber: string;
        PartNumberDescription: string;
        PriceItem: number;
        Quantity: number;
        TotalPriceItem: number;
        TotalPrice?: string;
    }[]
})
