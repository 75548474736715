import { useState, useEffect } from 'react';
import { Button, Input, Select, Icon, Header, Modal, Popup, VerticalMenu } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { useTranslation } from 'react-i18next';
import { RoleComponent } from 'components/roleComponent';
import useFetch from 'helpers/hooks/useFetch';
import { HSL } from 'resources';
import { SelectOption, FilterProductPartsDTO, ProductsPartsDTO, Product, Part } from 'models/hsl/general';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import AddProductByUpload from './new-product/addProductByUpload';
import { useRecoilState } from 'recoil';
import { hslPartsAtom } from 'resources/atoms/hslAtom';
import '../../../styles/hsl.scss';

const InitialFilter: FilterProductPartsDTO = {
    NameProducts: [],
    ProductDescription: '',
    PartNumbers: [],
    PartNumberDescription: '',
    FinalPrice: '',
    ListPrice: '',
    PageIndex: 1,
    PageSize: 10
};

function ProductSearch() {
    const { t } = useTranslation(['common', 'hsl', 'user', 'home', 'notification']);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const [filters, setFilters] = useState(InitialFilter);
    const [filteredData, setFilteredData] = useState<ProductsPartsDTO[]>([]);
    const [productOptions, setProductOptions] = useState<SelectOption[]>([]);
    const [productFilterOptions, setProductFilterOptions] = useState<SelectOption[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState<ProductsPartsDTO | null>(null);
    const [showUploadProduct, setShowUploadProduct] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isPartNumberDisabled, setIsPartNumberDisabled] = useState(false);
    const [isProductDisabled, setIsProductDisabled] = useState(false);
    const [newProduct, setNewProduct] = useState<Product>();
    const [openNewProductModal, setOpenNewProductModal] = useState(false);
    const [partsOptions, setPartsOptions] = useState<SelectOption[]>([]);

    const [allProducts, getProducts] = useFetch<any, any>({
        url: HSL.getProducts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getProductsParts, loadingProductParts] = useFetch<FilterProductPartsDTO, ProductsPartsDTO[]>({
        url: HSL.getProductsParts,
        method: 'POST',
        onError: t('hsl:translation.NotFoundProducts')
    });

    const [deleteProducts, deleteProductsParts] = useFetch<ProductsPartsDTO, ProductsPartsDTO[]>({
        url: HSL.deleteProductsParts,
        method: 'POST',
        onError: t('notification:critical.Removed')
    });

    const [updateProducts, updateProductsParts, loadingUpdate] = useFetch<ProductsPartsDTO, ProductsPartsDTO[]>({
        url: HSL.updateProductsParts,
        method: 'POST',
        onError: t('notification:critical.Update')
    });

    const [, createProduct, loadingCreateProduct] = useFetch<Product, Product[]>({
        url: HSL.createProduct,
        method: 'POST',
        onError: t('notification:critical.Create')
    });

    const [productParts, createProductsParts, loadingCreate] = useFetch<ProductsPartsDTO[], ProductsPartsDTO[]>({
        url: HSL.createProductParts,
        method: 'POST',
        onError: t('notification:critical.Create')
    });

    useEffect(() => {
        getAllProductsParts(filters);
        getAllParts();
        fetchData(getProducts, setProductOptions, true);
    }, [productParts, deleteProducts, updateProducts]);

    const fetchData = async (fetchFunction: any, setOptions: any, isProductOptions: boolean = false) => {
        await fetchFunction(null, (current: any) => {
            if (current?.data) {
                const options = current?.data?.map((item: any) => ({
                    value: item.PartNumber || item.Name || item,
                    text: item.PartNumber || item.Name || item
                }));
                if (isProductOptions) {
                    setOptions([
                        {
                            value: 'new-product',
                            text: (
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase',
                                        color: '#10659F',
                                        display: 'flex',
                                        alignContent: 'flex-end',
                                        gap: '12px'
                                    }}
                                >
                                    <Icon
                                        name="slidercontrols-plus"
                                        root="common"
                                        size="small"
                                        style={{ marginLeft: '4px', marginBottom: '4px' }}
                                    />
                                    <div>{t('hsl:translation.CreateNewProduct')}</div>
                                </div>
                            )
                        },
                        ...options
                    ]);
                    setProductFilterOptions(options);
                } else {
                    setOptions(options);
                }
            }
        });
    };

    useEffect(() => {
        const partsOptions = parts.map(part => ({
            value: part.PartNumber,
            text: part.PartNumber
        }));
        setPartsOptions(partsOptions);
    }, [parts]);

    const getAllProductsParts = async (filters: FilterProductPartsDTO) => {
        await getProductsParts(filters, (current: any) => {
            if (current?.data.Data) {
                setFilteredData(current?.data.Data);
                setTotalItems(current.data.TotalCount);
            }
        });
    };

    const getAllParts = async () => {
        await getParts(null, (current: any) => {
            if (current.data) {
                setParts(current.data);
            }
        });
    };

    const applyFilters = () => {
        getAllProductsParts(filters);
    };

    const resetFilters = () => {
        setFilters(InitialFilter);
        getAllProductsParts(InitialFilter);
    };

    const onDelete = (index: ICellData) => {
        deleteProductsParts(index.rowData);
    };

    const onEdit = (index: ICellData) => {
        setCurrentEditItem(index.rowData);
        setIsAdding(false);
        setOpenEditModal(true);
    };

    const handleSave = async () => {
        const itemsToSave = Array.isArray(currentEditItem) ? currentEditItem : [currentEditItem];
        if (itemsToSave.length > 0) {
            await createProductsParts(itemsToSave.slice(0, 1), () => { });
            setOpenEditModal(false);
        }
    };

    const handleUpdate = async () => {
        await updateProductsParts(currentEditItem!, () => { });
        setOpenEditModal(false);
    };

    const handleCreateNewProduct = () => {
        setOpenEditModal(false);
        setOpenNewProductModal(true);
    };

    const handleCreateProduct = async () => {
        await createProduct(newProduct, () => { });
        setOpenNewProductModal(false);
        setOpenEditModal(true);
        setNewProduct({ Name: '', Description: '' });
        fetchData(getProducts, setProductOptions, true);
    };

    const onAdd = () => {
        setCurrentEditItem(null);
        setIsAdding(true);
        setOpenEditModal(true);
    };

    const handleSelectChange = (type: string, value: string) => {
        let updatedItem = { ...currentEditItem! };
        if (type === 'PartNumber') {
            const selectedPart = parts?.find((part: Part) => part.PartNumber === value);
            if (selectedPart) {
                updatedItem.PartNumber = value;
                updatedItem.PartDescription = selectedPart.Description || '';
                updatedItem.FinalPrice = selectedPart.FinalPrice || '';
                updatedItem.ListPrice = selectedPart.ListPrice || '';
                setIsPartNumberDisabled(true);
            } else {
                setIsPartNumberDisabled(false);
            }
        } else if (type === 'Product') {
            const selectedProduct = allProducts?.data.find((product: Product) => product.Name === value);
            if (selectedProduct) {
                updatedItem.NameProduct = value;
                updatedItem.ProductDescription = selectedProduct.Description || '';
                setIsProductDisabled(true);
            } else {
                setIsProductDisabled(false);
            }
        }
        setCurrentEditItem(updatedItem);
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('pages:product')} logo={false} menu={false}>
                    <div style={{ margin: '10px' }}>
                        <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                            <Button
                                content={t('hsl:translation.AddProduct/Parts')}
                                type="primary"
                                size="medium"
                                iconPosition="right"
                                icon={<Icon name="add" root="building" />}
                                onClick={onAdd}
                                description={t('hsl:translation.AddProduct/Parts')}
                            />
                            <Button
                                content={t('hsl:translation.ImportProducts/Parts')}
                                type="primary"
                                size="medium"
                                iconPosition="right"
                                icon={<Icon name="file-upload" root="building" />}
                                onClick={() => setShowUploadProduct(true)}
                                description={t('hsl:translation.ImportProducts/Parts')}
                            />
                        </RoleComponent>
                    </div>
                </Header>
                <div className="form4Columns">
                    <Select
                        placeholder={t('common:translation.PartNumber')}
                        label={t('common:translation.PartNumber')}
                        value={filters.PartNumbers}
                        options={partsOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, PartNumbers: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.PartDescription')}
                        label={t('hsl:translation.PartDescription')}
                        value={filters.PartNumberDescription}
                        onChange={value => setFilters({ ...filters, PartNumberDescription: value })}
                    />
                    <Select
                        placeholder={t('common:translation.Product')}
                        label={t('common:translation.Product')}
                        value={filters.NameProducts}
                        options={productFilterOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, NameProducts: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.ProductDescription')}
                        label={t('hsl:translation.ProductDescription')}
                        value={filters.ProductDescription}
                        onChange={value => setFilters({ ...filters, ProductDescription: value })}
                    />
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const renderEditModal = () => {
        const isFormValid = () => {
            return (
                currentEditItem?.PartNumber &&
                currentEditItem?.PartDescription &&
                currentEditItem?.FinalPrice &&
                currentEditItem?.ListPrice &&
                currentEditItem?.NameProduct &&
                currentEditItem?.ProductDescription
            );
        };

        return (
            <Modal open={openEditModal} closeIcon={true} onClose={() => setOpenEditModal(false)}>
                <Modal.Header>{isAdding ? t('common:actions.Add') : t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form3Columns">
                        <Select
                            helperText={t('hsl:translation.HelperTextPn')}
                            indicator="required"
                            placeholder={currentEditItem?.PartNumber || t('common:translation.PartNumber')}
                            label={t('common:translation.PartNumber')}
                            value={currentEditItem?.PartNumber}
                            options={partsOptions}
                            search={true}
                            onChange={value => handleSelectChange('PartNumber', value)}
                            onSearch={value => {
                                setCurrentEditItem({ ...currentEditItem!, PartNumber: value });
                                setIsPartNumberDisabled(false);
                            }}
                            disabled={!isAdding}
                        />
                        <Input
                            indicator="required"
                            label={t('hsl:translation.PartDescription')}
                            value={currentEditItem?.PartDescription}
                            onChange={value => setCurrentEditItem({ ...currentEditItem!, PartDescription: value })}
                            disabled={isPartNumberDisabled}
                        />
                        <Input
                            indicator="required"
                            label={t('hsl:translation.FinalPrice')}
                            value={currentEditItem?.FinalPrice}
                            onChange={value => setCurrentEditItem({ ...currentEditItem!, FinalPrice: value })}
                            disabled={isPartNumberDisabled && isAdding}
                        />
                        <Input
                            indicator="required"
                            label={t('hsl:translation.ListPrice')}
                            value={currentEditItem?.ListPrice}
                            onChange={value => setCurrentEditItem({ ...currentEditItem!, ListPrice: value })}
                            disabled={isPartNumberDisabled && isAdding}
                        />
                        <Select
                            indicator="required"
                            label={t('common:translation.Product')}
                            placeholder={t('common:translation.Product')}
                            value={currentEditItem?.NameProduct}
                            options={productOptions}
                            search={true}
                            onChange={value => {
                                if (value === 'new-product') {
                                    handleCreateNewProduct();
                                    return;
                                }
                                handleSelectChange('Product', value);
                            }}
                            disabled={!isAdding}
                        />
                        <Input
                            indicator="required"
                            label={t('hsl:translation.ProductDescription')}
                            value={currentEditItem?.ProductDescription}
                            onChange={value => setCurrentEditItem({ ...currentEditItem!, ProductDescription: value })}
                            disabled={isProductDisabled}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    {isAdding ? (
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            onClick={handleSave}
                            loading={loadingCreate}
                            disabled={!isFormValid()}
                        />
                    ) : (
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            onClick={handleUpdate}
                            loading={loadingUpdate}
                            disabled={!isFormValid()}
                        />
                    )}
                </Modal.Footer>
            </Modal>
        );
    };

    const renderNewProductModal = () => {
        return (
            <Modal open={openNewProductModal} closeIcon={true} onClose={() => setOpenNewProductModal(false)}>
                <Modal.Header>{t('common:actions.Add')}</Modal.Header>
                <Modal.Content>
                    <div className="form3Columns">
                        <Input
                            indicator="required"
                            label={t('common:translation.Product')}
                            value={newProduct?.Name}
                            onChange={value => setNewProduct({ ...newProduct!, Name: value })}
                        />
                        <Input
                            indicator="required"
                            label={t('hsl:translation.ProductDescription')}
                            value={newProduct?.Description}
                            onChange={value => setNewProduct({ ...newProduct!, Description: value })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        onClick={handleCreateProduct}
                        loading={loadingCreateProduct}
                        disabled={!newProduct?.Name && !newProduct?.Description}
                    />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableProductRender = () => {
        return (
            <div className="Table-product-parts" style={{ marginTop: '26px', marginLeft: '20px' }}>
                <DataTable columnResizeMode="fit" data={filteredData} loading={loadingProductParts}>
                    <DataTable.Column
                        field={'NameProduct'}
                        align="center"
                        header={t('common:translation.Product')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'ProductDescription'}
                        align="center"
                        header={t('hsl:translation.ProductDescription')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'PartNumber'}
                        align="center"
                        header={t('common:translation.PartNumber')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'PartDescription'}
                        align="center"
                        header={t('hsl:translation.PartDescription')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'FinalPrice'}
                        align="center"
                        header={t('hsl:translation.FinalPrice')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field={'ListPrice'}
                        align="center"
                        header={t('hsl:translation.ListPrice')}
                        sortable={true}
                    />
                    <DataTable.Column
                        field="opcoes"
                        renderer={e => (
                            <Popup
                                className="popup-theme-wrap"
                                element={<Icon size="large" root="common" name="ellipsis-vertical" />}
                                on="click"
                                align="center"
                            >
                                <VerticalMenu>
                                    <VerticalMenu.Header>{t('common:tabs.Options')}</VerticalMenu.Header>
                                    <VerticalMenu.Item onClick={() => onDelete(e)}>
                                        <div className="Menu-vertical">
                                            <Icon size="small" root="common" name="delete" className="icon-event" />
                                            {t('common:actions.Delete')}
                                        </div>
                                    </VerticalMenu.Item>
                                    <VerticalMenu.Item onClick={() => onEdit(e)}>
                                        <div className="Menu-vertical">
                                            <Icon size="small" root="common" name="edit" className="icon-event" />
                                            {t('common:translation.Edit')}
                                        </div>
                                    </VerticalMenu.Item>
                                </VerticalMenu>
                            </Popup>
                        )}
                        header={t('common:tabs.Options')}
                    />
                </DataTable>
                <DataTable.Pagination
                    totalItems={totalItems}
                    itemsPerPage={10}
                    onPageChange={pageNumber => {
                        const filterPage: FilterProductPartsDTO = { ...filters, PageIndex: parseInt(pageNumber.toString()) };
                        getAllProductsParts(filterPage);
                    }}
                />
            </div>
        );
    };

    const renderContent = () => {
        if (showUploadProduct) {
            return (
                <AddProductByUpload
                    onSave={() => {
                        setShowUploadProduct(false);
                        getAllProductsParts(filters);
                    }}
                    onBack={() => {
                        setShowUploadProduct(false);
                        getAllProductsParts(filters);
                    }}
                />
            );
        } else {
            return (
                <>
                    {searchFieldsRender()}
                    {renderEditModal()}
                    {tableProductRender()}
                    {renderNewProductModal()}
                </>
            );
        }
    };

    return renderContent();
}

export default ProductSearch;
