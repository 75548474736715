import { FC, useState, useEffect } from 'react';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { Button, FileDrop, Icon, Modal, Input, Loader, VerticalMenu, Popup } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import { useSetRecoilState } from 'recoil';
import { HSL, notificationAtom } from 'resources';
import useFetch from 'helpers/hooks/useFetch';
import { ProductsPartsDTO } from 'models/hsl/general';
import 'styles/hsl.scss';
import { downloadTemplateExcel } from 'resources/api/hsl_api/fileReports';

interface UploadProductProps {
    onSave: () => void;
    onBack: () => void;
}

const AddProductByUpload: FC<UploadProductProps> = ({ onSave, onBack }) => {
    const setNotification = useSetRecoilState(notificationAtom);
    const { t } = useTranslation(['hsl', 'common']);
    const [data, setData] = useState<ProductsPartsDTO[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [editData, setEditData] = useState<ProductsPartsDTO | null>(null);
    const [isAdding, setIsAdding] = useState(false);
    const [loading, setLoading] = useState(false);

    const [processedFile, postUploadFile, loadingFile] = useFetch<any, ProductsPartsDTO[]>({
        url: HSL.postProductFile,
        method: 'POST'
    });

    const [, createProductsParts, loadingCreate] = useFetch<ProductsPartsDTO[], ProductsPartsDTO[]>({
        url: HSL.createProductParts,
        method: 'POST',
        onError: t('notification: critical.Create')
    });

    useEffect(() => {
        if (processedFile?.data) {
            setData(processedFile?.data);
        }
    }, [processedFile]);

    const onAdd = () => {
        setEditData(null);
        setIsAdding(true);
        setOpenModal(true);
    };

    const onEdit = (rowData: ICellData) => {
        const item = data[rowData.rowIndex];
        const updatedItem = {
            ...item,
            Id: rowData.rowIndex
        };
        setEditData(updatedItem);
        setOpenModal(true);
    };

    const onSaveEdit = () => {
        if (editData) {
            let updatedData;
            if (isAdding) {
                updatedData = [editData, ...data];
            } else {
                updatedData = data.map((item, index) => (index === editData.Id ? { ...item, ...editData } : item));
            }
            setData(updatedData);
            setOpenModal(false);
            setEditData(null);
            setIsAdding(false);
        }
    };

    const onDelete = (index: number) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const onSubmit = async () => {
        await createProductsParts(data, () => {});
        onSave();
    };

    const handleUploadFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async e => {
            if (e.target) {
                const base64String = (e.target.result as string).replace('data:', '').replace(/^.+,/, '');

                if (file !== undefined) {
                    try {
                        await postUploadFile({
                            fileName: file.name,
                            fileContent: base64String
                        });
                        setNotification(old => ({ ...old, type: 'success', message: t('common:translation.UploadSuccess') }));
                    } catch (e) {
                        setNotification(old => ({ ...old, type: 'critical', message: t('common:translation.UploadError') }));
                    }
                }
            }
        };
        reader.readAsDataURL(file);
    };

    const handleExportExcel = async () => {
        setLoading(true);
        await downloadTemplateExcel(
            { fileName: 'productsParts_template' },
            `productsParts_template.xlsx`,
            () => {},
            () => {}
        );

        setLoading(false);
    };

    const renderModal = () => {
        return (
            <Modal open={openModal} closeIcon={true} onClose={() => setOpenModal(false)}>
                <Modal.Header>{isAdding ? t('common:actions.Add') : t('hsl:translation.EditData')}</Modal.Header>
                <Modal.Content>
                    <div className="form3Columns">
                        <Input
                            placeholder={t('common:translation.PartNumber')}
                            label={t('common:translation.PartNumber')}
                            value={editData?.PartNumber}
                            onChange={value => setEditData({ ...editData!, PartNumber: value })}
                        />
                        <Input
                            placeholder={t('hsl:translation.PartDescription')}
                            label={t('hsl:translation.PartDescription')}
                            value={editData?.PartDescription}
                            onChange={value => setEditData({ ...editData!, PartDescription: value })}
                        />
                        <Input
                            placeholder={t('hsl:translation.FinalPrice')}
                            label={t('hsl:translation.FinalPrice')}
                            value={editData?.FinalPrice}
                            onChange={value => setEditData({ ...editData!, FinalPrice: value })}
                        />
                    </div>
                    <div className="form3Columns">
                        <Input
                            placeholder={t('hsl:translation.ListPrice')}
                            label={t('hsl:translation.ListPrice')}
                            value={editData?.ListPrice}
                            onChange={value => setEditData({ ...editData!, ListPrice: value })}
                        />
                        <Input
                            placeholder={t('common:translation.Product')}
                            label={t('common:translation.Product')}
                            value={editData?.NameProduct}
                            onChange={value => setEditData({ ...editData!, NameProduct: value })}
                        />
                        <Input
                            placeholder={t('hsl:translation.ProductDescription')}
                            label={t('hsl:translation.ProductDescription')}
                            value={editData?.ProductDescription}
                            onChange={value => setEditData({ ...editData!, ProductDescription: value })}
                        />
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button content={t('common:actions.Save')} type="primary" onClick={onSaveEdit} />
                </Modal.Footer>
            </Modal>
        );
    };

    const tableProductRender = () => {
        return data ? (
            <div style={{ marginTop: '26px' }}>
                {loadingFile ? (
                    <Loader text={t('common:actions.Loading')} />
                ) : (
                    <div className="Table-product-parts">
                        <DataTable
                            columnResizeMode="fit"
                            data={data ?? []}
                            scrollable={true}
                            scrollWidth="100%"
                            resizableColumns={true}
                        >
                            <DataTable.HeaderBar>
                                <DataTable.HeaderBar.Item
                                    content="Add"
                                    iconSize="small"
                                    icon="slidercontrols-plus"
                                    onClick={onAdd}
                                />
                            </DataTable.HeaderBar>
                            <DataTable.Column
                                field="NameProduct"
                                align="center"
                                header={t('common:translation.Product')}
                                sortable={true}
                            />
                            <DataTable.Column
                                field="ProductDescription"
                                align="center"
                                header={t('hsl:translation.ProductDescription')}
                                sortable={true}
                            />
                            <DataTable.Column
                                field="PartNumber"
                                align="center"
                                header={t('common:translation.PartNumber')}
                                sortable={true}
                            />
                            <DataTable.Column
                                field="PartDescription"
                                align="center"
                                header={t('hsl:translation.PartDescription')}
                                sortable={true}
                            />
                            <DataTable.Column
                                field="ListPrice"
                                align="center"
                                header={t('hsl:translation.ListPrice')}
                                sortable={true}
                                renderer={e => <p>US$ {e.value}</p>}
                            />
                            <DataTable.Column
                                field="FinalPrice"
                                align="center"
                                header={t('hsl:translation.FinalPrice')}
                                sortable={true}
                                renderer={e => <p>US$ {e.value}</p>}
                            />

                            <DataTable.Column
                                field="opcoes"
                                renderer={e => (
                                    <Popup
                                        className="popup-theme-wrap"
                                        element={<Icon size="large" root="common" name="ellipsis-vertical" />}
                                        on="click"
                                        align="center"
                                    >
                                        <VerticalMenu>
                                            <VerticalMenu.Header>{t('common:tabs.Options')}</VerticalMenu.Header>
                                            <VerticalMenu.Item onClick={() => onDelete(e.rowIndex)}>
                                                <div className="Menu-vertical">
                                                    <Icon size="small" root="common" name="delete" className="icon-event" />
                                                    {t('common:actions.Delete')}
                                                </div>
                                            </VerticalMenu.Item>
                                            <VerticalMenu.Item onClick={() => onEdit(e)}>
                                                <div className="Menu-vertical">
                                                    <Icon size="small" root="common" name="edit" className="icon-event" />
                                                    {t('common:translation.Edit')}
                                                </div>
                                            </VerticalMenu.Item>
                                        </VerticalMenu>
                                    </Popup>
                                )}
                                header={t('common:tabs.Options')}
                            />
                            <DataTable.Pagination totalItems={data.length} itemsPerPage={10} />
                        </DataTable>
                    </div>
                )}
                <div className="button-hsl">
                    <Button
                        content={t('common:actions.Save')}
                        type="primary"
                        size="medium"
                        iconPosition="right"
                        loading={loadingCreate}
                        onClick={onSubmit}
                        description={t('common:actions.Save')}
                        disabled={data.length === 0}
                    />
                    <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
                </div>
            </div>
        ) : null;
    };

    return (
        <div>
            <div className="button-hsl">
                <Button
                    content={t('hsl:translation.DownloadTemplate')}
                    onClick={() => {
                        handleExportExcel();
                    }}
                    type="secondary"
                    size="medium"
                    loading={loading}
                />
            </div>
            <FileDrop
                acceptedTypes={['.xlsx']}
                resetButtonText={t('common:actions.Clean')}
                multiple={false}
                buttonText={t('common:actions.BrowseFiles')}
                placeholder={t('common:actions.Draganddropfileshere')}
                onAccepted={files => handleUploadFile(files[0])}
            />
            {tableProductRender()}
            {renderModal()}
        </div>
    );
};

export default AddProductByUpload;
