import { Badge, Button, Header, Icon, Input, Loader, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { RoleComponent } from 'components/roleComponent';
import { formatDate } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import { MaintenanceFilter } from 'models/maintenanceReport/allMaintenances';
import { modalitys, statusReport } from 'models/maintenanceReport/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { MAINTENANCES_REPORTS, cnpjMask } from 'resources';

function MaintenanceReportSearch() {
    const navigate = useNavigate();
    const { t } = useTranslation(['common', 'maintenancereport',]);
    const initialFilter = {
        Id: undefined,
        CompanyName: '',
        CompanyCnpj: '',
        SerialNumber: '',
        ApprovalStatusId: 0,
        ModalityId: 0,
        ItemByPage: 10,
        CurrentPage: 0
    };

    const [maintenanceFilter, setMaintenanceFilter] = useState<MaintenanceFilter>(initialFilter);
    const [filteredPartners, setFilteredPartners] = useState<string[]>([]);

    const [maintenanceReports, getMaintenances, loadingRmas] = useFetch<MaintenanceFilter>({
        url: MAINTENANCES_REPORTS.getAllMaintenances,
        method: 'POST',
        redirectOnError: true
    });

    const [partners, getPartner] = useFetch({
        url: MAINTENANCES_REPORTS.GetAllPartners,
        method: 'GET',
        redirectOnError: true
    });

    useEffect(() => {
        getAllMaintenances(maintenanceFilter);
        getAllPartner();
    }, []);

    const getAllMaintenances = async (filter: MaintenanceFilter) => {
        await getMaintenances(
            {
                ...filter,
                CompanyName: filter.CompanyName.toUpperCase(),
                CompanyCnpj: filter.CompanyCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
            },
            current => {
                setMaintenanceFilter(old => ({
                    ...old
                }));
            }
        );
    };

    const getAllPartner = async () => {
        await getPartner(null, current => {
            setFilteredPartners(current.data.partner);
        });
    };

    const resetFilter = () => {
        setMaintenanceFilter(initialFilter);
        getAllMaintenances(initialFilter);
        getAllPartner();
    };

    const handlePageChange = (page: number | string) => {
        const filterWithCurrentPage = {
            ...maintenanceFilter,
            CurrentPage: parseInt(page.toString()) - 1
        };
        setMaintenanceFilter(filterWithCurrentPage);
        getAllMaintenances(filterWithCurrentPage);
    };

    const statusRenderer = (status: number) => {
        const statusMap: any = {
            1: { color: 'yellow', content: t('common:status.WaitingApproval') },
            2: { color: 'red', content: t('common:status.Disapproved') },
            3: { color: 'green', content: t('common:status.Approved') },
            4: { color: 'yellow', content: t('common:status.PartiallyApproved') },
            5: { color: 'grey', content: t('common:status.Finished') }

        };

        const defaultStatus = { color: 'gray', content: t('common:status.NotInformed') };
        const currentStatus = statusMap[status] || defaultStatus;

        return <Badge color={currentStatus.color} content={currentStatus.content} />;
    };

    const handlePartnerInputChange = (value: string) => {
        const filtered = partners?.data.partner.filter((partner: string) => partner.toLowerCase().includes(value.toLowerCase()));
        setFilteredPartners(filtered);
        setMaintenanceFilter({ ...maintenanceFilter, CompanyName: value });
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <Header title={t('maintenancereport:page_name')} logo={false} menu={false}>
                    <div style={{ margin: '10px' }}>
                        <Button
                            content={t('maintenancereport:translation.CreateReportMaintenance')}
                            type="primary"
                            size="medium"
                            iconPosition="right"
                            icon={<Icon name="add" root="building" />}
                            onClick={() => navigate('new-report')}
                            description={t('maintenancereport:translation.CreateReportMaintenance')}
                        />
                    </div>
                </Header>
                <div className="form3Columns">
                    <Input
                        placeholder={t('maintenancereport:translation.RegistrationNumber')}
                        label={t('maintenancereport:translation.RegistrationNumber')}
                        type="number"
                        onChange={value => setMaintenanceFilter({ ...maintenanceFilter, Id: parseInt(value) })}
                        value={maintenanceFilter?.Id?.toString()}
                    />
                    {
                        <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                            <Select
                                placeholder={maintenanceFilter.CompanyName || t('common:translation.Partner')}
                                label={t('common:translation.Partner')}
                                value={maintenanceFilter.CompanyName}
                                options={filteredPartners.map(partner => ({ value: partner, text: partner }))}
                                onSearch={handlePartnerInputChange}
                                onChange={value => setMaintenanceFilter({ ...maintenanceFilter, CompanyName: value })}
                                search
                            />
                            <Input
                                placeholder="CNPJ"
                                label="CNPJ"
                                onChange={value => setMaintenanceFilter({ ...maintenanceFilter, CompanyCnpj: value })}
                                value={cnpjMask(maintenanceFilter?.CompanyCnpj)}
                            />
                        </RoleComponent>
                    }
                </div>
                <div className="form2Columns">
                    <Select
                        className="SelectStyle"
                        label={t('common:translation.Status')}
                        placeholder={t('common:translation.Status')}
                        options={statusReport}
                        onChange={value => setMaintenanceFilter({ ...maintenanceFilter, ApprovalStatusId: value })}
                        defaultValue={maintenanceFilter.ApprovalStatusId! || ''}
                    />
                    <Select
                        className="SelectStyle"
                        label={t('common:translation.Type')}
                        placeholder={t('common:translation.Type')}
                        options={modalitys}
                        onChange={value => setMaintenanceFilter({ ...maintenanceFilter, ModalityId: value })}
                        defaultValue={maintenanceFilter.ModalityId || ''}
                    />
                    <Input
                        placeholder={t('common:translation.SerialNumber')}
                        label={t('common:translation.SerialNumber')}
                        type="number"
                        onChange={value => setMaintenanceFilter({ ...maintenanceFilter, SerialNumber: value })}
                        value={maintenanceFilter.SerialNumber}
                    />
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={() => resetFilter()}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={() => getAllMaintenances(maintenanceFilter)}
                    />
                </div>
            </div>
        );
    };

    const tableReportsRender = () => {
        return (
            <div style={{ marginTop: '26px' }}>
                <DataTable
                    columnResizeMode="fit"
                    data={maintenanceReports?.data.reports}
                    onCellClick={e => {
                        navigate('report', { state: { actualReport: e?.rowData } });
                    }}
                >
                    <DataTable.Column field="Id" header={t('ID')} />
                    <DataTable.Column
                        field="CreatedAt"
                        header={t('maintenancereport:translation.DateOfRegistration')}
                        renderer={e => <>{formatDate(e?.rowData.CreatedAt)}</>}
                    />
                    <DataTable.Column field="CompanyName" header={t('common:translation.Partner')} />
                    <DataTable.Column field="SerialNumber" header={t('common:translation.SerialNumber')} />
                    <DataTable.Column field="ProductName" header={t('common:translation.Product')} />
                    <DataTable.Column
                        field="ApprovalStatusId"
                        header={t('common:translation.Status')}
                        renderer={e => statusRenderer(e.value)}
                    />
                    <DataTable.Pagination
                        totalItems={maintenanceReports?.data.pagination.totalItems}
                        itemsPerPage={maintenanceReports?.data.pagination.itemsPerPage}
                        activePage={maintenanceFilter.CurrentPage + 1}
                        onPageChange={(page: string | number) => handlePageChange(page)}
                    />
                </DataTable>
            </div>
        );
    };

    return (
        <>
            {loadingRmas && <Loader text={t('common:actions.Loading')} />}
            {!loadingRmas && (
                <>
                    {searchFieldsRender()}
                    {maintenanceReports && tableReportsRender()}
                </>
            )}
        </>
    );
}

export default MaintenanceReportSearch;
