import { useEffect, useState } from 'react';
import { Button, Header, Icon, Input, Select, DatePicker, Checkbox } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { RoleComponent } from 'components/roleComponent';
import { adjustDatesToFrom, formatDate } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import { HSL, COMPANY_MANAGER, notificationAtom } from 'resources';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DeliveryData, FilterDeliveryDTO, GroupedDelivery, GuiaData, SelectOption } from 'models/hsl/general';
import { hslCountriesAtom, hslPartnersAtom, hslPartsAtom } from 'resources/atoms/hslAtom';
import { CompanyDTO } from 'models/userManager/addCustomer';

const initialFilter: FilterDeliveryDTO = {
    IdSap: '',
    CompanyName: [],
    Country: [],
    Delivery: '',
    BillOfLading: '',
    Carrier: [],
    PartNumber: [],
    DateStart: '',
    DateEnd: '',
    PageIndex: 1,
    PageSize: 10
};

function DeliverySearch() {
    const navigate = useNavigate();
    const [partners, setPartners] = useRecoilState(hslPartnersAtom);
    const [countries, setCountries] = useRecoilState(hslCountriesAtom);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const { user: userDetails } = useUser();
    const { t } = useTranslation(['common', 'hsl', 'user', 'home']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [filters, setFilters] = useState<FilterDeliveryDTO>(initialFilter);
    const [filteredData, setFilteredData] = useState<GroupedDelivery[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [carrierOptions, setCarrierOptions] = useState<SelectOption[]>([]);
    const [partsOptions, setPartsOptions] = useState<SelectOption[]>([]);

    const [, getDeliveries, loadingDeliveries] = useFetch<FilterDeliveryDTO, any>({
        url: HSL.getDeliveries,
        method: 'POST'
    });

    const [, updateStatusGuia] = useFetch<GuiaData[], any>({
        url: HSL.updateBillOfLadings,
        method: 'POST'
    });

    const [, getCarriers] = useFetch({
        url: HSL.getCarriers,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getPartners] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getFilteredDeliveries(filters);
        fetchData(getCarriers, setCarrierOptions);
        if (!partners.length) {
            getAllPartners();
        }
        if (!parts.length) {
            getAllParts();
        }
    }, []);

    useEffect(() => {
        const partsOptions = parts.map(part => ({
            value: part.PartNumber,
            text: part.PartNumber
        }));
        setPartsOptions(partsOptions);
    }, [parts]);

    const fetchData = async (fetchFunction: any, setOptions: any) => {
        try {
            await fetchFunction(null, (current: any) => {
                if (current?.data) {
                    const options = current?.data?.map((item: any) => ({
                        value: item.PartNumber || item,
                        text: item.PartNumber || item
                    }));
                    setOptions(options);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
                }
            });
        } catch (error) {
            setNotification(old => ({ ...old, type: 'critical', message: t('common:errors.Unexpected') }));
        }
    };

    const getFilteredDeliveries = (filter: FilterDeliveryDTO) => {
        getDeliveries(filter, resp => {
            if (resp.data.Data) {
                setFilteredData(groupByDeliveryAndIdSap(resp.data.Data));
                setTotalItems(resp.data.TotalCount);
            }
        });
    };

    const getAllPartners = () => {
        getPartners(null, resp => {
            if (resp.data) {
                const partnersData = resp.data.map((partner: CompanyDTO) => ({
                    value: partner.name,
                    text: partner.name
                }));
                setPartners(partnersData);

                const uniqueCountries = Array.from(new Set(resp.data.map((partner: CompanyDTO) => String(partner.country))));
                const countriesData = uniqueCountries.map(country => ({
                    value: country as string,
                    text: country as string
                }));
                setCountries(countriesData);
            }
        });
    };

    const getAllParts = () => {
        getParts(null, (current: any) => {
            if (current.data.length) {
                setParts(current.data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    const applyFilters = () => {
        getFilteredDeliveries(filters);
    };

    const resetFilters = () => {
        setFilters(initialFilter);
        getFilteredDeliveries(initialFilter);
    };

    const handleChangeStatusGuia = (cellData: DeliveryData, value: boolean) => {
        const guiaData: GuiaData[] = [
            {
                Id: cellData.Id,
                IdSap: cellData.IdSap,
                GUIA: cellData.Billoflading,
                State: value ? 'OK' : 'ERRO',
                ErrorNotExist: true
            }
        ];
        updateStatusGuia(guiaData);
    };

    const groupByDeliveryAndIdSap = (data: DeliveryData[]): GroupedDelivery[] => {
        const grouped: Record<string, GroupedDelivery> = {};
        data?.forEach(item => {
            const key = `${item.Delivery}-${item.IdSap}`;
            if (!grouped[key]) {
                grouped[key] = {
                    Id: item.Id,
                    Delivery: item.Delivery,
                    IdSap: item.IdSap,
                    CompanyName: item.CompanyName,
                    Country: item.Country,
                    Carrier: item.Carrier,
                    Billoflading: item.Billoflading,
                    BillofladingApprovedDate: item.BillofladingApprovedDate,
                    RegisterDate: item.RegisterDate,
                    Items: []
                };
            }
            grouped[key].Items.push({
                Id: item.Id,
                PartNumber: item.PartNumber,
                PartNumberDescription: item.PartNumberDescription,
                Quantity: item.Quantity,
                PartnerReceived: item.PartnerReceived,
                PartnerReceivedDate: item.PartnerReceivedDate
            });
        });

        return Object.values(grouped);
    };

    const headerRender = () => {
        return (
            <Header title={t('hsl:translation.NewInventory')} logo={false} menu={false}>
                <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                    <div style={{ margin: '10px' }}>
                        <Button
                            content={t('hsl:translation.AddDelivery')}
                            type="primary"
                            size="medium"
                            iconPosition="right"
                            icon={<Icon name="file-upload" root="building" />}
                            onClick={() => navigate('new-delivery')}
                            description={t('hsl:translation.AddDelivery')}
                        />
                        <Button
                            content={t('hsl:translation.AddGuia')}
                            type="primary"
                            size="medium"
                            iconPosition="right"
                            icon={<Icon name="file-upload" root="building" />}
                            onClick={() => navigate('new-guia')}
                            description={t('hsl:translation.AddGuia')}
                        />
                    </div>
                </RoleComponent>
            </Header>
        );
    };

    const searchFieldsRender = () => {
        return (
            <div>
                <div className="form4Columns">
                    <Input
                        placeholder={t('hsl:translation.Delivery')}
                        label={t('hsl:translation.Delivery')}
                        type="text"
                        name="Delivery"
                        value={filters.Delivery}
                        onChange={value => setFilters({ ...filters, Delivery: value })}
                    />
                    <Input
                        placeholder={t('hsl:translation.BillofLading')}
                        label={t('hsl:translation.BillofLading')}
                        type="text"
                        name="Guia"
                        value={filters.BillOfLading}
                        onChange={value => setFilters({ ...filters, BillOfLading: value })}
                    />
                    <Select
                        placeholder={t('hsl:translation.Carrier')}
                        label={t('hsl:translation.Carrier')}
                        options={carrierOptions}
                        search={true}
                        onChange={value => setFilters({ ...filters, Carrier: value })}
                    />
                    <Select
                        placeholder={t('common:translation.PartNumber')}
                        label={t('common:translation.PartNumber')}
                        options={partsOptions}
                        search={true}
                        multiple={true}
                        onChange={value => setFilters({ ...filters, PartNumber: value })}
                    />
                </div>
                <div className="form4Columns">
                    <DatePicker
                        type="daterange"
                        data-id="unique-datepicker"
                        label={t('hsl:translation.RegistrationDate')}
                        rangeValue={{ to: filters?.DateEnd, from: filters?.DateStart }}
                        displayFormat={'DD-MM-YYYY'}
                        closeOnSelection={true}
                        closeOnDocumentClick={true}
                        onRangeSelect={({ to, from }) => {
                            const { DateStart, DateEnd } = adjustDatesToFrom(from, to);
                            setFilters({ ...filters, DateStart: DateStart, DateEnd: DateEnd });
                        }}
                    />
                    <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                        <Select
                            label={t('user:address.Country')}
                            placeholder={t('user:address.Country')}
                            options={countries}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, Country: value })}
                        />
                        <Select
                            label={t('common:translation.Partner')}
                            placeholder={t('common:translation.Partner')}
                            options={partners}
                            multiple={true}
                            search={true}
                            onChange={value => setFilters({ ...filters, CompanyName: value })}
                        />
                        <Input
                            placeholder={t('hsl:translation.IdSap')}
                            label={t('hsl:translation.IdSap')}
                            type="text"
                            name="IdSap"
                            value={filters.IdSap}
                            onChange={value => setFilters({ ...filters, IdSap: value })}
                        />
                    </RoleComponent>
                </div>
                <div className="buttonsSearch">
                    <Button
                        type="secondary"
                        icon="badge-delete"
                        content={t('common:actions.ResetFilters')}
                        iconPosition="right"
                        onClick={resetFilters}
                    />
                    <Button
                        type="primary"
                        icon="filter1"
                        content={t('common:actions.ApplyFilters')}
                        iconPosition="right"
                        onClick={applyFilters}
                    />
                </div>
            </div>
        );
    };

    const tableDeliveryRender = () => {
        return (
            <div className="Table-delivery">
                <DataTable columnResizeMode="fit" data={filteredData} loading={loadingDeliveries}>
                    <DataTable.Column field="Delivery" align="center" header={t('Delivery')} sortable={true} />
                    <DataTable.Column
                        field="RegisterDate"
                        align="center"
                        header={t('hsl:translation.RegistrationDate')}
                        sortable={true}
                        renderer={e => <>{formatDate(e?.rowData.RegisterDate)}</>}
                    />
                    <DataTable.Column field="IdSap" align="center" header={t('hsl:translation.IdSap')} sortable={true} />

                    <DataTable.Column
                        field="CompanyName"
                        align="center"
                        header={t('common:translation.Partner')}
                        sortable={true}
                    />
                    <DataTable.Column field="Carrier" align="center" header={t('hsl:translation.Carrier')} sortable={true} />
                    <DataTable.Column
                        field="Billoflading"
                        align="center"
                        header={t('hsl:translation.BillofLading')}
                        sortable={true}
                    />
                    {(userDetails?.ROLE == '1' || userDetails?.ROLE == '2') && (
                        <DataTable.Column
                            field="BillofladingApprovedDate"
                            align="center"
                            header={t('hsl:translation.StatusBill')}
                            renderer={cellData => (
                                <div>
                                    <Checkbox
                                        toggle={true}
                                        defaultChecked={cellData.value ? true : false}
                                        disabled={cellData.value ? true : false}
                                        onChange={(value: boolean) => handleChangeStatusGuia(cellData.rowData, value)}
                                    />
                                </div>
                            )}
                        />
                    )}
                    <DataTable.Column
                        field="details"
                        renderer={e => (
                            <div onClick={() => navigate('delivery-details', { state: { selectedDelivery: e?.rowData } })}>
                                <Icon size="small" root="common" name="search" className="icon-event" />
                            </div>
                        )}
                        align="center"
                        header={t('common:translation.Details')}
                    />
                    <DataTable.Pagination
                        totalItems={totalItems}
                        itemsPerPage={5}
                        onPageChange={pageNumber => {
                            const filterPage: FilterDeliveryDTO = { ...filters, PageIndex: parseInt(pageNumber.toString()) };
                            getFilteredDeliveries(filterPage);
                        }}
                    />
                </DataTable>
            </div>
        );
    };

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className="container">
            {headerRender()}
            {searchFieldsRender()}
            {tableDeliveryRender()}
            <div className="button-hsl">
                <Button
                    type="secondary"
                    icon="arrow-left"
                    content={t('common:actions.Back')}
                    onClick={handleBack}
                    className="back-button"
                />
            </div>
        </div>
    );
}

export default DeliverySearch;
