import { useState, FC } from 'react';
import { Button, Select, DatePicker } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { hslPartnersAtom } from 'resources/atoms/hslAtom';
import { notificationAtom } from 'resources';
import { ReportContractData, SelectOption } from 'models/hsl/general';
import '../../../styles/hsl.scss';


interface ContractReportProps {
    onBack: () => void;
}

const ContractReport: FC<ContractReportProps> = ({ onBack }) => {
    const { t } = useTranslation(['common', 'hsl', 'user', 'home', 'notification']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [error, setError] = useState<string>('');
    const [reportData, setReportData] = useState<ReportContractData>({
        Partner: '',
        Period: {
            to: '', from: ''
        },
        Type: ''
    });
    const partners = useRecoilValue(hslPartnersAtom);

    const contractType: SelectOption[] = [
        { value: 'Contract', text: t('common:translation.Contract') },
        { value: 'Warranty', text: t('common:services.Warranty') },
    ]

    const handleSelectChange = (field: keyof ReportContractData, value: string) => {
        setReportData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleDateChange = (value: any) => {
        setReportData(prevState => ({
            ...prevState,
            Period: value,
        }));
    };

    const onGenerate = () => {
        setNotification(old => ({ ...old, type: 'success', message: t('notification:success.Report') }));
    };

    const handlePartnerChange = (selectedPartnerName: string) => {
        const selectedPartner = partners.find(p => p.value === selectedPartnerName);
        if (selectedPartner) {
            setReportData({
                ...reportData,
                Partner: selectedPartner.value,
            });
        }
    };

    return (
        <div>
            <div className='form3Columns'>
                <Select
                    indicator="required"
                    className="SelectStyle"
                    value={reportData.Type}
                    label={t('common:translation.Type')}
                    placeholder={t("common:translation.Type")}
                    options={contractType}
                    onChange={value => handleSelectChange('Type', value)}
                />
                <DatePicker
                    type="daterange"
                    label={t('common:translation.Period')}
                    value={reportData.Period.to}
                    error={error}
                    displayFormat={'DD/MM/YYYY'}
                    closeOnDocumentClick={true}
                    onTextChange={value => setError(value)}
                    onRangeSelect={handleDateChange}
                />
                <Select
                    indicator='required'
                    className='select-type'
                    placeholder={t('common:translation.Partner')}
                    label={t('common:translation.Partner')}
                    options={partners}
                    search={true}
                    value={reportData.Partner}
                    onChange={value => handlePartnerChange(value)}
                />
            </div>
            <div className="buttonsSearch">
                <Button
                    type="secondary"
                    icon="badge-delete"
                    content={t('common:actions.ResetFilters')}
                    iconPosition="right"
                    onClick={() => setReportData({
                        Partner: '', Period: {
                            to: '', from: ''
                        }, Type: ''
                    })}
                />
                <Button
                    type="primary"
                    icon="report"
                    content={t('common:actions.Generate')}
                    iconPosition="right"
                    onClick={onGenerate}
                />
                <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className='button-back' />
            </div>
        </div>
    );
}

export default ContractReport;
