import { Icon, Loader, Table } from '@scuf/common';
import { MicroSigaInvcoiceDTO, MicroSigaBillingDTO, OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import useFetch from 'helpers/hooks/useFetch';
import { SPARE_PARTS } from 'resources';
import Alert from 'components/alert';
import { formatDate } from 'helpers';

const SparePartsInvoicing: React.FC = () => {
    const { t } = useTranslation(['spareparts', 'common']);
    const [orderData, setorderData] = useRecoilState<OrderDTO>(orderAtom);
    const [sparePartsList, getStatusOrders, loading] = useFetch<{ salesOrderNumber: string }, MicroSigaBillingDTO>({
        url: SPARE_PARTS.getStatusOrders
    });

    useEffect(() => {
        getStatusOrders({ salesOrderNumber: orderData?.numberPV });
    }, []);

    return (
        <div className="container">
            {loading ? <Loader text={t('common:actions.Loading')} /> : <div />}
            <>
                <div className="contentPageContainer">
                    <div className="wrap-content">
                        {sparePartsList?.data && sparePartsList?.data.invoices.length > 0 ? (
                            [
                                <div>
                                    <h1>{t('translation.Billing') + ' #' + sparePartsList?.data?.salesOrderNumber}</h1>
                                    <br />
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell content={t('common:translation.Date')} />
                                            <Table.HeaderCell content={t('common:translation.CurrierName')} />
                                            <Table.HeaderCell content={t('repaircenter:rma.budget.EntryInvoice')} />
                                        </Table.Header>
                                        <Table.Body>
                                            {sparePartsList
                                                ? [
                                                    sparePartsList?.data?.invoices?.map((item: MicroSigaInvcoiceDTO) => (
                                                        <Table.Row>
                                                            <Table.Cell>{formatDate(item?.issued)} </Table.Cell>
                                                            <Table.Cell>{item?.currierName} </Table.Cell>
                                                            <Table.Cell>{item?.invoiceNumber} </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                ]
                                                : '--'}
                                        </Table.Body>
                                    </Table>
                                    <br />
                                </div>
                            ]
                        ) : (
                            <Alert text={t('common:empty.NoBillingData')} />
                        )}
                    </div>
                </div>
            </>
        </div>
    );
};

export default SparePartsInvoicing;
