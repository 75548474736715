const apiURL = process.env.REACT_APP_API;

export class INITIAL_PAGE {
    static readonly initialPageUrl = `${apiURL}/InitialPage`;
    static readonly searchAssistance: string = `${this.initialPageUrl}/searchAssistance`;
    static readonly getModelsByUser: string = `${this.initialPageUrl}/GetModelsByUser`;
}

export class REPAIR_CENTER {
    static readonly repairCenterApi = process.env.REACT_APP_REPAIR_CENTER_API;
    static readonly repairCenterUrl = `${this.repairCenterApi}/Rma`;
    static readonly Microssiga = `${this.repairCenterApi}/Microssiga`;
    static readonly EosUrl = `${this.repairCenterApi}/Eos`;
    static readonly SatisfactionSurvey = `${this.repairCenterApi}/SatisfactionSurvey`;
    static readonly getRmas: string = `${this.repairCenterUrl}/GetRmas`;
    static readonly getRmasLite: string = `${this.repairCenterUrl}/GetRmasLite`;
    static readonly getRmasCA: string = `${this.repairCenterUrl}/GetRmasCA`;
    static readonly getCustomers: string = `${this.repairCenterUrl}/GetRmasCA`;
    static readonly getPartNumberModels: string = `${this.Microssiga}/GetPartNumberModels`;
    static readonly addItemInCartCA: string = `${this.repairCenterUrl}/AddItemInCartCA`;
    static readonly removeItemsFromCartCA: string = `${this.repairCenterUrl}/RemoveItemsFromCartCA`;
    static readonly getEquipmentProblemsCA: string = `${this.repairCenterUrl}/GetEquipmentProblemsCA`;
    static readonly getItemsInCartCA: string = `${this.repairCenterUrl}/GetItemsInCartCA`;
    static readonly removeItemsFromCart: string = `${this.repairCenterUrl}/RemoveItemsFromCart`;
    static readonly getMessages: string = `${this.repairCenterUrl}/GetAllRmaMessages`;
    static readonly getAllChats: string = `${this.repairCenterUrl}/GetAllChats`;
    static readonly consultSerialNumber: string = `${this.Microssiga}/ConsultSerialNumber`;
    static readonly getEquipmentProblems: string = `${this.repairCenterUrl}/GetEquipmentProblems`;
    static readonly getRmaDetails: string = `${this.repairCenterUrl}/GetRmaDetails`;
    static readonly chatAddMessage: string = `${this.repairCenterUrl}/ChatAddMessage`;
    static readonly addNote: string = `${this.repairCenterUrl}/AddNote`;
    static readonly chatMessageRead: string = `${this.repairCenterUrl}/ChatMessageRead`;
    static readonly requestDelete: string = `${this.repairCenterUrl}/RequestDelete`;
    static readonly requestCreate: string = `${this.repairCenterUrl}/RequestCreate`;
    static readonly uploadFile: string = `${this.repairCenterUrl}/UploadFile`;
    static readonly uploadRmaFile: string = `${this.repairCenterUrl}/UploadRMAFile`;
    static readonly downloadFile: string = `${this.repairCenterUrl}/DownloadFile`;
    static readonly removeFile: string = `${this.repairCenterUrl}/RemoveFile`;
    static readonly addItemInCart: string = `${this.repairCenterUrl}/AddItemInCart`;
    static readonly getItemsInCart: string = `${this.repairCenterUrl}/GetItemsInCart`;
    static readonly getServiceTypes: string = `${this.repairCenterUrl}/GetServiceTypes`;
    static readonly getProblemLocationsAndTypes: string = `${this.repairCenterUrl}/GetProblemLocationsAndTypes`;
    static readonly createRma: string = `${this.repairCenterUrl}/CreateRma`;
    static readonly changeStatusRmaByAdmin: string = `${this.repairCenterUrl}/UpdateStatus`;
    static readonly deleteRma: string = `${this.repairCenterUrl}/DeleteRma`;
    static readonly deactivateRma: string = `${this.repairCenterUrl}/DeactivateRma`;
    static readonly updatePackage: string = `${this.repairCenterUrl}/UpdatePackage`;
    static readonly alterAddressInRma: string = `${this.repairCenterUrl}/UpdateAddress`;
    static readonly removeEquipmentByAdmin: string = `${this.repairCenterUrl}/RemoveEquipmentByAdmin`;
    static readonly alterItemsInRma: string = `${this.repairCenterUrl}/UpdateItems`;
    static readonly insertItemsInRma: string = `${this.repairCenterUrl}/InsertItemInRma`;
    static readonly deleteItemsInRma: string = `${this.repairCenterUrl}/DeleteItems`;
    static readonly alterRmaModality: string = `${this.repairCenterUrl}/UpdateType`;
    static readonly updateInvoice: string = `${this.repairCenterUrl}/UpdateInvoice`;
    static readonly updateRma: string = `${this.repairCenterUrl}/UpdateRma`;
    static readonly getOsDetails: string = `${this.Microssiga}/GetOsDetails`;
    static readonly approveOs: string = `${this.repairCenterUrl}/ApproveOs`;
    static readonly resendEmail: string = `${this.repairCenterUrl}/ResendEmail`;
    static readonly reproveOs: string = `${this.repairCenterUrl}/ReproveOs`;
    static readonly searchClientsResume: string = `${this.repairCenterUrl}/SearchClientsResume`;
    static readonly getUserInfo: string = `${this.repairCenterUrl}/GetUserInfo`;
    static readonly addUserLinked: string = `${this.repairCenterUrl}/AddUserLinked`;
    static readonly removeUserLinked: string = `${this.repairCenterUrl}/RemoveUserLinked`;
    static readonly getBudgetsPDF: string = `${this.Microssiga}/GetBudgetPDF`;
    static readonly getReport: string = `${this.repairCenterUrl}/GetReport`;
    static readonly getSatisfactionSurveyReport: string = `${this.repairCenterApi}/SatisfactionSurvey/GetReport`;
    static readonly getSatisfactionSurveyList: string = `${this.repairCenterUrl}/GetSatisfactionSurveyList`;
    static readonly createSatisfactionSurvey: string = `${this.SatisfactionSurvey}/CreateSatisfactionSurvey`;
    static readonly updateSatisfactionSurvey: string = `${this.SatisfactionSurvey}/UpdateSatisfactionSurvey`;
    static readonly getSatisfactionSurvey: string = `${this.SatisfactionSurvey}/GetSatisfactionSurvey`;
    static readonly addSatisfactionSurvey: string = `${this.repairCenterUrl}/AddSatisfactionSurvey`;
    static readonly getModelsEOS: string = `${this.EosUrl}/GetModelsEOS`;
    static readonly createEOS: string = `${this.EosUrl}/CreateEOS`;
    static readonly addModelEos: string = `${this.EosUrl}/AddModelEos`;
    static readonly deleteEOS: string = `${this.EosUrl}/DeleteModelEOS`;
    static readonly editEOS: string = `${this.EosUrl}/UpdateEOS`;
    static readonly alterAddressInEOS: string = `${this.EosUrl}/AlterAddressInEOS`;
    static readonly updateStatusOS: string = `${this.Microssiga}/UpdateStatusOS`;
    static readonly uploadModelEOS: string = `${this.EosUrl}/UploadModelEOS`;
    static readonly UploadEOS: string = `${this.EosUrl}/UploadEOS`;
    static readonly generatePdfFromRma: string = `${this.repairCenterUrl}/GeneratePdfFromRma`;
    static readonly getTechnicalReportHTML: string = `${this.repairCenterUrl}/GetTechnicalReportHTML`;
    static readonly processRma: string = `${this.repairCenterUrl}/processRma`;
    static readonly RemovePN: string;
    static readonly getPendentOS: string = `${this.repairCenterUrl}/GetPendentOS`;
    static readonly postUploadFile: string = `${this.EosUrl}/ProcessEOSFile`;
}

export class REPAIR_CENTER_CA {
    static readonly repairCenterUrl = `${apiURL}/RepairCenter`;
    static readonly getRmasCA: string = `${this.repairCenterUrl}/GetRmasCA`;
    static readonly uploadStatusRma: string = `${this.repairCenterUrl}/UploadStatusRma`;
    static readonly uploadListOfStatusEquipmentCA: string = `${this.repairCenterUrl}/UploadListOfStatusEquipmentCA`;
    static readonly uploadCustomerCA: string = `${this.repairCenterUrl}/UploadCustomerCA`;
    static readonly getRmaDetailsCA: string = `${this.repairCenterUrl}/GetRmaDetailsCA`;
    static readonly addItemInCartCA: string = `${this.repairCenterUrl}/AddItemInCartCA`;
    static readonly createRmaCA: string = `${this.repairCenterUrl}/CreateRmaCA`;
    static readonly uploadStatusEquipmentCA: string = `${this.repairCenterUrl}/UploadStatusEquipmentCA`;
}

export class SPARE_PARTS {
    static readonly sparePartsApi = process.env.REACT_APP_SPARE_PARTS_API;
    static readonly orders = `${this.sparePartsApi}/Order`;
    static readonly spareParts = `${this.sparePartsApi}/SpareParts`;
    static readonly item = `${this.sparePartsApi}/Item`;
    static readonly file = `${this.sparePartsApi}/File`;
    static readonly chat = `${this.sparePartsApi}/Chat`;
    static readonly microsiga = `${this.sparePartsApi}/Microssiga`;
    static readonly assistance = `${this.sparePartsApi}/Assistance`;
    static readonly getOrders: string = `${this.orders}/GetOrders`;
    static readonly getAllMessages = `${this.chat}/GetAllMessages`;
    static readonly chatAddMessage = `${this.chat}/ChatAddMessage`;
    static readonly getAllChats = `${this.chat}/GetAllChats`;
    static readonly getLatestEvents: string = `${this.spareParts}/GetLatestEvents`;
    static readonly getAmountOrdersOpenAdmin: string = `${this.spareParts}/getAmountOrdersOpenAdmin`;
    static readonly getChatWithMessagesNotReadOrLatest: string = `${this.spareParts}/getChatWithMessagesNotReadOrLatest`;
    static readonly getamountOrdersWarrantyAdmin: string = `${this.spareParts}/getamountOrdersWarrantyAdmin`;
    static readonly getamountOrdersCotationAdmin: string = `${this.spareParts}/getamountOrdersCotationAdmin`;
    static readonly getamountOrdersPreAprovedAdmin: string = `${this.spareParts}/getamountOrdersPreAprovedAdmin`;
    static readonly setChatMessagesAsRead: string = `${this.spareParts}/SetChatMessagesAsRead`;
    static readonly getAllPartNumbers: string = `${this.microsiga}/GetAllPartNumbers`;
    static readonly getAllPartNumbersByLOB: string = `${this.microsiga}/GetAllPartNumbersByLOB`;
    static readonly getAllPartNumbersWithoutAllowedPortal: string = `${this.microsiga}/GetAllPartNumbersWithoutAllowedPortal`;
    static readonly getOptions: string = `${this.microsiga}/GetOptions`;
    static readonly GetProductsOptionsByLOB: string = `${this.microsiga}/GetProductsOptionsByLOB`;
    static readonly searchPartNumbers: string = `${this.microsiga}/SearchPartNumbers`;
    static readonly searchPartNumbersByLOB: string = `${this.microsiga}/SearchPartNumbersByLOB`;
    static readonly searchPartNumbersADM: string = `${this.spareParts}/SearchPartNumbersADM`;
    static readonly addMessage: string = `${this.spareParts}/AddMessage`;
    static readonly getById: string = `${this.orders}/GetOrderById`;
    static readonly uploadFile: string = `${this.file}/UploadFile`;
    static readonly addBudgetInOrder: string = `${this.spareParts}/AddBudgetInOrder`;
    static readonly processOrder: string = `${this.orders}/ProcessOrder`;
    static readonly deleteFile: string = `${this.file}/DeleteFile`;
    static readonly deleteItemPN: string = `${this.spareParts}/RemovePN`;
    static readonly getAdminNotes: string = `${this.spareParts}/GetAdminNotes`;
    static readonly addAdminNote: string = `${this.spareParts}/AddAdminNote`;
    static readonly editOrder: string = `${this.orders}/EditOrder`;
    static readonly adiconaPn: string = `${this.item}/AdicionaPn`;
    static readonly removePn: string = `${this.item}/RemovePn`;
    static readonly getReport: string = `${this.orders}/GetReport`;
    static readonly getListPartnersHIS: string = `${this.spareParts}/getListPartnersHIS`;
    static readonly getPartnerHISDetails: string = `${this.spareParts}/getPartnerHISDetails`;
    static readonly getStatusOrders: string = `${this.microsiga}/GetInvoice`;
    static readonly getAlltatus: string = `${this.sparePartsApi}/Status/GetAllStatus`;
    static readonly editUser: string = `${this.spareParts}/editUser`;
    static readonly getDefaultCart: string = `${this.spareParts}//GetDefaultCart`;
    static readonly addItemInCart: string = `${this.orders}/AddItemInCart`;
    static readonly removeItemsFromCart: string = `${this.orders}/RemoveItemsFromCart`;
    static readonly createOrder: string = `${this.orders}/CreateOrder`;
    static readonly getItemsInCart: string = `${this.orders}/GetItemInCart`;
    static readonly uploadBudget: string = `${this.file}/UploadBudget`;
    static readonly downloadOrderFile: string = `${this.file}/DownloadFile`;
    static readonly deleteBudget: string = `${this.orders}/DeleteBudget`;
    static readonly getProducts: string = `${this.microsiga}/GetAllPartNumbers`;
    static readonly generateInMS: string = `${this.microsiga}/CreateOrderInMs`;
    static readonly uploadImage: string = `${this.sparePartsApi}/ImageManagement/UploadImages`;
    static readonly getLOBByPartNumber: string = `${this.microsiga}/GetLOBByPartNumber`;
    static readonly getImages: string = `${this.sparePartsApi}/ImageManagement/GetImages`;
}

export class MAINTENANCES_REPORTS {
    static readonly maintenanceURL = process.env.REACT_APP_MAINTENANCE_REPORT_API;
    static readonly getAllMaintenances: string = process.env.REACT_APP_MAINTENANCE_REPORT_GETREPORT ?? '';
    static readonly GetAllPartners: string = process.env.REACT_APP_MAINTENANCE_REPORT_GETALLPARTNERS ?? '';
    static readonly createNewMaintenance: string = process.env.REACT_APP_MAINTENANCE_REPORT_CREATEREPORT ?? '';
    static readonly updateReport: string = process.env.REACT_APP_MAINTENANCE_REPORT_UPDATEREPORT ?? '';
    static readonly uploadFile: string = process.env.REACT_APP_MAINTENANCE_REPORT_UPLOADFILE ?? '';
    static readonly getFile: string = process.env.REACT_APP_MAINTENANCE_REPORT_GETFILE ?? '';
    static readonly deleteFile: string = process.env.REACT_APP_MAINTENANCE_REPORT_DELETEFILE ?? '';
    static readonly sendEmailMaintenanceReport: string = process.env.REACT_APP_MAINTENANCE_REPORT_SENDEMAIL ?? '';
    static readonly reportExcel: string = process.env.REACT_APP_MAINTENANCE_REPORT_REPORTEXCEL ?? '';
}

export class USER_MANAGER {
    static readonly userManagerURL = `${process.env.REACT_APP_USER_API}/User`;
    static readonly searchClients: string = `${this.userManagerURL}/SearchClients`;
    static readonly getUsers: string = `${this.userManagerURL}/GetUsers`;
    static readonly GetUserById: string = `${this.userManagerURL}/GetUserById`;
    static readonly editCustomer: string = `${this.userManagerURL}/EditCustomer`;
    static readonly getGroups: string = `${this.userManagerURL}/GetGroups`;
    static readonly deleteUser: string = `${this.userManagerURL}/DeleteUser`;
    static readonly removeUser: string = `${this.userManagerURL}/RemoveUser`;
    static readonly updateUser: string = `${this.userManagerURL}/Update`;
    static readonly updateRole: string = `${this.userManagerURL}/UpdateRole`;
    static readonly addUser: string = `${this.userManagerURL}/AddUser`;
    static readonly editUser: string = `${this.userManagerURL}/Update`;
    static readonly updateUserCompany: string = `${this.userManagerURL}/UpdateUserCompany`;
    static readonly addGroupToCustomer: string = `${this.userManagerURL}/AddGroupToCustomer`;
    static readonly removeGroupFromUser: string = `${this.userManagerURL}/RemoveGroupFromUser`;
    static readonly setCustomerOptions: string = `${this.userManagerURL}/SetCustomerOptions`;
    static readonly getAvailableModules: string = `${this.userManagerURL}/GetAvailableModules`;
    static readonly getAvailableKeys: string = `${this.userManagerURL}/GetAvailableKeys`;
    static readonly addModuleToUser: string = `${this.userManagerURL}/AddModuleToUser`;
    static readonly removeModuleFromUser: string = `${this.userManagerURL}/RemoveModuleFromUser`;
    static readonly addKeyToUser: string = `${this.userManagerURL}/AddKeyToUser`;
    static readonly removeKeyFromUser: string = `${this.userManagerURL}/RemoveKeyFromUser`;
    static readonly searchPendencies: string = `${this.userManagerURL}/SearchPendencies`;
    static readonly addPendencyBlockAccess: string = `${this.userManagerURL}/AddPendencyBlockAccess`;
    static readonly finishPendency: string = `${this.userManagerURL}/FinishPendency`;
    static readonly getUserEvents: string = `${this.userManagerURL}/GetUserEvents`;
    static readonly getUserNotes: string = `${this.userManagerURL}/GetUserNotes`;
    static readonly addNote: string = `${this.userManagerURL}/AddNote`;
    static readonly addCustomer: string = `${this.userManagerURL}/AddCustomer`;
    static readonly searchAdmins: string = `${this.userManagerURL}/SearchAdmins`;
    static readonly addAdmin: string = `${this.userManagerURL}/AddAdmin`;
    static readonly getReport: string = `${this.userManagerURL}/GetReport`;
    static readonly login: string = `${this.userManagerURL}/Login`;
    static readonly GetCustomer = `${this.userManagerURL}/Microssiga/GetCustomer`;
    static readonly rmaUser: string = `${this.userManagerURL}/RmaUser`;
}

export class MICROSIGA_API {
    static readonly microssigaCustumer = `${process.env.REACT_APP_MICROSIGA_API}/GetCustomer`;
}

export class USER_MODULE {
    static readonly userModuleURL = `${process.env.REACT_APP_USER_API}/UserModule`;
    static readonly insertUserModule: string = `${this.userModuleURL}/InsertUserModule`;
    static readonly insertCompanyLOB: string = `${this.userModuleURL}/InsertCompanyLOB`;
    static readonly getUserModules: string = `${this.userModuleURL}/GetCompanyModules`;
    static readonly getCompanyLOBs: string = `${this.userModuleURL}/GetCompanyLOBS`;
}

export class USER_PERMISSION {
    static readonly userPermissionURL = `${process.env.REACT_APP_USER_API}/UserPermission`;
    static readonly getUserPermission: string = `${this.userPermissionURL}/GetUserPermissionById`;
    static readonly updateUserPermission: string = `${this.userPermissionURL}/UpdateUserPermission`;
    static readonly getCompanyPermissions: string = `${this.userPermissionURL}/GetCompanyPermissions`;
    static readonly updateCompanyPermissions: string = `${this.userPermissionURL}/UpdateCompanyPermissions`;
    static readonly getCompanyPermissionByCompanyId: string = `${this.userPermissionURL}/GetCompanyPermissionByCompanyId`;
}
export class PERMISSION_MANAGER {
    static readonly userManagerURL = `${process.env.REACT_APP_USER_API}/Permission`;
    static readonly createPermission: string = `${this.userManagerURL}/CreatePermission`;
    static readonly getPermission: string = `${this.userManagerURL}/GetPermissions`;
}
export class MODULE_PERMISSION {
    static readonly modulePermission = `${process.env.REACT_APP_USER_API}/ModulePermission`;
    static readonly getModulePermissions: string = `${this.modulePermission}/GetModulePermissions`;
}

export class MODULE {
    static readonly module = `${process.env.REACT_APP_USER_API}/Module`;
    static readonly getModules: string = `${this.module}/GetModules`;
}

export class LOB {
    static readonly lob = `${process.env.REACT_APP_USER_API}/LOB`;
    static readonly getLOBs: string = `${this.lob}/GetLOB`;
}

export class COMPANY_MANAGER {
    static readonly companyManagerURL = `${process.env.REACT_APP_USER_API}/Company`;
    static readonly ManagerURL = `${process.env.REACT_APP_USER_API}`;
    static readonly getCompanyByCnpj: string = `${this.companyManagerURL}/GetCompanyByCnpj`;
    static readonly getCompanyFromMicrosiga: string = `${this.companyManagerURL}/GetCompanyFromMicrosiga`;
    static readonly getCompanyByCnpjLogin: string = `${this.companyManagerURL}/GetCompanyByCnpjLogin`;
    static readonly getCompanyById: string = `${this.companyManagerURL}/GetCompanyById`;
    static readonly GetCompanyByIdSap: string = `${this.companyManagerURL}/GetCompanyByIdSap`;
    static readonly getCompanies: string = `${this.companyManagerURL}/GetCompanies`;
    static readonly sendEmail: string = `${this.ManagerURL}/Email/SendEmail`;
    static readonly createCompany: string = `${this.companyManagerURL}/CreateCompany`;
    static readonly getAssistances = `${this.companyManagerURL}/GetPartners`;
    static readonly getHslPartners: string = `${this.companyManagerURL}/GetHslPartners`;
}

export class SSO {
    static readonly ssoURL = `${process.env.REACT_APP_USER_API}/Sso`;
    static readonly generateToken: string = `${this.ssoURL}/GetToken`;
}

export class HSL {
    static readonly postUploadContractFile: string = `${process.env.REACT_APP_HSL_API}/Contract/ProcessContractFile`;
    static readonly postUploadWarrantyFile: string = `${process.env.REACT_APP_HSL_API}/Contract/ProcessWarrantyFile`;
    static readonly createContractByUpload: string = `${process.env.REACT_APP_HSL_API}/Contract/CreateContractByUpload`;
    static readonly createWarrantyByUpload: string = `${process.env.REACT_APP_HSL_API}/Contract/CreateWarrantyByUpload`;
    static readonly createContractByForm: string = `${process.env.REACT_APP_HSL_API}/Contract/CreateContractByForm`;
    static readonly createWarrantyByForm: string = `${process.env.REACT_APP_HSL_API}/Contract/CreateWarrantyByForm`;
    static readonly getContracts: string = `${process.env.REACT_APP_HSL_API}/Contract/GetContracts`;
    static readonly getAllContracts: string = `${process.env.REACT_APP_HSL_API}/Contract/GetAllContracts`;
    static readonly getContractById: string = `${process.env.REACT_APP_HSL_API}/Contract/getContractById`;
    static readonly getContractBySerialNumber: string = `${process.env.REACT_APP_HSL_API}/Contract/GetContractBySerialNumber`;
    static readonly inactivateContract: string = `${process.env.REACT_APP_HSL_API}/Contract/InactivateContract`;
    static readonly deleteContract: string = `${process.env.REACT_APP_HSL_API}/Contract/DeleteContract`;
    static readonly updateContract: string = `${process.env.REACT_APP_HSL_API}/Contract/UpdateContract`;
    static readonly getContractExcelFile: string = `${process.env.REACT_APP_HSL_API}/Contract/GetExcelFile`;
    static readonly postUploadFile: string = `${process.env.REACT_APP_HSL_API}/Delivery/ProcessDeliveryFile`;
    static readonly validateEquipment: string = `${process.env.REACT_APP_HSL_API}/Contract/ValidateEquipmentData`;
    static readonly getPartners: string = `${process.env.REACT_APP_HSL_API}/Delivery/GetPartners`;
    static readonly getCarriers: string = `${process.env.REACT_APP_HSL_API}/Delivery/GetCarriers`;
    static readonly postGuiaFile: string = `${process.env.REACT_APP_HSL_API}/Delivery/ProcessGuiaFile`;
    static readonly createDelivery: string = `${process.env.REACT_APP_HSL_API}/Delivery/CreateDelivery`;
    static readonly validateDelivery: string = `${process.env.REACT_APP_HSL_API}/Delivery/ValidateDeliveryData`;
    static readonly validateGuia: string = `${process.env.REACT_APP_HSL_API}/Delivery/ValidateGuiaData`;
    static readonly updateBillOfLadings: string = `${process.env.REACT_APP_HSL_API}/Delivery/UpdateBillOfLadings`;
    static readonly updateReceivedItem: string = `${process.env.REACT_APP_HSL_API}/Delivery/UpdateReceivedItem`;
    static readonly deleteDelivery: string = `${process.env.REACT_APP_HSL_API}/Delivery/DeleteDelivery`;
    static readonly getDeliveries: string = `${process.env.REACT_APP_HSL_API}/Delivery/GetDeliveries`;
    static readonly getPendentDeliveries: string = `${process.env.REACT_APP_HSL_API}/Delivery/GetPendentDeliveries`;
    static readonly getInventoryItems: string = `${process.env.REACT_APP_HSL_API}/Inventory/GetInventoryItems`;
    static readonly getInventoryItemsPartDetails: string = `${process.env.REACT_APP_HSL_API}/Inventory/GetInventoryItemsPartDetails`;
    static readonly createNewAction: string = `${process.env.REACT_APP_HSL_API}/Inventory/CreateActionInventory`;
    static readonly getInventoryExcelFile: string = `${process.env.REACT_APP_HSL_API}/Inventory/GetExcelFile`;
    static readonly getActions: string = `${process.env.REACT_APP_HSL_API}/LogInventory/GetLogsByItemInventoryId`;
    static readonly updateLogInventoryInfo: string = `${process.env.REACT_APP_HSL_API}/LogInventory/UpdateLogInventoryInformations`;
    static readonly getParts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/GetAllParts`;
    static readonly getProducts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/GetAllProduct`;
    static readonly getProductsParts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/GetPartsProductsList`;
    static readonly deleteProductsParts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/DeleteProductParts`;
    static readonly updateProductsParts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/UpdateProductParts`;
    static readonly postProductFile: string = `${process.env.REACT_APP_HSL_API}/ProductParts/ProcessProductFile`;
    static readonly createProductParts: string = `${process.env.REACT_APP_HSL_API}/ProductParts/CreateProductsParts`;
    static readonly createProduct: string = `${process.env.REACT_APP_HSL_API}/ProductParts/CreateProduct`;
    static readonly getPartById: string = `${process.env.REACT_API_HSL_API}/ProductParts/GetPartById`;
    static readonly createRepair: string = `${process.env.REACT_APP_HSL_API}/Repair/CreateRepair`;
    static readonly updateRepair: string = `${process.env.REACT_APP_HSL_API}/Repair/UpdateRepair`;
    static readonly getRepairs: string = `${process.env.REACT_APP_HSL_API}/Repair/GetRepairs`;
    static readonly getRepairId: string = `${process.env.REACT_APP_HSL_API}/Repair/GetRepairById`;
    static readonly addPnInRepair: string = `${process.env.REACT_APP_HSL_API}/Repair/AddPnInRepair`;
    static readonly removePnInRepair: string = `${process.env.REACT_APP_HSL_API}/Repair/RemovePnInRepair`;
    static readonly getRepairPartsByRepairId: string = `${process.env.REACT_APP_HSL_API}/Repair/GetRepairPartsByRepairId`;
    static readonly deleteRepair: string = `${process.env.REACT_APP_HSL_API}/Repair/DeleteRepair`;
    static readonly getAllPartNumberEquipment: string = `${process.env.REACT_APP_HSL_API}/Repair/GetAllPartNumberEquipment`;
    static readonly createQuotation: string = `${process.env.REACT_APP_HSL_API}/Quotation/CreateQuotation`;
    static readonly getQuotations: string = `${process.env.REACT_APP_HSL_API}/Quotation/GetQuotations`;
    static readonly deleteQuotation: string = `${process.env.REACT_APP_HSL_API}/Quotation/DeleteQuotation`;
    static readonly getFileQuotation: string = `${process.env.REACT_APP_HSL_API}/Quotation/GetFile`;
    static readonly getLogRepairsByRepairId: string = `${process.env.REACT_APP_HSL_API}/Repair/GetLogRepairsByRepairId`;
    static readonly getRepairExcelFile: string = `${process.env.REACT_APP_HSL_API}/Repair/GetExcelFile`;
    static readonly getTemplateExcelFile: string = `${process.env.REACT_APP_HSL_API}/File/DownloadFile`;
    static readonly getPendingInventoryItems: string = `${process.env.REACT_APP_HSL_API}/Inventory/GetPendingInventoryItems`;
}
