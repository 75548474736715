import { Button, Icon, Table, Carousel } from '@scuf/common';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { createOrder } from 'resources/api/spareParts_api/createOrder';
import { customerCartAtom, orderCartAtom, orderCartFileAtom, orderWarrantyCartAtom } from 'resources/atoms/spareParts';
import { companyAtom, notificationAtom } from 'resources';
import { OrderCartDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import useUser from 'helpers/hooks/useUser';
import { LabelDescription } from 'components';
import { formatDate } from 'helpers';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { HttpResponse } from 'axios-http';
import { uploadFileWarranty } from 'resources/api/spareParts_api/File';
import { destiny } from 'models/spareParts';

const GARANTIA = 4;

function CartResume(): JSX.Element {
    const { t } = useTranslation(['spareparts', 'common']);
    const [defaultCart, setdefaultCart] = useRecoilState<OrderCartDTO>(orderCartAtom);
    const [warrantyCart] = useRecoilState<OrderItemsDTO[]>(orderWarrantyCartAtom);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [file, setFile] = useRecoilState<File | null>(orderCartFileAtom);
    const setNotification = useSetRecoilState(notificationAtom);
    const resetRecoil = useResetRecoilState(customerCartAtom);
    const resetRecoilOrder = useResetRecoilState(orderCartAtom);
    const [userCompany] = useRecoilState<CompanyDTO>(companyAtom);
    const { user } = useUser();

    const validate = useCallback(() => {
        return !!defaultCart?.orderItems?.length && defaultCart?.allowPartial != null && !!defaultCart?.shippingTypeId;
    }, [defaultCart]);

    const uploadSPFile = async (orderId: number): Promise<HttpResponse | undefined> => {
        if (file != undefined) {
            //gerando o novo nome do arquivo
            let filename: string[] = file.name.split('.');
            let posfix = `_${Date.parse(Date())}.${filename[filename.length - 1]}`;
            let prefix: string = '';
            for (let i = 0; i < filename.length - 1; i++) prefix += filename[i];
            let newName = prefix + posfix;

            //enviando para o servidor
            let f: File = new File([file], newName, { type: file.type });
            return await uploadFileWarranty(f, orderId ?? 0, defaultCart.orderItems[0]?.email, 'WARRANTY');
        }
    };

    const getPriceFreight = () => {
        if (defaultCart.typeId === GARANTIA) {
            return { destination: 2, priceFreight: 0 };
        } else if ((userCompany?.state === 'MG' || userCompany?.state === 'SP') && defaultCart.total <= 49) {
            return { priceFreight: 9.99 };
        } else if (userCompany?.state !== 'MG' && userCompany?.state !== 'SP' && defaultCart.total <= 99) {
            return { priceFreight: 17.99 };
        }
    };

    async function addOrder() {
        setLoading(true);

        var requestBody = {
            ...defaultCart,
            email: user?.EMAIL!,
            companyCnpj: userCompany?.cnpj,
            companyName: userCompany?.name,
            companyId: user?.COMPANY!,
            ssoId: user?.SSOID!,
            total: defaultCart.total,
            orderItems: defaultCart.orderItems.map(e => {
                const { id, ...rest } = e;
                return rest as OrderItemsDTO;
            }),
            warrantyItems: warrantyCart.map(e => {
                const { id, ...rest } = e;
                return rest as OrderItemsDTO;
            }),
            priceFreight: getPriceFreight()?.priceFreight ?? defaultCart.priceFreight,
            destination: getPriceFreight()?.destination ?? defaultCart.destination
        };

        var resp = await createOrder(requestBody);

        if (resp.status !== 200) {
            setNotification(old => ({ ...old, message: 'Create' }));
        } else {
            setNotification(old => ({ ...old, message: 'Create', type: 'success' }));
            let upResp = await uploadSPFile(resp.data?.id);
            if (upResp?.error ?? false) setNotification(old => ({ ...old, message: upResp?.message, type: 'important' }));
        }

        resetRecoil();
        resetRecoilOrder();
        setFile(null);
        navigate('/spare-parts');

        setLoading(false);
    }

    const typeTranslations: { [key: number]: string } = {
        1: t('translation.InChoice'),
        2: t('translation.Price'),
        3: t('common:status.PreApproved'),
        4: t('common:services.Warranty')
    };

    return (
        <>
            <h1>
                <Icon name="shopping-bag" size="large" root="building" /> {t('common:translation.Order')}
            </h1>
            <div className="grid-view">
                <LabelDescription
                    title={t('common:translation.Type')}
                    value={typeTranslations[defaultCart.typeId] || ''}
                ></LabelDescription>
                <LabelDescription
                    title={t('translation.Destination')}
                    value={destiny.find(item => item.value === defaultCart.destination)?.text}
                ></LabelDescription>
                <LabelDescription
                    title={t('translation.InvoicePartial')}
                    value={defaultCart.allowPartial == true ? t('common:actions.Yes') : t('common:actions.No')}
                ></LabelDescription>
                <LabelDescription title={t('translation.RequestedNumber')} value={defaultCart.number}></LabelDescription>
                <LabelDescription
                    title={t('translation.Transport')}
                    value={defaultCart.shippingTypeId == 1 ? t('translation.FreightHoneywell') : t('translation.ClientRemove')}
                ></LabelDescription>
            </div>
            <h1>
                <Icon name="shopping-bag" size="large" root="building" /> {t('register:type.Company')}
            </h1>
            <div className="grid-view">
                <LabelDescription title={t('user:company.Name')} value={userCompany?.name}></LabelDescription>
                <LabelDescription title={t('CNPJ')} value={userCompany?.cnpj}></LabelDescription>
                <LabelDescription title={t('repaircenter:translation.Responsible')} value={user?.NAME}></LabelDescription>
                <LabelDescription title={t('user:address.State')} value={userCompany?.state}></LabelDescription>
                <LabelDescription title={t('user:address.City')} value={userCompany?.city}></LabelDescription>
                <LabelDescription title={t('user:address.Neighborhood')} value={userCompany?.neighborhood}></LabelDescription>
                <LabelDescription title={t('user:address.PublicPlace')} value={userCompany?.street}></LabelDescription>
                <LabelDescription title={t('user:address.Number')} value={userCompany?.number}></LabelDescription>
                <LabelDescription title={t('user:address.Complement')} value={userCompany?.complement}></LabelDescription>
                <LabelDescription title={t('CEP')} value={userCompany?.zipCode}></LabelDescription>
                <LabelDescription title={t('IE Isento')}></LabelDescription>
            </div>
            <h1>
                <Icon name="shopping-bag" size="large" root="building" /> {t('translation.AddedProducts')}
            </h1>
            {defaultCart.typeId != 4 ? (
                <div className="div-datatable">
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Image')} />
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={t('common:translation.Quantity')} />
                            <Table.HeaderCell content={t('translation.UnitValueWithoutDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Discount')} />
                            <Table.HeaderCell content={t('translation.UnitValueWithDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Total')} />
                        </Table.Header>
                        <Table.Body>
                            {defaultCart
                                ? [
                                    defaultCart.orderItems?.map((order: OrderItemsDTO) => (
                                        <Table.Row key={order.id}>
                                            <Table.Cell>
                                                {order.images?.toString() !== "" ?
                                                    <Carousel className="carouselcart" autoPlay={true} transitionTime={6000} height={200}>
                                                        {order.images?.map((image: string, idx: number) => (
                                                            <Carousel.Slide
                                                                key={idx}
                                                                className='carousel-image'
                                                                image={`data:image/png;base64,${image}`}
                                                            />
                                                        ))}
                                                    </Carousel>
                                                    : <img className="imagepd" alt="imagem padrão" src={require('../../../../../assets/HON192.png')} />
                                                }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {[
                                                    <strong key={order.code}>{[order.code]}</strong>,
                                                    <br />,
                                                    order.model + ' - ' + order.description
                                                ]}
                                            </Table.Cell>
                                            <Table.Cell>{order.quantity}</Table.Cell>
                                            <Table.Cell>{order.netPrice}</Table.Cell>
                                            <Table.Cell>{[order.discount * 100, '%']}</Table.Cell>
                                            <Table.Cell>
                                                {(order.netPrice - order.discount * order.netPrice).toFixed(2)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {((order.netPrice - order.discount * order.netPrice) * order.quantity).toFixed(
                                                    2
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                ]
                                : '--'}
                            <Table.Row>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell>{t('translation.ShippingPrice')}</Table.Cell>
                                <Table.Cell>
                                    {userCompany?.state == 'MG' || userCompany?.state == 'SP'
                                        ? defaultCart?.total < 49
                                            ? 'USD 9,99'
                                            : t('translation.FreeShipping')
                                        : userCompany?.state != null
                                            ? defaultCart?.total < 99
                                                ? 'USD 17,99'
                                                : t('translation.FreeShipping')
                                            : ''}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell>{t('cart.ValueTotal')}</Table.Cell>
                                <Table.Cell>{defaultCart.total.toFixed(2)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            ) : (
                <div className="div-datatable">
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Image')} />
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={t('common:translation.Information')} />
                            <Table.HeaderCell content={t('spareparts:productinfots.DefectFound')} />
                        </Table.Header>
                        <Table.Body>
                            {warrantyCart
                                ? [
                                    warrantyCart.map((order: OrderItemsDTO) => (
                                        <Table.Row key={order.id}>
                                            <Table.Cell>
                                                {order.images?.toString() !== "" ?
                                                    <Carousel className="carouselcart" autoPlay={true} transitionTime={6000} height={200}>
                                                        {order.images?.map((image: string, idx: number) => (
                                                            <Carousel.Slide
                                                                key={idx}
                                                                className='carousel-image'
                                                                image={`data:image/png;base64,${image}`}
                                                            />
                                                        ))}
                                                    </Carousel>
                                                    : <img className="imagepd" alt="imagem padrão" src={require('../../../../../assets/HON192.png')} />
                                                }
                                            </Table.Cell>
                                            <Table.Cell>{order.code} </Table.Cell>
                                            <Table.Cell>
                                                {[
                                                    t('spareparts:productinfots.order_data.SerialNumberEqp'),
                                                    ': ',
                                                    order.serialNumberEquipment,
                                                    <br />,
                                                    t('spareparts:productinfots.order_data.SerialNumberPart'),
                                                    ': ',
                                                    order.serialNumberPart,
                                                    <br />,
                                                    formatDate(order.createdAt)
                                                ]}
                                            </Table.Cell>
                                            <Table.Cell>{order.descriptionDefectFound}</Table.Cell>
                                        </Table.Row>
                                    ))
                                ]
                                : '--'}
                        </Table.Body>
                    </Table>
                </div>
            )}
            {defaultCart.typeId === GARANTIA ? (
                <h1>
                    <Icon name="shopping-bag" size="large" root="building" /> {t('common:translation.FinalClient')}
                </h1>
            ) : (
                ''
            )}
            {defaultCart.typeId === GARANTIA ? (
                <div className="grid-view">
                    <LabelDescription
                        title={t('user:company.Name')}
                        value={defaultCart?.finalClient?.companyName}
                    ></LabelDescription>
                    <LabelDescription title={t('E-mail')} value={defaultCart?.finalClient?.email}></LabelDescription>
                </div>
            ) : (
                ''
            )}
            <div className="div-datatable">
                <div className="buttonsSearch" style={{ position: 'absolute', width: '92%' }}>
                    <Button
                        type="primary"
                        icon="exit"
                        loading={loading}
                        disabled={!validate() || loading}
                        content={t('common:actions.Finish')}
                        onClick={() => {
                            addOrder();
                        }}
                    />
                </div>
            </div>
        </>
    );
}
export default CartResume;
