import { Table, Carousel } from '@scuf/common';
import { formatDate } from 'helpers';
import { OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import useFetch from 'helpers/hooks/useFetch';
import { SPARE_PARTS } from 'resources';

const ProductAddedWaranty: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common', 'notification']);

    const [imagesMap, setImagesMap] = useState<{ [key: string]: string[] }>({});
    const [, fetchImages] = useFetch<{ partNumbers: string[] }, any>({
        url: SPARE_PARTS.getImages,
        method: 'POST',
        onError: t('notification:critical.ErroImage')
    });

    const getImages = async (partNumbers: string[]): Promise<void> => {
        await fetchImages({ partNumbers }, resp => {
            const imagesResponse = resp?.data ?? [];

            const newImagesMap: { [key: string]: string[] } = {};
            imagesResponse.forEach((imageData: any) => {
                if (imageData.partNumber && imageData.images) {
                    newImagesMap[imageData.partNumber] = imageData.images;
                }
            });

            setImagesMap(newImagesMap);
        });
    };

    useEffect(() => {
        if (orderData && orderData.orderItems) {
            const partNumbers = orderData.orderItems.map(item => item.partNumber!);
            getImages(partNumbers);
        }
    }, [orderData]);

    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>
                        {t('translation.AddedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
                    </h2>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Image')} />
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={''} />
                            <Table.HeaderCell content={t('translation.EquipSerial')} />
                            <Table.HeaderCell content={t('translation.SerialPart')} />
                            <Table.HeaderCell content={t('common:translation.Date')} />
                            <Table.HeaderCell content={t('common:translation.Problem')} />
                        </Table.Header>
                        <Table.Body>
                            {orderData?.orderItems &&
                                orderData?.orderItems?.map((item: any) => (
                                    <Table.Row>
                                        <Table.Cell>
                                            {imagesMap[item.partNumber]?.length > 0 ? (
                                                <Carousel
                                                    className="carouselcart"
                                                    autoPlay={true}
                                                    transitionTime={6000}
                                                    height={200}
                                                >
                                                    {imagesMap[item.partNumber].map((image: string, idx: number) => (
                                                        <Carousel.Slide
                                                            key={idx}
                                                            className="carousel-image"
                                                            image={`data:image/png;base64,${image}`}
                                                        />
                                                    ))}
                                                </Carousel>
                                            ) : (
                                                <img
                                                    className="imagepd"
                                                    alt="imagem padrão"
                                                    src={require('../../../../../../assets/HON192.png')}
                                                />
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>{item.partNumber}</Table.Cell>
                                        <Table.Cell>{item.description} </Table.Cell>
                                        <Table.Cell>{item.serialNumberEquipment} </Table.Cell>
                                        <Table.Cell>{item.serialNumberPart} </Table.Cell>
                                        <Table.Cell>{formatDate(item.createdAt)} </Table.Cell>
                                        <Table.Cell>{item.descriptionDefectFound} </Table.Cell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedWaranty;
