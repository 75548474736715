import { Badge, Divider } from '@scuf/common';
import useUser from 'helpers/hooks/useUser';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ChatSidebarItemProps = {
    id: number;
    itWasReadByAdmin?: boolean;
    itWasReadByCustomer?: boolean;
    companyId?: number;
    enable: boolean
};

type ChatSidebarProps = {
    company?: CompanyDTO[];
    items?: ChatSidebarItemProps[];
    onChange: (id: number) => void;
};

const ChatSidebar: React.FC<ChatSidebarProps> = ({ company, items, onChange }: ChatSidebarProps) => {
    const { t } = useTranslation();
    const { user } = useUser();
    const [currentItem, setCurrentItem] = useState<number>();

    return (
        <div style={{ margin: '12px' }}>
            {items?.map(e => (
                <div
                    className="sidebar-chat-item"
                    onClick={() => {
                        setCurrentItem(e.id);
                        onChange(e.id);
                    }}
                >
                    <div className={`${currentItem === e.id ? 'selected' : ''}, ${e.enable ? '' : 'disabled-chat'}`} key={e.id}>
                        {t('common:translation.Number')} {e?.id}
                        <h5>{company?.find(x => x.id === e.companyId)?.name}</h5>
                    </div>
                    {user?.ROLE == '1' || user?.ROLE == '2'
                        ? !e.itWasReadByAdmin && currentItem !== e.id && <Badge content="Nova mensagem" color="green" />
                        : !e.itWasReadByCustomer && currentItem !== e.id && <Badge content="Nova mensagem" color="green" />}
                </div>
            ))}
        </div>
    );
};

export default ChatSidebar;
