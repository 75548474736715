import { Table, Carousel } from '@scuf/common';
import { OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import { SPARE_PARTS } from 'resources';
import useFetch from 'helpers/hooks/useFetch';

const ProductAddedPreApproved: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common', 'notification']);

    const [imagesMap, setImagesMap] = useState<{ [key: string]: string[] }>({});
    const [, fetchImages] = useFetch<string[], any>({
        url: SPARE_PARTS.getImages,
        method: 'POST',
        onError: t('notification:critical.ErroImage')
    });

    const getImages = async (partNumbers: string[]): Promise<void> => {
        await fetchImages(partNumbers, resp => {
            const imagesResponse = resp?.data ?? [];

            const newImagesMap: { [key: string]: string[] } = {};
            imagesResponse.forEach((imageData: any) => {
                if (imageData.partNumber && imageData.images) {
                    newImagesMap[imageData.partNumber] = imageData.images;
                }
            });

            setImagesMap(newImagesMap);
        });
    };

    useEffect(() => {
        if (orderData && orderData.orderItems) {
            const partNumbers = orderData.orderItems.map(item => item.partNumber!);
            getImages(partNumbers);
        }
    }, [orderData]);

    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>
                        {t('translation.SimulatedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
                    </h2>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Image')} />
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={''} />
                            <Table.HeaderCell content={t('common:translation.Quantity')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueNotDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Discount')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Total')} />
                        </Table.Header>
                        <Table.Body>
                            {orderData
                                ? orderData?.orderItems?.map((item: any) => (
                                      <Table.Row key={item.partNumber}>
                                          <Table.Cell>
                                              {imagesMap[item.partNumber]?.length > 0 ? (
                                                  <Carousel
                                                      className="carouselcart"
                                                      autoPlay={true}
                                                      transitionTime={6000}
                                                      height={200}
                                                  >
                                                      {imagesMap[item.partNumber].map((image: string, idx: number) => (
                                                          <Carousel.Slide
                                                              key={idx}
                                                              className="carousel-image"
                                                              image={`data:image/png;base64,${image}`}
                                                          />
                                                      ))}
                                                  </Carousel>
                                              ) : (
                                                  <img
                                                      className="imagepd"
                                                      alt="imagem padrão"
                                                      src={require('../../../../../../assets/HON192.png')}
                                                  />
                                              )}
                                          </Table.Cell>
                                          <Table.Cell>{item?.partNumber} </Table.Cell>
                                          <Table.Cell width={5}> {item?.description} </Table.Cell>
                                          <Table.Cell>{item?.quantity} </Table.Cell>
                                          <Table.Cell>${item?.netPrice?.toFixed(2)} </Table.Cell>
                                          <Table.Cell>{item?.discount * 100 + '%'} </Table.Cell>
                                          <Table.Cell>
                                              ${(item?.netPrice - item.netPrice * item?.discount).toFixed(2)}{' '}
                                          </Table.Cell>
                                          <Table.Cell>
                                              ${((item?.netPrice - item.netPrice * item?.discount) * item?.quantity).toFixed(2)}{' '}
                                          </Table.Cell>
                                      </Table.Row>
                                  ))
                                : ''}
                        </Table.Body>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{''}</Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{t('translation.NoTaxIncluded')} </Table.Cell>
                                <Table.Cell>{t('translation.FinalValues')} </Table.Cell>
                                <Table.Cell>{t('common:translation.Total')} </Table.Cell>
                                <Table.Cell>${(orderData?.total + orderData.priceFreight).toFixed(2)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedPreApproved;
