import { Badge, Button, Card, FileDrop, Header, Icon, Input, Loader, Modal, Select } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import '@scuf/datatable/honeywell/theme.css';
import { dateFormatFromMicrosft } from 'helpers';
import { statusOptions, statusRmaCA, supportLevelCA, typeOptions } from 'models/repaircenter/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getRmaFilter, getRmaFilterCA, getRmas, getRmasCA } from 'resources/api/';
import * as Xlsx from 'xlsx';
import { ModelEOSResult, ModelEosXlsx } from 'models/repaircenter/eosModel';
import { RMAStatus, RmaEquipment, RmaCustomerCA, RmaResultModel, RmaResultModelCA } from 'models/repaircenter/rmaModel';
import { UploadStatusRma, notificationAtom, UploadEquipmentRma, UploadCustomerCA } from 'resources';
import { useSetRecoilState } from 'recoil';
import React from 'react';
import moment from 'moment';

function RmaSearchCA() {
    var [rmaNumber, setRmaNumber] = useState<any>([]);
    var [serialNumber, setNumberSerie] = useState<any>([]);
    var [typeIdSet, setTypeIdSet] = useState<any>([]);
    var [date, setDate] = useState<any>([]);
    var [serviceOrder, setServiceOrder] = useState<any>([]);
    var [stOptions, setStatusOptions] = useState<any>([]);
    var [itemPerPage, setItemByPage] = useState<any>([]);
    var [rmaNumberClear, setRmaNumberClear] = useState<any>([]);
    const [loadingRmas, setLoadingRmas] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();
    const { t } = useTranslation(['repaircenter', 'common']);
    const [dataRma, setDataRma] = useState<any[]>([]);

    const [modalStatuRma, setModalStatusRma] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { getJsDateFromExcel } = require('excel-date-to-js');
    const setNotification = useSetRecoilState(notificationAtom);
    const [modalEquipmentRma, setModalEquipmentRma] = useState<boolean>(false);
    const [modalCustomerCA, setModalCustomerCA] = useState<boolean>(false);
    const [resultData, setResultData] = useState<[]>([]);
    const [userName, setUserName] = useState<string>('');

    const [fileToSend, setFileToSend] = useState<{
        file: any;
        order_id: any;
        type: string;
    } | null>(null);

    const itemsPerPage = [
        { value: '5', text: '5' },
        { value: '10', text: '10' },
        { value: '25', text: '15' },
        { value: '20', text: '20' }
    ];
    const uploadXslx = async (Xslx: RMAStatus[]): Promise<void> => {
        setLoading(true);
        const resp = await UploadStatusRma(Xslx);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Edit' }));
            setModalStatusRma(false);
        } else {
            setNotification(old => ({ ...old, message: 'Edit' }));
        }
        setLoading(false);
    };
    const uploadXslxEquipment = async (Xslx: RmaEquipment[]): Promise<void> => {
        setLoading(true);
        const resp = await UploadEquipmentRma(Xslx);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Edit' }));
            setModalEquipmentRma(false);
        } else {
            setNotification(old => ({ ...old, message: 'Edit' }));
        }
        setLoading(false);
    };
    const uploadXslxCustomer = async (Xslx: RmaCustomerCA[]): Promise<void> => {
        setLoading(true);
        const resp = await UploadCustomerCA(Xslx);
        if (!resp.error) {
            setNotification(old => ({ ...old, type: 'success', message: 'Edit' }));
            setModalCustomerCA(false);
        } else {
            setNotification(old => ({ ...old, message: 'Edit' }));
        }
        setLoading(false);
    };

    function clearFilter() {
        setRmaNumberClear('');
        setRmaNumber('');
        setNumberSerie('');
        setDate('');
        setServiceOrder('');
        setTypeIdSet('');
        setStatusOptions('');
        setItemByPage('');
        getRmaDetails();
    }

    async function getRmaDetails() {
        setLoadingRmas(true);
        let resp: { status: number; data: any } = await getRmasCA(itemPerPage);
        if (resp.status !== 200) {
            setLoadingRmas(false);
            return resp.status;
        } else {
            setLoadingRmas(false);
            let rmaArray: any[] = [];
            resp.data.Result.forEach((rma: any) => {
                let newDate = dateFormatFromMicrosft(rma?.PersistDate);
                //Conversão de data
                const dateStr = rma?.CreatedAt;
                const match = dateStr?.match(/\d+/);
                const timestamp = match ? parseInt(match[0]) : null;
                const date = timestamp ? new Date(timestamp) : null;
                const formattedDate = date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : 'N/A';
                rmaArray.push({
                    id: rma?.Id,
                    supportLevelId: (
                        <>
                            <Badge color="green">{supportLevelCA[rma?.SupportLevelId.Id].text},</Badge>
                            <Badge color="green">{rma?.SupportLevelId.Support}</Badge>
                        </>
                    ),
                    user: rma?.UserId.Name,
                    statusId: <Badge color={statusRmaCA[rma?.StatusId - 1]?.color}>{statusRmaCA[rma?.StatusId - 1].text} </Badge>,
                    qtdItems: rma?.QtdItems,
                    //serialNumber: rma?.SerialNumber,

                    dateCreated: moment(rma?.CreatedAt).format('MM/DD/YYYY HH:mm:ss')
                });
            });
            setResultData(resp.data.Result);
            setDataRma(rmaArray);
            setLoadingRmas(false);
            setUserName(rmaArray[0].user);
        }
    }

    async function getRmaFilterDetails(
        rmaNumber: any,
        serialNumber: any,
        //numberSerie: any,
        date: any,
        serviceOrder: any,
        stOptions: any,
        itemByPage: any
    ) {
        setLoadingRmas(true);
        let resp: { status: number; data: any } = await getRmaFilterCA(
            rmaNumber,
            serialNumber,
            date,
            serviceOrder,
            stOptions,
            itemByPage
        );
        if (resp.status !== 200) {
            setLoadingRmas(false);
            return resp.status;
        } else {
            setLoadingRmas(false);
            let rmaArray: any[] = [];
            resp.data.Result.forEach((rma: any) => {
                let newDate = dateFormatFromMicrosft(rma?.PersistDate);
                rmaArray.push({
                    id: rma?.Id,
                    supportLevelId: (
                        <>
                            <Badge color="green">{supportLevelCA[rma?.SupportLevelId.Id].text},</Badge>
                            <Badge color="green">{rma?.SupportLevelId.Support}</Badge>
                        </>
                    ),
                    user: rma?.UserId.Name,
                    statusId: <Badge color={statusRmaCA[rma?.StatusId - 1]?.color}>{statusRmaCA[rma?.StatusId - 1].text} </Badge>,
                    qtdItems: rma?.QtdItems,
                    serialNumber: rma?.SerialNumber,
                    osNumber: rma?.OsNumber,
                    dateCreated: moment(rma?.CreatedAt).format('MM/DD/YYYY HH:mm:ss')
                });
            });

            setDataRma(rmaArray);
            setLoadingRmas(false);
        }
    }
    function uploadStatusModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalStatusRma(false)}
                size="small"
                open={modalStatuRma}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <div className="modalHeader">
                        <h3>{t('manage_eos.translation.LoadTable').toUpperCase()} </h3>
                    </div>
                    <div className="div-alert-os">
                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                        <h5>
                            {t('manage_eos.translation.TextNotificationFile')}
                            <a href="https://sites.honeywell.com.br/mshomolog/_Uploads/TemplateStatusRma.xlsx"> clique aqui </a>
                            para fazer o download.
                        </h5>
                    </div>
                    <br />
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFileToSend(old => ({
                                    ...old!,
                                    file: file[0]
                                }));
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => setModalStatusRma(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                loading={loading}
                                icon="slidercontrols-plus"
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                onClick={async () => {
                                    const data = await fileToSend?.file.arrayBuffer();
                                    const read = Xlsx.read(data);
                                    let jsonDataXlsx: RMAStatus[] = Xlsx.utils.sheet_to_json(read.Sheets['itens']);
                                    uploadXslx(
                                        jsonDataXlsx.map((e: RMAStatus) => {
                                            return {
                                                ...e,
                                                RMA_ID: e.RMA_ID
                                            };
                                        })
                                    );
                                }}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }
    function uploadEquipmentModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalEquipmentRma(false)}
                size="small"
                open={modalEquipmentRma}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <div className="modalHeader">
                        <h3>{t('manage_eos.translation.LoadTable').toUpperCase()} </h3>
                    </div>
                    <div className="div-alert-os">
                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                        <h5>
                            {t('manage_eos.translation.TextNotificationFile')}
                            <a href="https://sites.honeywell.com.br/mshomolog/_Uploads/TemplateStatusEquipment.xlsx">
                                {' '}
                                clique aqui{' '}
                            </a>
                            para fazer o download.
                        </h5>
                    </div>
                    <br />
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFileToSend(old => ({
                                    ...old!,
                                    file: file[0]
                                }));
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => setModalEquipmentRma(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                loading={loading}
                                icon="slidercontrols-plus"
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                onClick={async () => {
                                    const data = await fileToSend?.file.arrayBuffer();
                                    const read = Xlsx.read(data);
                                    let jsonDataXlsx: RmaEquipment[] = Xlsx.utils.sheet_to_json(read.Sheets['itens']);
                                    uploadXslxEquipment(
                                        jsonDataXlsx.map((e: RmaEquipment) => {
                                            return {
                                                ...e,
                                                SerialNumber: e.SerialNumber
                                            };
                                        })
                                    );
                                }}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    function uploadCustomerModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalCustomerCA(false)}
                size="small"
                open={modalCustomerCA}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <div className="modalHeader">
                        <h3>{t('manage_eos.translation.LoadTable').toUpperCase()} </h3>
                    </div>
                    <div className="div-alert-os">
                        <Icon root="common" name="badge-important" color="#f37021" size="xlarge" />
                        <h5>
                            {t('manage_eos.translation.TextNotificationFile')}
                            <a href="https://sites.honeywell.com.br/mshomolog/_Uploads/TemplateCustomer.xlsx"> clique aqui </a>
                            para fazer o download.
                        </h5>
                    </div>
                    <br />
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFileToSend(old => ({
                                    ...old!,
                                    file: file[0]
                                }));
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                type="secondary"
                                content={t('common:actions.Close')}
                                onClick={() => setModalCustomerCA(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                loading={loading}
                                icon="slidercontrols-plus"
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                onClick={async () => {
                                    const data = await fileToSend?.file.arrayBuffer();
                                    const read = Xlsx.read(data);
                                    let jsonDataXlsx: RmaCustomerCA[] = Xlsx.utils.sheet_to_json(read.Sheets['itens']);
                                    uploadXslxCustomer(
                                        jsonDataXlsx.map((e: RmaCustomerCA) => {
                                            return {
                                                ...e,
                                                Name: e.Name
                                            };
                                        })
                                    );
                                }}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }
    useEffect(() => {
        getRmaDetails();
        setItemByPage(10);
    }, []);

    useEffect(() => {}, [resultData]);

    return (
        <>
            {loadingRmas && <Loader text={t('common:actions.Loading')} />}
            {uploadStatusModal()}
            {uploadEquipmentModal()}
            {uploadCustomerModal()}
            {!loadingRmas && (
                <>
                    <div>
                        <Header title={t('translation.RMA')} logo={false} menu={false}>
                            <Header.IconItem
                                icon={<Icon name="graph" size="large" root="building" />}
                                badge="20"
                                description={t('translation.GenerateReportofyourRMARequests')}
                            ></Header.IconItem>
                            <Header.IconItem
                                icon={<Icon name="badge-help" size="large" root="building" />}
                                badge="20"
                                description={t('translation.AccessRMAworks')}
                            ></Header.IconItem>
                            <Header.IconItem
                                icon={<Icon name="add" size="large" root="building" />}
                                badge="20"
                                onClick={() => navigate('/module/repair-centerCA/newRmaCA', { state: userName })}
                                description={t('Create new RMA')}
                            ></Header.IconItem>
                        </Header>

                        <div className="form4Columns">
                            <Input
                                placeholder="RMA"
                                label="RMA"
                                type="number"
                                onChange={value => setRmaNumber(value)}
                                value={rmaNumber}
                            />
                            <Input
                                placeholder={t('common:translation.SerialNumber')}
                                label={t('common:translation.SerialNumber')}
                                onChange={value => setNumberSerie(value)}
                                value={serialNumber}
                            />
                            <Input
                                placeholder={t('common:translation.Period')}
                                label={t('common:translation.Period')}
                                type="date"
                                onChange={value => setDate(value)}
                                value={date}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.Status')}
                                placeholder={t('common:translation.Status')}
                                options={statusRmaCA}
                                onChange={value => setStatusOptions(value)}
                            />
                        </div>
                        <div className="form4Columns">
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.Supportlevel')}
                                placeholder={t('common:translation.Supportlevel')}
                                options={supportLevelCA}
                                onChange={value => setTypeIdSet(value)}
                            />
                            <Select
                                className="SelectStyle"
                                label={t('common:translation.ItemperPage')}
                                placeholder={t('common:translation.ItemperPage')}
                                options={itemsPerPage}
                                onChange={value => setItemByPage(value)}
                            />
                        </div>
                        <div className="buttonsSearch">
                            <Button
                                type="secondary"
                                icon="badge-delete"
                                content={t('common:actions.ResetFilters')}
                                iconPosition="right"
                                onClick={() => {
                                    clearFilter();
                                }}
                            />
                            <Button
                                type="primary"
                                icon="filter1"
                                content={t('common:actions.ApplyFilters')}
                                iconPosition="right"
                                onClick={() =>
                                    getRmaFilterDetails(
                                        rmaNumber,
                                        serialNumber,
                                        date,
                                        serviceOrder,
                                        date,
                                        stOptions
                                        // itemPerPage
                                    )
                                }
                            />
                        </div>
                        <div>
                            <Button
                                type="primary"
                                icon="document-publish"
                                content={'Upload RMA Status'}
                                iconPosition="right"
                                onClick={() => setModalStatusRma(true)}
                            />
                            <Button
                                type="primary"
                                icon="document-publish"
                                content={'Upload RMA Equipment'}
                                iconPosition="right"
                                onClick={() => setModalEquipmentRma(true)}
                            />
                            <Button
                                type="primary"
                                icon="document-publish"
                                content={'Upload Customer'}
                                iconPosition="right"
                                onClick={() => setModalCustomerCA(true)}
                            />
                        </div>
                    </div>
                    <div className="div-datatable">
                        <DataTable
                            data={dataRma}
                            search
                            rows={itemPerPage}
                            reorderableColumns
                            onCellClick={e =>
                                navigate('/module/repair-centerCA/detailsRmaCA/Summary', {
                                    state: { id: e.rowData.id, data: resultData.filter((item: any) => item.Id == e.rowData.id) }
                                })
                            }
                        >
                            <DataTable.Column field="id" header={'RMA ID'} sortable initialWidth={200} />
                            <DataTable.Column field="supportLevelId" header={t('Support Level')} sortable />
                            <DataTable.Column field="user" header="User" sortable />
                            <DataTable.Column field="statusId" header={t('common:translation.Status')} sortable />
                            <DataTable.Column field="qtdItems" header={t('manualRma:Equipments')} sortable />
                            <DataTable.Column field="dateCreated" header={t('Created')} sortable />

                            <DataTable.Pagination totalItems={dataRma.length} itemsPerPage={itemPerPage} />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
}

export default RmaSearchCA;
