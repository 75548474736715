export function dateFormatFromMicrosft(date?: string) {
    if (date == null || date == '') {
        return '';
    }
    const reg = /\d+/;
    const timestamp = reg.exec(date)![0] ? parseInt(reg.exec(date)![0], 10) : 0;
    const d = new Date(timestamp);

    if (d.getFullYear() > 3000) {
        return '';
    } else {
        return (
            ('0' + d.getDate()).slice(-2) +
            '/' +
            ('0' + (d.getMonth() + 1)).slice(-2) +
            '/' +
            d.getFullYear() +
            ' ' +
            ('0' + d.getHours()).slice(-2) +
            ':' +
            ('0' + d.getMinutes()).slice(-2)
        );
    }
}

export function formatDate(date: Date | string | undefined) {
    if (!date) return '';
    const toDate: Date = new Date(date);

    return `${toDate.getUTCDate()}/${toDate.getUTCMonth() + 1}/${toDate.getFullYear()}`;
}

export function formatDateMDA(date: Date | string | undefined) {
    if (!date) return '';
    const toDate: Date = new Date(date);

    return `${toDate.getUTCMonth() + 1}/${toDate.getUTCDate()}/${toDate.getFullYear()}`;
}

export const formatDateToUTCMinus3 = (dateString: string | undefined) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    // Ajuste para UTC-3
    date.setHours(date.getHours() - 3);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const toJsDate = (date?: string): Date => {
    if (date === null) return new Date(Date.now());
    const reg = /\-?\d+/;
    const timestamp = reg.exec(date!);
    const formattedDate = new Date(parseInt(timestamp![0], 10) ?? Date.now());
    return formattedDate;
};

export const adjustDatesToFrom = (dateInicio: Date, dateFim: Date): { DateStart: Date; DateEnd: Date } => {
    const adjustedDateInicio = new Date(dateInicio);
    adjustedDateInicio.setHours(0, 0, 0, 0);

    const adjustedDateFim = new Date(dateFim);
    adjustedDateFim.setHours(23, 59, 59, 999);

    return { DateStart: adjustedDateInicio, DateEnd: adjustedDateFim };
};
