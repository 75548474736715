import { Button, Input, Modal, Select, Table } from '@scuf/common';
import useFetch from 'helpers/hooks/useFetch';
import { EmailServiceType, ValidateCompanyEmailFields } from 'models/repaircenter/detailsRma';
import { useTranslation } from 'react-i18next';
import { COMPANY_MANAGER, USER_MANAGER, cnpjMask, telephoneMask } from 'resources';
import Alert from './alert';
import { useState } from 'react';
import { CompanyDTO, latamCountries } from 'models/userManager/addCustomer';
import { UserPortal } from 'models';

type ModalProps = {
    modalState: 'pending' | 'email' | 'company' | undefined;
    onChangeModalState: (state: string) => void;
    user: UserPortal;
};

export const CompanySelectionModal: React.FC<ModalProps> = ({ modalState, onChangeModalState, user }: ModalProps) => {
    const { t } = useTranslation(['home', 'homets', 'common']);
    const [country, setCountry] = useState<String>();
    const [company, setCompany] = useState<CompanyDTO>();
    const [, sendEmail, sendingEmail] = useFetch<EmailServiceType<ValidateCompanyEmailFields>>({
        url: COMPANY_MANAGER.sendEmail,
        method: 'POST',
        onError: 'Send',
        onSuccess: 'Send'
    });
    // const [modalState, setModalState] = useState<'pending' | 'email' | 'company' | undefined>('company');
    const [, userUpdate, updatingUser] = useFetch<{ ssoId: string; companyId: number }>({
        url: USER_MANAGER.updateUserCompany,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });
    const [fields, setFields] = useState<ValidateCompanyEmailFields>({ companyName: '', email: '', cnpj: '', idSap: '' });
    const [, getCompanies, findingCompany] = useFetch<{ companyDto: string }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyByCnpjLogin,
        onError: 'LoadInfo'
    });

    const [, getCompaniesByIdSap, findingCompanyByIdSap] = useFetch<{ idSap: string }, CompanyDTO>({
        url: COMPANY_MANAGER.GetCompanyByIdSap,
        onError: 'LoadInfo'
    });
    const handleClick = () => {
        if (modalState == 'company') onChangeModalState('email');
        else {
            onChangeModalState('company');
            setFields({ cnpj: fields.cnpj, companyName: '', email: '', idSap: '' });
        }
    };
    const validateCNPJ = (): boolean => {
        return (fields.cnpj?.match(/\d+/g)?.join('')!.length ?? 0) >= 14;
    };
    const validateSendEmail = (): boolean => {
        return (
            ((fields.cnpj?.match(/\d+/g)?.join('')!.length ?? 0) >= 14 || fields.idSap !== null) &&
            !!fields?.companyName?.length! &&
            !!fields.email.length
        );
    };

    async function UpdateUser() {
        await userUpdate({ ssoId: user.ssoId, companyId: company?.id! }, _ => onChangeModalState('pending'));
    }
    return (
        <Modal className="modalW600" size="small" closeOnDocumentClick={false} open={!!modalState} closeOnDimmerClick={false}>
            <div className="modalConsultWaranty">
                <Modal.Header>
                    <div className="modalHeader">
                        <h2>
                            {modalState == 'pending' ? t('consultCompany.pendingApproval') : t('consultCompany.selectCompany')}
                        </h2>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Alert
                        text={modalState == 'pending' ? t('consultCompany.infoPendency') : t('consultCompany.infoNewCompany')}
                        className="message-initial"
                    />
                    <>
                        <Select
                            label={t('consultCompany.country')}
                            options={latamCountries}
                            value={country}
                            search={true}
                            onChange={value => setCountry(value)}
                        />
                    </>

                    <>
                        {modalState != 'pending' && country === 'Brasil' && (
                            <Input
                                fluid
                                label={t('consultCompany.id')}
                                value={cnpjMask(fields.cnpj ?? '')}
                                onChange={cnpj => {
                                    if ((cnpj.match(/\d+/g)?.join('')!.length ?? 0) <= 14) setFields(old => ({ ...old, cnpj }));
                                }}
                            ></Input>
                        )}

                        {modalState != 'pending' && country !== 'Brasil' && country != null && (
                            <Input
                                fluid
                                label={'IdSap'}
                                value={fields.idSap ?? ''}
                                onChange={idSap => setFields(old => ({ ...old, idSap }))}
                            ></Input>
                        )}
                        {
                            {
                                company: (
                                    <>
                                        <div className="buttonsSearch" style={{ justifyContent: 'space-between' }}>
                                            <a href="#" onClick={handleClick}>
                                                {t('consultCompany.notCompany')}
                                            </a>
                                            <Button
                                                content={t('common:actions.Search')}
                                                size="small"
                                                loading={findingCompany || findingCompanyByIdSap}
                                                disabled={
                                                    (!validateCNPJ() && !country) || findingCompany || findingCompanyByIdSap
                                                }
                                                onClick={() => {
                                                    country === 'Brasil'
                                                        ? getCompanies(
                                                              {
                                                                  companyDto: fields.cnpj!.match(/\d+/g)?.join('')!
                                                              },
                                                              current => {
                                                                  setCompany(current.data);
                                                              }
                                                          )
                                                        : getCompaniesByIdSap(
                                                              {
                                                                  idSap: fields.idSap!
                                                              },
                                                              current => {
                                                                  setCompany(current.data);
                                                              }
                                                          );
                                                }}
                                            />
                                        </div>
                                        {!!company && (
                                            <>
                                                <Table>
                                                    <Table.Header>
                                                        <Table.HeaderCell content={t('CNPJ')} />
                                                        <Table.HeaderCell content={t('user:address.Company')} />
                                                        <Table.HeaderCell content={t('common:translation.Modules')} />
                                                    </Table.Header>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>{company?.cnpj} </Table.Cell>
                                                            <Table.Cell>{company?.name} </Table.Cell>
                                                            <Table.Cell>
                                                                <div
                                                                    style={{
                                                                        overflowY: 'scroll',
                                                                        overflowX: 'unset',
                                                                        maxHeight: '150px'
                                                                    }}
                                                                >
                                                                    {company?.companyLOBs?.map(e => (
                                                                        <p>{e.lob.lobCode}</p>
                                                                    ))}
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                <div className="buttonsSearch">
                                                    <Button
                                                        content={t('common:actions.Register')}
                                                        size="small"
                                                        loading={updatingUser}
                                                        onClick={() => UpdateUser()}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ),
                                email: (
                                    <>
                                        <Input
                                            fluid
                                            label="E-mail"
                                            value={fields.email}
                                            onChange={email => setFields(old => ({ ...old, email }))}
                                        />
                                        <Input
                                            fluid
                                            label={t('consultCompany.companyName')}
                                            value={fields.companyName}
                                            onChange={companyName => setFields(old => ({ ...old, companyName }))}
                                        />
                                        <Input
                                            fluid
                                            label={t('consultCompany.phone')}
                                            value={telephoneMask(fields.phone ?? '')}
                                            onChange={phone => setFields(old => ({ ...old, phone }))}
                                        />
                                        <div className="buttonsSearch" style={{ justifyContent: 'space-between' }}>
                                            <a href="#" onClick={handleClick}>
                                                {t('common:actions.Back')}
                                            </a>
                                            <Button
                                                content={t('common:actions.Send')}
                                                size="small"
                                                loading={sendingEmail}
                                                disabled={!validateSendEmail() || sendingEmail}
                                                onClick={() => {
                                                    sendEmail({
                                                        NewCompany: true,
                                                        Body: 'Criação de Companhia',
                                                        Subject: 'Criação de Companhia',
                                                        ToEmail:
                                                            country === 'Brasil'
                                                                ? process.env.REACT_APP_REPAIR_CENTER_EMAIL!
                                                                : process.env.REACT_APP_HSL_EMAIL!,
                                                        Fields: fields
                                                    });
                                                    // setemailSendSucess(true);
                                                }}
                                            />
                                        </div>
                                    </>
                                ),
                                pending: null
                            }[modalState!]
                        }
                    </>
                </Modal.Content>
            </div>
        </Modal>
    );
};
