import { selectorFamily } from 'recoil';

interface FeatureFlags {
    [key: string]: boolean;
}

const defaultFeatures: FeatureFlags = {
    RepairCenter: false,
    SpareParts: false,
    MaintenanceReport: false,
    HSLWeb: true
};

const featuresByCountry: { [country: string]: FeatureFlags } = {
    MEXICO: { RepairCenter: false, SpareParts: false, MaintenanceReport: false, HSLWeb: true },
    BRASIL: { RepairCenter: true, SpareParts: true, MaintenanceReport: true, HSLWeb: false }
};

export const featuresState = selectorFamily<FeatureFlags, string>({
    key: 'featuresState',
    get: (country: string) => () => {
        return featuresByCountry[country] || defaultFeatures;
    }
});
