import { UserAccess, UserAccount } from 'models';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { atom } from 'recoil';
import { persistAtom } from './persistanceAtom';
import { UserDTO } from 'models/user/sso';
import { SatisfactionSurvey } from 'models/repaircenter/satisfactionSurvey';

export const userState = atom({
    key: 'user',
    default: new UserAccount()
});

export const userAccessState = atom({
    key: 'userAccess',
    default: new UserAccess()
});

export const userOIDCState = atom({
    key: 'userOIDC',
    default: '',
    effects_UNSTABLE: [persistAtom]
});

export const user  = atom({
    key: 'userDtoAtom',
    default:  null as unknown as UserDTO
})

export const companyDtoAtom = atom({
    key: 'companyDtoAtom',
    default:  null as unknown as CompanyDTO
})

export const companyDTO = atom({
    key: 'companiesDtoAtom',
    default:  null as unknown as CompanyDTO[]
})

export const satisfactionSurveyAtom = atom({
    key: 'satisfactionDtoAtom',
    default:  null as unknown as SatisfactionSurvey[]
})