import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

//Teste de commit

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);

reportWebVitals();
