import { ChatSidebar } from 'components';
import useFetch from 'helpers/hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { COMPANY_MANAGER, ChatRma, REPAIR_CENTER } from 'resources';
import RepairCenterChat from './chat';
import { RmaChatTable, SparePartsChatTable } from 'models/repaircenter/detailsRma';
import useUser from 'helpers/hooks/useUser';
import { useRecoilState } from 'recoil';
import { CompanyFilterDTO } from 'models';
import { Pagination } from '@scuf/common';

type RepairCenterChatType = {
    roleId?: number;
};

const RmaAdminChat: React.FC<RepairCenterChatType> = () => {
    const [currentChat, setCurrentChat] = useState<number>();
    const { user } = useUser();
    const [unreadMessage, setUnreadMessage] = useState<number>(0);
    const [chatsRma, setchatsRma] = useRecoilState<RmaChatTable[]>(ChatRma)
    const [currentPage, setCurrentPage] = useState(1);
    const [chats, loadChats, loading, updateChat] = useFetch<{ roleId: number, ssoId: string, companyId: number }, RmaChatTable[]>({
        url: REPAIR_CENTER.getAllChats,
    });
    const [companyFilter, getCompanies, loadingData, setFilter] = useFetch<CompanyFilterDTO, CompanyFilterDTO>({
        url: COMPANY_MANAGER.getCompanies,
        method: 'POST',
        onError: 'LoadInfo'
    });
    const handleData = async (indice = 1): Promise<void> => {
        getCompanies({
            ...companyFilter?.data, indice, CompanyId: Array.from(new Set(
                (chatsRma?.map(item => item?.companyId) || []).filter(companyId => companyId !== null) as number[]
            ))
        });

    };

    const paginatedChats = chatsRma.slice((currentPage - 1) * 10, currentPage * 10);

    useEffect(() => {
        handleData();

    }, []);

    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!loading && (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '40%' }}>
                            <ChatSidebar
                                company={companyFilter?.data?.companies!}
                                items={paginatedChats?.map(e => ({ ...e, id: e.rmaId }))}
                                onChange={(id: number) => {
                                    setchatsRma(old => [...old].map(e => (e?.orderId === id ? { ...e, itWasReadByAdmin: true } : e)));
                                    setCurrentChat(id);
                                }}
                            />
                        </div>
                        {currentChat && <RepairCenterChat rmaId={currentChat} company={companyFilter?.data?.companies!} />}
                    </div>
                    <div style={{ width: '100%', marginTop: '1.5rem' }}>
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={10}
                            totalItems={chatsRma.length}
                            onPageChange={(pageNumber: any) => handlePageChange(pageNumber)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default RmaAdminChat;
