import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Input, Select, Header, DatePicker, TextArea, Loader } from '@scuf/common';
import { FilterRepairDTO, RepairAction, RepairDTO } from 'models/hsl/general';
import { HSL } from 'resources';
import { useNavigate, useLocation } from 'react-router-dom';
import useFetch from 'helpers/hooks/useFetch';
import RepairDetails from './repairDetails';
import RepairAddPartsUsed from './repairAddPartsUsed';

const InitialRepair: RepairDTO = {
    EndDate: '',
    WorkingTime: '',
    RepairDescription: '',
    Misuse: false,
    Status: 0
};

function ExecuteRepair() {
    const { t } = useTranslation(['common', 'hsl']);
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = useLocation();
    const { selectedRepair } = state;
    const [addPart, setAddPart] = useState(false);

    const statusType = [
        { value: '0', text: t('hsl:translation.EnteredRepair') },
        { value: '1', text: t('hsl:translation.Diagnosis') },
        { value: '2', text: t('hsl:translation.Repairing') },
        { value: '3', text: t('hsl:translation.WaitingParts') },
        { value: '4', text: t('hsl:translation.SentCustomer') }
    ];

    const misuseOptions = [
        { value: true, text: t('common:actions.Yes') },
        { value: false, text: t('common:actions.No') }
    ];

    const extractIdFromUrl = (url: string): number => {
        const id = url.lastIndexOf('/');
        return Number(url.substring(id + 1));
    };

    const [data, setData] = useState(InitialRepair);

    const [, updateRepair, loadingUpdate] = useFetch<{}>({
        url: `${HSL.updateRepair}/${extractIdFromUrl(location.pathname)}`,
        method: 'PUT',
        onError: t('common:errors.Unexpected')
    });

    const [, getRepair, loadingGet] = useFetch<FilterRepairDTO, any>({
        url: HSL.getRepairs,
        method: 'POST',
        onError: 'LoadInfo'
    });

    useEffect(() => {
        if (!selectedRepair) {
            const initialSearch: FilterRepairDTO = {
                Id: extractIdFromUrl(location.pathname),
                PageIndex: 1,
                PageSize: 1
            };
            getRepair(initialSearch, resp => {
                if (resp.data) {
                    setData(resp.data?.Data[0]);
                }
            });
        } else {
            setData({
                EndDate: selectedRepair?.EndDate,
                WorkingTime: selectedRepair?.WorkingTime,
                RepairDescription: selectedRepair?.RepairDescription,
                Misuse: selectedRepair?.Misuse,
                Status: selectedRepair?.Status
            });
        }
    }, [selectedRepair]);

    const handleSave = async () => {
        const payload: RepairAction = {
            EndDate: data?.EndDate,
            WorkingTime: data.WorkingTime,
            RepairDescription: data.RepairDescription,
            Misuse: data.Misuse,
            Status: data.Status
        };
        await updateRepair(payload);
        refreshRepairData();
    };

    const refreshRepairData = () => {
        const initialSearch: FilterRepairDTO = {
            Id: extractIdFromUrl(location.pathname),
            PageIndex: 1,
            PageSize: 1
        };
        getRepair(initialSearch, resp => {
            if (resp.data) {
                setData(resp.data?.Data[0]);
            } else {
                setData({
                    EndDate: selectedRepair?.EndDate,
                    WorkingTime: selectedRepair?.WorkingTime,
                    RepairDescription: selectedRepair?.RepairDescription,
                    Misuse: selectedRepair?.Misuse,
                    Status: selectedRepair?.Status
                });
            }
        });
    };

    const handleNavigation = () => {
        navigate('/hsl');
    };

    return (
        <div className="container">
            {loadingGet ? (
                <Loader text={t('common:actions.Loading')} />
            ) : (
                <>
                    <Header title={t('hsl:translation.RepairAction')} logo={false} menu={false}></Header>

                    <Card>
                        <Card.Content>
                            <RepairDetails details={selectedRepair} />
                        </Card.Content>
                    </Card>

                    <Card>
                        <Card.Content>
                            <div className="form4Columns">
                                <DatePicker
                                    indicator="required"
                                    reserveSpace={false}
                                    type="date"
                                    label={t('hsl:translation.EndDate')}
                                    value={data?.EndDate}
                                    displayFormat={'DD/MM/YYYY'}
                                    closeOnDocumentClick={true}
                                    onChange={(value: Date) => setData({ ...data, EndDate: value.toDateString() })}
                                />
                                <Input
                                    placeholder={t('hsl:translation.WorkTime')}
                                    value={data?.WorkingTime}
                                    onChange={value => setData({ ...data, WorkingTime: value })}
                                    label={t('hsl:translation.WorkTime')}
                                />
                                <Select
                                    placeholder={t('hsl:translation.Misuse')}
                                    label={t('hsl:translation.Misuse')}
                                    options={misuseOptions}
                                    value={data?.Misuse}
                                    onChange={value => setData({ ...data, Misuse: value })}
                                />
                                <Select
                                    placeholder={t('hsl:translation.Status')}
                                    label={t('hsl:translation.Status')}
                                    value={data?.Status?.toString()}
                                    options={statusType}
                                    onChange={value => {
                                        const selectedValue = parseInt(value);
                                        if (selectedValue === 3) {
                                            alert(t('hsl:translation.ErrorCannotSelectWaitingParts'));
                                        } else {
                                            setData({ ...data, Status: selectedValue });
                                        }
                                    }}
                                    disabled={addPart}
                                />
                            </div>
                            <div className="form3Columns">
                                <TextArea
                                    fluid={true}
                                    className="textArea"
                                    label={t('hsl:translation.DescriptionRepair')}
                                    value={data?.RepairDescription}
                                    placeholder={t('hsl:translation.DescriptionRepair')}
                                    onChange={value => setData({ ...data, RepairDescription: value })}
                                />
                            </div>
                        </Card.Content>
                    </Card>

                    <Card>
                        <Card.Content>
                            <RepairAddPartsUsed
                                setAddPart={setAddPart}
                                onSubmit={() => {
                                    refreshRepairData();
                                }}
                            />
                        </Card.Content>
                    </Card>

                    <div className="button-hsl">
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            onClick={handleSave}
                            className="button-add"
                            loading={loadingUpdate}
                            disabled={!data.EndDate}
                        />
                        <Button
                            content={t('common:actions.Back')}
                            type="secondary"
                            onClick={handleNavigation}
                            className="button-back"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default ExecuteRepair;
