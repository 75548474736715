import { Button, Card, DatePicker, FileDrop, Icon, Input, Modal, Popup, Select, TextArea } from '@scuf/common';
import { LabelDescription } from 'components';
import Alert from 'components/alert';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { attendanceTime, materialType } from 'models';
import { ErrorsMsgModel } from 'models/repaircenter/detailsRma';
import { ModelEOS } from 'models/repaircenter/eosModel';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
    cnpjMask,
    companyAtom,
    COMPANY_MANAGER,
    companyDtoAtom,
    CreatNewRma,
    EosListAtom,
    moneyMask,
    notificationAtom,
    REPAIR_CENTER
} from 'resources';
import { getAdrresByCEP } from 'resources/api/externalApi';
import { getModelsEOS } from 'resources/api/repaircenter_api/searchEOS';
import { downloadFile } from 'resources/api/';
import { orderCartFileAtom } from 'resources/atoms/spareParts';
import usePreventClickjacking from 'helpers/hooks/usePreventingClickjacking';

function DetailsRma() {
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const { t } = useTranslation(['repaircenter', 'common', 'user']);
    const navigate = useNavigate();
    const [loadingCEP, setLoadingCEP] = useState<boolean>(false);
    const [errorsMsg, setErrorsMsg] = useState<ErrorsMsgModel>(new ErrorsMsgModel());
    let hasEmptyField: any = {};
    const [details, setDetails] = useRecoilState(CreatNewRma);
    const [submit, setSubmit] = useState(false);
    const [serviceTypes, getServiceTypes] = useFetch({ url: REPAIR_CENTER.getServiceTypes });
    const { user: userDetails } = useUser();
    const [eosList, setEosList] = useRecoilState<ModelEOS[]>(EosListAtom);
    const id = userDetails?.COMPANY;
    const [company, setCompany] = useRecoilState(companyAtom);
    const [loadingModel, setLoadingModel] = useState<boolean>(false);
    const [modalState, setModalState] = useState<boolean>(false);
    const [file, setFile] = useRecoilState<File | null>(orderCartFileAtom);
    const uniqueModels = new Set();
    let foundN = false;

    useEffect(() => {
        getServiceTypes();
        Company();
        getEOSModels();
    }, []);

    const requiredField = (field: any): string => {
        hasEmptyField[field] = !field;
        if (!field && submit) {
            return t('common:rules.RequiredField');
        }
        return '';
    };

    const optionsYesNoop = [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' }
    ];

    const [responseCompany, getCompanyById] = useFetch<{ id: number | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: 'LoadInfo'
    });

    const Company = async () => {
        await getCompanyById({ id }, current => {
            setCompany(current.data!);
            setDetails({ ...details, cnpjCompany: current?.data?.cnpj! });
        });
    };

    const loadCEPInfo = async (cep: any) => {
        setLoadingCEP(true);
        let resp: { status: number; data: any } = await getAdrresByCEP(cep);
        if (resp.status !== 200) {
            setErrorsMsg({ ...errorsMsg, cep: t('common:actions.TryAgain') });
        } else {
            setDetails({
                ...details,
                address: {
                    zipCode: resp.data.cep,
                    city: resp.data.localidade,
                    state: resp.data.uf,
                    street: resp.data.logradouro,
                    neighborhood: resp.data.bairro
                }
            });
            setErrorsMsg(new ErrorsMsgModel());
        }
        setLoadingCEP(false);
    };

    const nextPage = async () => {
        setSubmit(true);
        if (Object.values(hasEmptyField).includes(true)) return;
        else navigate('summary');
    };

    const buildSelect = (list: any[] | undefined) => {
        if (!list) return [];
        let listResult = [];
        for (var i in list) {
            let d: any = { value: list[i].id, text: list[i].name, problemTypes: list[i].problemTypes };
            listResult.push(d);
        }
        return listResult;
    };

    const getEOSModels = async () => {
        setLoadingModel(true);
        let resp = await getModelsEOS();
        if (resp.status != 200) {
            setNotification(old => ({ ...old, message: 'NoResult', type: 'information' }));
            setLoading(false);
        } else {
            const priorityOrder = ['N1', 'N2', 'N3'];

            const sortedEosList = [...resp.data].sort((a, b) => {
                return priorityOrder.indexOf(b.blockingLevel) - priorityOrder.indexOf(a.blockingLevel);
            });
            setEosList(sortedEosList);
        }

        setLoadingModel(false);
    };

    const download = async (fileName: string) => {
        await downloadFile(fileName);
    };

    const renderModal = () => {
        return (
            <Modal
                className="modal"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <Card>
                    <FileDrop
                        placeholder={t('common:actions.Draganddropfileshere')}
                        resetButtonText={t('common:actions.Delete')}
                        onReset={() => {
                            setFile(null);
                            setDetails({ ...details, nfeFile: undefined });
                        }}
                        acceptedTypes={['.xml', '.pdf']}
                        buttonText={t('common:actions.BrowseFiles')}
                        onAccepted={file => {
                            setFile(file[0]);
                            setDetails({ ...details, nfeFile: file[0].name });
                        }}
                    />
                    <br />
                    <div className="buttonModal">
                        <Button
                            content={file ? t('common:actions.Finish') : t('common:actions.Close')}
                            onClick={() => setModalState(false)}
                            size="medium"
                            textTransform={true}
                        />
                    </div>
                </Card>
            </Modal>
        );
    };

    usePreventClickjacking();

    return (
        <>
            {renderModal()}
            <div>
                <div className="container">
                    <span>
                        <div>
                            <h1>
                                <Icon root="common" name="entity-details" size="large" /> {t('repaircenterdash:Details')}
                            </h1>
                            {(details?.serviceTypeId === 5 || details?.serviceTypeId === 6) &&
                                details?.rmaItems?.map(item => (
                                    <div key={item.id}>
                                        {eosList?.map(eos => {
                                            if (
                                                item?.model === eos?.model &&
                                                !uniqueModels.has(item?.model) &&
                                                !uniqueModels.has(item?.rmaItemsEquipment?.problemType?.name + item?.model)
                                            ) {
                                                uniqueModels.add(item?.model);
                                                if (eos?.blockingLevel === 'N3') {
                                                    foundN = true;
                                                    return (
                                                        <Alert
                                                            className="space"
                                                            text={
                                                                t('common:translation.Model') +
                                                                ': ' +
                                                                eos?.model +
                                                                ' ' +
                                                                t('manage_eos.translation.N3Blockade')
                                                            }
                                                        />
                                                    );
                                                } else if (eos?.blockingLevel === 'N1') {
                                                    return (
                                                        <Alert
                                                            className="space"
                                                            text={
                                                                t('common:translation.Model') +
                                                                ': ' +
                                                                eos?.model +
                                                                ' ' +
                                                                t('manage_eos.translation.N1Blockade')
                                                            }
                                                        />
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </div>
                                ))}

                            <div className="form3Columns">
                                <Select
                                    indicator={'required'}
                                    label={t('common:translation.RMAType')}
                                    error={requiredField(details?.serviceTypeId)}
                                    placeholder={t('common:translation.RMAType')}
                                    options={buildSelect(serviceTypes?.data)}
                                    value={details?.serviceTypeId}
                                    onChange={(value: any) => {
                                        setDetails({
                                            ...details,
                                            serviceTypeId: value,
                                            shipping: {
                                                ...details?.shipping,
                                                typeId: value ? 3 : 2,
                                                requestedByCustomer: !(value === 5 || value === 6) ? true : false
                                            }
                                        });
                                    }}
                                />
                                <Popup
                                    className="popup-theme-wrap"
                                    element={<Icon root="common" name="badge-info" size="large" />}
                                    on="click"
                                >
                                    <Card>
                                        <Card.Header title={t('common:translation.MaintenanceContract')} />
                                        <Card.Content>{t('translation.maintenance')}</Card.Content>
                                        <Card.Header title={t('common:translation.FactoryWarranty')} />
                                        <Card.Content>{t('translation.factorywarranty')}</Card.Content>
                                        <Card.Header title={t('common:translation.ServiceWarranty')} />
                                        <Card.Content>{t('translation.servicewarranty')}</Card.Content>
                                        <Card.Header title={t('common:translation.ExchangeInWarranty')} />
                                        <Card.Content>{t('translation.exchangewarranty')}</Card.Content>
                                        <Card.Header title={t('common:translation.Others')} />
                                        <Card.Content>{t('translation.others')}</Card.Content>
                                    </Card>
                                </Popup>
                            </div>
                            <div className="form3Columns">
                                <Input
                                    label={t('translation.CNPJintheNote')}
                                    indicator="required"
                                    helperText="CNPJ"
                                    value={details?.cnpjNota}
                                    error={
                                        requiredField(details?.cnpjNota) ||
                                        (details?.cnpjNota && details.cnpjNota.length < 18
                                            ? requiredField(undefined) && t('notification:information.CNPJSize')
                                            : undefined)
                                    }
                                    onChange={(value: string) => {
                                        setDetails({
                                            ...details,
                                            cnpjNota: cnpjMask(value),
                                            cnpjCompany: responseCompany?.data?.cnpj!
                                        });
                                    }}
                                />
                                <Input
                                    label={t('translation.RemittanceNF-enumber')}
                                    helperText={t('translation.RemittanceNF-enumberText')}
                                    type="text"
                                    indicator="required"
                                    value={details?.invoice}
                                    error={requiredField(details?.invoice)}
                                    onChange={(value: any) => {
                                        setDetails({ ...details, invoice: value });
                                    }}
                                />
                                <Popup
                                    className="popup-theme-wrap"
                                    element={<Icon root="common" name="badge-info" size="large" />}
                                    on="click"
                                >
                                    <div className="form3Columns">
                                        <div
                                            className="no-padding blue-text"
                                            style={{
                                                margin: '12px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => {
                                                download('DeclaraçãoIsençãodeInscriçãoEstadual.xls');
                                            }}
                                        >
                                            <LabelDescription title={t('common:translation.Oritention1')} />
                                        </div>
                                        <div
                                            className="no-padding blue-text"
                                            style={{
                                                margin: '12px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                textDecoration: 'underline'
                                            }}
                                            onClick={() => {
                                                download('ProcedimentoEmissãoNF.pdf');
                                            }}
                                        >
                                            <LabelDescription title={t('common:translation.Oritention2')} />
                                        </div>
                                    </div>
                                </Popup>
                                <div className="container-button1">
                                    <Button
                                        type="inline-secondary"
                                        icon="attachment"
                                        onClick={() => setModalState(true)}
                                        content={t('common:actions.AddNfe')}
                                        iconPosition="right"
                                    />
                                    {file && (
                                        <LabelDescription className="form3Columns" title={t('common:translation.File')}>
                                            <>
                                                {file?.name}{' '}
                                                <Icon
                                                    name="delete"
                                                    onClick={() => {
                                                        setFile(null);
                                                        setDetails({ ...details, nfeFile: undefined });
                                                    }}
                                                ></Icon>
                                            </>
                                        </LabelDescription>
                                    )}
                                </div>
                                <Input
                                    label={t('translation.TotalRemittanceNF-e')}
                                    helperText={t('translation.TotalRemittanceNF-eText')}
                                    indicator="required"
                                    error={requiredField(details?.valorInvoice)}
                                    value={moneyMask(details?.valorInvoice?.toString() ?? '')}
                                    onChange={(value: any) => {
                                        const numericValue = value.replace(/[^\d.]/g, '');
                                        setDetails({ ...details, valorInvoice: numericValue });
                                    }}
                                />

                                <Select
                                    options={optionsYesNoop}
                                    label={t('translation.RequestTransport')}
                                    indicator="required"
                                    value={details?.shipping?.requestedByCustomer}
                                    disabled={details?.serviceTypeId === 5 || details?.serviceTypeId === 6}
                                    error={requiredField(details?.shipping?.typeId)}
                                    onChange={(value: boolean) => {
                                        // Type ID nesse caso é SOLICITADO (3) OU CLIENTE NÃO SOLICITOU (2)
                                        // Os valores são do BD
                                        setDetails(old => ({
                                            ...old,
                                            shipping: {
                                                ...old?.shipping,
                                                typeId: value ? 3 : 2,
                                                requestedByCustomer: value
                                            }
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </span>
                    <br />
                    <div>
                        <h1>
                            <Icon root="common" name="shipping" size="large" /> {t('repaircenter:rma.order.Shipping')}
                        </h1>
                        <div className="form3Columns">
                            <Input
                                label={t('translation.ResponsibleUser')}
                                value={details?.package?.name}
                                error={requiredField(details?.package?.name)}
                                indicator="required"
                                onChange={value => setDetails(old => ({ ...old, package: { ...old?.package, name: value } }))}
                            ></Input>
                            <Input
                                label={t('rma.budget.Approval.Phone')}
                                value={details?.package?.phone}
                                error={requiredField(details?.package?.phone)}
                                indicator="required"
                                onChange={value => setDetails(old => ({ ...old, package: { ...old.package, phone: value } }))}
                            ></Input>
                            <Select
                                options={attendanceTime}
                                label={t('rma.shipping.BusinessHour')}
                                error={requiredField(details?.package?.time)}
                                indicator="required"
                                value={details?.package?.time}
                                onChange={value => setDetails(old => ({ ...old, package: { ...old.package, time: value } }))}
                            ></Select>
                            <Select
                                options={materialType}
                                label={t('rma.shipping.MaterialType')}
                                indicator="required"
                                value={details?.package?.materialType}
                                error={requiredField(details?.package?.materialType)}
                                onChange={value =>
                                    setDetails(old => ({ ...old, package: { ...old.package, materialType: value } }))
                                }
                            ></Select>
                            <Input
                                label={t('rma.shipping.Height')}
                                indicator="required"
                                value={details?.package?.height}
                                error={requiredField(details?.package?.height)}
                                onChange={value => setDetails(old => ({ ...old, package: { ...old.package, height: value } }))}
                            ></Input>
                            <Input
                                label={t('rma.shipping.Width')}
                                indicator="required"
                                value={details?.package?.width}
                                error={requiredField(details?.package?.width)}
                                onChange={(value: any) =>
                                    setDetails(old => ({ ...old, package: { ...old.package, width: value } }))
                                }
                            ></Input>
                            <Input
                                label={t('rma.shipping.Length')}
                                indicator="required"
                                value={details?.package?.length}
                                error={requiredField(details?.package?.length)}
                                onChange={value => setDetails(old => ({ ...old, package: { ...old.package, length: value } }))}
                            ></Input>
                            <Input
                                label={t('rma.shipping.Weight') + ' (Kg)'}
                                indicator="required"
                                value={details?.package?.weight}
                                error={requiredField(details?.package?.weight)}
                                onChange={value => setDetails(old => ({ ...old, package: { ...old.package, weight: value } }))}
                            ></Input>
                            <div>
                                <DatePicker
                                    displayFormat="DD/MM/YYYY"
                                    label={t('rma.shipping.ShipmentSentDate')}
                                    error={requiredField(details?.package?.date)}
                                    indicator="required"
                                    value={details?.package?.date}
                                    onChange={(value: any) => {
                                        setDetails(old => ({ ...old, package: { ...old?.package, date: value } }));
                                    }}
                                ></DatePicker>
                            </div>
                        </div>
                    </div>
                    <span>
                        <div>
                            <h1>
                                <Icon root="common" name="home" size="large" /> {t('common:translation.Address')}
                            </h1>
                            <div className="form3Columns">
                                <>
                                    <Input
                                        placeholder="CEP"
                                        label="CEP"
                                        type="text"
                                        iconPosition="right"
                                        indicator="required"
                                        value={details?.address?.zipCode}
                                        onBlur={async () => {
                                            await loadCEPInfo(details?.address?.zipCode);
                                        }}
                                        error={requiredField(details?.address?.zipCode)}
                                        icon={<Icon root="common" name="search" />}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                address: {
                                                    ...details.address,
                                                    zipCode: value
                                                }
                                            })
                                        }
                                    />
                                    {loadingCEP && <Icon name="refresh" className="loadingIcon" loading={true} size="large" />}{' '}
                                </>
                                <div className="form3Columns">
                                    <Input
                                        placeholder={t('user:address.State')}
                                        label={t('user:address.State')}
                                        indicator="required"
                                        type="text"
                                        value={details?.address?.state}
                                        error={requiredField(details?.address?.state)}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                address: {
                                                    ...details.address,
                                                    state: value
                                                }
                                            })
                                        }
                                    />
                                    <Input
                                        placeholder={t('user:address.City')}
                                        label={t('user:address.City')}
                                        indicator="required"
                                        type="text"
                                        value={details?.address?.city}
                                        error={requiredField(details?.address?.city)}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                address: {
                                                    ...details.address,
                                                    city: value
                                                }
                                            })
                                        }
                                    />
                                    <Input
                                        placeholder={t('user:address.Neighborhood')}
                                        label={t('user:address.Neighborhood')}
                                        indicator="required"
                                        type="text"
                                        value={details?.address?.neighborhood}
                                        error={requiredField(details?.address?.neighborhood)}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                address: {
                                                    ...details.address,
                                                    neighborhood: value
                                                }
                                            })
                                        }
                                    />
                                    <br />
                                    <Input
                                        placeholder={t('user:address.Street')}
                                        label={t('user:address.Street')}
                                        indicator="required"
                                        type="text"
                                        value={details?.address?.street}
                                        error={requiredField(details?.address?.street)}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                address: {
                                                    ...details.address,
                                                    street: value
                                                }
                                            })
                                        }
                                    />
                                    <Input
                                        placeholder={t('user:address.Number')}
                                        label={t('user:address.Number')}
                                        indicator="required"
                                        type="number"
                                        min={0}
                                        value={details?.addressNumber}
                                        error={requiredField(details?.addressNumber)}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                addressNumber: value
                                            })
                                        }
                                    />
                                    <Input
                                        placeholder={t('user:address.Complement')}
                                        label={t('user:address.Complement')}
                                        type="text"
                                        value={details?.addressComplement}
                                        onChange={value =>
                                            setDetails({
                                                ...details,
                                                addressComplement: value
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                    <br />
                    <br />
                    <span>
                        <div>
                            <h1>
                                <Icon root="common" name="edit" size="large" /> {t('common:translation.Comments')}
                            </h1>
                            <div className="form3Columns">
                                <TextArea
                                    fluid={true}
                                    className="textArea"
                                    helperText={t('translation.DescriptionAcessory')}
                                    label={t('common:translation.Accessory')}
                                    value={details?.acessorios}
                                    placeholder={t('common:translation.Accessory')}
                                    onChange={(value: any) => {
                                        setDetails({ ...details, acessorios: value });
                                    }}
                                />
                                <TextArea
                                    fluid={true}
                                    className="textArea"
                                    helperText={t('translation.DescriptionComment')}
                                    label={t('common:translation.Comments')}
                                    value={details?.observacao}
                                    placeholder={t('common:translation.Comments')}
                                    onChange={(value: any) => {
                                        setDetails({ ...details, observacao: value });
                                    }}
                                />

                                <div className="buttonsSearch">
                                    <Button
                                        content={t('common:actions.Cancel')}
                                        size="small"
                                        textTransform={true}
                                        type="secondary"
                                        className="buttonCancelSecondary"
                                        onClick={() => navigate('/repair-center')}
                                    />
                                    <Button
                                        content={t('common:actions.Continue')}
                                        size="small"
                                        onClick={() => nextPage()}
                                        disabled={foundN || details.nfeFile === undefined}
                                    />
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
}
export default DetailsRma;
