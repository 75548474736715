import { Button, Icon, Modal, Table } from '@scuf/common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { USER_MANAGER, companyDtoAtom } from 'resources';
import { CompanyDTO } from 'models/userManager/addCustomer';
import Alert from 'components/alert';
import { UserDTO } from 'models/user/sso';
import useFetch from 'helpers/hooks/useFetch';

const UsersInfo: React.FC = () => {
    const [company, setCompany] = useRecoilState<CompanyDTO>(companyDtoAtom);
    const navigate = useNavigate();
    const { t } = useTranslation(['usermanager', 'common']);
    const [, removeUser, loadingAction] = useFetch<{ ssoId: string }, boolean>({
        url: USER_MANAGER.removeUser,
        onError: 'Removed',
        onSuccess: 'Removed'
    });
    const handleDelete = async (ssoId: string): Promise<void> => {
        await removeUser({ ssoId }, _ =>
            setCompany(old => ({ ...old, users: old.users?.filter((e: UserDTO) => e.ssoId !== ssoId) }))
        );
    };

    const [open, setOpen] = useState<string | null>(null);

    const ConfirmModal: React.FC = () => {
        return (
            <Modal open={!!open} size="small">
                <h2>{t('common:actions.ConfirmDelete')}</h2>
                <div className="buttonsSearch">
                    <Button type="secondary" content={t('common:actions.No')} onClick={() => setOpen(null)} />
                    <Button
                        content={t('common:actions.Yes')}
                        onClick={() => {
                            handleDelete(open!);
                            setOpen(null);
                        }}
                    />
                </div>
            </Modal>
        );
    };

    return (
        <>
            {<ConfirmModal />}
            {(company?.users && company?.users?.length > 0 && (
                <>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>{t('ID')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('user:info.Name')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('Email')}</Table.HeaderCell>

                            <Table.HeaderCell>{t('common:actions.Action')}</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {company.users?.map((user: UserDTO, index: number) => (
                                <Table.Row key={index}>
                                    <Table.Cell
                                        className="clickable"
                                        onClick={() => navigate('../customer', { state: { id: user.ssoId } })}
                                    >
                                        {user.ssoId}
                                    </Table.Cell>
                                    <Table.Cell>{user.name}</Table.Cell>
                                    <Table.Cell>{user.email}</Table.Cell>

                                    <Table.Cell>
                                        {(loadingAction && <Icon name="refresh" loading={true} />) || (
                                            <Icon
                                                className="clickable"
                                                size="large"
                                                name="delete"
                                                onClick={() => setOpen(user.ssoId)}
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </>
            )) || <Alert text={t('user:NoUserRegistered')} />}
        </>
    );
};

export default UsersInfo;
