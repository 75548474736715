import { Tab } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { COMPANY_MANAGER, HSL, notificationAtom } from 'resources';
import { useSetRecoilState } from 'recoil';
import { hslCountriesAtom, hslPartnersAtom, hslPartsAtom } from 'resources/atoms/hslAtom';
import { useEffect } from 'react';
import { CompanyDTO } from 'models/userManager/addCustomer';
import ProductSearch from './products/productSearch';
import InventorySearch from './inventory/inventorySearch';
import ContractSearch from './contracts/contractSearch';
import RepairSearch from './repair/repairSearch';
import QuotationSearch from './quotation/quotationSearch';
import useUser from 'helpers/hooks/useUser';
import useFetch from 'helpers/hooks/useFetch';
import 'styles/main.scss';
import 'styles/repaircenter.scss';

const SUPERADMIN = '1';
const ADMIN = '2';

function HslPage() {
    const { t } = useTranslation(['hsl']);
    const setNotification = useSetRecoilState(notificationAtom);
    const setPartners = useSetRecoilState(hslPartnersAtom);
    const setCountries = useSetRecoilState(hslCountriesAtom);
    const setParts = useSetRecoilState(hslPartsAtom);
    const { user: userDetails } = useUser();

    const [, getPartners] = useFetch({
        url: COMPANY_MANAGER.getHslPartners,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        getAllPartners();
        getAllParts();
    }, []);

    const getAllPartners = () => {
        getPartners(null, resp => {
            if (resp.data) {
                const partnersData = resp.data.map((partner: CompanyDTO) => ({
                    value: partner.name,
                    text: partner.name
                }));
                setPartners(partnersData);

                const uniqueCountries = Array.from(new Set(resp.data.map((partner: CompanyDTO) => String(partner.country))));
                const countriesData = uniqueCountries.map(country => ({
                    value: country as string,
                    text: country as string
                }));
                setCountries(countriesData);
            }
        });
    };

    const getAllParts = () => {
        getParts(null, (current: any) => {
            if (current.data.length) {
                setParts(current.data);
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
            }
        });
    };

    return (
        <div className="container">
            <Tab className="tab-with-divider">
                <Tab.Pane title={t('translation.Repair')}>
                    <RepairSearch />
                </Tab.Pane>
                <Tab.Pane title={t('translation.Contracts')}>
                    <ContractSearch />
                </Tab.Pane>
                <Tab.Pane title={t('translation.Inventory')}>
                    <InventorySearch />
                </Tab.Pane>
                {userDetails?.ROLE === SUPERADMIN || userDetails?.ROLE === ADMIN ? (
                    <Tab.Pane title={t('translation.ProductManagement')}>
                        <ProductSearch />
                    </Tab.Pane>
                ) : (
                    []
                )}
                <Tab.Pane title={t('translation.Quotation')}>
                    <QuotationSearch />
                </Tab.Pane>
            </Tab>
        </div>
    );
}

export default HslPage;
