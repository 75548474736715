import { Button, Card, FileDrop, Icon, Loader, Modal, Table } from '@scuf/common';
import Alert from 'components/alert';
import { OrderDTO, OrderFileDTO } from 'models/spareParts/orderById';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';
import { downloadOrderFile, uploadFileWarranty, deleteFile } from 'resources/api/spareParts_api/File';
import { orderAtom } from 'resources/atoms/spareParts';
import Budget from '../acordion/processing/components/budget';
import { formatDate } from 'helpers';

function SparePartsFile() {
    const { t } = useTranslation(['spareparts', 'common']);
    const ld = require('lodash');
    const [orderData, setorderData] = useRecoilState<OrderDTO>(orderAtom);
    const [modalState, setModalState] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const setNotification = useSetRecoilState(notificationAtom);
    const [data, setData] = useState<{ file: any | null }>({ file: null });
    const [fileToSend, setFileToSend] = useState<{
        formFile: any;
        orderId: any;
        addedByUserId: any;
        type: string;
    } | null>(null);

    const orderFiles = orderData?.orderFiles?.filter(x => x.type.toLowerCase() !== 'budget');

    function renderModal(): JSX.Element {
        return (
            <Modal
                className="modalW600"
                onClose={() => setModalState(false)}
                size="small"
                open={modalState}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
            >
                <div className="modalConsultWaranty">
                    <Modal.Header>
                        <div className="modalHeader">
                            <h2>{t('common:translation.File').toUpperCase()}</h2>
                        </div>
                    </Modal.Header>
                    <div className="container-selects">
                        <FileDrop
                            placeholder={t('common:actions.Draganddropfileshere')}
                            resetButtonText={t('common:actions.Delete')}
                            onAccepted={file => {
                                setFileToSend(old => ({
                                    ...old!,
                                    orderId: orderData.id,
                                    formFile: file[0],
                                    addedByUserId: orderData?.userId
                                }));
                            }}
                            onReset={() => {
                                setData(old => ({ ...old, file: null }));
                            }}
                        />
                    </div>
                    <Card.Footer>
                        <div className="buttonModal">
                            <Button
                                content={t('common:actions.Close')}
                                type="secondary"
                                onClick={() => setModalState(false)}
                                size="medium"
                                textTransform={true}
                            />
                            <Button
                                type="primary"
                                icon="slidercontrols-plus"
                                onClick={() => {
                                    postFile();
                                }}
                                content={t('common:actions.Create')}
                                iconPosition="right"
                                loading={loading}
                            />
                        </div>
                    </Card.Footer>
                </div>
            </Modal>
        );
    }

    async function postFile() {
        if (fileToSend != undefined) {
            let filename: string[] = fileToSend.formFile.name.split('.');
            let posfix = `_${Date.parse(Date())}.${filename[filename.length - 1]}`;
            let prefix: string = '';
            for (let i = 0; i < filename.length - 1; i++) prefix += filename[i];
            let newName = prefix + posfix;
            let f: File = new File([fileToSend.formFile], newName, { type: fileToSend.type });
            let resp = await uploadFileWarranty(f, fileToSend.orderId, fileToSend.addedByUserId, 'OTHERS');
            if (resp?.error ?? false) setNotification(old => ({ ...old, message: resp?.message, type: 'important' }));
            else {
                setNotification(old => ({ ...old, message: '', type: 'success' }));
                let newOrderFiles: OrderFileDTO[] = ld.cloneDeep(orderData.orderFiles);
                newOrderFiles.push(resp.data);
                setorderData(old => ({ ...old, orderFiles: newOrderFiles }));
            }

            setModalState(false);
        } else console.log('Não tem o que mandar');
    }

    async function removeFile(fileId: number) {
        let resp = await deleteFile(fileId);

        if (resp.status != 200) {
            setNotification(old => ({ ...old, message: 'Removed' }));
        } else {
            setorderData(old => ({
                ...old,
                orderFiles: [...old.orderFiles.filter(item => item.id !== fileId)]
            }));
            setNotification(old => ({ ...old, message: 'Removed', type: 'success' }));
        }
    }

    return (
        <div>
            {renderModal()}
            {loading && <Loader text={t('common:actions.Loading')} />}
            <div>
                <div className="contentPageContainer">
                    <div className="wrap-content">
                        <h1>{t('common:translation.File')}</h1>
                        <br />
                        {orderFiles.length > 0 || orderData.budget?.file.id != null ? (
                            <div className="chat-display">
                                <div>
                                    <Budget></Budget>
                                    <h3>{t('common:translation.Others')}</h3>
                                    <Table>
                                        <Table.Header>
                                            <Table.HeaderCell content={t('common:translation.File')} />
                                            <Table.HeaderCell content={t('common:translation.About')} />
                                            {/* <Table.HeaderCell content={t('common:translation.Author')} /> */}
                                            <Table.HeaderCell content={t('common:translation.Date')} />
                                            <Table.HeaderCell content={t('common:translation.Download')} />
                                            <Table.HeaderCell content={t('common:actions.Delete')} />
                                        </Table.Header>
                                        <Table.Body>
                                            {orderData &&
                                                orderData.orderFiles
                                                    ?.filter(x => x.type.toLowerCase() !== 'budget')
                                                    .map((item: OrderFileDTO) => (
                                                        <Table.Row>
                                                            <Table.Cell>{item?.fileName} </Table.Cell>
                                                            <Table.Cell>{item?.type} </Table.Cell>
                                                            <Table.Cell>{formatDate(item?.createdAt)} </Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    <Icon
                                                                        className="clickable"
                                                                        root="common"
                                                                        name="file-download"
                                                                        size="medium"
                                                                        onClick={async () => {
                                                                            await downloadOrderFile(item.id, item.fileName);
                                                                        }}
                                                                    />
                                                                }
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    <Icon
                                                                        className="clickable"
                                                                        root="common"
                                                                        name="delete"
                                                                        size="medium"
                                                                        loading={true}
                                                                        onClick={() => {
                                                                            removeFile(item?.id);
                                                                        }}
                                                                    />
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                        </Table.Body>
                                    </Table>
                                    <br />
                                    <Card.Content className="buttonsSearch">
                                        <Button
                                            type="primary"
                                            icon="slidercontrols-plus"
                                            onClick={() => setModalState(true)}
                                            content={t('common:actions.Add')}
                                            iconPosition="right"
                                        />
                                    </Card.Content>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <Alert text={t('common:empty.Nofilesadded')} />
                                <br />
                                <Card.Content className="buttonsSearch">
                                    <Button
                                        type="primary"
                                        icon="slidercontrols-plus"
                                        onClick={() => setModalState(true)}
                                        content={t('common:actions.Add')}
                                        iconPosition="right"
                                    />
                                </Card.Content>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SparePartsFile;
