import { Badge, Button, Modal } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import useFetch from 'helpers/hooks/useFetch';
import { StatusOption } from 'models/hsl/general';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HSL } from 'resources';

interface ModalProps {
    open: boolean;
    repairId: number;
    onClose: () => void;
}

const ModalDetailsLog: FC<ModalProps> = ({ open, repairId, onClose }) => {
    const { t } = useTranslation(['common', 'hsl']);

    const [repairs, getLogRepairs, loadingLog] = useFetch<{ repairId: number }>({
        url: HSL.getLogRepairsByRepairId,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const statusOptions: StatusOption[] = [
        { value: '0', text: t('hsl:translation.EnteredRepair'), color: 'yellow' },
        { value: '1', text: t('hsl:translation.WaitingParts'), color: 'red' },
        { value: '2', text: t('hsl:translation.Repaired'), color: 'green' },
        { value: '3', text: t('hsl:translation.SentCustomer'), color: 'blue' }
    ];

    useEffect(() => {
        if (repairId !== 0 && open) {
            getLogRepairs({ repairId: repairId });
        }
    }, [repairId, open]);

    return (
        <Modal size="large" open={open} closeIcon={true} onClose={() => onClose()} closeOnDimmerClick={false}>
            <Modal.Header>
                <h1>{t('hsl:translation.ChangeLog')}</h1>
            </Modal.Header>

            <Modal.Content>
                <DataTable
                    columnResizeMode="fit"
                    data={repairs?.data ?? []}
                    scrollable={true}
                    scrollHeight="200%"
                    resizableColumns={true}
                    loading={loadingLog}
                >
                    <DataTable.Column
                        field="Status"
                        align="center"
                        header={'Status'}
                        sortable={true}
                        renderer={rowData => {
                            const statusOption = statusOptions.find(option => option.value === rowData.value.toString());
                            const badgeColor = statusOption?.color ?? 'grey';

                            return <Badge color={badgeColor}>{statusOption?.text ?? '-'}</Badge>;
                        }}
                    />
                    <DataTable.Column field="ResponsibleUser" align="center" header={t('hsl:translation.User')} sortable={true} />
                    <DataTable.Column field="CreatedAt" align="center" header={t('common:translation.Date')} sortable={true} />
                </DataTable>
            </Modal.Content>

            <Modal.Footer>
                <Button type="primary" content={t('common:translation.Confirm')} onClick={() => onClose()} />
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDetailsLog;
