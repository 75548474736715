import { Badge, Button, Card, Divider, Input, Select, Table } from '@scuf/common';
import { deliveryTimeOptions, listAproved } from 'models/spareParts';
import { OrderDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import Budget from './budget';
import { processOrder } from 'resources/api/spareParts_api/processOrder';
import { SPARE_PARTS, notificationAtom } from 'resources';
import useUser from 'helpers/hooks/useUser';
import { UserRole } from 'models/user/sso';
import { RoleComponent } from 'components/roleComponent';
import useFetch from 'helpers/hooks/useFetch';

const Price: React.FC = () => {
    const [orderData, setorderData] = useRecoilState<OrderDTO>(orderAtom);
    const [orderItems, setOrderItems] = useState<OrderItemsDTO[]>();
    const { t } = useTranslation(['spareparts', 'common']);
    const [loading, setLoading] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [typeAprovReject, setTypeAproceReject] = useState<any>();
    const setNotification = useSetRecoilState(notificationAtom);
    useEffect(() => {
        setOrderItems(orderData?.orderItems ?? []);
    }, []);

    const { user } = useUser();

    const [, editOrder, loadingEdit] = useFetch<OrderDTO>({
        url: SPARE_PARTS.editOrder,
        method: 'POST',
        onError: 'Change',
        onSuccess: 'Change'
    });


    const process = async () => {
        setLoading(true);
        const aux = { ...orderData, orderItems: [...orderItems!], roleId: parseInt(user?.ROLE!), };

        const resp = await processOrder(aux);
        if (resp.error) {
            setNotification(old => ({ ...old, message: 'Process' }));
        } else {
            setorderData(resp.data);
            setNotification(old => ({ ...old, message: 'Process', type: 'success' }));
        }
        setLoading(false);
    };

    const showResultBadge = (orderItems?: OrderItemsDTO[], itemId?: number) => {

        let label = '';
        let color: any = 'yellow';
        const admin = orderItems?.find(e => e.id === itemId && e.adminApproval !== undefined)?.adminApproval ?? null;
        const customer = orderItems?.find(e => e.id === itemId && e.customerApproval !== undefined)?.customerApproval ?? null;

        if (customer === null && admin === null) {
            label = 'common:status.Processing';
        } else if (customer === true && admin === null) {
            label = 'common:status.ApprovedByCustomer';
            color = 'green';
        } else if (customer === false && (admin || admin === null)) {
            label = 'common:status.RejectedByCustomer';
            color = 'red';
        } else if ((customer || customer === null) && admin === false) {
            label = 'common:status.RejectedByCompany';
            color = 'red';
        } else if ((customer && admin) || (admin && (orderData.typeId == 3 || orderData.typeId == 4))) {
            label = 'common:status.Approved';
            color = 'green';
        } else if (customer === false && admin === false) {
            label = 'common:status.Repproved';
            color = 'red';
        }
        else if (admin === true || customer === null) {
            label = 'Aguardando Cliente';
            color = 'green';
        }
        return <Badge color={color} content={t(label)} />;
    };
    return (
        <>
            <div className="container-selects">
                <Budget></Budget>
                <div className="div-datatable">
                    <div>
                        <h3>{t('translation.OrderItems') + '(' + orderData?.orderItems?.length + ')'}</h3>
                        <Divider />
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.Item')} />
                                {orderData?.typeId != 4 ?
                                    <Table.HeaderCell content={t('spareparts:translation.Deadline')} /> : <Table.HeaderCell content={t('sparepats:productinfots.order_data.SerialNumberPart')} />}
                                {orderData?.typeId != 4 ?
                                    <Table.HeaderCell content={t('spareparts:translation.DeadlinePersonlized')} /> : <Table.HeaderCell content={t('spareparts:productinfots.order_data.SerialNumberEqp')} />}
                                <Table.HeaderCell content={t('spareparts:translation.HoneywellAprroval')} />
                                <Table.HeaderCell content={t('spareparts:translation.CustomerAprroval')} />
                                <Table.HeaderCell content={t('common:translation.Result')} />
                            </Table.Header>
                            <Table.Body>
                                {orderData?.orderItems?.map((item: OrderItemsDTO, index: number) => (
                                    <Table.Row key={index}>
                                        <Table.Cell width={3}>
                                            {item?.partNumber} {' / '}
                                            {item?.description}
                                        </Table.Cell>
                                        {orderData?.typeId != 4 ?
                                            <Table.Cell width={3}>
                                                <Select
                                                    className="select-type"
                                                    disabled={
                                                        ([UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) || orderData?.statusId === 3) ||
                                                        orderData?.statusId !== 2
                                                    }
                                                    options={deliveryTimeOptions}
                                                    placeholder={item?.deadline}
                                                    onChange={value => {


                                                        setOrderItems(old =>
                                                            [...old!].map(e => {
                                                                if (e.id == item.id) return { ...e, deadline: value };
                                                                else return e;
                                                            })
                                                        );
                                                    }}
                                                />

                                            </Table.Cell>
                                            : <Table.Cell width={3}>{item?.serialNumberPart}</Table.Cell>

                                        }
                                        <Table.Cell width={3}>
                                            {orderData?.typeId != 4 ?
                                                <Input
                                                    disabled={[UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) || orderData?.statusId === 3 ||
                                                        orderData?.statusId !== 2}
                                                    onChange={value => {

                                                        setOrderItems(old =>
                                                            [...old!].map(e => {
                                                                if (e.id == item.id) return { ...e, deadline: value };
                                                                else return e;
                                                            })
                                                        );
                                                        setDisable(true);
                                                    }}
                                                    placeholder={item?.deadline}
                                                // value={item?.deadline}
                                                />
                                                :
                                                item?.serialNumberEquipment
                                            }
                                        </Table.Cell>
                                        <Table.Cell width={3}>
                                            <Select
                                                className="select-type"
                                                options={listAproved}
                                                disabled={[UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) || orderData?.statusId === 3 ||
                                                    orderData?.statusId !== 2}
                                                placeholder={
                                                    item?.adminApproval === true
                                                        ? t('common:status.Approved')
                                                        : item?.adminApproval === false
                                                            ? t('common:status.Rejected')
                                                            : t('common:status.WaitingCompany')
                                                }
                                                onChange={value => {

                                                    setOrderItems(old =>
                                                        [...old!].map(e => {
                                                            if (e.id == item.id && orderData.typeId === 4 && value) return { ...e, adminApproval: value, customerApproval: true }
                                                            else if (e.id == item.id) return { ...e, adminApproval: value };
                                                            else return e;
                                                        })
                                                    );
                                                    setDisable(true);
                                                }}
                                            />
                                        </Table.Cell>

                                        <Table.Cell width={3}>
                                            <Select
                                                disabled={
                                                    ([UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.ROLE!) ||
                                                        item?.customerApproval ||
                                                        item?.customerApproval == false) ||
                                                    orderData?.statusId === 2
                                                }
                                                className="select-type"
                                                placeholder={
                                                    ((orderData?.typeId === 3) || (orderData?.typeId === 4) || (item.customerApproval === true))
                                                        ? t('common:status.Approved')
                                                        : item?.customerApproval === false
                                                            ? t('common:status.Rejected')
                                                            : t('common:status.WaitingClient')
                                                }
                                                onChange={value => {

                                                    setOrderItems(old =>
                                                        [...old!].map(e => {
                                                            if (e.id == item.id) return { ...e, customerApproval: value };
                                                            else return e;
                                                        })
                                                    );
                                                    setDisable(true);
                                                }}
                                                options={listAproved}
                                            // disabled
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{showResultBadge(orderItems, item.id)}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                        <Card.Content className="buttonsSearch">
                            <Button
                                loading={loading}
                                type="primary"
                                //disabled={[UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) || orderData?.statusId == 3}
                                disabled={(orderData.budget == null && orderData.typeId == 2) || disable == false}
                                icon="slidercontrols-plus"
                                onClick={() => process()}
                                content={t('spareparts:productinfots.order_data.ProcessOrder')}
                                iconPosition="right"
                            />
                        </Card.Content>


                    </div>
                </div>
                <br></br>
            </div>
        </>
    );
};
export default Price;
