import { Chat } from 'components';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { ChatTable, RmaChatTable } from 'models/repaircenter/detailsRma';
import { UserRole } from 'models/user/sso';
import { useEffect, useState } from 'react';
import { REPAIR_CENTER } from 'resources';

type RepairCenterChatType = {
    rmaId?: number;
    company?: any
};

const RepairCenterChat: React.FC<RepairCenterChatType> = ({ rmaId, company }: RepairCenterChatType) => {
    const [enable, setEnable] = useState<boolean>(true);
    const [messages, loadMessages, loading, updateMessages] = useFetch<{ rmaId: number; roleId: number }, ChatTable[]>({
        url: REPAIR_CENTER.getMessages
    });
    const { user } = useUser();

    const [, addMessage] = useFetch<RmaChatTable, ChatTable>({
        url: REPAIR_CENTER.chatAddMessage,
        method: 'POST'
    });
    useEffect(() => {
        if (rmaId != undefined) loadMessages({ rmaId: rmaId, roleId: parseInt(user?.ROLE!) });
    }, [rmaId]);
    return (
        <Chat
            loading={loading}
            onSend={async (message, enabled) => {
                await addMessage(
                    {
                        message,
                        rmaId: rmaId!,
                        userName: user?.NAME! ?? '',
                        itWasReadByAdmin: [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user?.ROLE!) ? true : false,
                        itWasReadByCustomer: [UserRole.CLIENT, UserRole.CLIENTADMIN].includes(user?.ROLE!) ? true : false,
                        ssoId: user?.SSOID!,
                        companyId: user?.COMPANY!,
                        roleId: parseInt(user?.ROLE!),
                        enable: enabled ?? true
                    },
                    current => {
                        if (!!current.data) {
                            updateMessages(old => [...old, current.data as ChatTable]);
                        }
                    }
                );
            }}
            messages={messages?.data ?? []}
            companies={company}
        />
    );
};

export default RepairCenterChat;
