import { Accordion, Button, Checkbox, Divider, Icon, Input, List, Modal, Select, Table } from '@scuf/common';
import { LabelDescription } from 'components';
import { RoleComponent } from 'components/roleComponent';
import { formatDate, formatDateToUTCMinus3, handleYesOrNo } from 'helpers';
import useFetch from 'helpers/hooks/useFetch';
import useUser from 'helpers/hooks/useUser';
import { EditableData, Observation, ReportItem, Report } from 'models/maintenanceReport/allMaintenances';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAINTENANCES_REPORTS, notificationAtom } from 'resources';
import { statusReport } from 'models/maintenanceReport/type';
import { useSetRecoilState } from 'recoil';
import ModalFileMaintenanceReport from './modalFile/modalFileMaintenanceReport';
import { downloadFile } from 'resources/api/MaintenancesReport_api/fileMaintenance';

const modalityMap: { [key: number]: string } = {
    1: 'CONTRATO',
    2: 'GARANTIA',
    3: 'AVULSO',
    4: 'VISITA'
};

const statusMap: { [key: number]: string } = {
    1: 'AGUARDANDO APROVAÇÃO',
    2: 'REPROVADO',
    3: 'APROVADO',
    4: 'NF INSERIDA'
};

const VISITA = 4;

const isQA = process.env.NODE_ENV !== 'production';

const MaintenanceReportDetails: React.FC = () => {


    const { t } = useTranslation(['maintenancereport', 'common', 'spareparts']);
    const { state } = useLocation();
    const { actualReport } = state;
    const { user } = useUser();
    const navigate = useNavigate();
    let counter = 1;

    const [changedData, setChangedData] = useState<EditableData>({
        ObservationClient: '',
        ObservationAdmin: '',
        ApprovalStatusId: actualReport.ApprovalStatusId,
        Value: actualReport.Value,
        RefundSent: actualReport.RefundSent,
        SentToFinanceDepartment: actualReport.SentToFinanceDepartment,
        NfApproved: actualReport.NfApproved
    });

    const [selectedReport, setSelectedReport] = useState<any>(actualReport);
    const [observations, setObservations] = useState<Observation[]>(selectedReport.Observations);
    const [openModalUpload, setOpenModalUpload] = useState<boolean>(false);
    const [showModalDeleteFile, setShowModalDeleteFile] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const handleSuccess = (message: string) => {
        setNotification({ type: 'success', message: 'Report' });
    };
    const handleError = (message: string) => {
        setNotification({ type: 'information', message: 'NoData' });
    };

    const [_, updateReport, loading] = useFetch<Report, any>({
        url: MAINTENANCES_REPORTS.updateReport,
        method: 'POST',
        redirectOnError: false
    });

    const [, postUploadReportFile, loadingFile] = useFetch<any, any>({
        
        url: MAINTENANCES_REPORTS.uploadFile?.replace('{ReportId}', selectedReport?.Id?.toString()),
        method: 'POST',
        options: {
            headers: {
                'Content-Type': 'application/pdf'
            }
        },
        redirectOnError: true
    });

    
    const [, sendEmail, loadingEmail] = useFetch<any, any>({
        url: MAINTENANCES_REPORTS.sendEmailMaintenanceReport,
        method: 'POST',
        onError: 'Send',
        onSuccess: 'Send'
    });

    const [, deleteReportFile, loadingDelete] = useFetch<any, any>({
        url: MAINTENANCES_REPORTS.deleteFile,
        method: 'POST',
        options: {
            headers: {
                'Content-Type': 'application/pdf'
            }
        },
        redirectOnError: true
    });

    const setNotification = useSetRecoilState(notificationAtom);

    const calculateNumberOfDays = () => {
        const assistanceStartDate = new Date(selectedReport.AssistanceStartDate);
        const assistanceEndDate = new Date(selectedReport.AssistanceEndDate);
        const timeDifferenceInMilliseconds = assistanceEndDate.getTime() - assistanceStartDate.getTime();
        return Math.ceil(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
    };

    const isAbleToSendNF = () => {
        return (
            modalityMap[selectedReport?.ModalityId] === 'GARANTIA' &&
            statusMap[selectedReport?.ApprovalStatusId] === 'APROVADO' &&
            selectedReport?.NfFileName === null
        );
    };

    const handleSaveChanges = async (): Promise<void> => {
        const body = {
            ...selectedReport,
            Value: parseFloat(changedData.Value ?? '0'),
            ApprovalStatusId: changedData.ApprovalStatusId,
            RefundSent: changedData.RefundSent,
            SentToFinanceDepartment: changedData.SentToFinanceDepartment,
            NfApproved: changedData.NfApproved,
            Observations: observations
        };

        try {
            await updateReport(body);
            if (selectedFile) {
                
                await uploadFileReport(selectedFile);
            }
            await handleSendEmail();
            setNotification(old => ({ ...old, type: 'success', message: 'Update' }));
            navigate('/maintenance-report');
        } catch {
            setNotification(old => ({ ...old, message: 'FailedToEdit' }));
        }
    };

    const handleSendEmail = async () => {
        const isToSendEmailStatus = changedData.ApprovalStatusId !== selectedReport.ApprovalStatusId;
        const isToSendEmailNF = selectedFile;
        let subject;
        let body;
        let to;
        const subjectPrefix = isQA ? 'QA: ' : '';

        if (isToSendEmailStatus && changedData.ApprovalStatusId) {
            subject = `${subjectPrefix} RELATÓRIO DE MANUTENÇÃO - ALTERAÇÃO DE STATUS`;
            body = {
                number: selectedReport?.Id.toString(),
                type: modalityMap[selectedReport?.ModalityId],
                date: formatDate(selectedReport?.CreatedAt).toString(),
                dateNow: formatDate(new Date()).toString(),
                status: statusMap[changedData.ApprovalStatusId]
            };
            to = [
                user?.EMAIL,
                process.env.REACT_APP_SPARE_PARTS_EMAIL?.toString(),
                process.env.REACT_APP_MAINTENANCE_REPORTS_EMAIL?.toString()
            ];
        }

        if (isToSendEmailNF) {
            subject = `${subjectPrefix} RELATÓRIO DE MANUTENÇÃO - UPLOAD DE NOTA FISCAL`;
            body = {
                number: selectedReport?.Id.toString(),
                type: modalityMap[selectedReport?.ModalityId],
                date: formatDate(selectedReport?.CreatedAt).toString(),
                dateNow: formatDate(new Date()).toString(),
                status: statusMap[4]
            };
            to = [
                process.env.REACT_APP_SPARE_PARTS_EMAIL?.toString(),
                process.env.REACT_APP_MAINTENANCE_REPORTS_EMAIL?.toString()
            ];
        }

        if (isToSendEmailStatus || isToSendEmailNF) {
            await sendEmail({
                Body: body,
                Subject: subject,
                To: to
            });
        }
    };

    const handleCancelChanges = () => {
        navigate('/maintenance-report');
    };

    const handleChange = (field: string, value: string | number | null | boolean): void => {
        setChangedData(old => ({ ...old, [field]: value }));
    };

    const handleAddObservation = (role: string) => {
        const newObservation = {
            User: user?.NAME,
            UserRole: parseInt(user?.ROLE ?? ''),
            Observation: role === 'Admin' ? changedData.ObservationAdmin : changedData.ObservationClient,
            CreatedAt: new Date()
        };
        setChangedData(prevState => ({
            ...prevState,
            ObservationAdmin: role === 'Admin' ? '' : prevState.ObservationAdmin,
            ObservationClient: role !== 'Admin' ? '' : prevState.ObservationClient
        }));
        setObservations(prevObservations => [...prevObservations, newObservation]);
    };

    const uploadFileReport = async (arquivo: any) => {
        const formData = new FormData();
        formData.append('file', arquivo);

        if (arquivo != undefined) {
            try {
                await postUploadReportFile(formData);
            } catch {
                setNotification(old => ({ ...old, message: 'FailedToUpload' }));
            }
        }
    };

    const getLabel = (input: boolean) => {
        if (input === true) {
            return t('common:actions.Yes');
        }
        return t('common:actions.No');
    };

    const handleDeleteFile = () => {
        deleteReportFile({ fileName: selectedReport?.NfFileName }, resp => {
            setShowModalDeleteFile(false);
            handleChange('NfApproved', null);
            setSelectedReport({ ...selectedReport, NfFileName: resp.data?.NfFileName });
        });
    };

    //-----------------------RENDER------------------------------------//
    const renderDefaultOptions = () => {
        return (
            <div className="container-default-options">
                <h2>
                    {t('common:translation.Report')} #{selectedReport?.Id}
                </h2>
                <div className="grid-view">
                    <LabelDescription title={t('ID')} value={selectedReport?.Id} />
                    <LabelDescription title={t('common:translation.Partner')} value={selectedReport?.CompanyName} />
                    <LabelDescription title={t('common:translation.RequestingUser')} value={selectedReport?.UserRequesting} />
                    <LabelDescription
                        title={t('maintenancereport:translation.RegistrationDate')}
                        value={formatDate(selectedReport?.CreatedAt)}
                    />
                    <LabelDescription title={t('common:translation.ServiceOrderNumber')} value={selectedReport?.OsNumber} />
                    <LabelDescription title={t('common:translation.Product')} value={selectedReport?.ProductName} />
                    <LabelDescription title={t('common:translation.PartNumberDoEquipamento')} value={selectedReport?.PNEquip} />
                    <LabelDescription title={t('common:translation.SerialNumber')} value={selectedReport?.SerialNumber} />
                    <LabelDescription
                        title={t('common:translation.Modality')}
                        value={modalityMap[selectedReport?.ModalityId] || 'DESCONHECIDO'}
                    />
                    <LabelDescription
                        title={t('common:translation.Value')}
                        value={selectedReport?.Value ? parseInt(selectedReport?.Value.toFixed(2)) : '-'}
                    />
                    <LabelDescription title={t('common:translation.Code')} value={selectedReport.Code ?? '-'} />
                    <LabelDescription
                        title={t('maintenancereport:translation.RefundAmount')}
                        value={selectedReport?.RefundValue ?? '-'}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.MaintenanceStatus')}
                        value={statusMap[selectedReport?.ApprovalStatusId]}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.FinalCustomer')}
                        value={selectedReport?.CustomerName}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.EndCustomerEmail')}
                        value={selectedReport?.CustomerEmail}
                    />

                    <LabelDescription
                        title={t('maintenancereport:translation.EndCustomerPhone')}
                        value={selectedReport?.CustomerPhone}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.ProductFailure')}
                        value={selectedReport?.FailureDescription}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.RepairActions')}
                        value={selectedReport?.RepairActions}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.TimeSpentOnRepair')}
                        value={selectedReport?.TimeSpent}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.LastRepairDate')}
                        value={formatDate(selectedReport?.LastDayOfRepair)}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.AssistanceStartDate')}
                        value={formatDate(selectedReport?.AssistanceStartDate)}
                    />
                    <LabelDescription
                        title={t('maintenancereport:translation.AssistanceEndDate')}
                        value={formatDate(selectedReport?.AssistanceEndDate)}
                    />
                    <LabelDescription title={t('maintenancereport:translation.NumberOfDays')} value={calculateNumberOfDays()} />
                    <LabelDescription title={t('common:translation.Sent')} value={getLabel(selectedReport?.RefundSent)} />
                    <LabelDescription
                        title={t('maintenancereport:translation.SentToFinance')}
                        value={getLabel(selectedReport?.SentToFinanceDepartment)}
                    />
                    {selectedReport.NfFileName && (
                        <LabelDescription title={t('common:tabs.Invoicing')} value>
                            <Button
                                type="link"
                                loading={loadingFile}
                                onClick={async () => {
                                    await downloadFile(
                                        selectedReport?.NfFileName,
                                        `NF_Report_${selectedReport?.Id}.pdf`,
                                        handleError,
                                        handleSuccess
                                    );
                                }}
                            >
                                {`NF_Report_${selectedReport?.Id}.pdf`}
                            </Button>
                        </LabelDescription>
                    )}
                </div>
                {isAbleToSendNF() && (
                    <div style={{ padding: 14, display: 'flex', gap: '21%' }}>
                        <LabelDescription title={t('spareparts:productinfots.UploadPurchaseInvoice')} value>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: 10 }}>
                                <Button
                                    className="button-upload"
                                    type="secondary"
                                    content={t('common:actions.AddFile')}
                                    iconPosition="right"
                                    onClick={() => setOpenModalUpload(true)}
                                />
                                {selectedFile && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1%', gap: '10px' }}>
                                        <span>{selectedFile.name}</span>
                                        <Icon
                                            className="clickable"
                                            root="common"
                                            name="delete"
                                            size="small"
                                            loading={true}
                                            onClick={() => setSelectedFile(null)}
                                        />
                                    </div>
                                )}
                            </div>
                        </LabelDescription>
                    </div>
                )}
                <div style={{ padding: 14 }}>
                    <LabelDescription title={t('maintenancereport:translation.Observations')} value>
                        <List bulleted style={{ paddingTop: '10px' }}>
                            {observations.map((item: Observation) => (
                                <List.Content key={item.Id}>
                                    {item.Id ? (
                                        <>
                                            <b>
                                                {item.User} ({formatDateToUTCMinus3(item.CreatedAt?.toString())})
                                            </b>
                                            <span>: {item.Observation}</span>
                                        </>
                                    ) : (
                                        <div style={{ color: 'gray' }}>
                                            <b>
                                                {item.User} ({formatDate(item.CreatedAt)}){' '}
                                            </b>
                                            - {t('maintenancereport:translation.SaveObservationMessage')} :<br></br>{' '}
                                            {item.Observation}
                                        </div>
                                    )}
                                </List.Content>
                            ))}
                        </List>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Input
                                className="form3Columns"
                                size="small"
                                placeholder={t('maintenancereport:translation.AddObservations')}
                                type="text"
                                icon={<Icon name="edit" root="common" size="small" />}
                                value={changedData?.ObservationClient}
                                onChange={value => {
                                    handleChange('ObservationClient', value);
                                }}
                                max={500}
                            />
                            <Button type="secondary" onClick={() => handleAddObservation('Client')}>
                                {t('common:actions.Send')}
                            </Button>
                        </div>
                    </LabelDescription>
                </div>
            </div>
        );
    };

    const renderTableDefault = () => {
        return (
            <div className="container-table-items-default">
                <LabelDescription title={t('maintenancereport:translation.ItensUsedInRepair')}></LabelDescription>
                <Table>
                    <Table.Header className="Tabelas">
                        <Table.HeaderCell>{t('ID')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('PN')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.Date')}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body className="Tabelas">
                        {selectedReport?.ReportItems?.map((item: ReportItem) => (
                            <Table.Row className="Tabelas" key={item.PartNumber}>
                                <Table.Cell>{counter++}</Table.Cell>
                                <Table.Cell>{item.PartNumber}</Table.Cell>
                                <Table.Cell>{item.Description}</Table.Cell>
                                <Table.Cell>{formatDate(item.CreatedAt)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    const renderTableVisit = () => {
        return (
            <div className="container-table-items-visit">
                <LabelDescription title={t('common:translation.Products')}></LabelDescription>
                <Table>
                    <Table.Header className="Tabelas">
                        <Table.HeaderCell>{t('ID')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.Product')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.SerialNumber')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.ProblemDescription')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('common:translation.Date')}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body className="Tabelas">
                        {selectedReport?.ReportItems?.map((item: ReportItem) => (
                            <Table.Row className="Tabelas" key={item.PartNumber}>
                                <Table.Cell>{counter++}</Table.Cell>
                                <Table.Cell>{item.Product}</Table.Cell>
                                <Table.Cell>{item.SerialNumber}</Table.Cell>
                                <Table.Cell>{item.ProblemDescription}</Table.Cell>
                                <Table.Cell>{formatDate(item.CreatedAt)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    const renderAdminOptions = () => {
        return (
            <div className="container-admin">
                <div className="admin-column-1">
                    <LabelDescription title={t('common:translation.Sent')}>
                        <Checkbox
                            label={handleYesOrNo(changedData.RefundSent)}
                            checked={changedData.RefundSent}
                            toggle={true}
                            onChange={(value: boolean) => handleChange('RefundSent', value)}
                        />
                    </LabelDescription>
                    <LabelDescription title={t('maintenancereport:translation.SentToFinance')}>
                        <Checkbox
                            label={handleYesOrNo(changedData.SentToFinanceDepartment)}
                            checked={changedData.SentToFinanceDepartment}
                            toggle={true}
                            onChange={(value: boolean) => handleChange('SentToFinanceDepartment', value)}
                        />
                    </LabelDescription>
                    <LabelDescription title={t('maintenancereport:translation.MaintenanceStatus')}>
                        <Select
                            size="large"
                            indicator={'required'}
                            placeholder={statusReport.find((e: any) => e.value === changedData.ApprovalStatusId)?.text}
                            options={statusReport}
                            onChange={e => {
                                handleChange('ApprovalStatusId', e);
                            }}
                            defaultValue={statusReport.findIndex((e: any) => e.value === changedData.ApprovalStatusId)}
                            value={changedData.ApprovalStatusId ?? null}
                        />
                    </LabelDescription>
                    <LabelDescription title={t('common:translation.Value')} value>
                        <Input
                            placeholder={t('common:translation.Value')}
                            icon={<Icon name="edit" root="common" size="small" />}
                            type="number"
                            onChange={value => handleChange('Value', parseFloat(value))}
                            value={changedData.Value}
                        />
                    </LabelDescription>
                </div>
                <div className="admin-column-2">
                    {selectedReport?.NfFileName && (
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.File')} />
                                <Table.HeaderCell content={t('common:translation.Type')} />
                                <Table.HeaderCell content={t('common:translation.Download')} />
                                <Table.HeaderCell content={t('common:actions.Delete')} />
                                <Table.HeaderCell content={t('common:translation.Approve')} />
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{`NF_Report_${selectedReport?.Id}`} </Table.Cell>
                                    <Table.Cell>
                                        {modalityMap[selectedReport?.ModalityId] === 'GARANTIA'
                                            ? t('common:tabs.Invoicing')
                                            : t('common:translation.Others')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            className="clickable"
                                            root="common"
                                            name="file-download"
                                            size="medium"
                                            loading={loadingFile}
                                            onClick={async () => {
                                                await downloadFile(
                                                    selectedReport?.NfFileName,
                                                    `NF_Report_${selectedReport?.Id}.pdf`,
                                                    handleSuccess,
                                                    handleError
                                                );
                                            }}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            className="clickable"
                                            root="common"
                                            name="delete"
                                            size="medium"
                                            loading={true}
                                            onClick={() => setShowModalDeleteFile(true)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox
                                            label={handleYesOrNo(changedData.NfApproved)}
                                            checked={changedData.NfApproved}
                                            toggle={true}
                                            onChange={(value: boolean) => handleChange('NfApproved', value)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    )}
                    <LabelDescription title={t('maintenancereport:translation.ObservationsAdmin')} value>
                        <div className="input-and-button">
                            <Input
                                className="input-obs"
                                size="small"
                                placeholder={t('maintenancereport:translation.AddObservations')}
                                type="text"
                                icon={<Icon name="edit" root="common" size="small" />}
                                value={changedData?.ObservationAdmin}
                                onChange={value => {
                                    handleChange('ObservationAdmin', value);
                                }}
                                max={500}
                            />
                            <Button type="secondary" onClick={() => handleAddObservation('Admin')}>
                                {t('common:actions.Send')}
                            </Button>
                        </div>
                    </LabelDescription>
                </div>
            </div>
        );
    };

    const renderModalDelete = () => {
        return (
            <Modal
                className="popup-theme-wrap"
                onClose={() => setShowModalDeleteFile(false)}
                open={showModalDeleteFile}
                size="small"
            >
                <Modal.Content>{t('maintenancereport:translation.DeleteInvoiceMessage')}</Modal.Content>
                <Modal.Footer>
                    <Button
                        type="secondary"
                        size="small"
                        content={t('common:actions.Cancel')}
                        onClick={() => setShowModalDeleteFile(false)}
                    />
                    <Button
                        type="primary"
                        size="small"
                        content={t('common:actions.Delete')}
                        loading={loadingDelete}
                        onClick={() => handleDeleteFile()}
                    />
                </Modal.Footer>
            </Modal>
        );
    };
    return (
        <div className="container">
            {renderDefaultOptions()}
            <Divider></Divider>
            <div style={{ padding: '14px 26px' }}>
                {selectedReport?.ModalityId !== VISITA ? renderTableDefault() : renderTableVisit()}
            </div>
            <Divider></Divider>
            <RoleComponent roles={['SUPERADMIN']}>
                <div style={{ padding: '30px' }}>
                    <Accordion>
                        <Accordion.Content title={t('common:tabs.Administrative')}>{renderAdminOptions()}</Accordion.Content>
                    </Accordion>
                </div>
            </RoleComponent>
            <div className="buttonsSearch">
                <Button
                    content={t('common:actions.Cancel')}
                    disabled={loading}
                    iconPosition="right"
                    loading={loading}
                    type="secondary"
                    onClick={handleCancelChanges}
                ></Button>
                <Button
                    content={t('common:actions.Save')}
                    disabled={loading}
                    iconPosition="right"
                    loading={loading || loadingFile || loadingEmail}
                    onClick={handleSaveChanges}
                    icon={<Icon className="iconArrow" name="slidercontrols-plus" root="common" />}
                ></Button>
            </div>
            <ModalFileMaintenanceReport
                open={openModalUpload}
                setSelectedFile={setSelectedFile}
                closeModal={() => setOpenModalUpload(false)}
            />
            {renderModalDelete()}
        </div>
    );
};
export default MaintenanceReportDetails;
